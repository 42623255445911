import {newChildOfGuardianUpdate, newGuardianOfChildUpdate} from "../models/User";
import {partitionChangesById} from "./partitionChanges";
import {ChildWithGuardian} from "../api/chessMasterApi";

export const createChildOfGuardianUpdates = (oldChildren: ChildWithGuardian[], children: ChildWithGuardian[]) => {
    const guardianChildChanges = partitionChangesById(
        oldChildren.map((cwg) => cwg.child),
        children.map((cwg) => cwg.child)
    );

    return [
        ...guardianChildChanges.added.map((child) => newChildOfGuardianUpdate(child.id)),
        ...guardianChildChanges.removed.map((child) => newChildOfGuardianUpdate(child.id, {_delete: true}))
    ]
};

export const createGuardianOfChildUpdates = (oldGuardians: ChildWithGuardian[], guardians: ChildWithGuardian[]) => {
    const guardianChildChanges = partitionChangesById(
        oldGuardians.map((cwg) => cwg.guardian),
        guardians.map((cwg) => cwg.guardian)
    );

    return [
        ...guardianChildChanges.added.map((guardian) => newGuardianOfChildUpdate(guardian.id)),
        ...guardianChildChanges.removed.map((guardian) => newGuardianOfChildUpdate(guardian.id, {_delete: true}))
    ]
};
