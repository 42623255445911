import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import FinanceUserPayment from "./FinanceUserPayment";
import {Payment} from "../../models/Payment";

interface Props {
    payment?: Payment
    onClose: () => void
}

export const FinanceUserPaymentModal: React.FC<Props> = ({payment, onClose}) => {
    return (
        <Modal show
               size="xl"
               onHide={onClose}
               dialogClassName="w-75">
            <Modal.Header closeButton>
                <Modal.Title>
                    Manual payment
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FinanceUserPayment payment={payment}
                                    onClose={onClose}/>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default FinanceUserPaymentModal;