import React from "react";

import Button from "react-bootstrap/Button";

import {selectConstants} from "../features/constants/constantsSlice";
import {isPlayMethodOnline, Tournament} from "../models/Tournament";
import {User} from "../models/User";
import {Match} from "../models/Match";
import {formatFullName} from "../util/formatFullName";
import {useAppSelector} from "../store";

interface Props {
    tournament: Tournament
    match: Match
    opponentId: User["id"]
}

const LinkToActiveMatch: React.FC<Props> = ({tournament, match, opponentId}) => {
    const {lichess_url} = useAppSelector(selectConstants);
    const opponent = tournament.ref_user[opponentId];
    const message = `You are playing ${formatFullName(opponent)} (round ${tournament.cur_round} of ${tournament.num_rounds})`;

    if (isPlayMethodOnline(tournament.play_method) && lichess_url && match.lichess_id) {
        const lichessUrl = `${lichess_url}/${match.lichess_id}`;

        return (
            <a href={lichessUrl} target="_blank" rel="noopener noreferrer">
                <Button variant="warning" size="sm" className="text-white">
                    {message}
                </Button>
            </a>
        );
    } else {
        return (
            <>{message}</>
        );
    }
};

export default LinkToActiveMatch;
