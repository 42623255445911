import React, {useCallback, useContext, useEffect} from "react";
import {useSearchUserInfosQuery} from "../../api/chessMasterApi";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import UsernameAndGroups from "../UsernameAndGroups";
import {Group} from "../../models/Group";
import {UserInfo} from "../../models/User";
import {PlayerFilterErrorContext} from "../PlayerFilterInput";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";

export interface Props {
    query: string
    groupId: Group["id"]
    setSelected?: (userInfo: UserInfo) => void
}

export const SingleUserPickerList: React.FC<Props> = ({query, groupId, setSelected}) => {
    const {setUserSearchError} = useContext(PlayerFilterErrorContext);
    const {data: users, isFetching, error} = useSearchUserInfosQuery(
        {query, ...(groupId >= 0) ? {group_id: groupId} : {}},
        {skip: query.length < 3 && groupId < 0}
    );
    const [selectedUser, setSelectedUser] = React.useState<UserInfo | undefined>(undefined);
    const updateSelectedUser = useCallback((userInfo: UserInfo) => {
        setSelectedUser(userInfo);
        if (setSelected) {
            setSelected(userInfo);
        }
    }, [setSelected]);

    useEffect(
        () => setUserSearchError?.((error) ? formatErrorMessage(error) : null),
        [error, setUserSearchError]
    );

    if (isFetching) {
        return (
            <Spinner className="text-center" animation="border"/>
        );
    }
    return (
        <ListGroup>
            {(users && users.length > 0) ? (
                users?.map((user) => (
                    <ListGroup.Item key={user.id}
                                    className={(selectedUser?.id === user.id) ? "bg-primary text-white" : ""}
                                    onClick={() => updateSelectedUser(user)}>
                        <UsernameAndGroups userInfo={user}/>
                    </ListGroup.Item>
                ))
            ) : (
                <ListGroup.Item>
                    <p className="text-center text-muted">(No users found)</p>
                </ListGroup.Item>
            )}
        </ListGroup>
    );
};