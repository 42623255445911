import React from "react";

import {formatFullName} from "../../util/formatFullName";
import {AuditRowWithAudit} from "./AuditList";
import {UserMinSchema} from "../../models/User";

interface Props {
    auditRow: AuditRowWithAudit
}

const nameAndUserName = (user: UserMinSchema) => (
    <span>
        {formatFullName(user)} <small className="text-muted">({user.username})</small>
    </span>
);

const AuditAuthUserDisplay: React.FC<Props> = ({auditRow}) => {
    const {ref_user} = auditRow.audit; 
    const realUser = (auditRow.real_user_id) ? ref_user[auditRow.real_user_id] : undefined;
    const authUser = (auditRow.auth_user_id) ? ref_user[auditRow.auth_user_id] : undefined;

    return (
        <div className="w-100">
            {(authUser) && (
                <span>
                    {(realUser && realUser.id !== authUser.id) ? (
                        <>
                            <strong>{nameAndUserName(realUser)} as</strong> {nameAndUserName(authUser)}
                        </>
                    ) : (
                        nameAndUserName(authUser)
                    )}
                </span>
            )}
        </div>
    );
};

export default AuditAuthUserDisplay;
