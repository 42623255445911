import {useSearchParams} from "react-router-dom";

const makeSetterName = (name: string) => {
    return `set${name.charAt(0).toUpperCase() + name.slice(1)}`;
};

export interface ParamOption {
    value: string
    setter: (v: string) => void
    isEmpty?: (v: string) => boolean
}

/*
 * This hook links up values in localstorage with values that come in as parameters on a
 * url. If the url has setting for the parameter options, then those will take precedence
 * otherwise it will use the values from localstorage. When the values are set, both the
 * url parameters need to be updated as well as the localstorage version.
 *
 * If the isEmpty function is included, then when that returns true when called on the
 * value being set, it will be omitted from the url parameters. This lets us avoid url
 * parameters like "?query=".
 */

export function useLocalStorageSearchParams(values: Record<string, ParamOption>) {
    // const initValues = Object.fromEntries(Object.entries(values).map(([k, v]) => [k, v.value]));
    const initValues = Object.entries(values).reduce((obj, item) => Object.assign(obj, {[item[0]]: item[1].value}), {});
    const [searchParams, setSearchParams] = useSearchParams(initValues);
    const setValue = (key: string) => (value: string) => {
        const v = values[key];
        v.setter(value);
        setSearchParams((params) => {
            if (v.isEmpty && v.isEmpty(value)) {
                params.delete(key);
            } else {
                params.set(key, value);
            }
            return params;
        });
    };

     const v = Object.keys(values).map((k) => ({[k]: searchParams.get(k)})).reduce((obj, item) => Object.assign(obj, item), {});
     const setters = Object.keys(values).map((k) => ({[makeSetterName(k)]: setValue(k)})).reduce((obj, item) => Object.assign(obj, item), {});

    return [
        v as Record<keyof typeof values, string>,
        setters as Record<keyof typeof values, (v: string) => void>
    ] as const;
}

