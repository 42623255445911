import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";

// Bootstrap
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import {
    selectLogin,
    setIsRegistering,
    setIsRequestingPasswordReset,
    setLoginCredentials
} from "./loginSlice";
import styles from './Login.module.css';
import * as api from "../../Api";
import {formControlFeedback} from "../../util/formControl";
import {useLoginMutation} from "../../api/chessMasterApi";
import {useAppSelector} from "../../store";

const loginSchema = yup.object().shape({
    username: yup.string().required().label("Username"),
    password: yup.string().required().label("Password"),
});

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const [login] = useLoginMutation();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const {isLoginPending} = useAppSelector(selectLogin);
    const onSubmit = ({username, password}: api.LoginRequest) => {
        login({username, password})
            .unwrap()
            .then((credentials) => dispatch(setLoginCredentials(credentials)))
            .catch((error) => setErrorMessage(error.data.message))
    };
    const defaultValues: api.LoginRequest = {
        username: "",
        password: ""
    };
    const {register, handleSubmit, formState: {errors}} = useForm<api.LoginRequest>({
        defaultValues,
        resolver: yupResolver(loginSchema)
    });

    const handleKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    // Note that the <form> cannot be outside the <Modal> or it don't work
    return (
        <Modal show={true}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop="static">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Pawnbroker login
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="login-form">
                        <Form.Group as={Row}
                                    controlId="loginUsername"
                                    className="mb-2">
                            <Form.Label column md={2}
                                        className={styles['username-title']}>Username</Form.Label>
                            <Col md={10}>
                                <Form.Control {...register("username")}
                                              placeholder="Name"
                                              autoComplete="username"
                                              onKeyDown={handleKeyPress}
                                              isInvalid={!!errors.username}
                                              autoCapitalize="off"/>
                                {formControlFeedback(errors.username)}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="loginPassword">
                            <Form.Label column md={2}
                                        className={styles['password-title']}>Password</Form.Label>
                            <Col md={10}>
                                <Form.Control {...register("password")}
                                              placeholder="Password"
                                              autoComplete="current-password"
                                              type="password"
                                              onKeyDown={handleKeyPress}
                                              isInvalid={!!errors.password}
                                              autoCapitalize="off"/>
                                {formControlFeedback(errors.password)}
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button variant="link"
                                onClick={() => dispatch(setIsRequestingPasswordReset(true))}>
                            <small>Forgot password?</small>
                        </Button>
                    </div>
                </Modal.Body>

                {(errorMessage) && (
                    <Modal.Body>
                        <div className="text-danger text-end">{errorMessage}</div>
                    </Modal.Body>
                )}

                <Modal.Footer>
                    <ButtonToolbar className="float-right">
                        <ButtonGroup className="me-1">
                            <Button onClick={() => dispatch(setIsRegistering(true))}
                                    variant="secondary"
                                    disabled={isLoginPending}>
                                Create new account
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button type="submit"
                                    variant="primary"
                                    disabled={isLoginPending}>
                                Login
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default Login;
