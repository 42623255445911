import React from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import UsernameAndTeam from "../../components/UsernameAndTeam";
import {Tournament} from "../../models/Tournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {nameSortFunc} from "../../util/nameSortFunc";
import {GroupMinSchema} from "../../models/Group";


const ActiveTournamentTeamPlayerList: React.FC<{tournament: Tournament, players: TournamentPlayer[]}> = ({tournament, players}) => {
    const columns: TableColumn<TournamentPlayer>[] = [{
        name: "Name",
        sortable: true,
        sortFunction: (a, b) => nameSortFunc(tournament.ref_user[a.user_id],
                                             tournament.ref_user[b.user_id]),
        cell: (row) => <UsernameAndTeam player={row} tournament={tournament}/>
    }, {
        name: "Score",
        selector: (row) => row.points,
        sortable: true,
        right: true
    }];

    return (
        <DataTable<TournamentPlayer> data={players}
                                     columns={columns}
                                     pagination/>
    );
};

interface Props {
    tournament: Tournament
    team: GroupMinSchema
    onClose: () => void
}

const ActiveTournamentTeamSummary: React.FC<Props> = ({tournament, team, onClose}) => {
    const players = tournament.players.filter((player) => player.team_id === team.id && player.participating);

    return (
        <Modal show={true}
               size="lg"
               onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Team summary for “{team.name}”
                    <div><small>Tournament “{tournament.name}”</small></div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ActiveTournamentTeamPlayerList tournament={tournament}
                                                players={players}/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose()}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveTournamentTeamSummary;
