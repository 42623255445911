import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from "react-hook-form";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

import {isUser, newUser, NewUser, newUserInfo, User} from "../../models/User";
import {setEditUserId} from './userTabSlice';
import {selectLogin} from "../login/loginSlice";
import UserEditRoles from "./UserEditRoles";
import UserEditDetails from "./UserEditDetails";
import UserEditGroups from "./UserEditGroups";
import {partitionChanges} from "../../util/partitionChanges";
import UserEditChildren from './UserEditChildren';
import {
    createChildOfGuardianUpdates,
    createGuardianOfChildUpdates
} from "../../util/createGuardianChildUpdates";
import {
    chessMasterApi,
    ChildrenForUserResponse,
    useChildrenForUserQuery,
    useGetUserQuery,
    useGroupsForUserQuery,
    useGuardiansForUserQuery,
    useUpdateChildrenForUserMutation,
    useUpdateGroupsForUserMutation,
    useUpdateGuardiansForUserMutation,
    useUpdateUserAddingRoleMutation,
    useUpdateUserMutation,
    useUpdateUserRemovingRoleMutation
} from "../../api/chessMasterApi";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import {setApiError} from "../api/ApiSlice";
import {phoneRegexp} from "../../util/validators";
import {emptyStringOr0ToNull, emptyStringToNull} from "../../util/yupTransforms";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";
import {UserGroup} from "../../models/UserGroup";
import {createUserGroupUpdates} from "../../util/createUserGroupUpdates";
import {selectConstantsNamedRoleInRoleIds} from "../constants/constantsSlice";
import UserEditGuardian from "./UserEditGuardian";
import {addToast, newToastError, newToastSuccess} from "../toast/toastSlice";
import {formatFullName} from "../../util/formatFullName";
import {useAppSelector} from "../../store";
import {PasswordForm} from "../../util/passwordSchema";

export const userSchema = yup.object().shape({
    username: yup.string().required().label("Username"),
    new_password: yup.string().nullable().label("Password"),
    email_address: yup.string().email().nullable().transform(emptyStringToNull).label("E-mail"),
    given_name: yup.string().label("Given name"),
    middle_name: yup.string().nullable().transform(emptyStringToNull).label("Middle name"),
    family_name: yup.string().required().label("Family name"),
    preferred_name: yup.string().nullable().transform(emptyStringToNull).label("Preferred name"),
    birthday: yup.string().nullable().label("Birthday"),
    school_year: yup.number().nullable().positive().max(30).transform(emptyStringOr0ToNull).label("School year"),
    phone_num: yup.string()
        .nullable()
        .matches(phoneRegexp, {message: "Invalid phone number", excludeEmptyString: true})
        .label("Phone number"),
    phone_num_note: yup.string().nullable().label('Phone note'),
    medical_note: yup.string().nullable().label('Phone note'),
    gender_id: yup.number().typeError("Please specify a gender").label("Gender")
});

const userFormSchema = yup.object().shape({
    user: userSchema
});

const UserEditDetailsCard: React.FC<{user: User | undefined}> = ({user}) => {
    return (
        <Card>
            <Card.Header>
                <Stack direction="horizontal">
                    <span>Details</span>
                    {(user?.disabled) && (
                        <Badge pill className="ms-auto" bg="danger">
                            Disabled
                        </Badge>
                    )}
                </Stack>
            </Card.Header>
            <Card.Body>
                <UserEditDetails user={user}/>
            </Card.Body>
        </Card>
    );
};

export interface UserForm {
    user: User | NewUser
    userGroups: UserGroup[]
    password: PasswordForm
}

interface UserEditProps {
    userId: User["id"]          // note userId < 0 means create new user
    onClose: (user: User | null) => void
}

const UserEdit: React.FC<UserEditProps> = ({userId, onClose}) => {
    const dispatch = useDispatch();
    const {privs, role_ids} = useAppSelector(selectLogin);
    const [updatedChildren, setUpdatedChildren] = useState<ChildrenForUserResponse>([]);
    const [updatedGuardians, setUpdatedGuardians] = useState<ChildrenForUserResponse>([]);
    const isGuardian = useAppSelector(selectConstantsNamedRoleInRoleIds("Adult/Parent", role_ids));
    const {data: children} = useChildrenForUserQuery({id: userId}, {skip: userId < 0});
    const {data: guardians} = useGuardiansForUserQuery({id: userId}, {skip: userId < 0});
    const {data: user} = useGetUserQuery(userId, {skip: userId < 0});
    const {data: userGroups} = useGroupsForUserQuery({userId}, {skip: userId < 0});
    const [updateUser, {isLoading: isUpdating}] = useUpdateUserMutation();
    const [updateGroupsForUser] = useUpdateGroupsForUserMutation();
    const [updateUserAddingRole] = useUpdateUserAddingRoleMutation();
    const [updateUserRemovingRole] = useUpdateUserRemovingRoleMutation();
    const [updateChildrenForUser] = useUpdateChildrenForUserMutation();
    const [updateGuardiansForUser] = useUpdateGuardiansForUserMutation();
    const formMethods = useForm<UserForm>({
        defaultValues: {user: newUser(), userGroups: []},
        resolver: yupResolver(userFormSchema)
    });
    const userAsUserInfo = useMemo(() => newUserInfo(user), [user]);
    const onSubmit = ({user: updatedUser, userGroups: updatedUserGroups, password}: UserForm) => {
        updatedUser.new_password = password.new_password;

        // user.groups_id & user.role_ids can be undefined when first creating a user and
        // they can't be entered yet
        const roleChanges = partitionChanges(user?.role_ids ?? [], (isUser(updatedUser)) ? updatedUser.role_ids : []);
        const groupUpdates = createUserGroupUpdates(userGroups ?? [], updatedUserGroups ?? []);
        const childrenUpdates = createChildOfGuardianUpdates(children ?? [], updatedChildren);
        const guardianUpdates = createGuardianOfChildUpdates(guardians ?? [], updatedGuardians);
        // if we're modifying a user, just send updated fields; for a new user, send them all
        const userPayload = (user) ? payloadChangesWithId(user, updatedUser) : updatedUser;

        updateUser({user: userPayload})
            .unwrap()
            .then((user) => {
                if (groupUpdates.length > 0) {
                    updateGroupsForUser({userId: user.id, groupUpdates: groupUpdates})
                        .catch((error) => {
                            dispatch(addToast(newToastError({
                                heading: "Error updating user",
                                body: `Error updating ${formatFullName(updatedUser)}\n updateGroupsForUser: ${error.data.message}`
                            })));
                        });
                }
                roleChanges.added.forEach((roleId: number) => updateUserAddingRole({userId: user.id, roleId}));
                roleChanges.removed.forEach((roleId: number) => updateUserRemovingRole({userId: user.id, roleId}));
                if (childrenUpdates.length > 0) {
                    updateChildrenForUser({userId: user.id, guardianUpdates: childrenUpdates})
                        .catch((error) => {
                            dispatch(setApiError(`updateChildrenForUser: ${error.data.message}`));
                            dispatch(addToast(newToastError({
                                heading: "Error updating user",
                                body: `Error updating ${formatFullName(updatedUser)}\nupdateChildrenForUser: ${error.data.message}`
                            })));
                        });
                }
                if (guardianUpdates.length > 0) {
                    updateGuardiansForUser({userId: user.id, guardianUpdates})
                        .catch((error) => {
                            dispatch(setApiError(`updateGuardiansForUser: ${error.data.message}`));
                            dispatch(addToast(newToastError({
                                heading: "Error updating user",
                                body: `Error updating ${formatFullName(updatedUser)}\nupdateGuardiansForUser: ${error.data.message}`
                            })));
                        });
                }
                // if we have just created a user, keep editing it otherwise close modal
                if (!user) {
                    dispatch(setEditUserId(user));
                } else {
                    onClose(user);
                }
            })
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "User updated",
                    body: `User ${formatFullName(updatedUser)} updated successfully`
                })));
            })
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch,"user");
            });
    };

    useEffect(() => {
        (user || userGroups) && formMethods.reset({user, userGroups})
    }, [user, formMethods, userGroups]);
    // save the children when the first come in
    useEffect(() => {
        (children) && setUpdatedChildren(children);
    }, [children]);
    useEffect(() => {
        (guardians) && setUpdatedGuardians(guardians);
    }, [guardians]);
    useEffect(() => {
        dispatch(chessMasterApi.util.invalidateTags(["ChildWithGuardian"]));
    }, [dispatch]);
    return (
        <Modal show={true}
               onHide={() => onClose(null)}
               backdrop="static"
               keyboard={!formMethods.formState.isDirty}
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Header>
                        <Modal.Title>Edit user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserEditDetailsCard user={user}/>

                        <Tabs defaultActiveKey="groups" className="mt-3">
                            <Tab eventKey="groups"
                                 title="Groups"
                                 disabled={!user}>
                                <div className="mt-3">
                                    {(user) ?
                                        <UserEditGroups user={user}/> :
                                        <span className="text-muted">
                                            Please save the user to update groups.
                                        </span>
                                    }
                                </div>
                            </Tab>
                            {(privs.admin) && (
                                <Tab eventKey="roles"
                                     title="Roles"
                                     disabled={!user}>
                                    <div className="mt-3">
                                        <UserEditRoles/>
                                    </div>
                                </Tab>
                            )}
                            {(isGuardian && user) && (
                                <Tab eventKey="children"
                                     title="Children"
                                     disabled={!user}>
                                    <div className="mt-3">
                                        <UserEditChildren guardian={userAsUserInfo}
                                                          children={updatedChildren}
                                                          setChildren={setUpdatedChildren}/>
                                    </div>
                                </Tab>
                            )}
                            {(privs.admin && user) && (
                                <Tab eventKey="guardians"
                                     title="Guardians"
                                     disabled={!user}>
                                    <div className="mt-3">
                                        <UserEditGuardian child={userAsUserInfo}
                                                          guardians={updatedGuardians}
                                                          setGuardians={setUpdatedGuardians}/>
                                    </div>
                                </Tab>
                            )}
                        </Tabs>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar>
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={() => onClose(null)}
                                        disabled={isUpdating}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary"
                                        disabled={isUpdating}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default UserEdit;