import {Middleware, MiddlewareAPI, AnyAction, Dispatch} from "redux";

import {Chess} from "@lubert/chess.ts";

import {AppDispatch, RootState} from "../store";
import {myMove, addMove, setBoardFen, serverMove, connected} from "../features/game/gameSlice";
import {socketEmit} from "./socketIO";

const game: Middleware = <S extends RootState>(store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => <A extends AnyAction>(action: A): A => {
    const dispatch: AppDispatch = store.dispatch;

    switch (action.type) {
    case myMove.type:
        console.log(`send move to server ${action.payload.from}-${action.payload.to}`);
        dispatch(socketEmit("move", action.payload));
        break;

    case serverMove.type:
        const chess = new Chess(store.getState().game.fen);
        const {from, to} = action.payload;
        const thisMove = chess.move({from, to});

        if (thisMove) {
            dispatch(addMove(thisMove));
            dispatch(setBoardFen(chess.fen()));
        } else {
            alert(`Illegal move from server: ${from} to ${to}!`);
        }
        break;

    case connected.type:
        const login = store.getState().login;
        // This logic is naive and wrong - it needs to send a list of "subscribed" games on "connect" AND join.
        dispatch(socketEmit("hello", {token: login.jwtToken, game_id: action.meta.gameId}));
        break;

    default:
        break;
    }
    return next(action);
};

export default game;