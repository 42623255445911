import React, {ChangeEvent, useCallback, useMemo} from "react";
import {TournamentStatusDescription} from "../models/TournamentStatus";
import {selectConstants} from "../features/constants/constantsSlice";
import {formControlOptionsFromStrings} from "../util/formControl";
import Form from "react-bootstrap/Form";
import {useAppSelector} from "../store";

interface Props {
    status: TournamentStatusDescription | ""
    onChangeStatus: (typeId: TournamentStatusDescription | "") => void;
}

const TournamentStatusSelect: React.FC<Props> = ({onChangeStatus, status}) => {
    const {tournament_status} = useAppSelector(selectConstants);
    const changeStatus = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChangeStatus(ev.target.value as TournamentStatusDescription);
    }, [onChangeStatus]);
    const allStatusOptions = useMemo(
        () => [
            <option key={-1} value="">(All status)</option>,
            ...formControlOptionsFromStrings(tournament_status.map((ts) => ts.description))
        ],
        [tournament_status]
    );

    return (
        <Form.Control as="select"
                      size="sm"
                      onChange={changeStatus}
                      value={status}>
            {allStatusOptions}
        </Form.Control>
    );
};

export default TournamentStatusSelect;