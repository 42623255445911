import React from "react";

interface Props {
    text: string | null
    className?: string
}

// take a string and return it as a string of <div>'s broken up by newlines so
// that the layout is more or less the same.

const DisplayAsTextBlock: React.FC<Props> = ({text, className}) => {
    return (
        <div className={className}>
            {
                text?.split('\n')
                    .map((line, index) => (
                        <div className="my-1" key={index}>{line}</div>
                    ))
            }
        </div>
    );
};

export default DisplayAsTextBlock;
