
// Parse a time duration like [hh:][mm:]ss into seconds. For use with yup transform().

export const parseDuration = (value: string | number, originalValue: string | number) => {
    const hhMmSsRE = "((\\d+):)?((\\d+):)?(\\d+)";
    if (typeof originalValue === "number") {
        return originalValue;
    }
    const match = originalValue?.match(hhMmSsRE);
    let secs = 0;

    if (match) {
        if (match[2]) {
            secs = Number(match[2])
        }
        if (match[4]) {
            secs = secs * 60 + Number(match[4]);
        }
        if (match[5]) {
            secs = secs * 60 + Number(match[5]);
        }
    } else {
        return NaN;
    }

    return secs;
};