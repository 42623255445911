import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";

import {Tournament} from "../../models/Tournament";
import {setActiveTournamentTeamSummary} from "./activeTournamentSlice";
import {GroupMinSchema} from "../../models/Group";

interface Props {
    team?: GroupMinSchema
    tournament: Tournament
}

const TeamName: React.FC<Props> = ({team, tournament}) => {
    const dispatch = useDispatch();

    if (!team) {
        return <span/>;
    }
    return (
        <Button variant="link"
                className="p-0 text-start"
                onClick={() => dispatch(setActiveTournamentTeamSummary({
                    team,
                    tournament
                }))}>
            {team.name}
        </Button>
    );
};

export default TeamName;