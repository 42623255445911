import React from "react";

import Button from "react-bootstrap/Button";

import {TournamentMinSchema} from "../models/Tournament";
import {addActiveTournament} from "../features/activeTab/activeTabSlice";
import {tournamentTabEventKeyFromTournament} from "../util/tabEventKey";
import {useAppDispatch} from "../store";
import {useNavigate} from "react-router-dom";

const TournamentName: React.FC<{tournament: TournamentMinSchema}> = ({tournament}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <Button variant="link"
                size="sm"
                className="p-0 text-start"
                onClick={() => {
                    dispatch(addActiveTournament(tournament));
                    navigate(`/${tournamentTabEventKeyFromTournament(tournament.key)}`);
                }}>
            {tournament.name}
        </Button>
    );
};

export default TournamentName;