import {formatISO} from "date-fns";
import {User} from "./User";
import {Tournament} from "./Tournament";

export type ByeMatchStatusDescription = "HalfPointBye" | "FullPointBye" | "ZeroPointBye"

export type MatchStatusDescription = "Pending" | "Playing" |
                                     "WhiteForfeit" | "BlackForfeit" | "DoubleForfeit" |
                                     "WhiteWin" | "BlackWin" | "Draw" |
                                     ByeMatchStatusDescription |
                                     "Delete"

export interface MatchStatus {
    id: number
    description: MatchStatusDescription
    bye: boolean,
    completed: boolean,
    forfeit: boolean,
    points: {
        white: number
        black: number
    }
}

export interface Match {
    id: number
    white_player_id: User["id"] | null
    black_player_id: User["id"] | null
    tournament_id: Tournament["id"]
    round_num: number
    board_num: number
    status: MatchStatusDescription
    create_time: string // ISO date
    start_time: string // ISO date
    end_time: string // ISO date
    lichess_id?: string
}

export const newMatch = (initFields: Partial<Match> = {}): Match => {
    const now = new Date();

    return ({
        id: 0,
        white_player_id: 0,
        black_player_id: 0,
        tournament_id: 0,
        board_num: 1,
        round_num: 0,
        status: "Pending",
        create_time: formatISO(now),
        start_time: formatISO(now),
        end_time: formatISO(now),

        ...initFields
    });
};

export const isMatchStatusComplete = (match: Match) => (
    !["Pending", "Playing"].includes(match.status)
);

export const isMatchStatusBye = (match: Match) => (
    ["HalfPointBye", "FullPointBye", "ZeroPointBye"].includes(match.status)
);

const matchStatusDescriptions: Record<MatchStatusDescription, {description: string, result: string}> = {
    "HalfPointBye": {description: "Half-point Bye", result: "½ - B"},
    "FullPointBye": {description: "Full-point Bye", result: "1 - B"},
    "ZeroPointBye": {description: "Zero-point Bye", result: "0 - B"},
    "Pending":      {description: "Pending", result: "–"},
    "Playing":      {description: "Playing", result: "–"},
    "WhiteForfeit": {description: "White Forfeit", result: "F - 1"},
    "BlackForfeit": {description: "Black Forfeit", result: "1 - F"},
    "DoubleForfeit":{description: "Double Forfeit", result: "F - F"},
    "WhiteWin":     {description: "White Win", result: "1 - 0"},
    "BlackWin":     {description: "Black Win", result: "1 - 1"},
    "Draw":         {description: "Draw", result: "½ - ½"},
    "Delete":       {description: "Delete", result: "❌"}
};

export const formatMatchStatusDescription = (matchStatus: MatchStatusDescription): string => {
    if (matchStatus in matchStatusDescriptions) {
        return matchStatusDescriptions[matchStatus].description;
    }
    return `[unknown match status ${matchStatus}]`;
};