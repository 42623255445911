import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {addToast, newToastMessage} from "../features/toast/toastSlice";
import {useConfirmEMailMutation} from "../api/chessMasterApi";

const ConfirmEMail: React.FC = () => {
    const dispatch = useDispatch();
    const [confirmEMail] = useConfirmEMailMutation();

    useEffect(
        () => {
            const sendEMailConfirmation = (href: string) => {
                const url = new URL(href);
                const match = url.hash.match(/#confirm=([a-zA-Z0-9_.~-]*)/);

                if (match) {
                    const token = match[1];

                    confirmEMail({token})
                        .unwrap()
                        .then(() => {
                            dispatch(addToast(newToastMessage({
                                priority: "success",
                                heading: "Confirmed",
                                body: "Your e-mail address has been confirmed"
                            })));
                            window.history.replaceState({}, document.title, ".");
                        })
                        .catch((error) => {
                            dispatch(addToast(newToastMessage({
                                priority: "danger",
                                heading: "Confirmation failed",
                                body: `Error confirming e-mail: ${error.data.message || error.data.msg}`
                            })));
                        });
                }
            };
            sendEMailConfirmation(window.location.href);
        },
        [confirmEMail, dispatch]
    );

    return <></>;
};

export default ConfirmEMail;