import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";

import {AppDispatch, RootState} from "../store";

import {removeActiveTournament,} from "../features/activeTab/activeTabSlice";
import {chessMasterApi} from "../api/chessMasterApi";

const activeTab: Middleware = <S extends RootState>(store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => <A extends AnyAction>(action: A): A => {
    const dispatch: AppDispatch = store.dispatch;

    // XXX Should these cases take the actions required directly or post a separate action
    // instead so the appropriate middleware can take whatever action on displaying a different
    // tab?
    switch (action.type) {
    case removeActiveTournament.type:
        // are we removing active tab?
        // if (selectActiveTab(store.getState()).key === tournamentTabEventKeyFromTournament(action.payload.id)) {
        //     // TODO this is shitty: it should pick the right tab to move to
        //     dispatch(setActiveTabKey("tournaments"))
        // }
        dispatch(chessMasterApi.util.invalidateTags([{type: "Tournament", id: action.payload.id}]));
        break;

    default:
        break;
    }
    return next(action);
};

export default activeTab;