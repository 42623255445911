import React, {useCallback, useMemo} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import Form from "react-bootstrap/Form";

import UserLichessLink from "./UserLichessLink";
import {formatFullName} from "../util/formatFullName";
import {nameSortFunc} from "../util/nameSortFunc";
import {isPlayMethodOnline, Tournament} from "../models/Tournament";
import {UserInfo} from "../models/User";
import {UseSelection} from "../hooks/useSelection";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import {PlayerSearch, PlayerSearchPlayer} from "../models/PlayerSearch";
import {useOnlinePlayersForTournamentQuery} from "../api/chessMasterApi";
import PlayerSearchAndTeam from "./PlayerSearchAndTeam";

interface PlayerWithPlayerSearch extends PlayerSearchPlayer {
    playerSearch: PlayerSearch
}

interface Props {
    playerSearch: PlayerSearch | undefined
    tournament: Tournament
    playerIdSelection: UseSelection<UserInfo["id"]>
    showAddedTime?: boolean
    isLoading?: boolean
    className?: string
}

const TournamentPlayerSearchList: React.FC<Props> = ({playerSearch, tournament, playerIdSelection, showAddedTime = false, isLoading, className}) => {
    const {data: onlinePlayers} = useOnlinePlayersForTournamentQuery({key: tournament.key}, {skip: !isPlayMethodOnline(tournament.play_method)});
    const onlinePlayerIds = useMemo(() => (
        new Set<UserInfo["id"]>(onlinePlayers?.online_players ?? [])
    ), [onlinePlayers]);
    const toggleAllSelected = useCallback(() => playerIdSelection.toggleAllSelected(), [playerIdSelection]);
    const columns: TableColumn<PlayerWithPlayerSearch>[] = [{
        name: <IndeterminateCheckbox checked={playerIdSelection.isAllSelected}
                                     indeterminate={playerIdSelection.isAnySelected}
                                     disabled={!playerIdSelection.isAnyEnabled}
                                     onChange={toggleAllSelected}/>,
        center: true,
        minWidth: '50px',
        grow: 0,
        cell: (row) => <Form.Check checked={playerIdSelection.selectedSet.has(row.id)}
                                                   onChange={(ev) => playerIdSelection.setSelected({
                                                       item: row.id,
                                                       isSelected: ev.target.checked
                                                   })}
                                                   disabled={playerIdSelection.disabledSet.has(row.id)}/>
    }, {
        selector: (row) => tournament.ref_user[row.id].lichess_id || '',
        name: "Lichess",
        grow: 1,
        omit: !isPlayMethodOnline(tournament.play_method),
        sortable: true,
        center: true,
        cell: (row) => <UserLichessLink user={tournament.ref_user[row.id]}/>
    }, {
        selector: (row) => formatFullName(tournament.ref_user[row.id]),
        name: "Name",
        sortable: true,
        grow: 4,
        sortFunction: (a, b) => nameSortFunc(tournament.ref_user[a.id],
                                                                             tournament.ref_user[b.id]),
        cell: (row) => (
            <PlayerSearchAndTeam player={row}
                                 tournament={tournament}
                                 playerSearch={row.playerSearch}
                                 online={onlinePlayerIds.has(row.id)}/>
        )
    }, {
        name: "Team",
        selector: (row) => (row.team_id) ? row.playerSearch.ref_group[row.team_id].name : "",
        sortable: true,
        wrap: true
    }, {
        selector: (row) => row.rating ?? "",
        name: "Rating",
        grow: 0,
        minWidth: '90px',
        sortable: true,
        right: true,
    }];
    // players and playerSearch can get out of sync so tie them together
    const playersWithPlayerSearch = useMemo(() => playerSearch?.players.map((player) => ({
        ...player,
        playerSearch
    })), [playerSearch]);

    // useEffect(() => {
    //     // disable players without a lichess_id for an online tournament
    //     const disabledPlayers = (isPlayMethodOnline(tournament.play_method) && playerSearch) ? playerSearch.players.filter((player) => !tournament.ref_user[player.id].lichess_id) : [];
    //
    //     playerIdSelection.setDisabledItems({items: disabledPlayers.map((player) => player.id)});
    // },
    // [playerIdSelection, playerSearch, tournament]);

    return (
        <DataTable<PlayerWithPlayerSearch> data={playersWithPlayerSearch ?? []}
                                           columns={columns}
                                           keyField="id"
                                           noDataComponent="(No players)"
                                           progressPending={isLoading}
                                           className={className}/>
    );
};

export default TournamentPlayerSearchList;
