import React from "react";
import {useDispatch} from "react-redux";
import {ErrorMessage} from "@hookform/error-message";
import PasswordStrengthBar from "react-password-strength-bar";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

import {addToast, newToastMessage} from "../toast/toastSlice";
import {usePasswordResetMutation} from "../../api/chessMasterApi";
import {selectLogin, setLoginCredentials} from "./loginSlice";
import {newPasswordForm, PasswordForm, passwordSchema} from "../../util/passwordSchema";
import {useAppSelector} from "../../store";

const defaultValues = newPasswordForm();

interface Props {
    token: string
}

const ResetPassword: React.FC<Props> = ({token}) => {
    const dispatch = useDispatch();
    const [passwordReset, {isLoading}] = usePasswordResetMutation();
    const {fullName, username} = useAppSelector(selectLogin);
    const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm<PasswordForm>({
        defaultValues,
        resolver: yupResolver(passwordSchema)
    });
    const onSubmit = ({new_password}: PasswordForm) => {
        passwordReset({token, new_password})
            .unwrap()
            .then((credentials) => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: "Password updated",
                    body: "Your password was updated successfully."
                })));
                dispatch(setLoginCredentials(credentials));
            })
            .catch((error) => {
                dispatch(addToast(newToastMessage({
                    priority: "danger",
                    heading: "Password update failed",
                    body: `Error updating password: ${error.data.message || error.data.msg}`
                })));
            });
    };
    register("score");
    const new_password = watch("new_password");

    return (
        <Modal show={true}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop="static">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>
                        Resetting password for {fullName} ({username})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row} className="mb-3"
                                controlId="registerPassword">
                        <Form.Label column md={4}>
                            New password
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control type="password"
                                          {...register("new_password")}
                                          isInvalid={!!errors.new_password}
                                          autoComplete="new-password"
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>} errors={errors}
                                          name="new_password"
                                          type="invalid"/>
                            <PasswordStrengthBar password={new_password}
                                                 onChangeScore={(score) => setValue("score", score)}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3"
                                controlId="registerConfirmPassword">
                        <Form.Label column md={4}>
                            Confirm password
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control type="password"
                                          {...register("confirm_password")}
                                          isInvalid={!!errors.confirm_password}
                                          autoComplete="new-password"
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>} errors={errors}
                                          name="confirm_password"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonToolbar className="float-right">
                        <ButtonGroup className="me-1">
                            <Button type="submit"
                                    variant="primary"
                                    disabled={isLoading}>
                                Reset password
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ResetPassword;