import React from "react";

import {selectConstantsTournamentTypeById} from "../constants/constantsSlice";
import {TournamentBase} from "../../models/Tournament";
import {useAppSelector} from "../../store";

interface Props {
    tournament: TournamentBase
}

const TournamentType: React.FC<Props> = ({tournament}) => {
    const tournamentTypeById = useAppSelector(selectConstantsTournamentTypeById);
    const tournamentType = tournamentTypeById[tournament.tournament_type_id];

    return (tournamentType) ? <span>{tournamentType.description}</span>
                            : <span>{`(tournament type ${tournament.tournament_type_id})`}</span>;
};

export default TournamentType;