import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {BsFillPersonLinesFill, BsGear, BsList, BsLock, BsPower} from "react-icons/bs";

import {
    setSettingsShowChangeRoleModal,
    setSettingsShowLoginAsModal,
    setSettingsShowModal
} from "../features/settings/settingsSlice";
import {
    selectLogin,
    selectLoginRoles,
    setLoginCredentials,
    setLoginIsLoggedIn
} from "../features/login/loginSlice";
import {
    chessMasterApi,
    useCancelLoginAsMutation,
    useLogoutMutation
} from "../api/chessMasterApi";
import {setApiError} from "../features/api/ApiSlice";
import {User} from "../models/User";
import {Privileges} from "../models/Privileges";
import {
    setRunChangePasswordForUser,
    setRunEditChild
} from "../features/parentTab/parentTabSlice";
import {useAppSelector} from "../store";

export const UserActionDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const {fullName, userId, realId, privs} = useAppSelector(selectLogin);
    const {availableRoles} = useAppSelector(selectLoginRoles);
    const [logout] = useLogoutMutation();
    const [loginAsCancel] = useCancelLoginAsMutation();
    const logoutUser = useCallback(() => {
        logout()
            .unwrap()
            .then(() => {
                dispatch(setLoginIsLoggedIn(false));
                dispatch(chessMasterApi.util.resetApiState());
            })
            .catch((error) => dispatch(setApiError(`logout: ${error.data.message}`)))
        },
        [dispatch, logout]
    );
    const doCancelLoginAs = useCallback(() => {
        loginAsCancel()
            .unwrap()
            .then((credentials) => dispatch(setLoginCredentials(credentials)))
            .catch((error) => dispatch(setApiError(`cancelLoginAs: ${error.data.message}`)));
        },
        [dispatch, loginAsCancel]
    );
    const renderLoginAsDropdownItems = (realId: User["id"] | null, privs: Privileges) => {
        if (realId) {
            return (
                <Dropdown.Item onClick={doCancelLoginAs}>
                    <BsFillPersonLinesFill/> Cancel "Login as"
                </Dropdown.Item>
            );
        } else if (privs.admin_as) {
            return (
                <Dropdown.Item onClick={() => dispatch(setSettingsShowLoginAsModal(true))}>
                    <BsFillPersonLinesFill/> Login as ...
                </Dropdown.Item>
        )}
    };

    return (
        <DropdownButton title={`Logged in as ${fullName}`} variant="white" align="end">
            <Dropdown.Item onClick={() => dispatch(setSettingsShowModal(true))}>
                <BsGear/> Settings ...
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(setRunChangePasswordForUser({userId, fullName}))}>
                <BsLock/> Change password ...
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(setRunEditChild(userId))}>
                <BsList/> Edit details ...
            </Dropdown.Item>
            {(availableRoles.length > 1) && (
                <Dropdown.Item onClick={() => dispatch(setSettingsShowChangeRoleModal(true))}>
                    <BsFillPersonLinesFill/> Switch roles ...
                </Dropdown.Item>
            )}
            {renderLoginAsDropdownItems(realId, privs)}
            <Dropdown.Item onClick={logoutUser}>
                <BsPower/> Logout
            </Dropdown.Item>
        </DropdownButton>
    );
};
