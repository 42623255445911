import React from "react";
import {useForm} from "react-hook-form";

import Popover, {PopoverProps} from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import {ProjectorSettings} from "../../models/ProjectorSettings";
import {selectTournamentTab, setTournamentProjectorSettings} from "./tournamentTabSlice";
import {useAppDispatch, useAppSelector} from "../../store";

const CarouselTooltip: React.FC = () => (
    <Tooltip>
        Seconds, 0 is off.
    </Tooltip>
);

export interface Props extends PopoverProps {
    onClose?: (didUpdate: boolean) => void
}

const TournamentProjectorSettings: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({onClose, ...props}, ref) => {
    const dispatch = useAppDispatch();
    const {projectorSettings} = useAppSelector(selectTournamentTab);
    const {register, handleSubmit, watch, getValues} = useForm<ProjectorSettings>({
        defaultValues: projectorSettings
    });
    const onSubmit = (settings: ProjectorSettings) => {
        dispatch(setTournamentProjectorSettings(settings));
        onClose?.(true);
    };

    return (
        <Popover ref={ref} {...props}>
            <Popover.Header>
                Projector settings
            </Popover.Header>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group as={Row} className="m-1" controlId="projectorColumns">
                        <Form.Label column sm="6">
                            Columns
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control {...register('columns', {valueAsNumber: true})}
                                          size='sm'
                                          type='number'
                                          min={1}
                                          max={5}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="m-1" controlId="projectorRows">
                        <Form.Label column sm="6">
                            Rows
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control {...register('rows', {valueAsNumber: true})}
                                          size='sm'
                                          type='number'
                                          min={0}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="m-1" controlId="projectorOffset">
                        <Form.Label column sm="6" disabled={watch('carousel') > 0}>
                            Offset
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control {...register('offset', {valueAsNumber: true})}
                                          size='sm'
                                          type='number'
                                          disabled={getValues('carousel') > 0}
                                          min={0}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="m-1" controlId="projectorCarousel">
                        <Form.Label column sm="6">
                            Carousel (s)
                        </Form.Label>
                        <Col sm="6">
                            <OverlayTrigger placement="right" overlay={CarouselTooltip}>
                                <Form.Control {...register('carousel', {valueAsNumber: true})}
                                              size='sm'
                                              type='number'
                                              min={0}/>
                            </OverlayTrigger>
                        </Col>
                    </Form.Group>

                    {/*<Form.Group as={Row} className="m-1" controlId="projectorOffset">*/}
                    {/*    <Form.Label column sm="6">*/}
                    {/*        Font size*/}
                    {/*    </Form.Label>*/}
                    {/*    <Col sm="6">*/}
                    {/*        <Form.Control {...register('fontSize', {valueAsNumber: true})}*/}
                    {/*                      size='sm'*/}
                    {/*                      type='number'*/}
                    {/*                      min={1}/>*/}
                    {/*    </Col>*/}
                    {/*</Form.Group>*/}

                    <hr/>

                    <Row className="mb-2">
                        <Col className="text-end">
                            <Button variant="secondary"
                                    onClick={() => onClose?.(false)}
                                    className="me-1"
                                    size="sm">
                                Cancel
                            </Button>
                            <Button type="submit"
                                    size="sm">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Popover>
    );
};

export default React.forwardRef<HTMLDivElement, Props>(TournamentProjectorSettings);
