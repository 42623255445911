import React from "react";
import {Tournament} from "../../models/Tournament";
import Button from "react-bootstrap/Button";

interface Props {
    tournament: Tournament;
}

export const ActiveTournamentConferenceLink: React.FC<Props> = ({tournament}) => {
    if (tournament.play_method.includes('Online') && tournament.conference_link) {
        return (
            <a href={tournament.conference_link} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
                <Button size="sm">Conference</Button>
            </a>
        );
    } else {
        return null;
    }
};