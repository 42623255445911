import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {selectConstantsPaymentSystem} from "../constants/constantsSlice";
import PaymentItemList from "./PaymentItemList";
import {Payment} from "../../models/Payment";
import PayUsingPayPalButton from "./PayUsingPayPalButton";
import PayUsingStripeButton from "./PayUsingStripeButton";
import {useAppSelector} from "../../store";

interface Props {
    payment: Payment
    amountPayable: number
    onClose: (isPaymentCompleted: boolean) => void
}

const PaymentModal: React.FC<Props> = ({payment, amountPayable, onClose}) => {
    const {paypalConfig, stripeConfig} = useAppSelector(selectConstantsPaymentSystem);

    return (
        <Modal show
               onHide={() => onClose(false)}
               backdrop="static"
               size="lg">
            <Modal.Header>
                Payment
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                <Container fluid>
                    <Row>
                        <PaymentItemList payment={payment} amountPayable={amountPayable}/>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Body className="mx-auto">
                {(paypalConfig) && <PayUsingPayPalButton payment={payment}
                                                         paypalConfig={paypalConfig}
                                                         onClose={onClose}/>}
                {(stripeConfig) && <PayUsingStripeButton payment={payment}
                                                         stripeConfig={stripeConfig}
                                                         onClose={onClose}/>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentModal;
