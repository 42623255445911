import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import {Tournament, TournamentLite} from "../models/Tournament";
import {UserMinSchema} from "../models/User";
import {selectLoginCanEditTournament} from "../features/login/loginSlice";
import ShowPlayerContactDetails from "./ShowPlayerContactDetails";
import {showFinancialsForUser} from "../features/activeTournaments/activeTournamentSlice";
import {formatFullName} from "../util/formatFullName";
import UserLichessOnlineSymbol from "./UserLichessOnlineSymbol";
import {useAppSelector} from "../store";

interface Props {
    tournament: Tournament | TournamentLite
    user: UserMinSchema
    online?: boolean
}

export const UsernameForFinancials: React.FC<Props> = ({tournament, user, online = false}) => {
    const dispatch = useDispatch();
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));

    return (
        <Stack direction="horizontal" className="w-100">
            {(canEditTournament) && (
                <ShowPlayerContactDetails tournament={tournament} user={user}/>
            )}
            <Button variant="link"
                    className="p-0"
                    onClick={() => dispatch(showFinancialsForUser(user))}>
                {formatFullName(user)}
            </Button>
            {(online) && (
                <UserLichessOnlineSymbol/>
            )}
            {(user?.username) ? <span className="text-muted ms-auto">({user.username})</span> : ""}
        </Stack>
    );
};