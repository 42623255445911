import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {User} from "../../models/User";
import {Group} from '../../models/Group';
import {SearchUserRequest} from "../../api/chessMasterApi";

interface UserTabState {
    editUserId: User["id"] | null     // create new user when editUserId < 0
    searchRequest: SearchUserRequest
    searchStatus: string
    searchPending: boolean
    filteredGroups: Group[]
    showBulkUpload: boolean
    bulkUploadFile: string | ArrayBuffer | null
    bulkUploadPending: boolean
}

const initialState: UserTabState = {
    editUserId: null,
    searchRequest: {},
    searchStatus: "Idle",
    searchPending: false,
    filteredGroups: [],
    showBulkUpload: false,
    bulkUploadFile: null,
    bulkUploadPending: false
};

export const loadUsers = createAction<void>("userTab/loadUsers");

export const slice = createSlice({
    name: "userTab",
    initialState,
    reducers: {
        setEditUserId: (state, action: PayloadAction<User["id"] | null>) => {
            state.editUserId = action.payload;
        },
        setShowBulkUpload: (state, action: PayloadAction<boolean>) => {
            state.showBulkUpload = action.payload;
        },
        setUserBulkUploadFile: (state, action: PayloadAction<string | ArrayBuffer | null>) => {
            state.bulkUploadFile = action.payload
        }
    }
});

export const selectUserTab = (state: RootState) => state.userTab;
export const {
    setEditUserId, setShowBulkUpload, setUserBulkUploadFile
} = slice.actions;

export default slice.reducer;