import React from "react";
import {Payment} from "../../models/Payment";
import {useAppDispatch} from "../../store";
import DataTable, {TableColumn} from "react-data-table-component";
import Button from "react-bootstrap/Button";
import {setRunEditProFormaPaymentModal} from "./groupEntryTabSlice";
import TournamentName from "../../components/TournamentName";

interface Props {
    payments: Payment[]
}

const ProFormaPaymentTable: React.FC<Props> = ({payments}) => {
    const dispatch = useAppDispatch();
    const columns: TableColumn<Payment>[] = [{
        name: "Action",
        center: true,
        grow: 1,
        cell: (row) => (
            <Button size="sm"
                    onClick={() => dispatch(setRunEditProFormaPaymentModal(row))}>
                Edit
            </Button>
        )
    }, {
        selector: (row) => row.tournament.name,
        name: "Tournament",
        grow: 4,
        format: (row) => <TournamentName tournament={row.tournament}/>
    }, {
        selector: (row) => row.group_payer.name,
        name: "Group",
        grow: 4
    }, {
        name: "# Players",
        selector: (row) => row.items.length,
        right: true,
        grow: 1
    }, {
        name: "# Remaining",
        selector: (row) => (row.max_items ?? 0) - row.items.length,
        right: true,
        grow: 1
    }, {
        selector: (row) => row.max_items ?? "",
        name: "Total",
        right: true,
        grow: 1
    }];

    return <DataTable<Payment> columns={columns}
                               data={payments ?? []}
                               pagination/>;
};

export default ProFormaPaymentTable;