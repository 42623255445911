import React from "react";
import {useFormContext} from "react-hook-form";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ErrorMessage } from "@hookform/error-message";
import {BulkUploadForm} from "./UserBulkUpload";

const UserBulkUploadFieldMappingCard: React.FC = () => {
    const {register, formState: {errors}, watch} = useFormContext<BulkUploadForm>();
    const method = watch("method");

    return (
        <Card>
            <Card.Header>
                Field mappings
            </Card.Header>
            <Card.Body>
                <p>
                    Specify the fields in the uploaded file which should be used for the
                    user's given name and surname. If specified, the e-mail address field
                    can help resolve conflicts.
                </p>

                <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={12}>
                        <Form.Check type="radio"
                                    label="Separate given and surname"
                                    value="separateGivenNameSurname"
                                    {...register("method")} />
                    </Form.Label>
                </Form.Group>

                <fieldset disabled={method !== "separateGivenNameSurname"}>
                    <Row>
                        <Col sm={{span: 10, offset: 2}}>
                            <Form.Group as={Row}
                                        controlId="userBulkUploadFieldGivenName">
                                <Form.Label column sm="4">
                                    Given name
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control {...register("givenNameField")}
                                                  placeholder="Given name field"
                                                  isInvalid={!!errors.givenNameField}/>
                                    <ErrorMessage as={<Form.Control.Feedback/>}
                                                  name="givenNameField"
                                                  type="invalid"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}
                                        controlId="userBulkUploadFieldSurname">
                                <Form.Label column sm="4">
                                    Surname
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control {...register("surnameField")}
                                                  placeholder="Surname field"
                                                  isInvalid={!!errors.surnameField}/>
                                    <ErrorMessage as={<Form.Control.Feedback/>}
                                                  name="surnameField"
                                                  type="invalid"/>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>

                <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={12}>
                        <Form.Check type="radio"
                                    label="Surname then given name, comma separated"
                                    value="combinedGivenNameSurnameCommaReversed"
                                    {...register("method")} />
                    </Form.Label>
                </Form.Group>

                <fieldset disabled={method !== "combinedGivenNameSurnameCommaReversed"}>
                    <Row>
                        <Col sm={{span: 10, offset: 2}}>
                            <Form.Group as={Row}
                                        controlId="userBulkUploadFieldName">
                                <Form.Label column sm="4">
                                    Name field
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control {...register("nameField")}
                                                  placeholder="Name field"
                                                  isInvalid={!!errors.nameField}/>
                                    <ErrorMessage as={<Form.Control.Feedback/>}
                                                  name="nameField"
                                                  type="invalid"/>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>

                <hr/>

                <Form.Group as={Row} controlId="userBulkUploadFieldEMail">
                    <Form.Label column sm="3">
                        E-mail (optional)
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control {...register("eMailField")}
                                      placeholder="E-mail field"
                                      isInvalid={!!errors.eMailField}/>
                        <ErrorMessage as={<Form.Control.Feedback/>}
                                      name="eMailField"
                                      type="invalid"/>
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default UserBulkUploadFieldMappingCard;