import React, {useContext, useEffect, useMemo} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import {useSearchUserInfosQuery} from "../api/chessMasterApi";
import {Tournament} from "../models/Tournament";
import {UserInfo} from "../models/User";
import UsernameAndGroups from "./UsernameAndGroups";
import {nameSortFunc} from "../util/nameSortFunc";
import {formatFullName} from "../util/formatFullName";
import AddPlayerToTournamentButton from "../features/activeTournaments/AddPlayerToTournamentButton";
import {formatErrorMessage} from "../middleware/RTKQueryErrorLogger";
import {PlayerFilterErrorContext} from "./PlayerFilterInput";

interface FilterPlayerListProps {
    tournament: Tournament
    query: string
    groupId: number
}

const PlayerFilterList: React.FC<FilterPlayerListProps> = ({tournament, query, groupId}) => {
    const {data: users, isFetching, error} = useSearchUserInfosQuery(
        {query, group_id: groupId},
        {skip: query.length < 3 && groupId < 0}
    );
    const {setUserSearchError} = useContext(PlayerFilterErrorContext);
    const tournamentPlayerIds = useMemo(
        () => new Set((tournament.players).map((tp) => tp.user_id)),
        [tournament.players]
    );
    const columns: TableColumn<UserInfo>[] = [{
        name: "Name",
        selector: (row) => formatFullName(row),
        sortable: true,
        sortFunction: nameSortFunc,
        cell: (row) => <UsernameAndGroups userInfo={row}/>
    }, {
        name: "Action",
        button: true,
        cell: (row) => (
            <AddPlayerToTournamentButton user={row}
                                         isAdded={tournamentPlayerIds.has(row.id)}
                                         tournament={tournament}/>
        )
    }];

    useEffect(
        () => setUserSearchError?.((error) ? formatErrorMessage(error) : null),
        [error, setUserSearchError]
    );

    return (
        <DataTable<UserInfo> data={users ?? []}
                             keyField="id"
                             columns={columns}
                             noDataComponent="(No users)"
                             progressPending={isFetching}
                             pagination/>
    );
};

export default PlayerFilterList;