import React, {useCallback} from "react";

import Button from "react-bootstrap/Button";
import {BsChevronDoubleLeft} from "react-icons/bs";

import {UserInfo} from "../../models/User";
import {addToast, newToastError, newToastSuccess} from "../toast/toastSlice";
import {useUpdateTournamentRemovingPlayersFromTeamMutation} from "../../api/chessMasterApi";
import {useAppDispatch} from "../../store";
import {Tournament} from "../../models/Tournament";
import {UseSelection} from "../../hooks/useSelection";

import {GroupMinSchema} from "../../models/Group";

interface Props {
    tournament: Tournament
    team: GroupMinSchema | undefined
    playerIdSelection: UseSelection<UserInfo["id"]>
    className?: string
}

const RemoveSelectedPlayersFromTeamButton: React.FC<Props> = ({tournament, team, playerIdSelection, className}) => {
    const dispatch = useAppDispatch();
    const [updateTournamentRemovingPlayersFromTeam] = useUpdateTournamentRemovingPlayersFromTeamMutation();
    const removePlayersFromTeam = useCallback(() => {
        const userIds = [...playerIdSelection.selectedSet];

        if (!team) {
            dispatch(addToast(newToastError({
                heading: "Internal error",
                body: "Cannot remove players without a team"
            })));
            return;
        }
        updateTournamentRemovingPlayersFromTeam({key: tournament.key, userIds})
            .unwrap()
            .then(() => {
                const nPlayers = userIds.length;

                dispatch(addToast(newToastSuccess({
                    heading: "Team updated",
                    body: `Removed ${nPlayers} ${(nPlayers === 1) ? "player" : "players"} from team "${team.name}"`
                })))
            })
            .then(() => {
                playerIdSelection.setSelectedItems({items: []});
            });
        },
        [dispatch, playerIdSelection, team, tournament, updateTournamentRemovingPlayersFromTeam]
    );

    return (
        <Button onClick={removePlayersFromTeam}
                disabled={!team || playerIdSelection.selectedSet.size === 0}
                variant="danger"
                className={className}>
            <BsChevronDoubleLeft/> Remove selected from team
        </Button>
    );
};

export default RemoveSelectedPlayersFromTeamButton;
