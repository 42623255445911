import React, {useContext} from "react";

import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import {useTournamentsForUserQuery} from "../../api/chessMasterApi";
import {ActiveTournamentContext} from "./ActiveTournament";
import {UserMinSchema} from "../../models/User";
import {formatFullName} from "../../util/formatFullName";
import ChildTournamentList from "../parentTab/ChildTournamentList";
import PastPaymentList from "../paymentTab/PastPaymentsList";
import UserLichessOnlineSymbol from "../../components/UserLichessOnlineSymbol";

interface Props {
    user: UserMinSchema;
    onClose: () => void;
}

const ActiveTournamentFinancialsForUser: React.FC<Props> = ({user, onClose}) => {
    const {onlinePlayerIds} = useContext(ActiveTournamentContext);
    const {data: tournamentsForUser} = useTournamentsForUserQuery({userId: user.id});
    const tournaments = ((tournamentsForUser ?? []).map((tbu) => tbu.tournament));

    return (
        <Modal show={true}
               onHide={onClose}
               size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Financials for user “{formatFullName(user)}”
                    {(onlinePlayerIds.has(user.id) && <UserLichessOnlineSymbol/>)}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Card.Title>Tournament history</Card.Title>
                        <ChildTournamentList child={user}
                                             tournaments={tournaments}/>
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Card.Title>Payment history</Card.Title>
                        <PastPaymentList user={user}/>
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button variant="primary" onClick={onClose}>Close</Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>

            {/*{(error) && (*/}
            {/*    <Modal.Footer>*/}
            {/*        <span className="text-end text-danger">*/}
            {/*            Error: {("status" in error) ? error.status : JSON.stringify(error)}*/}
            {/*        </span>*/}
            {/*    </Modal.Footer>*/}
            {/*)}*/}
        </Modal>
    );
};

export default ActiveTournamentFinancialsForUser;
