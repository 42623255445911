import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {setIsResettingPassword} from "./loginSlice";

// Check if there's a password reset token in the url. If there is, capture it
// in the isResettingPassword as a flag to show the user the right screen and
// clear the hashtag from the url.

const CheckPasswordReset: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(
        () => {
            const url = new URL(window.location.href);
            const match = url.hash.match(/#pwdreset=([a-zA-Z0-9_.~-]*)/);

            if (match) {
                const token = match[1];

                window.history.replaceState({}, document.title, ".");
                dispatch(setIsResettingPassword(token));
            }
        },
        [dispatch]
    );

    return <></>;
};

export default CheckPasswordReset;
