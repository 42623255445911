import React, {useCallback, useMemo} from "react";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import DataTable, {TableColumn} from "react-data-table-component";

import Form from "react-bootstrap/Form";

import {useOnlinePlayersForTournamentQuery} from "../api/chessMasterApi";
import PaidSymbol from "./PaidSymbol";
import UserLichessLink from "./UserLichessLink";
import UsernameAndTeam from "./UsernameAndTeam";
import {formatFullName} from "../util/formatFullName";
import {nameSortFunc} from "../util/nameSortFunc";
import {isPlayMethodOnline, Tournament} from "../models/Tournament";
import {TournamentPlayer} from "../models/TournamentPlayer";
import {UserInfo} from "../models/User";
import {UseSelection} from "../hooks/useSelection";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import TeamName from "../features/activeTournaments/TeamName";

const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface Props {
    players: TournamentPlayer[]
    noPlayersMessage?: string
    tournament: Tournament
    playerIdSelection: UseSelection<TournamentPlayer["user_id"]>
    showAddedTime?: boolean
    showTeam?: boolean
    isLoading?: boolean
    className?: string
}

const TournamentPlayerList: React.FC<Props> = ({players, tournament, playerIdSelection, showAddedTime = false, showTeam = false, noPlayersMessage = "(No players)", isLoading, className}) => {
    const {data: onlinePlayers} = useOnlinePlayersForTournamentQuery({key: tournament.key}, {skip: !isPlayMethodOnline(tournament.play_method)});
    const onlinePlayerIds = useMemo(() => (
        new Set<UserInfo["id"]>(onlinePlayers?.online_players ?? [])
    ), [onlinePlayers]);
    const toggleAllSelected = useCallback(() => playerIdSelection.toggleAllSelected(), [playerIdSelection]);
    const columns: TableColumn<TournamentPlayer>[] = [{
        name: <IndeterminateCheckbox checked={playerIdSelection.isAllSelected}
                                     indeterminate={playerIdSelection.isAnySelected}
                                     disabled={!playerIdSelection.isAnyEnabled}
                                     onChange={toggleAllSelected}/>,
        center: true,
        minWidth: '50px',
        grow: 0,
        cell: (row) => <Form.Check checked={playerIdSelection.selectedSet.has(row.user_id)}
                                                   onChange={(ev) => playerIdSelection.setSelected({
                                                       item: row.user_id,
                                                       isSelected: ev.target.checked
                                                   })}
                                                   disabled={playerIdSelection.disabledSet.has(row.user_id)}/>
    }, {
        selector: (row) => tournament.ref_user[row.user_id].lichess_id || '',
        name: "Lichess",
        grow: 1,
        omit: !isPlayMethodOnline(tournament.play_method),
        sortable: true,
        center: true,
        cell: (row) => <UserLichessLink user={tournament.ref_user[row.user_id]}/>
    }, {
        selector: (row) => formatFullName(tournament.ref_user[row.user_id]),
        name: "Name",
        sortable: true,
        grow: 4,
        sortFunction: (a, b) => nameSortFunc(tournament.ref_user[a.user_id],
                                                                             tournament.ref_user[b.user_id]),
        cell: (row) => (
            <UsernameAndTeam player={row}
                             tournament={tournament}
                             online={onlinePlayerIds.has(row.user_id)}
                             omitTeam/>
        )
    }, {
        selector: (row) => row.paid ?? "",
        name: "Paid",
        grow: 1,
        sortable: true,
        center: true,
        cell: (row) => (row.paid) ? <PaidSymbol className="mt-2 float-end"/> : null
    }, {
        selector: (row) => row.team_id ?? "",
        name: "Team",
        omit: !showTeam,
        grow: 4,
        wrap: true,
        sortable: true,
        cell: (row) => (
            <TeamName tournament={tournament}
                      team={(row.team_id) ? tournament.ref_group[row.team_id] : undefined}/>
        )
    }, {
        selector: (row) => row.add_time,
        name: "Added",
        omit: !showAddedTime,
        sortable: true,
        grow: 2,
        format: (row) => (
            (row.add_time) ? format(utcToZonedTime(new Date(row.add_time), tzName), "PP HH:mm:ss") : ""
        )
    }];

    // useEffect(() => {
    //     // disable players without a lichess_id for an online tournament
    //     const disabledPlayers = (isPlayMethodOnline(tournament.play_method)) ? players.filter((player) => !tournament.ref_user[player.user_id].lichess_id) : [];
    //
    //     playerIdSelection.setDisabledItems({items: disabledPlayers.map((player) => player.user_id)});
    // },
    // [playerIdSelection, players, tournament]);

    return (
        <DataTable<TournamentPlayer> data={players}
                                     columns={columns}
                                     keyField="id"
                                     noDataComponent={noPlayersMessage}
                                     progressPending={isLoading}
                                     className={className}/>
    );
};

export default TournamentPlayerList;
