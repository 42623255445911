import React, {ChangeEvent, useCallback} from "react";

import Form from "react-bootstrap/Form";

import {Tournament} from "../models/Tournament";
import {selectConstants} from "../features/constants/constantsSlice";
import {formControlOptions} from "../util/formControl";
import {useAppSelector} from "../store";

interface Props {
    typeId: Tournament["tournament_type_id"] | -1
    onChangeTypeId: (typeId: Tournament["tournament_type_id"] | -1) => void;
}

const TournamentTypeSelect: React.FC<Props> = ({typeId, onChangeTypeId}) => {
    const {tournament_types} = useAppSelector(selectConstants);
    const changeTypeId = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => onChangeTypeId(Number(ev.target.value)),
        [onChangeTypeId]
    );

    return (
        <Form.Control as="select"
                      size="sm"
                      onChange={changeTypeId}
                      value={typeId}>
            <option key={-1} value={-1}>(All types)</option>
            {formControlOptions(tournament_types)}
        </Form.Control>
    );
};

export default TournamentTypeSelect;