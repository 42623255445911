// helpers for yup transform function

// -- convert to null if empty
export const emptyStringToNull = (value: any, originalValue: any) => {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
};

// convert to null if empty or 0
export const emptyStringOr0ToNull = (value: any, originalValue: any) => {
    if (typeof originalValue === 'string' && (originalValue === '' || parseInt(originalValue) === 0)) {
        return null;
    }
    return value;
};

