import React, {useCallback, useMemo, useState} from "react";

import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import {chessMasterApi} from "../../api/chessMasterApi";
import {User, UserInfo} from "../../models/User";
import {Group, GroupMinSchema} from "../../models/Group";
import PlayerFilterInput, {PlayerFilterErrorContext} from "../../components/PlayerFilterInput";
import GroupMembershipAddUsersTable from "./GroupMembershipAddUsersTable";
import GroupMembershipCreateUser from "./GroupMembershipCreateUser";

interface Props {
    assignedUsers: UserInfo[]
    group: GroupMinSchema
    addUsers: (users: UserInfo[], addToGroup: boolean) => void
}

const GroupMembershipAddUsers: React.FC<Props> = ({assignedUsers, group, addUsers}) => {
    const [query, setQuery] = useState<Group["name"]>("");
    const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([]);
    const [userSearchError, setUserSearchError] = useState<string | null>(null);
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);
    const selectedUsersNotAssigned = useMemo(
        () => {
            const assignedUserIds = new Set(assignedUsers.map((ui) => ui.id));
            return selectedUsers.filter((ui) => !assignedUserIds.has(ui.id));
        },
        [assignedUsers, selectedUsers]
    );
    const [groupId, setGroupId] = useState<Group["id"]>(0);
    const [lazySearchUserInfos] = chessMasterApi.endpoints.searchUserInfos.useLazyQuery();
    const resetSearchParams = () => {
        setQuery("");
        setGroupId(-1);
    };
    const onCloseNewUser = useCallback((user: User | null) => {
        if (user) {
            lazySearchUserInfos({id: user.id})
                .unwrap()
                .then((userInfos) => {
                    if (userInfos.length === 1) {
                        addUsers([userInfos[0]], false);
                    }
                });
        }
    }, [addUsers, lazySearchUserInfos]);

    return (
        <Accordion>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Add members ...</Accordion.Header>
                <Accordion.Body>
                    <Card>
                        <Card.Body>
                            <PlayerFilterErrorContext.Provider value={{
                                userSearchError, setUserSearchError,
                                groupSearchError, setGroupSearchError
                            }}>
                                <PlayerFilterInput query={query} setQuery={setQuery}
                                                   groupId={groupId} setGroupId={setGroupId}/>
                                <GroupMembershipAddUsersTable query={query}
                                                              groupId={groupId}
                                                              assignedUsers={assignedUsers}
                                                              setSelectedUsers={setSelectedUsers}/>
                            </PlayerFilterErrorContext.Provider>
                        </Card.Body>
                        <Card.Footer>
                            <ButtonToolbar className="float-end">
                                <ButtonGroup className="me-1">
                                    <Button variant="outline-secondary"
                                            onClick={resetSearchParams}>
                                        Reset
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <Button type="submit"
                                            onClick={() => addUsers(selectedUsersNotAssigned, true)}
                                            disabled={selectedUsersNotAssigned.length === 0}
                                            variant="primary">
                                        Add
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Card.Footer>
                    </Card>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Create user ...</Accordion.Header>
                <Accordion.Body>
                    <GroupMembershipCreateUser group={group}
                                               onClose={onCloseNewUser}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default GroupMembershipAddUsers;
