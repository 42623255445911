import React from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {formatMoney} from "accounting";

import {newPaymentItem, Payment, PaymentItem} from "../../models/Payment";

const formatTotalDescription = (payment: Payment) => (
    `Total (${payment.items.length} ${(payment.items.length === 1) ? "item" : "items"})`
);

const TOTAL_PAYMENT_ID = -2;

interface Props {
    payment: Payment
    amountPayable: number
}

const PaymentItemList: React.FC<Props> = ({payment, amountPayable}) => {
    const items = [
        ...payment?.items ?? [],
        ...(payment.credit) ? [newPaymentItem({payment_id: -1, description: "Credit", amount: payment.credit})] : [],
        newPaymentItem({payment_id: TOTAL_PAYMENT_ID, description: formatTotalDescription(payment), amount: `${amountPayable}`}),
    ];
    const columns: TableColumn<PaymentItem>[] = [{
        selector: (row) => row.payment_id,
        name: "Payment id",
        omit: true
    }, {
        selector: (row) => row.description,
        name: "Description",
        grow: 8,
        format: (row) => (row.payment_id === TOTAL_PAYMENT_ID) ? <i>{row.description}</i> : row.description
    }, {
        selector: (row) => row.amount,
        name: "Amount",
        right: true,
        format: (row) => formatMoney(row.amount, "$")
    }];

    return (
        <DataTable<PaymentItem> data={items}
                                columns={columns}
                                dense
                                keyField="payment_id"
                                noDataComponent={"(No payments outstanding)"}/>
    );
};

export default PaymentItemList;
