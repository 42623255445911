
const filenameFromContentDisposition = (s: string) => {
    const match = s.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

    return match?.[1] ?? "unknown";
};

export const saveResponseUsingHiddenElement = async (response: Response) => {
    const headers = Object.fromEntries(response.headers);
    const mimeType = headers["content-type"] ?? "text/plain";
    const filename = filenameFromContentDisposition(headers["content-disposition"] ?? "");
    const data = await response.blob();

    const hiddenElement = document.createElement('a');
    const url = window.URL || window.webkitURL;

    hiddenElement.href = url.createObjectURL(new Blob([data], {type: mimeType}));
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();

    return filename;
};