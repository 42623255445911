import {Tournament} from "../models/Tournament";
import {MatchStatus, MatchStatusDescription} from "../models/Match";
import {TournamentRound} from "../models/TournamentRound";
import {UserInfo} from "../models/User";

// Create a running score list for the tournament so far.  Within each round, there is a
// map of UserInfo.id to score for that user's score at the end of that round.

export const makeScoresForTournament = (tournament: Tournament, matchStatusByDescription: Record<MatchStatusDescription, MatchStatus>): Record<TournamentRound["round_num"], Record<UserInfo["id"], number>> => {
    // XXX check rounds are in order
    const scoresByRound: Record<TournamentRound["round_num"], Record<UserInfo["id"], number>> = {};
    const scores = Object.fromEntries(tournament.players.map((player) => [player.user_id, 0]));
    for (const round of tournament.rounds) {
        round.matches.forEach((match) => {
            const matchResult = matchStatusByDescription[match.status];

            if (match.white_player_id) {
                scores[match.white_player_id] += matchResult.points.white;
            }
            if (match.black_player_id) {
                scores[match.black_player_id] += matchResult.points.black;
            }
        });
        scoresByRound[round.round_num] = {...scores};
    }
    return scoresByRound;
};