import React from "react";
import {useNavigate} from "react-router-dom";

import Badge from "react-bootstrap/Badge";

import {formatFullName} from "../../util/formatFullName";
import {AuditRowWithAudit} from "./AuditList";
import {UserMinSchema} from "../../models/User";
import {TournamentMinSchema} from "../../models/Tournament";
import {GroupMinSchema} from "../../models/Group";

const AuditTargetBadge: React.FC<{label: string, value: string}> = ({label, value}) => (
    <Badge bg="subtle" text="dark" className="me-1">
        <span className="text-muted">{label}: </span>
        {value}
    </Badge>
);

const AuditTargetUser: React.FC<{user: UserMinSchema}> = ({user}) => {
    const navigate = useNavigate();

// XXX only for priv = "admin" or "group_admin"
    return (
        <Badge bg="subtle" text="dark" className="me-1"
               onClick={() => navigate(`/users/${user.id}`)}>
            <span className="text-muted">User: </span>
            {formatFullName(user)}
        </Badge>
    )
};

const AuditTargetGroup: React.FC<{group: GroupMinSchema}> = ({group}) => {
    const navigate = useNavigate();

    // XXX only for priv = "admin" or "group_admin"
    return (
        <Badge bg="subtle" text="dark" className="me-1"
               onClick={() => navigate(`/groups/${group.id}`)}>
            <span className="text-muted">Group: </span>
            {group.name}
        </Badge>
    )
};

const AuditTargetTournament: React.FC<{tournament: TournamentMinSchema}> = ({tournament}) => {
    const navigate = useNavigate();

    return (
        <Badge bg="subtle" text="dark" className="me-1"
               onClick={() => navigate(`/tournament/${tournament.id}`)}>
            <span className="text-muted">Tournament: </span>
            {tournament.name}
        </Badge>
    );
};

const AuditTargetDisplay: React.FC<{auditRow: AuditRowWithAudit}> = ({auditRow}) => (
    <h6 className="w-100 auditTarget">
        {(auditRow.user_id) && (
            <AuditTargetUser user={auditRow.audit.ref_user[auditRow.user_id]}/>
        )}
        {(auditRow.role_id) && (
            <AuditTargetBadge label="Role" value={auditRow.audit.ref_role[auditRow.role_id].name}/>
        )}
        {(auditRow.group_id) && (
            <AuditTargetGroup group={auditRow.audit.ref_group[auditRow.group_id]}/>
        )}
        {/*{(auditRow.location) && (*/}
        {/*    <AuditTargetBadge label="Location" value={locationKey(auditRow.location)}/>*/}
        {/*)}*/}
        {(auditRow.tournament_id) && (
            <AuditTargetTournament tournament={auditRow.audit.ref_tournament[auditRow.tournament_id]}/>
        )}
    </h6>
);

export default AuditTargetDisplay;
