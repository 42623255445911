import React from "react";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import {selectConstants} from "../constants/constantsSlice";
import {Tournament} from "../../models/Tournament";
import {Match} from "../../models/Match";
import {useAppSelector} from "../../store";

const LichessBoardTooltip: React.FC = () => (
    <Tooltip>View Lichess board</Tooltip>
);

interface Props {
    tournament: Tournament
    match: Match
    projectorView?: boolean
}

const ActiveTournamentBoardNumber: React.FC<Props> = ({tournament, match, projectorView}) => {
    const {lichess_url} = useAppSelector(selectConstants);

    if (!projectorView && tournament.play_method === "LichessOnline" && lichess_url && match.lichess_id) {
        const lichessUrl = `${lichess_url}/${match.lichess_id}`;

        return (
            <OverlayTrigger overlay={<LichessBoardTooltip/>}>
                <a href={lichessUrl} target="_blank" rel="noopener noreferrer">
                    <Button>{match.board_num}</Button>
                </a>
            </OverlayTrigger>
        );
    } else if (projectorView) {
        return <strong>{match.board_num}</strong>;
    } else {
        return <>{match.board_num}</>;
    }
};

export default ActiveTournamentBoardNumber;
