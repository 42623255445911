import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {DevTool} from "@hookform/devtools";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {NewLocation} from "../models/Location";
import {formControlOptionsFromStrings} from "../util/formControl";
import {selectConstants} from "../features/constants/constantsSlice";
import {ErrorMessage} from "@hookform/error-message";
import {useAppSelector} from "../store";

const locationFormSchema = yup.object().shape({
    address1: yup.string().required().label("Address1"),
    address2: yup.string(),
    locality: yup.string().required().label("Locality"),
    state: yup.string().required().label("State"),
    postcode: yup.string().matches(/^[0-9]{4}$/, 'Must be exactly 4 digits').label("Postcode"),
    country: yup.string().label("Country")
});

interface Props {
    location: NewLocation
    onSubmit: (location: NewLocation) => void
    onCancel: () => void
}

export const LocationForm: React.FC<Props> = ({location, onSubmit, onCancel}) => {
    const {states} = useAppSelector(selectConstants);
    const {handleSubmit, register, formState: {errors}, control} = useForm<NewLocation>({
        defaultValues: location,
        reValidateMode: "onChange",
        resolver: yupResolver(locationFormSchema)
    });
    return (
        <Form>
            <Form.Group as={Row} md={6} controlId="localityEditAddress1">
                <Form.Label column md={2}>
                    Address 1
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("address1")}
                                  placeholder="Address 1"
                                  isInvalid={!!errors.address1}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="address1"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="localityEditAddress2">
                <Form.Label column md={2}>
                    Address 2
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("address2")}
                                  placeholder="Address 2"
                                  isInvalid={!!errors.address2}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="address2"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="localityEditLocality">
                <Form.Label column md={2}>
                    Locality
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("locality")}
                                  placeholder="Locality"
                                  isInvalid={!!errors.locality} />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="locality"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={2}>
                    State
                </Form.Label>
                <Col md={4}>
                    <Form.Control {...register("state")}
                                  as="select"
                                  isInvalid={!!errors.state}>
                        {formControlOptionsFromStrings(states)}
                    </Form.Control>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="state"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={2}>
                    Postcode
                </Form.Label>
                <Col md={4}>
                    <Form.Control {...register("postcode")}
                                  placeholder="Postcode"
                                  isInvalid={!!errors.postcode}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="postcode"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="localityEditCountry">
                <Form.Label column md={2}>
                    Country
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("country")}
                                  placeholder="Country"
                                  isInvalid={!!errors.country}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="country"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <ButtonToolbar className="float-end mt-1">
                <ButtonGroup>
                    <Button variant="outline-primary"
                            className="me-1"
                            onClick={() => onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant="outline-primary"
                            onClick={() => handleSubmit(onSubmit)()}>
                        Submit
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
            <DevTool control={control}/>
        </Form>
    );
};