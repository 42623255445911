import React, {SyntheticEvent, useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {BsTrophy} from "react-icons/bs";

import {ActiveTabDetail, removeActiveTournamentKey, selectActiveTab} from "../activeTab/activeTabSlice";
import {useAppDispatch, useAppSelector} from "../../store";
import {tournamentTabEventKeyFromTournament} from "../../util/tabEventKey";

const tabWhenCurrentTabCloses = (tabDetail: ActiveTabDetail, tabDetails: ActiveTabDetail[]) => {
    const index = tabDetails.findIndex((td) => td.tournamentKey === tabDetail.tournamentKey);

    if (index < 0) {
        console.error(`couldn't find tab for tournament ${tabDetail.tournamentKey} to close`);
    }
    if (tabDetails.length === 1) {
        return "tournaments";
    } else if (index < tabDetails.length - 1) {
        return `${tournamentTabEventKeyFromTournament(tabDetails[index + 1].tournamentKey)}`;
    } else {
        return `${tournamentTabEventKeyFromTournament(tabDetails[index - 1].tournamentKey)}`;
    }
};

const ActiveTournamentTabTitle: React.FC<{tabDetail: ActiveTabDetail}> = ({tabDetail}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {tabDetails} = useAppSelector(selectActiveTab);
    const closeTournament = useCallback(
        (ev: SyntheticEvent) => {
            const nextTab = tabWhenCurrentTabCloses(tabDetail, tabDetails);
            ev.stopPropagation();

            dispatch(removeActiveTournamentKey(tabDetail.tournamentKey));
            navigate(`/${nextTab}`, {replace: true});
        },
        [dispatch, navigate, tabDetail, tabDetails]
    );

    // I wanted to use CloseButton here but as the tab title is a button and buttons
    // can't be nested, I can't do that.
    return (
        <span>
            <span className="btn btn-close me-2" onClick={closeTournament}/>
            <BsTrophy className="me-1 tabIcon"/>
            {tabDetail.name}
        </span>
    );
};

export default ActiveTournamentTabTitle;
