import React from "react";
import {BsLink} from "react-icons/bs";
import Badge from "react-bootstrap/Badge";

interface Props {
    user: {lichess_id: string | null}
    className?: string
}

const UserLichessLink: React.FC<Props> = ({user, className}) => (
    (user?.lichess_id) ? <Badge pill bg="info" className={className}><BsLink className="me-1"/>{user.lichess_id}</Badge> : null
);

export default UserLichessLink;