import {createSlice, PayloadAction, Draft} from '@reduxjs/toolkit';
import * as api from "../../Api";
import jwt_decode from "jwt-decode";

import {LoginResponse, JWTToken, TokenRefreshResponse} from "../../Api";
import {RootState} from "../../store";
import {Privileges, PrivilegeName} from "../../models/Privileges";
import {Role} from "../../models/Role";
import {User} from "../../models/User";
import {selectConstants} from "../constants/constantsSlice";
import {chessMasterApi} from "../../api/chessMasterApi";
import {Tournament, TournamentLite} from "../../models/Tournament";

interface LoginState {
    username: string
    password: string
    fullName: string
    status: string
    userId: User["id"]
    realId: User["id"] | null
    privs: Privileges
    role_ids: Role["id"][]
    sel_role: Role["id"] | null // null == all available roles
    isLoggedIn: boolean
    isLoginPending: boolean
    jwtToken: api.JWTToken | null
    exp: number | null         // token expiry, seconds since epoch
    refreshInterval: number
    constantRefreshInterval: number
    isRegistering: boolean
    isRequestingPasswordReset: boolean
    isResettingPassword: string | null  // token to include in request
}

const initialState: LoginState = {
    username: '',
    password: '',
    fullName: '',
    status: 'Idle',
    userId: 0,
    realId: null,
    privs: {},
    role_ids: [],
    sel_role: null,
    isLoggedIn: false,
    isLoginPending: false,
    jwtToken: null,
    exp: null,
    refreshInterval: 60,        // secs, default
    constantRefreshInterval: 2 * 60 * 60, // secs, 2h
    isRegistering: false,
    isRequestingPasswordReset: false,
    isResettingPassword: null
};

interface JwtIdentity {
    id: number
    real_id: User["id"]
    full_name: string
}

export interface JwtClaims {
    sub: JwtIdentity
    iat: number
    exp: number
    privs: [PrivilegeName]
    role_ids: Role["id"][]
    sel_role: Role["id"] | null
    username: string
}

const loginFulfilled = (state: Draft<LoginState>, action: PayloadAction<LoginResponse>) => {
    state.status = "Logged in.";
    state.jwtToken = action.payload.access_token;
    const claims = jwt_decode<JwtClaims>(state.jwtToken);
    console.log(`Token decode: ${JSON.stringify(claims)}`);
    state.userId = claims.sub.id;
    state.realId = claims.sub.real_id;
    state.privs = Object.fromEntries(claims.privs.map((c) => [c, true])) as Privileges;
    state.role_ids = claims.role_ids;
    state.sel_role = claims.sel_role;
    state.fullName = claims.sub.full_name;
    state.username = claims.username;
    state.exp = claims.exp;
    state.isLoggedIn = true;
    state.isLoginPending = false;
};

const logoutState = (state: Draft<LoginState>) => {
    state.status = "Logged out.";
    state.jwtToken = null;
    state.userId = 0;
    state.realId = null;
    state.fullName = '';
    state.isLoggedIn = false;
    state.isLoginPending = false;
};

export const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setLoginUserId: (state, action: PayloadAction<number>) => {
            state.userId = action.payload;
        },
        setLoginIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
            if (!action.payload) {
                logoutState(state);
            }
        },
        setLoginJWTToken: (state, action: PayloadAction<JWTToken | null>) => {
            state.jwtToken = action.payload;
        },
        setLoginCredentials: (state, action: PayloadAction<LoginResponse>) => {
            loginFulfilled(state, action);
        },
        refreshCredentials: (state, action: PayloadAction<TokenRefreshResponse>) => {
            loginFulfilled(state, action);
            state.refreshInterval = action.payload.refresh_interval;
        },
        setIsRegistering: (state, action: PayloadAction<boolean>) => {
            state.isRegistering = action.payload;
        },
        setIsRequestingPasswordReset: (state, action: PayloadAction<boolean>) => {
            state.isRequestingPasswordReset = action.payload;
        },
        setIsResettingPassword: (state, action: PayloadAction<string | null>) => {
            state.isResettingPassword = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(chessMasterApi.endpoints.changeRole.matchFulfilled, loginFulfilled);
    }
});

export const selectLogin = (state: RootState) => state.login;
export const selectLoginRoles = (state: RootState) => {
    const {sel_role, role_ids} = selectLogin(state);
    const {roles} = selectConstants(state);
    const getRolesWithIds = (ids: Role["id"][]): Role[] => (
        roles.filter((role) => ids.includes(role.id))
    );

    return {
        selectedRoles: getRolesWithIds((sel_role) ? [sel_role] : role_ids),
        availableRoles: getRolesWithIds(role_ids)
    };
};
export const selectLoginCanEditTournament = (tournament: Tournament | TournamentLite) => ({login: {privs}}: RootState) => (
    tournament.controls.director || privs.admin || privs.tourn_admin
);
export const {
    setLoginFullName, setLoginUserId,
    setLoginCredentials, refreshCredentials,
    setLoginIsLoggedIn, setLoginJWTToken,
    setIsRegistering, setIsRequestingPasswordReset,
    setIsResettingPassword
} = slice.actions;

export default slice.reducer;
