import React, {useState} from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {Tournament, TournamentLite} from "../../models/Tournament";
import {useDeleteTournamentMutation} from "../../api/chessMasterApi";
import {
    prepareToDeleteTournamentKey,
    removeActiveTournamentKey
} from "../activeTab/activeTabSlice";
import {useDispatch} from "react-redux";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useNavigate} from "react-router-dom";

interface Props {
    tournament: Tournament | TournamentLite
    onClose: () => void
}

const TournamentDelete: React.FC<Props> = ({tournament, onClose}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteTournament, {isLoading: isUpdating}] = useDeleteTournamentMutation();
    const [errorMessage, setErrorMessage] = useState("");

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               size="lg">
            <Modal.Header>
                <Modal.Title>Delete tournament &mdash; {tournament.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Do you want to delete tournament “{tournament.name}”?</p>
                <p className="font-italic mx-5">
                    {tournament.description}
                </p>
            </Modal.Body>

            {(errorMessage) && (
                <Modal.Footer>
                    <div className="text-danger text-end">Error: {errorMessage}</div>
                </Modal.Footer>
            )}
            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup className="me-1">

                        <Button variant="secondary"
                                onClick={onClose}
                                disabled={isUpdating}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button type="submit"
                                variant="primary"
                                onClick={() => {
                                    dispatch(prepareToDeleteTournamentKey({key: tournament.key, isPreparedToDelete: true}));
                                    deleteTournament({key: tournament.key})
                                        .unwrap()
                                        .then(() => {
                                            dispatch(removeActiveTournamentKey(tournament.key));
                                            onClose();
                                        })
                                        .then(() => {
                                            dispatch(addToast(newToastSuccess({
                                                heading: "Tournament deleted",
                                                body: `Deleted tournament “${tournament.name}”`
                                            })));
                                        })
                                        .then(() => {
                                            navigate("/tournaments");
                                        })
                                        .catch((error) => {
                                            dispatch(prepareToDeleteTournamentKey({key: tournament.key, isPreparedToDelete: false}));
                                            setErrorMessage(error.data.message);
                                        });
                                }}
                                disabled={isUpdating}>
                            Delete
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default TournamentDelete;