import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Card from "react-bootstrap/Card";
import {Role} from "../../models/Role";

interface RoleItemProps {
    role: Role
    index: number
}

const RoleItem: React.FC<RoleItemProps> = ({role, index}) => (
    <Draggable draggableId={`${role.id}`} index={index}>
        {(provided, snapshot) => (
            <ListGroup.Item {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={provided.draggableProps.style}
                            className={(snapshot.isDragging) ? "bg-light" : ""}>
                {role.name}
            </ListGroup.Item>
        )}
    </Draggable>
);

interface RoleListProps {
    roles: Role[]
    placeholder?: React.ReactNode | null
}

const RoleList: React.FC<RoleListProps> = ({roles, placeholder}) => (
    <ListGroup className="scrolling-list-group">
        {roles.map((role, i) => <RoleItem key={role.id} role={role} index={i}/>)}
        {placeholder}
    </ListGroup>
);

interface DroppableRoleListProps {
    droppableId: string
    title: string
    roles: Role[]
}

// The Card acts as the droppable target

export const DroppableRoleList: React.FC<DroppableRoleListProps> = ({droppableId, title, roles}) => (
    <Droppable droppableId={droppableId} type="Role">
        {(provided, snapshot) => (
            <Card ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={(snapshot.isDraggingOver) ? "border border-dark" : ""}>
                <Card.Header>
                    <Card.Title>{title}</Card.Title>
                    <small>Drag and drop</small>
                </Card.Header>
                <Card.Body>
                    <RoleList roles={roles}
                              placeholder={provided.placeholder}/>
                </Card.Body>
            </Card>
        )}
    </Droppable>
);