import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

import {Game} from "../../models/Game";
import {removeActiveGame} from "./activeGamesSlice";
import {FaChessPawn} from "react-icons/fa";

const ActiveGameTabTitle: React.FC<{game: Game}> = ({game}) => {
    const dispatch = useDispatch();
    const closeGame = useCallback(
        () => dispatch(removeActiveGame(game)),
        [dispatch, game]
    );

    return (
        <>
            <span className="btn btn-close me-2" onClick={closeGame}/>
            <FaChessPawn className="me-1 tabIcon"/>
            <span>Game {game.id}</span>
        </>
    );
};

export default ActiveGameTabTitle;
