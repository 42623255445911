import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

import {NewUser, User, UserInfo} from "../../models/User";

interface ParentTabState {
    childEdit: User | NewUser | null
    runEditChild: User["id"] | undefined
    runChangePasswordForUser: {userId: UserInfo["id"], fullName: string} | undefined
}

const initialState: ParentTabState = {
    childEdit: null,
    runEditChild: undefined,
    runChangePasswordForUser: undefined
};

export const loadParent = createAction<void>("parentTab/loadParent");

export const slice = createSlice({
    name: "parentTab",
    initialState,
    reducers: {
        editChild: (state, action: PayloadAction<User | NewUser | null>) => {
            state.childEdit = action.payload;
        },
        setRunEditChild: (state, action: PayloadAction<User["id"] | undefined>) => {
            state.runEditChild = action.payload;
        },
        setRunChangePasswordForUser: (state, action: PayloadAction<{userId: UserInfo["id"], fullName: string} | undefined>) => {
            state.runChangePasswordForUser = action.payload;
        }
    }
});

export const selectParentTab = (state: RootState) => state.parentTab;
export const {
    editChild,
    setRunEditChild, setRunChangePasswordForUser
} = slice.actions;

export default slice.reducer;
