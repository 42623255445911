import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";
import {createAction, PrepareAction} from "@reduxjs/toolkit";

import io, {Socket} from "socket.io-client";

import {GameServerSocketIOURL} from "../Api";
import {AppDispatch, RootState} from "../store";

export interface AddSocketListener {
    eventName: string
    actionName: string
    meta: unknown
}

export interface RemoveSocketListener {
    eventName: string
}

export interface SocketEmit {
    eventName: string
    data: any
}

/**
 * Create an action to start listening for socket.io events of eventType which will be
 * dispatched as redux action. Data in the body of the event will be attached as the
 * action's payload.
 *
 * @param wsUrl - the web socket url to connect to
 * @param eventName - the socket.io event type to listen for
 * @param actionName - the redux event type to dispatch
 * @param meta - some additional data which will be in action.meta
 *
 * @public
 */
export const addSocketListener = createAction<PrepareAction<AddSocketListener>>("socketIO/addSocketListener",
    (wsUrl: string, eventName: string, actionName: string, meta: unknown) => ({
            payload: {eventName, actionName, meta}}));

/**
 * Stop listening for socket.io events of eventType.
 *
 * @param wsUrl - the web socket url to connect to
 * @param eventName - the socket.io event type to stop listening for
 *
 * @public
 */
export const removeSocketListener = createAction<PrepareAction<RemoveSocketListener>>("socketIO/removeSocketListener",
    (wsUrl: string, eventName: string) => ({payload: {eventName}}));

/**
 * Emit a socket.io message with eventName and data
 *
 * @param eventName - the socket.io event type to send
 * @param data - the data to send in the message
 *
 * @public
 */
export const socketEmit = createAction<PrepareAction<SocketEmit>>('socketIO/emit',
    (eventName: string, data: any) => ({payload: {eventName, data}}));

const urlToSocketIO: Map<string, Socket> = new Map();
const socketForURL = (url: string) => {
    if (urlToSocketIO.has(url)) {
        return urlToSocketIO.get(url)!;
    } else {
        const socket = io(GameServerSocketIOURL);
        urlToSocketIO.set(url, socket);
        return socket;
    }
};

const socketIO: Middleware = <S extends RootState>(store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => <A extends AnyAction>(action: A): A => {
    const dispatch: AppDispatch = store.dispatch;
    let socket;

    switch (action.type) {
    case socketEmit.type:
        socket = socketForURL(action.payload.wsUrl);
        socket.emit(action.payload.eventName, action.payload.data);
        break;

    case addSocketListener.type:
        socket = socketForURL(action.payload.wsUrl);
        socket.on(action.payload.eventName, (data: any) => dispatch({
            type: action.payload.actionName,
            payload: data,
            meta: action.payload.meta
        }));
        break;

    case removeSocketListener.type:
        socket = socketForURL(action.payload.wsUrl);
        socket.off(action.payload.eventName);
        break;
    }
    return next(action);
};

export default socketIO;