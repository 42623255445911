import * as yup from "yup";
import zxcvbn from "zxcvbn";

export interface BasePasswordForm {
    new_password: string
    score: number
}

export interface PasswordForm extends BasePasswordForm {
    confirm_password: string
}

export const newPasswordForm = (): PasswordForm => ({
    new_password: "",
    confirm_password: "",
    score: 0
});

export const basePasswordSchema = yup.object().shape({
    new_password: yup.string().required().min(4)
        .test({
            name: "password strength",
            message: "Password is too weak",
            test: (value, context) => (value) ? zxcvbn(value).score >= 2 : false
        })
        .label("Password"),
    score: yup.number()
});

export const passwordSchema = basePasswordSchema.shape({
    confirm_password: yup.string().required()
        .oneOf([yup.ref("new_password")], "Passwords don't match")
        .label("Confirm password")
});

export const optionalPasswordSchema = passwordSchema.shape({
        new_password: yup.string()
            .test({
                name: "empty-or-min-4",
                message: "Password must be empty or minimum 4 characters",
                test: (value) => !value || value.length >= 4
            })
            .test({
                name: "password strength",
                message: "Password is too weak",
                test: (value) => (
                    (value) ? zxcvbn(value).score >= 2 : true
                )
            })
            .label("Password"),
        confirm_password: yup.string()
            .oneOf([yup.ref("new_password")], "Passwords don't match")
            .label("Confirm password")
});
