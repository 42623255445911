import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {BsFillArrowUpSquareFill, BsXSquareFill} from "react-icons/bs";
import {formatMoney} from "accounting";
import {useDebouncedCallback} from "use-debounce";
import {DevTool} from "@hookform/devtools";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {chessMasterApi, usePaymentInitiateMutation} from "../../api/chessMasterApi";
import {InterimPaymentItem, Payment} from "../../models/Payment";
import FinanceSearch from "./FinanceSearch";
import PaymentListItems from "./PaymentItemsList";
import {setApiError} from "../api/ApiSlice";
import {addToast, newToastMessage} from "../toast/toastSlice";
import UserPaymentDetails from "./UserPaymentDetails";
import {UserInfo} from "../../models/User";
import {useAppDispatch} from "../../store";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import { SearchErrorMessageContext } from "../../components/SearchErrorMessageContext";

const AddCheckbox = React.forwardRef<HTMLElement>((props, ref)=> (
    <BsFillArrowUpSquareFill {...props} className="fs-5"/>
));

const RemoveCheckbox = React.forwardRef<HTMLElement>((props, ref) => (
    <BsXSquareFill {...props} className="fs-5"/>
));

const addItems = (paymentItems: InterimPaymentItem[], items: InterimPaymentItem[]) => {
    const ids = new Set(paymentItems.map(item => item.identifier));

    return [...paymentItems, ...items.filter((item) => !ids.has(item.identifier))];
};
const removeItems = (paymentItems: InterimPaymentItem[], items: InterimPaymentItem[]) => {
    const removeIds = new Set(items.map(item => item.identifier));

    return paymentItems.filter((item) => !removeIds.has(item.identifier));
};

export interface UserPaymentForm {
    reference: Payment["reference"]
    amount: Payment["amount"]
    credit: Payment["credit"]
    payer: UserInfo | null
}

interface Props {
    payment?: Payment
    onClose: () => void
}

const FinanceUserPayment: React.FC<Props> = ({payment, onClose}) => {
    const dispatch = useAppDispatch();
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>("");
    const [query, setQuery] = useState("");
    const setDebouncedQuery = useDebouncedCallback(setQuery, 300);
    const [paymentItems, setPaymentItems] = useState<InterimPaymentItem[]>((payment) ? payment.items : []);
    const [paymentItemsFromSearch, setPaymentItemsFromSearch] = useState<InterimPaymentItem[]>([]);
    const [lazySearchPaymentEntry] = chessMasterApi.endpoints.searchPaymentEntry.useLazyQuery();
    const [lazyInterimPaymentForUser] = chessMasterApi.endpoints.interimPaymentForUser.useLazyQuery();
    const [paymentInitiate] = usePaymentInitiateMutation();
    const defaultValues: UserPaymentForm = {
        reference: payment?.reference ?? "",
        amount: "-0.00",     // we will update this from payment.amount later but need to make sure it will differ
        credit: payment?.credit ?? "0.00",
        payer: payment?.payer ?? null
    };
    const formMethods = useForm<UserPaymentForm>({
        defaultValues
    });
    const payer = formMethods.watch("payer");
    const amount = Number(formMethods.watch("amount"));
    const credit = Number(formMethods.watch("credit"));
    const itemTotal = paymentItems.reduce((sum, item) => sum + Number(item.amount), 0);
    const paymentAmount = (formMethods.formState.dirtyFields.amount) ? amount : Math.max(itemTotal - credit, 0);
    const paymentDescription = (paymentAmount: number, paymentItems: InterimPaymentItem[]) => (
        `${formatMoney(paymentAmount, "$")} for ${paymentItems.length} ${(paymentItems.length === 1) ? "item" : "items"}`
    );
    const omitPaymentItems = (items: InterimPaymentItem[]) => {
        const removeIds = new Set(paymentItems.map(item => item.identifier));

        return items.filter((item) => !removeIds.has(item.identifier));
    };
    const onSubmit = ({reference, payer}: UserPaymentForm) => {
        paymentInitiate({
            id: payment?.id,
            items: paymentItems.map((payment) => payment.identifier),
            reference,
            payment_system: "manual",
            amount: Number(paymentAmount).toFixed(2),
            payer_id: payer?.id ?? null
        })
            .unwrap()
            .then((payment) => {
                dispatch(addToast(newToastMessage({
                    heading: "Manual payment",
                    body: paymentDescription(Number(payment.amount), payment.items),
                    priority: "success"
                })));
                dispatch(chessMasterApi.util.invalidateTags(["Payment"]));
                onClose();
            })
            .catch((error) => dispatch(setApiError(error.data.message)));
    };
    const formatItemTotalBalance = () => [
        `Item total ${formatMoney(itemTotal, "$")}`,
        ...(amount !== itemTotal) ? [`balance ${formatMoney(amount - itemTotal, "$")}`] : []
    ].join(", ");

    // When the user changes the payer and nothing is in the search field, automatically
    // search for the payment items for the payer and display them.
    useEffect(() => {
        if (query && query.length >= 3) {
            lazySearchPaymentEntry({query})
                .unwrap()
                .then((payment) => {
                    setPaymentItemsFromSearch(payment.items);
                    setSearchErrorMessage(null);
                })
                .catch((error) => setSearchErrorMessage(formatErrorMessage(error)));
        }
    }, [dispatch, lazySearchPaymentEntry, query]);
    useEffect(() => {
        if (payer) {
            lazyInterimPaymentForUser({id: payer.id})
                .unwrap()
                .then((interimPayment) => {
                    if (!query) {
                        setPaymentItemsFromSearch(interimPayment.items);
                    }
                    formMethods.setValue("credit", interimPayment.credit);
                })
                .catch((error) => dispatch(setApiError(error.data.message)));
        } else if (!query) {
            setPaymentItemsFromSearch([]);
        }
    }, [dispatch, formMethods, lazyInterimPaymentForUser, payer, query]);

    useEffect(() => {
        // only update total if the user hasn't changed it
        if (!formMethods.formState.dirtyFields.amount) {
            formMethods.setValue("amount", paymentAmount.toFixed(2));
        }
    }, [credit, formMethods, itemTotal, paymentAmount]);
    useEffect(() => {
        // when we are editing a payment, if the payment amount differs from the item total,
        // set that as the amount paid and mark it dirty. It must differ from the defaultValue
        // or dirty won't be set which is why we initialised it to "-0.00" above for the case
        // when the payment amount was set to 0. This may be a bit too clever ...
        if (payment) {
            const itemTotal = payment.items.reduce((sum, item) => sum + Number(item.amount), 0);

            if (Number(payment.amount) !== itemTotal) {
                formMethods.setValue("amount", payment.amount, {shouldDirty: true});
            }
        }
    }, [formMethods, payment]);

    return (
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Selected payment items</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <FormProvider {...formMethods}>
                                    <UserPaymentDetails itemTotal={itemTotal}/>
                                </FormProvider>
                            </Card.Body>
                            <Card.Body>
                                <PaymentListItems items={paymentItems ?? []}
                                                  selectionComponent={RemoveCheckbox}
                                                  onSelected={(items) => setPaymentItems(removeItems(paymentItems, items))}/>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonToolbar className="float-end">
                                    {(Number(credit) > 0) && (
                                        <InputGroup.Text className="bg-success text-white me-2">
                                            Credit {formatMoney(credit, "$")}
                                        </InputGroup.Text>
                                    )}
                                    <InputGroup.Text className="text-info me-2">
                                        {formatItemTotalBalance()}
                                    </InputGroup.Text>
                                    <ButtonGroup>
                                        <Button type="submit"
                                                disabled={!payer || (paymentItems.length === 0 && Number(paymentAmount) === 0)}>
                                            Pay {paymentDescription(paymentAmount, paymentItems)}
                                        </Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Add payment items</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                                    <FinanceSearch query={query}
                                                   setQuery={setDebouncedQuery}/>
                                </SearchErrorMessageContext.Provider>
                            </Card.Body>
                            <Card.Body>
                                <PaymentListItems items={omitPaymentItems(paymentItemsFromSearch)}
                                                  selectionComponent={AddCheckbox}
                                                  onSelected={(items) => setPaymentItems(addItems(paymentItems, items))}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <DevTool control={formMethods.control}/>
        </Form>
    );
};

export default FinanceUserPayment;
