import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import MultiPersonPicker from "../../components/multiPersonPicker/MultiPersonPicker";

import {UserInfo} from "../../models/User";
import {ChildrenForUserResponse} from "../../api/chessMasterApi";

interface Props {
    child: UserInfo
    guardians: ChildrenForUserResponse
    setGuardians: (children: ChildrenForUserResponse) => void
}

export const UserEditGuardian: React.FC<Props> = ({child, guardians, setGuardians}) => {
    const updateGuardians = (guardians: UserInfo[]) => {
        setGuardians(guardians.map((guardian) => ({child, guardian, guardian_type: null})));
    };

    return (
        <Container>
            <Form.Group as={Row} controlId="userGuardian">
                <Form.Label column md={2}
                            className="text-end">
                    Guardians
                </Form.Label>
                <Col md={10}>
                    <MultiPersonPicker onChange={updateGuardians}
                                       selectedUserInfos={(guardians ?? []).map((cfu) => cfu.guardian)}
                                       isInvalid={false}/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default UserEditGuardian;