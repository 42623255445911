import React from "react";

// 3rd party
import DataTable, {TableColumn} from "react-data-table-component";

import {formatFullName} from "../../util/formatFullName";
import {Rating} from "../../models/Rating";
import {nameSortFunc} from "../../util/nameSortFunc";
import {useSearchRatingQuery} from "../../api/chessMasterApi";
import {TournamentType} from "../../models/TournamentType";

interface Props {
    tournamentType: TournamentType
    query: string
}

export const RatingList: React.FC<Props> = ({tournamentType, query}) => {
    const {data: ratings, isFetching} = useSearchRatingQuery({tournamentTypeId: tournamentType.id, query});
    const columns: TableColumn<Rating>[] = [{
        selector: (row) => (row.user) ? formatFullName(row.user) : "",
        name: "Player",
        sortable: true,
        sortFunction: (a, b) => nameSortFunc(a.user, b.user),
        format: (row) => formatFullName(row.user) + ` (${row.user.username})`
    }, {
        selector: (row) => row.provisional || row.finalised,
        name: "Rating",
        sortable: true,
        right: true,
    }];

    return (
        <DataTable<Rating> data={ratings ?? []}
                           columns={columns}
                           keyField="id"
                           progressPending={isFetching}
                           noDataComponent="(No ratings)"
                           pagination/>
    );
};

export default RatingList;
