import React, {useCallback, useState} from "react";

import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

import {UserInfo} from "../../models/User";
import DataTable, {TableColumn} from "react-data-table-component";
import {formatFullName, formatFullNameWithUsername} from "../../util/formatFullName";
import {nameSortFunc} from "../../util/nameSortFunc";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {useGroupUsersForGroupQuery} from "../../api/chessMasterApi";
import {Group} from "../../models/Group";
import {GroupUser} from "../../models/GroupUser";
import Badge from "react-bootstrap/Badge";

interface Props {
    group: Group
    assignedUsers: UserInfo[]
    setAssignedUsers: (users: UserInfo[]) => void
    isLoading?: boolean
}

const GroupMembershipEditor: React.FC<Props> = ({group, assignedUsers, setAssignedUsers, isLoading}) => {
    const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([]);
    const {data: userGroups} = useGroupUsersForGroupQuery({groupId: group.id});
    const handleUsersSelected = useCallback((
            {selectedRows}: {selectedRows: UserInfo[]}) => setSelectedUsers(selectedRows),
        []);
    const removeSelected = (users: UserInfo[]) => {
        const selectedUserIds = new Set(users.map((user) => user.id));
        setAssignedUsers(assignedUsers.filter((user) => !selectedUserIds.has(user.id)));
    };
    const userToUserGroup = (userGroups ?? []).reduce((acc: Record<UserInfo["id"], GroupUser>, userGroup: GroupUser) => {
        acc[userGroup.user.id] = userGroup;
        return acc;
    }, {});
    const columns: TableColumn<UserInfo>[] = [{
        selector: (row) => formatFullName(row),
        name: "Name",
        sortable: true,
        grow: 8,
        sortFunction: (a, b) => nameSortFunc(a, b),
        format: (row) => <>
            {formatFullNameWithUsername(row)} {(userToUserGroup[row.id]?.group_admin) ? <Badge bg="warning">Admin</Badge> : null}
        </>,
    }, {
        selector: (row) => (row.age) ?? "",
        name: "Age",
        sortable: true,
        grow: 8
    }, {
        name: "Groups",
        grow: 8,
        format: (row) => row.groups.sort().join(", ")
    }];

    return (
        <Container fluid>
            <Row>
                <DataTable<UserInfo> data={assignedUsers}
                                     columns={columns}
                                     selectableRows
                                     onSelectedRowsChange={handleUsersSelected}
                                     keyField="id"
                                     noDataComponent="(No members)"
                                     progressPending={isLoading}
                                     className="w-100 mt-3"
                                     pagination/>
            </Row>
            <Row>
                <div className="d-flex justify-content-end">
                    <ButtonToolbar className="float-end mt-3">
                        <ButtonGroup className="me-1">
                            <Button onClick={() => removeSelected(selectedUsers)}
                                    variant="danger"
                                    disabled={selectedUsers.length === 0}>
                                Remove selected
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
            </Row>
        </Container>
    );
};

export default GroupMembershipEditor;
