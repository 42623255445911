import React, {useMemo, useState} from "react";

import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe, StripeCardElementOptions} from "@stripe/stripe-js";
import {BsExclamationCircle} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {StripeConfig, usePaymentCompletedMutation} from "../../api/chessMasterApi";
import {Payment} from "../../models/Payment";
import {
    addToast,
    newToastError,
    newToastSuccess
} from "../toast/toastSlice";
import {useDispatch} from "react-redux";

interface BillingDetails {
    name: string
    phone: string
    email: string
}

interface StripeCardDetailsProps {
    billingDetails: BillingDetails
    setBillingDetails: (billingDetails: BillingDetails) => void
}

const ErrorMessage: React.FC<{ message: string }> = ({message}) => (
    <div className="ErrorMessage" role="alert">
        <BsExclamationCircle />{" "}{message}
    </div>
);
  
const StripeCardDetails: React.FC<StripeCardDetailsProps> = ({billingDetails, setBillingDetails}) => {
    return (
        <>
            <InputGroup>
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control type="text"
                              placeholder=""
                              required
                              value={billingDetails.name}
                              onChange={(ev) => setBillingDetails({
                                  ...billingDetails,
                                  name: ev.target.value
                              })}/>
            </InputGroup>
            <InputGroup>
                <InputGroup.Text>E-mail</InputGroup.Text>
                <Form.Control type="email"
                              placeholder=""
                              required
                              value={billingDetails.email}
                              onChange={(ev) => setBillingDetails({
                                  ...billingDetails,
                                  email: ev.target.value
                              })}
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text>Phone</InputGroup.Text>
                <Form.Control type="text"
                              placeholder=""
                              required
                              value={billingDetails.phone}
                              onChange={(ev) => setBillingDetails({
                                  ...billingDetails,
                                  phone: ev.target.value
                              })}/>
            </InputGroup>
        </>
    );
};

interface StripeButtonProps {
    payment: Payment;
    onClose: (isPaymentCompleted: boolean) => void;
}

const StripeButton: React.FC<StripeButtonProps> = ({payment, onClose}) => {
    const dispatch = useDispatch();
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [paymentCompleted] = usePaymentCompletedMutation();
    const elements = useElements();
    const stripe = useStripe();
    const [error, setError] = useState<string|null|undefined>(null);
    const [billingDetails, setBillingDetails] = useState({
        email: "",
        phone: "",
        name: ""
    });

    const payWithStripe = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (!stripe || !elements) {
            alert("Error initialising Stripe");
            return;
        }
        // const result = await stripe.redirectToCheckout({
        //     sessionId: payment.payment_token
        // });
        const card = elements.getElement(CardElement);
        if (card === null) {
            setError("Stripe missing element Card");
            return;
        }
        setPaymentInProgress(true);
        stripe.confirmCardPayment(payment.payment_token, {
            payment_method: {
                card: card,
                billing_details: billingDetails
            }
        })
            .then((result) => {
                if (result.error) {
                    setError(result.error.message);
                    setPaymentInProgress(false);
                } else if (result.paymentIntent.status === "succeeded") {
                    paymentCompleted({payment})
                        .unwrap()
                        .then(() => onClose(true))
                        .then(() => {
                            dispatch(addToast(newToastSuccess({
                                heading: "Stripe",
                                body: "Payment succeeded"
                            })));
                        })
                        .catch((err) => setError(err.toString()));
                } else {
                    setError(`Stripe processing: status ${result.paymentIntent.status}, result ${result}`);
                    setPaymentInProgress(false);
                    dispatch(addToast(newToastError({
                        heading: "Stripe",
                        body: `Payment failed: ${result.paymentIntent.status}`
                    })));
                }
            })
            .catch((error) => {
                setError(error.message);
                alert("confirmCardPayment: error, see log");
                console.log("confirmCardPayment: error", error);
                setPaymentInProgress(false);
                dispatch(addToast(newToastError({
                    heading: "Stripe",
                    body: error.message
                })));
            });
    };
    const options: StripeCardElementOptions = {
        iconStyle: "solid",
        style: {
            base: {
                fontSize: '16px',
                lineHeight: '2',
                color: '#ffffff',
                iconColor: '#dddddd',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#ffa9a9',
            }
        }
    };
    
    return (
        <Form className="PayUsingStripe" onSubmit={payWithStripe}>
            <StripeCardDetails billingDetails={billingDetails} setBillingDetails={setBillingDetails}/>
            <CardElement options={options}/>
            {(error) && <ErrorMessage message={error}/>}
            <Button type="submit" disabled={paymentInProgress}>
                {(!paymentInProgress) ? "Pay via Stripe™" : "Payment in progress ..."}
            </Button>
        </Form>
    );
};

interface Props {
    payment: Payment
    stripeConfig: StripeConfig
    onClose: (isPaymentCompleted: boolean) => void
}

const PayUsingStripeButton: React.FC<Props> = ({payment, stripeConfig, onClose}) => {
    const stripePromise = useMemo(
        () => loadStripe(stripeConfig.public_key),
        [stripeConfig.public_key]
    );

    return (
        <Elements stripe={stripePromise}>
            <StripeButton payment={payment} onClose={onClose}/>
        </Elements>
    );
};

export default PayUsingStripeButton;