import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import {useChangePasswordMutation} from "../../api/chessMasterApi";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {User} from "../../models/User";
import PasswordStrengthBar from "react-password-strength-bar";
import {DevTool} from "@hookform/devtools";
import {PasswordForm, passwordSchema} from "../../util/passwordSchema";

interface ChangePasswordForm extends PasswordForm {
    password: string        // existing password
}

const changePasswordSchema = passwordSchema.shape({
    password: yup.string().required().min(3).label("Existing password")
});

interface ChangePasswordProps {
    userId: User["id"]
    fullName: string
    onClose?: () => void
}

const ChangePassword: React.FC<ChangePasswordProps> = ({userId, fullName, onClose}) => {
    const dispatch = useDispatch();
    const [changePassword] = useChangePasswordMutation();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const {handleSubmit, register, control, formState: {errors}, watch, setValue} = useForm<ChangePasswordForm>({
        resolver: yupResolver(changePasswordSchema)
    });
    const new_password = watch("new_password");
    const onSubmit = ({password, new_password}: ChangePasswordForm) => {
        changePassword({user_id: userId, auth_password: password, new_password})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: "Password updated",
                    body: `Password updated for ${fullName}`
                })));
                onClose?.();
            })
            .catch((error) => setErrorMessage(error.data.message));
    };
    register("score");

    return (
        <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card.Body>
                    <Form.Group as={Row} className="mb-3"
                                controlId="changePasswordOld">
                        <Form.Label column md="4">
                            Your password
                        </Form.Label>
                        <Col md="8">
                            <Form.Control type="password"
                                          {...register("password")}
                                          isInvalid={!!errors.password}
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>} errors={errors}
                                          name="password"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3"
                                controlId="changePasswordNew">
                        <Form.Label column md="4">
                            New password
                        </Form.Label>
                        <Col md="8">
                            <Form.Control type="password"
                                          {...register("new_password")}
                                          isInvalid={!!errors.new_password}
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>} errors={errors}
                                          name="new_password"
                                          type="invalid"/>
                            <PasswordStrengthBar password={new_password}
                                                 onChangeScore={(score) => setValue("score", score)}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3"
                                controlId="changePasswordConfirm">
                        <Form.Label column md="4">
                            Confirm password
                        </Form.Label>
                        <Col md="8">
                            <Form.Control type="password"
                                          {...register("confirm_password")}
                                          isInvalid={!!errors.confirm_password}
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>} errors={errors}
                                          name="confirm_password"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Card.Body>

                {(errorMessage) && (
                    <Card.Footer className="text-end">
                        <span className="text-danger">Error: {errorMessage}</span>
                    </Card.Footer>
                )}

                <Card.Footer className="text-end">
                    <Button size="sm"
                            variant="secondary"
                            className="me-1"
                            onClick={onClose}>Cancel</Button>
                    <Button size="sm"
                            onClick={handleSubmit(onSubmit)}>Change password</Button>
                </Card.Footer>
            </Form>
            <DevTool control={control}/>
        </Card>
    );
};

export default ChangePassword;
