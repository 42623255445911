
// split the ids into a triple: {added: ids, unchanged: ids, removed: ids}

export type ChangeSet<T> = {added: T[], unchanged: T[], removed: T[]};

export const partitionChanges = <T>(oldItems: T[], newItems: T[]): ChangeSet<T> => {
    const oldSet = new Set<T>(oldItems), newSet = new Set<T>(newItems);

    const added = newItems.filter((n) => !oldSet.has(n));
    const unchanged = oldItems.filter((n) => newSet.has(n));
    const removed = oldItems.filter((n) => !newSet.has(n));

    return {added, unchanged, removed};
};

export const partitionChangesById = <T extends {id: number}>(oldItems: T[], newItems: T[]): ChangeSet<T> => {
    const oldSet = new Set<number>(oldItems.map((item) => item.id)),
          newSet = new Set<number>(newItems.map((item) => item.id));

    const added = newItems.filter((item) => !oldSet.has(item.id));
    const unchanged = oldItems.filter((item) => newSet.has(item.id));
    const removed = oldItems.filter((item) => !newSet.has(item.id));

    return {added, unchanged, removed};
};
