import React, {useContext} from 'react';
import {useDispatch} from "react-redux";
import {useDebouncedCallback} from "use-debounce";
import {BsArrowClockwise, BsInfoCircle} from 'react-icons/bs';

import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import {chessMasterApi} from "../../api/chessMasterApi";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {auditSearchHelpPopover} from "../../util/SearchHelpPopover";

interface Props {
    query: string
    setQuery: (query: string) => void
}

const AuditSearch: React.FC<Props> = ({query, setQuery}) => {
    const dispatch = useDispatch();
    const {searchErrorMessage} = useContext(SearchErrorMessageContext);
    const setDebouncedQuery = useDebouncedCallback((value: string) => setQuery(value), 300);
    const refreshAudit = () => {
        dispatch(chessMasterApi.util.invalidateTags(["Audit"]));
    };

    return (
        <div className="mb-3">
            <InputGroup className={(searchErrorMessage) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              defaultValue={query}
                              onChange={(ev) => setDebouncedQuery(ev.target.value)}
                              placeholder="Search ..."/>
                <OverlayTrigger trigger="click" placement="bottom"
                                overlay={auditSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
                <Button variant="outline-primary"
                        onClick={refreshAudit}>
                    <BsArrowClockwise/>
                </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {searchErrorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default AuditSearch;