// format errors from the server api from the error.data.errors.json field
import React from "react";

export const formatJsonErrors = (jsonErrors: {} | undefined) => {
    if (jsonErrors) {
        return (
            <ul>
                {Object.entries(jsonErrors).map(([key, message], index) => (
                    <li key={index}><>{key}: {message}</></li>
                ))}
            </ul>
        );
    } else {
        return null
    }
};

export const formatArrayJsonErrors = (arrayJsonErrors: {}[] | undefined) => {
    if (arrayJsonErrors) {
        return (
            <ul>
                {Object.entries(arrayJsonErrors).map(([key, jsonErrors], index) => (
                    <li key={index}>{key}: {formatJsonErrors(jsonErrors)}</li>
                ))}
            </ul>
        );
    } else {
        return null;
    }
};