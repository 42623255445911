// sort highest to lowest
import {Group} from "../models/Group";
import {
    sortTournamentPlayersByPoints,
    TournamentPlayer
} from "../models/TournamentPlayer";

export const sortPlayersInTeamsByPoints = (playersByTeam: Map<Group["id"], TournamentPlayer[]>) => {
    const sortedPlayersByTeam = new Map<Group["id"], TournamentPlayer[]>();

    playersByTeam.forEach((players, teamId) => {
        sortedPlayersByTeam.set(teamId, sortTournamentPlayersByPoints(players));
    });

    return sortedPlayersByTeam;
};