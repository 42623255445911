import React from "react";
import DataTable, {ConditionalStyles, TableColumn} from "react-data-table-component";
import {BsArrowReturnRight} from "react-icons/bs";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";

import {
    useDownloadTournamentContactsCSVMutation,
    useGetTournamentContactDetailsQuery
} from "../../api/chessMasterApi";
import {Tournament} from "../../models/Tournament";
import {UserContactDetails} from "../../models/UserContactDetails";
import {User} from "../../models/User";
import {formatFullName} from "../../util/formatFullName";
import {selectConstantsGenderById} from "../constants/constantsSlice";
import UserEMailAddress from "../../components/UserEMailAddress";
import Button from "react-bootstrap/Button";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useAppDispatch, useAppSelector} from "../../store";

interface ContactDetailsTableProps {
    contactDetails: UserContactDetails
    userIds: User["id"][]
    isGuardian?: boolean
}

const ContactDetailsTable: React.FC<ContactDetailsTableProps> = ({contactDetails, userIds, isGuardian= false}) => {
    const genderById = useAppSelector(selectConstantsGenderById);
    const {ref_user, ref_group} = contactDetails;
    const conditionalRowStyles: ConditionalStyles<User["id"]>[] = [{
        when: (row) => isGuardian,
        classNames: ["bg-light"]
    }];
    const columns: TableColumn<User["id"]>[] = [{
        name: "Name",
        grow: 2,
        sortable: true,
        selector: (row) => formatFullName(ref_user[row]),
        cell: (row) => (
            <span>{(isGuardian) ? <span><BsArrowReturnRight/> <i>Guardian</i>: </span> : ""}{formatFullName(ref_user[row])}</span>
        )
    }, {
        name: "Gender",
        sortable: true,
        selector: (row) => genderById[ref_user[row].gender_id].description,
        format: (row) => genderById[ref_user[row].gender_id].description
    }, {
        name: "School year",
        sortable: true,
        selector: (row) => ref_user[row].school_year ?? 0,
        format: (row) => ref_user[row].school_year
    }, {
        name: "E-mail",
        grow: 2,
        cell: (row) => <UserEMailAddress user={ref_user[row]}/>
    }, {
        name: "Phone",
        wrap: true,
        selector: (row) => ref_user[row].phone_num ?? "",
        format: (row) => ref_user[row].phone_num
    }, {
        name: "Phone notes",
        grow: 2,
        wrap: true,
        selector: (row) => ref_user[row].phone_num_note ?? "",
        format: (row) => ref_user[row].phone_num_note
    }, {
        name: "Medical notes",
        grow: 2,
        wrap: true,
        selector: (row) => ref_user[row].medical_note ?? "",
        format: (row) => ref_user[row].medical_note
    }, {
        name: "Groups",
        grow: 2,
        wrap: true,
        selector: (row) => ref_user[row].group_ids.map((gid) => ref_group[gid].name).sort().join(", "),
        format: (row) => ref_user[row].group_ids.map((gid) => ref_group[gid].name).sort().join(", ")
    }];

    return (
        <DataTable<User["id"]> columns={columns}
                               data={userIds}
                               noTableHead={isGuardian}
                               conditionalRowStyles={conditionalRowStyles}
                               expandableRows={true}
                               expandableRowsComponent={({data: userId}) => (
                                   (contactDetails) && (
                                        <ContactDetailsTable contactDetails={contactDetails}
                                                             userIds={ref_user[userId].guardian_ids}
                                                             isGuardian/>
                                    )
                               )}
                               expandableRowExpanded={(row) => ref_user[row].guardian_ids.length > 0}
                               expandableRowsHideExpander={true}
                               expandableIcon={{collapsed: undefined, expanded: undefined}}/>
    );
};

interface Props {
    tournament: {
        key: Tournament["key"]
        name: string
    }
    onClose: () => void
}

const TournamentPlayerContactDetailView: React.FC<Props> = ({tournament, onClose}) => {
    const dispatch = useAppDispatch();
    const {data: contactDetails, error} = useGetTournamentContactDetailsQuery({key: tournament.key});
    const [downloadTournamentContactsCSV] = useDownloadTournamentContactsCSVMutation();
    const runSaveContactsCSV = (t: typeof tournament) => {
        downloadTournamentContactsCSV({key: tournament.key})
            .unwrap()
            .then(({filename}) => {
                dispatch(addToast(newToastSuccess({
                    heading: "CSV download complete",
                    body: `CSV file downloaded to "${filename}"`
                })))
            })
    };

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               fullscreen
               dialogClassName="w-100">
            <Modal.Header closeButton>
                <Modal.Title className="w-100">
                    <Stack direction="horizontal" gap={5}>
                        <span>
                            Tournament “{tournament.name}” &mdash; Player contact details
                        </span>
                        <span className="ms-auto">
                            <Button variant="secondary"
                                    size="sm"
                                    onClick={() => runSaveContactsCSV(tournament)}>
                                Save CSV ...
                            </Button>
                        </span>
                    </Stack>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(contactDetails) ? (
                    <ContactDetailsTable contactDetails={contactDetails} userIds={contactDetails.player_ids}/>
                ) : (
                    <Spinner className="text-center" animation="border"/>
                )}
            </Modal.Body>

            {(error) && (
                <Modal.Footer>
                    <span className="text-danger">{JSON.stringify(error)}</span>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default TournamentPlayerContactDetailView;
