import {newTournamentStaffUpdate, TournamentStaff} from "../models/TournamentStaff";

export const createStaffUpdates = (oldStaff: TournamentStaff[], staff: TournamentStaff[]) => {
    const oldIdStaff = Object.fromEntries(oldStaff.map((ts) => [ts.user_id, ts])),
          idStaff = Object.fromEntries(staff.map((ts) => [ts.user_id, ts]));
    const deletedStaff = oldStaff.filter((ts) => !idStaff[ts.user_id]);
    const changedStaff = staff.filter((ts) => !oldIdStaff[ts.user_id] || ts !== oldIdStaff[ts.user_id]);

    return [
        ...deletedStaff.map((ts) => newTournamentStaffUpdate(ts.user_id, {_delete: true})),
        ...changedStaff.map((ts) => newTournamentStaffUpdate(ts.user_id, {director: ts.director}))
    ]
};
