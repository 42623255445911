import React, {useContext} from 'react';
import {useDispatch} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {BsArrowClockwise, BsInfoCircle} from 'react-icons/bs';

import {chessMasterApi} from "../../api/chessMasterApi";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {financeSearchHelpPopover} from "../../util/SearchHelpPopover";

interface Props {
    query: string
    setQuery: (query: string) => void
    // searchErrorMessage?: string | null
}

const FinanceSearch: React.FC<Props> = ({query, setQuery}) => {
    const dispatch = useDispatch();
    const {searchErrorMessage} = useContext(SearchErrorMessageContext);
    const setDebouncedQuery = useDebouncedCallback((value: string) => setQuery(value), 300);
    const refreshFinanceResults = () => {
        dispatch(chessMasterApi.util.invalidateTags(["Payment"]));
    };

    return (
        <div className="mb-3">
            <InputGroup className={(searchErrorMessage) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              defaultValue={query}
                              onChange={(ev) => setDebouncedQuery(ev.target.value)}
                              placeholder="Search ..."
                              isInvalid={!!searchErrorMessage}/>
                <OverlayTrigger trigger="click" placement="bottom" overlay={financeSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
                <Button variant="outline-primary"
                        onClick={refreshFinanceResults}>
                    <BsArrowClockwise/>
                </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">{searchErrorMessage}</Form.Control.Feedback>
        </div>
    );
};

export default FinanceSearch;