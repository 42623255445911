import React, {useMemo} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

// import {GameTab} from "../features/gameTab/GameTab";
import {
    ActiveTabDetail,
    addActiveTournament,
    selectActiveTab
} from "../features/activeTab/activeTabSlice";
import {Privileges} from "../models/Privileges";
import {selectActiveGames} from "../features/activeGames/activeGamesSlice";
import {selectLogin} from "../features/login/loginSlice";
import {selectConstantsNamedRoleInRoleIds} from "../features/constants/constantsSlice";
import {makeActiveGameTabs} from "../features/activeGames/makeActiveGameTabs";
import ActiveTournamentTabTitle
    from "../features/activeTournaments/ActiveTournamentTabTitle";
import {tournamentTabEventKeyFromTournament} from "../util/tabEventKey";
import styles from "./PawnBrokerTabs.module.css";
import {useAppDispatch, useAppSelector} from "../store";


const tabNameFromPath = (pathname: string) => {
    const pathComponents = pathname && pathname.split("/");

    if (pathComponents.length < 2) {
        return DEFAULT_TAB;
    }
    const tabName = pathComponents[1];
    if (tabName === "tournament") {     // include tournament id if it's a tournament
        if (pathComponents.length < 3) {
            return DEFAULT_TAB;
        }
        return `${tabName}/${pathComponents[2]}`;
    }
    return tabName;
};

const initialTabs = (privs: Privileges, isChild: boolean) => [
    <Tab key="tournaments" eventKey="tournaments" title="Tournaments">
        <Outlet/>
    </Tab>,
    // <Tab key="games" eventKey="games" title="Games">
    //     <GameTab/>
    // </Tab>,
    <Tab key="rating" eventKey="rating" title="Rating">
        <Outlet/>
    </Tab>,
    (privs.guardian) && (
        <Tab key="children" eventKey="children" title="Children">
            <Outlet/>
        </Tab>
    ),
    (!isChild) && (
        <Tab key="payment" eventKey="payment" title="Payment">
            <Outlet/>
        </Tab>
    ),
    (privs.admin || privs.group_admin) && (
        <Tab key="users" eventKey="users" title="Users">
            <Outlet/>
        </Tab>
    ),
    (privs.admin || privs.group_admin) && (
        <Tab key="groups" eventKey="groups" title="Groups">
            <Outlet/>
        </Tab>
    ),
    (privs.group_admin) && (
        <Tab key="groupEntry" eventKey="groupEntry" title="Group entry">
            <Outlet/>
        </Tab>
    ),
    (privs.audit_view) && (
        <Tab key="audit" eventKey="audit" title="Audit">
            <Outlet/>
        </Tab>
    ),
    (privs.finance_view) && (
        <Tab key="finance" eventKey="finance" title="Finance">
            <Outlet/>
        </Tab>
    )
];

const DEFAULT_TAB = "tournaments";

const PawnBrokerTabs: React.FC = () => {
    const dispatch = useAppDispatch();
    const {tournament_key} = useParams();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {gameDescriptions} = useAppSelector(selectActiveGames);
    const {tabDetails} = useAppSelector(selectActiveTab);
    const {privs, role_ids} = useAppSelector(selectLogin);
    const isChild = useAppSelector(selectConstantsNamedRoleInRoleIds("Child/Student", role_ids));
    const makeActiveTournamentTabs = (activeTournamentTabDetails: ActiveTabDetail[]) => (
        activeTournamentTabDetails.map((tabDetail) => (
            <Tab key={`tournament-${tabDetail.tournamentKey}`}
                 eventKey={tournamentTabEventKeyFromTournament(tabDetail.tournamentKey)}
                 title={<ActiveTournamentTabTitle tabDetail={tabDetail}/>}
                 tabClassName={styles["tournament-tab"]}>
                <Outlet/>
            </Tab>
        ))
    );

    if (tournament_key) {
        const td = tabDetails.find((td) => td.tournamentKey === tournament_key);
        if (td) {
            dispatch(addActiveTournament({key: td.tournamentKey, id: 0, name: td.name}));
        }
    }

    const tabs = useMemo(() => [
        ...initialTabs(privs, !!isChild),
        ...makeActiveGameTabs(gameDescriptions),
        ...makeActiveTournamentTabs(tabDetails)
    ], [privs, isChild, gameDescriptions, tabDetails]);

    const activeTab = useMemo(
        () => {
            const tabName = tabNameFromPath(pathname);
            return (tabs.map((tab) => tab && tab.props.eventKey).includes(tabName)) ? tabName : DEFAULT_TAB;
        },
        [pathname, tabs]
    );

    return (
        <Tabs activeKey={activeTab}
              className="px-3"
              id="display-tabs"
              onSelect={(key) => (key) && navigate(`/${key}`)}
              mountOnEnter
              unmountOnExit>
            {tabs}
        </Tabs>
    );
};

export default PawnBrokerTabs;