import React, {useContext, useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {ActiveTournamentContext} from "./ActiveTournament";
import {useSearchTournamentPlayersQuery} from "../../api/chessMasterApi";
import useSelection from "../../hooks/useSelection";
import AddSelectedPlayersToTeamButton from "./AddSelectedPlayersToTeamButton";
import {UserInfo} from "../../models/User";
import GroupEntrySearch from "../groupEntryTab/GroupEntrySearch";
import TournamentPlayerSearchList from "../../components/TournamentPlayerSearchList";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {isSetEqual} from "../../util/isSetEqual";
import {GroupMinSchema} from "../../models/Group";

interface Props {
    selectedTeam: GroupMinSchema | undefined
}

const ActiveTournamentTeamsPlayers: React.FC<Props> = ({selectedTeam}) => {
    const [query, setQuery] = useState("");
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const {tournament} = useContext(ActiveTournamentContext);
    const {data: playerSearch, error, isLoading} = useSearchTournamentPlayersQuery({tournamentKey: tournament.key, query});
    const playerIdSelection= useSelection<UserInfo["id"]>({
        allItems: playerSearch?.players.map((player) => player.id) ?? []
    });

    useEffect(
        () => setSearchErrorMessage?.((error) ? formatErrorMessage(error) : null),
        [error, setSearchErrorMessage]
    );

    useEffect(() => {
        const allPlayerIds = (playerSearch?.players ?? []).map((player) => player.id);

        if (!isSetEqual(playerIdSelection.allItemsSet, new Set(allPlayerIds))) {
            playerIdSelection.setAllItems({items: allPlayerIds});
        }
    }, [playerIdSelection, playerSearch]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                        <GroupEntrySearch query={query} setQuery={setQuery}/>
                    </SearchErrorMessageContext.Provider>
                </Col>
            </Row>
            <Row>
                <Col className="text-end py-1">
                    <AddSelectedPlayersToTeamButton tournament={tournament}
                                                    team={selectedTeam}
                                                    playerIdSelection={playerIdSelection}/>
                </Col>
            </Row>
            <Row>
                <Col className="py-1">
                    <TournamentPlayerSearchList playerSearch={playerSearch}
                                                tournament={tournament}
                                                playerIdSelection={playerIdSelection}
                                                isLoading={isLoading}/>
                </Col>
            </Row>
        </Container>
    );
};

export default ActiveTournamentTeamsPlayers;
