import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from "../../store";
import {Tournament, TournamentLite} from "../../models/Tournament";
import {Match} from "../../models/Match";
import {updateItemListWithItem} from "../../util/updateItemListWithItem";
import {UserInfo, UserMinSchema} from "../../models/User";
import {TournamentRound} from "../../models/TournamentRound";

import {GroupMinSchema} from "../../models/Group";

interface ActiveTournamentsState {
    tournamentRePairTournamentRound: TournamentRound | null
    tournamentTeamBarring: Tournament | null
    tournamentModifyStaff: Tournament | TournamentLite | null
    tournamentTeamBarringPending: boolean
    tournamentTeamSummary: {tournament: Tournament, team: GroupMinSchema} | null
    tournamentPlayerSummary: {tournament: Tournament, player: UserInfo} | null
    financialsForUser: UserMinSchema | undefined
    showPersonalDetails: {tournament: Tournament | TournamentLite, user: UserMinSchema} | null
}

const initialState: ActiveTournamentsState = {
    tournamentRePairTournamentRound: null,
    tournamentTeamBarring: null,
    tournamentModifyStaff: null,
    tournamentTeamBarringPending: false,
    tournamentTeamSummary: null,
    tournamentPlayerSummary: null,
    financialsForUser: undefined,
    showPersonalDetails: null
};

export const slice = createSlice({
    name: 'activeTournaments',
    initialState,
    reducers: {
        setActiveTournamentRePairMatches: (state, action: PayloadAction<TournamentRound | null>) => {
            state.tournamentRePairTournamentRound = action.payload;
        },
        setActiveTournamentTeamBarring: (state, action: PayloadAction<Tournament | null>) => {
            state.tournamentTeamBarring = action.payload;
        },
        modifyTournamentStaff: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentModifyStaff = action.payload;
        },
        showFinancialsForUser: (state, action: PayloadAction<UserMinSchema | undefined>) => {
            state.financialsForUser = action.payload;
        },
        setActiveTournamentTeamSummary: (state, action: PayloadAction<{tournament: Tournament, team: GroupMinSchema} | null>) => {
            state.tournamentTeamSummary = action.payload;
        },
        setActiveTournamentPlayerSummary: (state, action: PayloadAction<{tournament: Tournament, player: UserInfo} | null>) => {
            state.tournamentPlayerSummary = action.payload;
        },
        setActiveTournamentRePairMatchPlayer: (state, action: PayloadAction<{match: Match, colour: "white" | "black", player: UserInfo}>) => {
            if (state.tournamentRePairTournamentRound) {
                const match = state.tournamentRePairTournamentRound?.matches.find((m) => m.id === action.payload.match.id);
                if (match) {
                    if (action.payload.colour === "white") {
                        match.white_player_id = action.payload.player.id;
                    } else {
                        match.black_player_id = action.payload.player.id;
                    }
                    state.tournamentRePairTournamentRound.matches = updateItemListWithItem(state.tournamentRePairTournamentRound.matches, match);
                }
            }
        },
        showActiveTournamentPersonalDetails: (state, action: PayloadAction<{tournament: Tournament | TournamentLite, user: UserMinSchema} | null>) => {
            state.showPersonalDetails = action.payload;
        }
    }
});

export const selectActiveTournaments = (state: RootState) => state.activeTournaments;

export const {
    setActiveTournamentRePairMatches, setActiveTournamentRePairMatchPlayer,
    setActiveTournamentTeamBarring, setActiveTournamentTeamSummary,
    setActiveTournamentPlayerSummary,
    modifyTournamentStaff,
    showFinancialsForUser, showActiveTournamentPersonalDetails
} = slice.actions;

export default slice.reducer;