import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import PastPaymentList from "./PastPaymentsList";
import {selectLogin} from "../login/loginSlice";
import {useAppSelector} from "../../store";

const PastPaymentModal: React.FC<{onClose: () => void}> = ({onClose}) => {
    const {userId} = useAppSelector(selectLogin);

    return (
        <Modal show
               onHide={onClose}
               size="xl">
            <Modal.Header closeButton>
                Past payments
            </Modal.Header>

            <Modal.Body>
                <PastPaymentList user={{id: userId}}/>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PastPaymentModal;
