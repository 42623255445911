interface UserForUsername {
    given_name: string | null
    family_name: string
}

export const zeroPad = (pad: number, n: number) => {
    const s = `${n}`;
    if (s.length < pad) {
        return Array(pad - s.length).fill("0").join("") + n;
    } else {
        return s;
    }
};

export const createUsernamePrefix = (user: UserForUsername) => {
    return [
        (user.given_name) ? user.given_name[0] : "",
        (user.family_name) ? user.family_name.slice(0, 3) : ""
    ].join("").slice(0, 3).toLowerCase();
};

/*
 * Ronald says:
 * The username will be generated by Pawnbroker using a simple rule such as first-name
 * initial, the first 2 characters of the surname, four digit sequence number.
 * usernames are not ever to be reused since they are linked to archives of games, tournaments etc.
 */

export const createUsernamesForUsernamePrefix = (prefix: string, count=4) => {
    const nDigits = 4;
    const tenPower = 10 ** nDigits;

    return Array(count).fill(0).map(() => `${prefix}${zeroPad(nDigits, Math.floor(Math.random() * tenPower))}`).sort();
};