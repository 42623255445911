import React from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {format} from "date-fns";
import {formatMoney} from "accounting";

import {useFinanceReportQuery} from "../../api/chessMasterApi";
import {FinanceReport} from "../../models/FinanceReport";

const FinanceReportList: React.FC = () => {
    const {data: financeReport} = useFinanceReportQuery();
    const columns: TableColumn<FinanceReport>[] = [{
        selector: (row) => row.period,
        name: "Period",
        sortable: true,
        format: (row) => format(new Date(row.period), "LLLL yyyy")
    }, {
        selector: (row) => row.income,
        name: "Income",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.income, "$")
    }, {
        selector: (row) => row.refunded,
        name: "Refunded",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.refunded, "$")
    }, {
        selector: (row) => row.disputed,
        name: "Disputed",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.disputed, "$")
    }, {
        selector: (row) => row.credit,
        name: "Credit",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.credit, "$")
    }, {
        selector: (row) => row.income_stripe,
        name: "Income stripe",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.income_stripe, "$")
    }, {
        selector: (row) => row.income_manual,
        name: "Income manual",
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.income_manual, "$")
    }];

    return (
        <DataTable<FinanceReport> data={financeReport ?? []}
                                  columns={columns}
                                  keyField="period"
                                  noDataComponent="(No finance report found)"/>
    );
};

export default FinanceReportList;
