import React from "react";

import Modal from "react-bootstrap/Modal";

import {UserInfo} from "../../models/User";
import ChangePassword from "./ChangePassword";

interface ChangePasswordProps {
    userId: UserInfo["id"]
    fullName: string
    onClose: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({userId, fullName, onClose}) => {
    return (
        <Modal show={true}
               onHide={onClose}
               backdrop="static"
               size="lg">
            <Modal.Header>
                <Modal.Title>
                    Change password for {fullName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ChangePassword userId={userId} fullName={fullName} onClose={onClose}/>
            </Modal.Body>
        </Modal>
    );
};

export default ChangePasswordModal;
