import {TournamentPlayer} from "../models/TournamentPlayer";
import {Group} from "../models/Group";

export const groupPlayersByTeam = (players: TournamentPlayer[]) => {
    const playersByTeam = new Map<Group["id"], TournamentPlayer[]>();

    players.forEach(player => {
        const teamId = player.team_id;

        if (teamId) {
            const teamPlayers = playersByTeam.get(teamId);

            if (teamPlayers) {
                teamPlayers.push(player);
            } else {
                playersByTeam.set(teamId, [player]);
            }
        }
    });
    return playersByTeam;
};