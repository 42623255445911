import React, {useCallback, useEffect, useState} from "react";
import useLocalStorageState from "use-local-storage-state";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {useAppDispatch, useAppSelector} from "../../store";
import {useSearchPaymentQuery} from "../../api/chessMasterApi";
import {selectGroupEntryTab, setRunEditProFormaPaymentModal} from "./groupEntryTabSlice";
import ProFormaPaymentTable from "./ProFormaPaymentTable";
import EditProFormaPaymentModal from "./EditProFormaPaymentModal";
import {Group} from "../../models/Group";
import {Tournament} from "../../models/Tournament";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import GroupEntrySearch from "./GroupEntrySearch";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";

const GroupEntryTab: React.FC = () => {
    const dispatch = useAppDispatch();
    const {runEditProFormaPaymentModal} = useAppSelector(selectGroupEntryTab);
    const closeProFormaPaymentModal = useCallback(() => dispatch(setRunEditProFormaPaymentModal(null)), [dispatch]);
    const [query, setQuery] = useLocalStorageState<Group["name"] | Tournament["name"]>("groupEntryTab/query", {defaultValue: ""});
    const params = {query: {value: query, setter: setQuery, isEmpty: (v: string) => !v}};
    const [values, setters] = useLocalStorageSearchParams(params);
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const {data: payments, error} = useSearchPaymentQuery({query, status: "Proforma"});

    useEffect(
        () => setSearchErrorMessage((error) ? formatErrorMessage(error) : null),
        [error]
    );

    return (
        <>
            {(runEditProFormaPaymentModal) && (
                <EditProFormaPaymentModal payment={runEditProFormaPaymentModal}
                                          onClose={closeProFormaPaymentModal}/>
            )}
            <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                <Container fluid>
                    <Row className="mt-3 g-0">
                        <GroupEntrySearch query={values.query}
                                          setQuery={setters.setQuery}/>
                    </Row>

                    <Row className="g-0">
                        <ProFormaPaymentTable payments={payments ?? []}/>
                    </Row>
                </Container>
            </SearchErrorMessageContext.Provider>
        </>
    );
};

export default GroupEntryTab;
