import React, {useEffect, useMemo} from "react";
import {useFormContext} from "react-hook-form";

import Form from "react-bootstrap/Form";

import {
    createUsernamePrefix,
    createUsernamesForUsernamePrefix
} from "../util/createUsernamesForUser";

import {formControlOptionsFromStrings} from "../util/formControl";
import {UserRegistration} from "./UserForm";

interface Props {
    givenName: string
    familyName: string
}

const RegistrationUserName: React.FC<Props> = ({givenName, familyName}) => {
    const {register, formState: {errors}, getValues, setValue} = useFormContext<UserRegistration>();
    const usernamePrefix = useMemo(
        () => createUsernamePrefix({given_name: givenName, family_name: familyName}),
        [familyName, givenName]
    );
    const usernames = useMemo(
        () => createUsernamesForUsernamePrefix(usernamePrefix),
        [usernamePrefix]
    );

    useEffect(() => {
        if (!usernames.includes(getValues("user.username"))) {
            setValue("user.username", "-1");
        }
    }, [getValues, setValue, usernames]);

    return (
        <Form.Control {...register("user.username")}
                      as="select"
                      isInvalid={!!errors.user?.username}>
            <option key={-1} value={-1}>(Please select)</option>
            {formControlOptionsFromStrings(usernames)}
        </Form.Control>
    );
};

export default RegistrationUserName;
