import React from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import {
    TournamentFinancials,
    TournamentFinancialsGroupEntry
} from "../../models/TournamentFinancials";
import PaidSymbol from "../../components/PaidSymbol";


interface Props {
    financials: TournamentFinancials | undefined
    isLoading: boolean
}

const ActiveTournamentGroupFinancials: React.FC<Props> = ({financials, isLoading}) => {
    const columns: TableColumn<TournamentFinancialsGroupEntry>[] = [{
        name: "Group",
        selector: (row) => row.name,
        grow: 4,
        sortable: true,
        format: (row) => row.name
    }, {
        name: "Entries",
        selector: (row) => row.n_items,
        grow: 1,
        right: true,
        sortable: true
    }, {
        name: "Participating",
        selector: (row) => row.n_participating,
        grow: 1,
        right: true,
        sortable: true
    }, {
       name: "Limit",
        selector: (row) => row.max_items,
        grow: 1,
        right: true,
        sortable: true
    }, {
        name: "Entry Fee",
        selector: (row) => row.amount ?? "",
        grow: 1,
        sortable: true,
        center: true,
        format: (row) => (row.amount !== null) ? (
            <PaidSymbol amount={row.amount}/>
        ) : ""
    }];

    return (
        <DataTable<TournamentFinancialsGroupEntry> data={financials?.group_entries ?? []}
                                                   columns={columns}
                                                   keyField="id"
                                                   noDataComponent="(No group entries)"
                                                   progressPending={isLoading}/>
    );
};

export default ActiveTournamentGroupFinancials;
