import React from "react";

import Card from "react-bootstrap/Card";

import {ListGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {UserGroup} from "../../models/UserGroup";

interface Props {
    userGroups: UserGroup[]
    setUserGroups: (userGroups: UserGroup[]) => void
    className?: string
}

const GroupAdminCard: React.FC<Props> = ({userGroups, setUserGroups, className}) => {
    const handleChange = (userGroup: UserGroup) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        // XXX this next line is wrong -- needs to update group_admin flag
        const updateUserGroup = (userGroup: UserGroup): UserGroup => ({...userGroup, group_admin: checked});
        const newUserGroups = userGroups.map((ug) => (ug.group.id === userGroup.group.id) ? updateUserGroup(ug) : ug);
        setUserGroups(newUserGroups);
    };

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>Group Admin</Card.Title>
                Be cautious granting group admin rights to users.
            </Card.Header>
            <Card.Body>
                <ListGroup>
                    {(userGroups ?? []).map((ug) => (
                        <ListGroup.Item key={ug.group.id}>
                            <Form.Check label={ug.group.name}
                                        checked={ug.group_admin}
                                        onChange={handleChange(ug)}/>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    );
};

export default GroupAdminCard;
