import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {Tournament} from "../../models/Tournament";

interface ApiState {
    error: string | undefined
    repairTournament: Tournament | null
}

const initialState: ApiState = {
    error: undefined,
    repairTournament: null
};

export const slice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setApiError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
        setApiErrorRepairTournament: (state, action: PayloadAction<Tournament | null>) => {
            state.repairTournament = action.payload;
        }
    }
});

export const selectApi = (state: RootState) => state.api;
export const {
    setApiError, setApiErrorRepairTournament
} = slice.actions;

export default slice.reducer;
