import React, {useCallback} from "react";
import {skipToken} from "@reduxjs/toolkit/query";
import {Controller, useFormContext} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {ErrorMessage} from "@hookform/error-message";
import * as yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import DatePicker from "react-datepicker";
import {format, parseISO} from "date-fns";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {formControlOptions} from "../util/formControl";
import {
    selectConstants,
    selectConstantsGroupTypeByDescription
} from "../features/constants/constantsSlice";
import {phoneRegexp} from "../util/validators";
import {emptyStringOr0ToNull, emptyStringToNull} from "../util/yupTransforms";
import {isUser, NewUser, NewUserWithRegistration, User} from "../models/User";
import RegistrationUserName from "./RegistrationUserName";
import GroupPicker from "./GroupPicker";
import UserEditGroups from "../features/userTab/UserEditGroups";
import {PasswordForm} from "../util/passwordSchema";
import LinkLichessButton from "./LinkLichessButton";
import {UserGroup} from "../models/UserGroup";
import {useGetGroupQuery} from "../api/chessMasterApi";
import {useAppSelector} from "../store";
import {Tournament} from "../models/Tournament";

export const userUpdateSchema = yup.object().shape({
    given_name: yup.string().nullable().label("First name"),
    family_name: yup.string().required().min(2).label("Last name"),
    gender_id: yup.string().notOneOf(["-1"], "Gender not selected").label("Gender"),
    birthday: yup.string().nullable().label("Birthday"),
    school_year: yup.number().nullable().positive().max(30).transform(emptyStringOr0ToNull).label("School year"),
    username: yup.string().notOneOf(["-1"], "Please select a username").label("Username"),
    email_address: yup.string().email().nullable().transform(emptyStringToNull).label("E-mail"),
    phone_num: yup.string()
        .nullable()
        .matches(phoneRegexp, {message: "Invalid phone number", excludeEmptyString: true})
        .label("Phone"),
    phone_num_note: yup.string().nullable().label('Phone note')
});

// if password form is present, validate it; if not, ignore it
export const userRegistrationSchema = (extraSchemas: {password?: yup.ObjectSchema<any>} = {}) => yup.object().shape({
    user: userUpdateSchema,
    ...(extraSchemas) ? extraSchemas : {}
});

export interface UserRegistration {
    user: NewUser
    tournament_key: Tournament["key"]   // enrol new user in this tournament
    guardian_id: User["id"]             // make new user have this guardian
    userGroups: UserGroup[]
    password: PasswordForm
}
// When creating, allow changes to the username and set password

interface RegistrationFormProps {
    user: NewUserWithRegistration
    isCreating: boolean
    showGroupSelector?: boolean
}

const UserForm: React.FC<RegistrationFormProps> = ({user, isCreating, showGroupSelector = false}) => {
    const {register, control, formState: {errors}, watch, setValue, setError, clearErrors} = useFormContext<UserRegistration>();
    const {genders} = useAppSelector(selectConstants);
    const schoolGroupType = useAppSelector(selectConstantsGroupTypeByDescription)["School"];
    const given_name = watch("user.given_name");
    const family_name = watch("user.family_name");
    const new_password = watch("password.new_password");
    const school_id = watch("user.school_id");
    const {data: selectedSchool} = useGetGroupQuery((school_id) ? {id: school_id} : skipToken);
    const setSchoolIdError = useCallback(
        (message: string | null) => (message) ? setError("user.school_id", {type: "invalid", message}) : clearErrors("user.school_id"),
        [clearErrors, setError]
    );
    register("user.school_id");

    return (
        <>
            <Form.Group as={Row} className="mb-3"
                        controlId="registerFirstName">
                <Form.Label column md={4}>
                    Given name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.given_name")}
                                  isInvalid={!!errors.user?.given_name}
                                  placeholder="First name"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.given_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPreferredName">
                <Form.Label column md={4}>
                    Preferred name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.preferred_name")}
                                  placeholder="(if different from given name)"
                                  isInvalid={!!errors.user?.preferred_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.preferred_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerLastName">
                <Form.Label column md={4}>
                    Family name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.family_name")}
                                  isInvalid={!!errors.user?.family_name}
                                  placeholder="Last name"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.family_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerUsername">
                <Form.Label column md={4}>
                    Username
                </Form.Label>
                <Col md={8}>
                    {(isCreating) ? (
                        <RegistrationUserName givenName={given_name ?? ""} familyName={family_name}/>
                    ) : (
                        <Form.Control type="text" {...register("user.username")} readOnly/>
                    )}
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.username"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerGender">
                <Form.Label column md={4}>
                    Gender
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.gender_id", {valueAsNumber: true})}
                                  as="select"
                                  isInvalid={!!errors.user?.gender_id}>
                        <option key={-1} value={-1}>(Please select)</option>
                        {formControlOptions(genders)}
                    </Form.Control>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.gender_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerBirthday">
                <Form.Label column md={4}>
                    Birthday
                </Form.Label>
                <Col md={8}>
                    <Controller
                        render={
                            ({field: {onChange, value, onBlur}}) => (
                                <DatePicker selected={(value) ? parseISO(value) : null}
                                            className="form-control"
                                            dateFormat="PP"     // long localised
                                            maxDate={new Date()}
                                            dropdownMode="select"
                                            shouldCloseOnSelect
                                            showYearDropdown
                                            wrapperClassName={(errors.user?.birthday) ? "is-invalid" : ""}
                                            placeholderText="Not required for adults"
                                            onChange={(value: Date) => onChange((value) ? format(value, "yyyy-MM-dd") : null)}
                                            onBlur={onBlur}/>
                            )
                        }
                        control={control}
                        name="user.birthday"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.birthday"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerSchool">
                <Form.Label column md={4}>
                    School
                </Form.Label>
                <Col md={8}>
                    <GroupPicker onChange={(school) => {
                                     setValue("user.school_id", school?.id ?? null, {shouldDirty: true})
                                 }}
                                 placeholder="School ..."
                                 selectedGroup={(school_id) ? selectedSchool : undefined}
                                 fixGroupTypeId={schoolGroupType.id}
                                 fieldError={errors.user?.school_id}
                                 setErrorMessage={setSchoolIdError}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.school_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            {(showGroupSelector) && (
                <Form.Group as={Row} className="mb-3"
                            controlId="registerGroups">
                    <Form.Label column md={4}>
                        Groups
                    </Form.Label>
                    <Col md={8}>
                        {(user) && (
                            <UserEditGroups user={user.user}/>
                        )}
                    </Col>
                </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3"
                        controlId="registerSchoolYear">
                <Form.Label column md={4}>
                    School year
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.school_year")}
                                  type="number"
                                  min={0}
                                  isInvalid={!!errors.user?.school_year}
                                  placeholder="Empty or 0 if not a student"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.school_year"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerEMail">
                <Form.Label column md={4}>
                    E-mail
                </Form.Label>
                <Col md={8}>
                    <Form.Control type="email"
                                  {...register("user.email_address")}
                                  isInvalid={!!errors.user?.email_address}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.email_address"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPhone">
                <Form.Label column md={4}>
                    Phone
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.phone_num")}
                                  placeholder="Please include area code"
                                  isInvalid={!!errors.user?.phone_num}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPhoneNotes">
                <Form.Label column md={4}>
                    Phone notes
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.phone_num_note")}
                                  as="textarea"
                                  maxLength={2048}
                                  isInvalid={!!errors.user?.phone_num_note}
                                  placeholder="Contact notes ..."/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num_note"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerMedicalNote">
                <Form.Label column md={4}>
                    Medical notes
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.medical_note")}
                                  as="textarea"
                                  maxLength={2048}
                                  isInvalid={!!errors.user?.medical_note}
                                  placeholder="Medical notes ..."/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.medical_note"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            {(isCreating) && (
                <>
                    <Form.Group as={Row} className="mb-3"
                                controlId="registerPassword">
                        <Form.Label column md={4}>
                            Password
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control type="password"
                                          {...register("password.new_password")}
                                          isInvalid={!!(errors.password?.new_password || errors.user?.new_password)}
                                          autoComplete="new-password"
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="password.new_password"
                                          type="invalid"/>
                            {/* note: error from server will be in user.new_password */}
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="user.new_password"
                                          type="invalid"/>
                            {(!new_password) && (
                                <small className="text-warning">
                                    User won't be able to log in with an empty password
                                </small>
                            )}
                            <PasswordStrengthBar password={watch("password.new_password")}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3"
                                controlId="registerConfirmPassword">
                        <Form.Label column md={4}>
                            Confirm password
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control type="password"
                                          {...register("password.confirm_password")}
                                          isInvalid={!!errors.password?.confirm_password}
                                          autoComplete="new-password"
                                          autoCapitalize="off"/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="password.confirm_password"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </>
            )}
            {(isUser(user)) && (
                <Form.Group as={Row} className="mb-3"
                            controlId="registerLichess">
                    <Form.Label column md={4}>
                        Online play
                    </Form.Label>
                    <Col md={8}>
                        <LinkLichessButton user={user}/>
                    </Col>
                </Form.Group>
            )}
            {(isUser(user)) && (
                <Form.Group as={Row} className="mb-3"
                            controlId="registerLichessPassword">
                    <Form.Label column md={4}>
                        Lichess password
                    </Form.Label>
                    <Col md={8}>
                        <Form.Control {...register("user.lichess_password")}
                                      placeholder="(Chess Ideas managed accounts only)"
                                      isInvalid={!!errors.user?.lichess_password}/>
                        <ErrorMessage as={<Form.Control.Feedback/>}
                                      name="user.lichess_password"
                                      type="invalid"/>
                    </Col>
                </Form.Group>
            )}
            <DevTool control={control}/>
        </>
    );
};

export default UserForm;
