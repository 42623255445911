import {
    configureStore,
    ThunkAction,
    Action,
    ConfigureStoreOptions
} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {save, load} from "redux-localstorage-simple";

import activeGamesReducer from "./features/activeGames/activeGamesSlice";
import activeTournamentsReducer from "./features/activeTournaments/activeTournamentSlice";
import activeTabReducer from "./features/activeTab/activeTabSlice";
import apiReducer from "./features/api/ApiSlice";
import constantsReducer from "./features/constants/constantsSlice";
import financeTabReducer from "./features/financeTab/financeTabSlice";
import gameReducer from "./features/game/gameSlice";
import gameTabReducer from "./features/gameTab/gameTabSlice";
import groupsTabReducer from "./features/groupsTab/groupsTabSlice";
import groupEntryTabReducer from "./features/groupEntryTab/groupEntryTabSlice";
import loginReducer from "./features/login/loginSlice";
import ratingTabReducer from "./features/ratingTab/ratingTabSlice";
import parentTabReducer from "./features/parentTab/parentTabSlice";
import paymentTabReducer from "./features/paymentTab/paymentTabSlice";
import settingsReducer from "./features/settings/settingsSlice";
import tournamentTabReducer from "./features/tournamentTab/tournamentTabSlice";
import userTabReducer from "./features/userTab/userTabSlice";
import toastReducer from './features/toast/toastSlice';

import activeGames from "./middleware/activeGames";
import activeTab from "./middleware/activeTab";
import game from "./middleware/game";
import socketIO from "./middleware/socketIO";
import {chessMasterApi} from "./api/chessMasterApi";
import {rtkQueryErrorLogger} from "./middleware/RTKQueryErrorLogger";

const createStore = (options?: ConfigureStoreOptions['preloadedState']) => configureStore({
    reducer: {
        api: apiReducer,
        activeTab: activeTabReducer,
        constants: constantsReducer,
        login: loginReducer,
        financeTab: financeTabReducer,
        game: gameReducer,
        gameTab: gameTabReducer,
        groupsTab: groupsTabReducer,
        groupEntryTab: groupEntryTabReducer,
        activeGames: activeGamesReducer,
        activeTournaments: activeTournamentsReducer,
        parentTab: parentTabReducer,
        paymentTab: paymentTabReducer,
        ratingTab: ratingTabReducer,
        settings: settingsReducer,
        toasts: toastReducer,
        tournamentTab: tournamentTabReducer,
        userTab: userTabReducer,
        [chessMasterApi.reducerPath]: chessMasterApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        game, socketIO, activeTab, activeGames,
        rtkQueryErrorLogger,
        chessMasterApi.middleware,
        save({states: ["activeTab"]})
    ]),
    preloadedState: options
});

const store = createStore(load({states: ["activeTab"]}));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType=void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;