import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import MultiPersonPicker, {userLabelKey} from "../../components/multiPersonPicker/MultiPersonPicker";
import {UserInfo} from "../../models/User";
import {Tournament} from "../../models/Tournament";
import {
    newTournamentStaffFromUserInfo,
    TournamentStaff
} from "../../models/TournamentStaff";
import {partitionChanges} from "../../util/partitionChanges";
import {TournamentStaffForm} from "../activeTournaments/ActiveTournamentUpdateStaff";

const TournamentAdmin: React.FC<{tournament: Tournament}> = ({tournament}) => {
    const {register, formState: {errors}, setValue, watch} = useFormContext<TournamentStaffForm>();
    const staff: TournamentStaff[] = watch("staff");
    const [staffUsers, setStaffUsers] = useState((staff ?? []).map((ts) => tournament.ref_user[ts.user_id]));
    const setStaff = (staffUserInfos: UserInfo[]) => {
        const changes = partitionChanges(staffUsers, staffUserInfos);
        const removedUserIds = changes.removed.map((user) => user.id);
        const newStaff = staff.filter((ts) => !removedUserIds.includes(ts.user_id))
            .concat(changes.added.map((user) => newTournamentStaffFromUserInfo(user)));

        setValue("staff", newStaff);
        setStaffUsers(staffUserInfos);
    };
    const setStaffDirector = (userId: UserInfo["id"], isDirector: boolean) => {
        const updateDirector = (ts: TournamentStaff, isDirector: boolean) => {
            return {...ts, director: isDirector};
        };

        setValue("staff", staff.map((s) => (s.user_id === userId) ? updateDirector(s, isDirector) : s));
    };

    register("staff");

    useEffect(() => {
        (tournament) && setStaffUsers(tournament.staff.map((ts) => tournament.ref_user[ts.user_id]))
    }, [tournament]);

    return (
        <Container>
            <Form.Group as={Row} controlId="tournamentStaff">
                <Form.Label column md={2}
                            className="text-end">
                    Staff
                </Form.Label>
                <Col md={10}>
                    <MultiPersonPicker onChange={setStaff}
                                       selectedUserInfos={staffUsers}
                                       isInvalid={!!errors.staff}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="staff"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="tournamentDirectors">
                <Form.Label column md={2}
                            className="text-end">
                    Directors
                </Form.Label>
                <Col md={10}>
                    {(staff ?? []).map((ts) => {
                        const user = staffUsers.find((user) => user.id === ts.user_id);
                        if (!user) {
                            throw new Error(`TournamentAdmin: unknown user id ${ts.user_id}`);
                        }
                        return (
                            <Form.Group key={ts.user_id}>
                                <Form.Check checked={ts.director}
                                            onChange={(ev) => setStaffDirector(ts.user_id, ev.target.checked)}
                                            label={userLabelKey(user)}
                                            isInvalid={!!errors.staff}/>
                                <ErrorMessage as={<Form.Control.Feedback/>}
                                              name="staff"
                                              type="invalid"/>
                            </Form.Group>
                        );
                    })}
                </Col>
            </Form.Group>

            <span className="text-danger">
                <ErrorMessage name="staff"/>
            </span>

        </Container>
    );
};

export default TournamentAdmin;