import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import FinanceReportList from "./FinanceReportList";

export const FinanceReportModal: React.FC<{onClose: () => void}> = ({onClose}) => {
    return (
        <Modal show
               size="xl"
               onHide={onClose}
               dialogClassName="w-75">
            <Modal.Header closeButton>
                <Modal.Title>
                    Finance Report
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FinanceReportList/>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default FinanceReportModal;