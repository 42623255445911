import React, {PropsWithChildren} from "react";
import {useAppSelector} from "../store";
import {Navigate} from "react-router-dom";
import {selectLogin} from "../features/login/loginSlice";
import {PrivilegeName, Privileges} from "../models/Privileges";

interface Props {
    priv: PrivilegeName | PrivilegeName[]
}

const RequireAuth: React.FC<PropsWithChildren<Props>> = ({priv, children}) => {
    const {privs} = useAppSelector(selectLogin);
    const requestedPrivs = (typeof priv === "string") ? [priv] : priv;
    const hasRequestedPriv = (privs: Privileges, requestedPrivs: PrivilegeName[]) => (
        requestedPrivs.some((priv) => privs[priv])
    );

    if (children === undefined) {
        console.error('RequireAuth: children is undefined');
    } else if (requestedPrivs.length === 0) {
        console.error('RequireAuth: no privileges requested');
    } else if (hasRequestedPriv(privs, requestedPrivs)) {
        return <>{children}</>;
    }
    return <Navigate to="/PermissionDenied"  />
};

export default RequireAuth;