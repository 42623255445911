import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

import {InterimPaymentItem, Payment} from "../../models/Payment";
import {chessMasterApi} from "../../api/chessMasterApi";

// import Payer from "@paypal/paypal-js/types/apis/orders";
export type Payer = any;       // (this isn't exported from @paypal/paypal-js)

interface PaymentTabState {
    payment: Payment | null
    payer: Payer | null
    pastPaymentModal: boolean | undefined
    selectedPaymentItems: InterimPaymentItem[]
}

const initialState: PaymentTabState = {
    payment: null,
    payer: null,
    pastPaymentModal: false,
    selectedPaymentItems: []
};

export const loadPayment = createAction<void>("paymentTab/loadPayment");

// @ts-ignore
export const slice = createSlice({
    name: "paymentTab",
    initialState,
    reducers: {
        setPaymentTabPayer: (state, action: PayloadAction<Payer>) => {
            state.payer = action.payload;
        },
        setSelectedPaymentItems: (state, action: PayloadAction<InterimPaymentItem[]>) => {
            state.selectedPaymentItems = action.payload;
        }
    },
    extraReducers: (builder) => {
        // when user uses loginAs, make sure we clear any selected payment items
        // XXX I'm not sure this should be needed: the paymentItems should probably be held in locally rather than in the store
        builder.addMatcher(chessMasterApi.endpoints.loginAs.matchFulfilled, (state, action) => {
            state.selectedPaymentItems = [];
        });
    }
});

export const selectPaymentTab = (state: RootState) => state.paymentTab;
export const {
    setPaymentTabPayer,
    setSelectedPaymentItems
} = slice.actions;

export default slice.reducer;