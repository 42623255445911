import React, {useState} from 'react';
import useLocalStorageState from "use-local-storage-state";
import {useDebouncedCallback} from "use-debounce";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import AuditList from './AuditList';
import AuditSearch from "./AuditSearch";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

const AuditTab = () => {
    const [query, setQuery] = useLocalStorageState("auditTab/query", {defaultValue: ""});
    const params = {query: {value: query, setter: setQuery, isEmpty: (v: string) => !v}};
    const [values, setters] = useLocalStorageSearchParams(params);
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const setDebouncedQuery = useDebouncedCallback(setters.setQuery, 300);

    return (
        <SearchErrorMessageContext.Provider
            value={{searchErrorMessage, setSearchErrorMessage}}>
            <Container fluid>
                <Row className="mt-3 g-0">
                    <AuditSearch query={values.query} setQuery={setDebouncedQuery}/>
                </Row>
                <Row className="g-0">
                    <AuditList query={values.query}/>
                </Row>
            </Container>
        </SearchErrorMessageContext.Provider>
    );
};

export default AuditTab;