import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import {UserInfo} from "../models/User";
import {Tournament} from "../models/Tournament";
import {formatFullName} from "../util/formatFullName";
import {
    setActiveTournamentPlayerSummary
} from "../features/activeTournaments/activeTournamentSlice";
import {TournamentPlayer} from "../models/TournamentPlayer";
import {selectLoginCanEditTournament} from "../features/login/loginSlice";
import ShowPlayerContactDetails from "./ShowPlayerContactDetails";
import UserLichessOnlineSymbol from "./UserLichessOnlineSymbol";
import {selectConstantsGenderById} from "../features/constants/constantsSlice";
import {useAppSelector} from "../store";

interface Props {
    player?: TournamentPlayer
    tournament: Tournament
    scores?: Record<UserInfo["id"], number>
    omitTeam?: boolean
    omitScore?: boolean
    online?: boolean
    showContactDetails?: boolean
}

const UsernameAndTeam: React.FC<Props> = ({player, tournament, scores, omitTeam = false, omitScore = false, online = false, showContactDetails = true}) => {
    const dispatch = useDispatch();
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));
    const user = (player) ? tournament.ref_user[player.user_id] : undefined;
    const score = (scores && player) ? scores[player.user_id] : player?.points;
    const team = (player?.team_id && !omitTeam) ? tournament?.ref_group[player.team_id]?.name : undefined;
    const genderById = useAppSelector(selectConstantsGenderById);
    const renderShortGender = (userInfo: UserInfo) => {
        return genderById[userInfo.gender_id].short;
    };
    const renderName = (userInfo: UserInfo) => {
        const fullName = formatFullName(userInfo);

        if (tournament) {
            return (
                <>
                    {(canEditTournament && showContactDetails) && (
                        <ShowPlayerContactDetails tournament={tournament} user={userInfo}/>
                    )}
                    <Button variant="link"
                            className="p-0 text-start"
                            onClick={() => dispatch(setActiveTournamentPlayerSummary({
                                tournament,
                                player: userInfo
                            }))}>
                        {fullName} ({renderShortGender(userInfo)})
                    </Button>
                </>
            );
        } else {
            return <>
                {fullName}
            </>;
        }
    };

    return (
        <div className="w-100">
            <Stack direction="horizontal">
                <span>{(user) ? renderName(user) : "(bye)"}</span>
                {(online) ? <UserLichessOnlineSymbol/> : ""}
                {!omitScore && (player?.points !== undefined) ? <span className="ms-auto text-nowrap">{" "}({score})</span> : ""}
            </Stack>
            <Stack direction="horizontal">
                {(user?.username) ? <small className="text-muted">({user.username})</small> : ""}
                {(team) ? <div className="small ms-auto">{team}</div> : null}
            </Stack>
        </div>
    );
};

export default UsernameAndTeam;