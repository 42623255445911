import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {FieldError} from "react-hook-form";
import {useDebouncedCallback} from "use-debounce";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import {BsInfoCircle} from "react-icons/bs";

import {useGetGroupQuery} from "../api/chessMasterApi";
import GroupPicker from "./GroupPicker";
import {userSearchHelpPopover} from "../util/SearchHelpPopover";
import {GroupMinSchema} from "../models/Group";

export interface PlayerFilterError {
    userSearchError: string | null
    setUserSearchError?: (message: string | null) => void
    groupSearchError: string | null
    setGroupSearchError?: (message: string | null) => void
}

interface PlayerFilterInputProps {
    query: string
    setQuery: (query: string) => void
    groupId: GroupMinSchema["id"]
    setGroupId: (groupId: GroupMinSchema["id"]) => void
    fieldError?: FieldError
}

export const PlayerFilterErrorContext = createContext<PlayerFilterError>({userSearchError: null, groupSearchError: null});

const PlayerFilterInput: React.FC<PlayerFilterInputProps> = ({query, setQuery, groupId, setGroupId, fieldError}) => {
    const [selectedGroup, setSelectedGroup] = useState<GroupMinSchema | undefined>(undefined);
    const setDebouncedQuery = useDebouncedCallback((value: string) => setQuery(value), 300);
    const {data: group} = useGetGroupQuery({id: groupId}, {skip: !groupId || groupId <= 0 || !!selectedGroup});
    const queryInput = useRef<HTMLInputElement>(null);
    const {userSearchError, groupSearchError, setGroupSearchError} = useContext(PlayerFilterErrorContext);
    const formatSearchError = (userSearchError: string | null, groupSearchError: string | null) => {
        if (userSearchError && groupSearchError) {
            return `User: ${userSearchError}; group: ${groupSearchError}`;
        } else {
            return userSearchError || groupSearchError;
        }
    };
    useEffect(() => {
        queryInput.current?.focus()
    }, []);

    useEffect(() => {
        (group) && setSelectedGroup(group)
    }, [group]);

    return (
        <div className="mb-3">
            <InputGroup className={(userSearchError || groupSearchError) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              name="query"
                              defaultValue={query}
                              ref={queryInput}
                              onChange={(ev) => setDebouncedQuery(ev.target.value)}
                              placeholder="Search ..."
                              isInvalid={!!userSearchError}/>
                <OverlayTrigger trigger="click"
                                placement="bottom"
                                overlay={userSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
                <InputGroup.Text className="p-0 w-50">
                    <GroupPicker onChange={(group) => {
                                     setGroupId(group?.id ?? -1);
                                     setSelectedGroup(group);
                                 }}
                                 selectedGroup={selectedGroup}
                                 fieldError={(groupSearchError) ? {type: "invalid"} : undefined}
                                 setErrorMessage={setGroupSearchError}/>
                </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {formatSearchError(userSearchError, groupSearchError)}
            </Form.Control.Feedback>
        </div>
    );
};

export default PlayerFilterInput;