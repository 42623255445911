import React, {useCallback, useEffect, useState} from 'react';
import useLocalStorageState from "use-local-storage-state";
import {useParams} from "react-router-dom";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {UserList} from "./UserList";
import {selectUserTab, setEditUserId, setShowBulkUpload} from './userTabSlice';
import UserEdit from "./UserEdit";
import UserBulkUpload from "./UserBulkUpload";
import {Group} from "../../models/Group";
import PlayerFilterInput, {PlayerFilterErrorContext} from "../../components/PlayerFilterInput";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import {useAppDispatch, useAppSelector} from "../../store";

const UserTab = () => {
    const dispatch = useAppDispatch();
    const {editUserId, showBulkUpload} = useAppSelector(selectUserTab);
    const [query, setQuery] = useLocalStorageState<Group["name"]>("userTab/query", {defaultValue: ""});
    const [groupId, setGroupId] = useLocalStorageState("userTab/groupId", {defaultValue: "-1"});
    const {user_id} = useParams();
    const [userSearchError, setUserSearchError] = useState<string | null>(null);
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);
    const params = {
        query: {value: query, setter: setQuery, isEmpty: (v: string) => !v},
        groupId: {value: groupId, setter: setGroupId, isEmpty: (v: string) => v === "-1"}
    };
    const [values, setters] = useLocalStorageSearchParams(params);
    const closeUserEdit = useCallback(() => dispatch(setEditUserId(null)), [dispatch]);
    const convertSetGroupId = useCallback(
        (groupId: Group["id"]) => setters.setGroupId(String(groupId)),
        [setters]
    );

    useEffect(() => {
        (user_id) && dispatch(setEditUserId(Number(user_id)));
    }, [dispatch, user_id]);

    return (
        <>
            {(editUserId) && (
                <UserEdit userId={editUserId} onClose={closeUserEdit}/>
            )}
            {(showBulkUpload) && (
                <UserBulkUpload/>
            )}
            <PlayerFilterErrorContext.Provider value={{
                userSearchError, setUserSearchError,
                groupSearchError, setGroupSearchError
            }}>
                <Container fluid>
                    <Row className="mt-3 g-0">
                        <Col>
                            <PlayerFilterInput query={values.query}
                                               setQuery={setters.setQuery}
                                               groupId={Number(values.groupId)}
                                               setGroupId={convertSetGroupId}/>
                        </Col>
                        <Col sm="auto" className="ms-3">
                            <ButtonToolbar>
                                {(false) && (
                                    <ButtonGroup className="me-1">
                                        <Button
                                            onClick={() => dispatch(setShowBulkUpload(true))}>
                                            Bulk upload ...
                                        </Button>
                                    </ButtonGroup>
                                )}
                                <ButtonGroup>
                                    <Button onClick={() => dispatch(setEditUserId(-1))}>
                                        Add User ...
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <UserList query={values.query} groupId={Number(values.groupId)}/>
                    </Row>
                </Container>
            </PlayerFilterErrorContext.Provider>
        </>
    );
};

export default UserTab;