import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";

interface SettingsState {
    isShowingSettingsModal: boolean
    isShowingSwitchRoleModal: boolean
    isShowingLoginAsModal: boolean
}

const initialState: SettingsState = {
    isShowingSettingsModal: false,
    isShowingSwitchRoleModal: false,
    isShowingLoginAsModal: false,
};

export const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettingsShowModal: (state, action: PayloadAction<boolean>) => {
            state.isShowingSettingsModal = action.payload;
        },
        setSettingsShowChangeRoleModal: (state, action: PayloadAction<boolean>) => {
            state.isShowingSwitchRoleModal = action.payload;
        },
        setSettingsShowLoginAsModal: (state, action: PayloadAction<boolean>) => {
            state.isShowingLoginAsModal = action.payload;
        }
    }
});

export const selectSettings = (state: RootState) => state.settings;
export const {
    setSettingsShowModal, setSettingsShowChangeRoleModal, setSettingsShowLoginAsModal
} = slice.actions;

export default slice.reducer;
