import React from "react";

import {FieldError} from "react-hook-form";
import Form from "react-bootstrap/Form";

// Helper function for displaying errors from react-hook-form on a
// page using react-bootstrap Form layout
//
// XXX this would be better done using:
// <ErrorMessage as={Form.Control.Feedback} errors={errors} name="name" type="invalid"/>

export const formControlFeedback = (error: FieldError | undefined) => {
    return (error) ? (
        <Form.Control.Feedback type="invalid">
            {error.message}
        </Form.Control.Feedback>
    ) : null;
};

// Helper function to take a list of {id, description} objects and
// turn them into a list of <option> for use in <select>

export const formControlOptions = (options: {id: number | string, description: string }[]) => options.map((op) => (
    <option key={op.id} value={op.id}>{op.description}</option>
));


// Helper function to take a list of {id, name} objects and
// turn them into a list of <option> for use in <select>

export const formControlOptionsFromName = (options: {id: number, name: string }[]) => options.map((op) => (
    <option key={op.id} value={op.id}>{op.name}</option>
));

// Helper function to take a list of strings and
// turn them into a list of <option> for use in <select>

export const formControlOptionsFromStrings = (options: string[]) => options.map((s, i) => (
    <option key={i} value={s}>{s}</option>
));
