import React, {useContext} from "react";
import {formatMoney} from "accounting";
import {format} from "date-fns";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import {DisplayAddress} from "../../components/DisplayAddress";
import {nameSortFunc} from "../../util/nameSortFunc";
import {formatFullName} from "../../util/formatFullName";
import {formatSecondsToReadable} from "../../util/formatSecondsToReadable";
import {ActiveTournamentContext} from "./ActiveTournament";
import {splitStaffByDirector} from "./ActiveTournamentUpdateStaff";
import DisplayAsTextBlock from "../../components/DisplayAsTextBlock";

const ActiveTournamentSummaryDetails: React.FC = () => {
    const {tournament} = useContext(ActiveTournamentContext);
    const [directors, administrators] = splitStaffByDirector(tournament.staff);

    return (
        <Container fluid className="p-0">
            {(tournament.description) && (
                <Row>
                    <Col md={12} className="mb-2">
                        <DisplayAsTextBlock className="fst-italic"
                                            text={tournament.description}/>
                    </Col>
                </Row>
            )}

            {(tournament.venue || tournament.venue_info) && (
                <Row>
                    <Col md={2} className="label">Venue</Col>
                    <Col md={6}>
                        <DisplayAddress address={tournament.venue}/>
                    </Col>
                    <Col md={4}>
                        <DisplayAsTextBlock text={tournament.venue_info}/>
                    </Col>
                </Row>
            )}

            <Row>
                <Col md={2} className="label">
                    {(directors.length === 1) ? "Director" : "Directors"}
                </Col>
                <Col md={8}>
                    {directors.map((user_id) => tournament.ref_user[user_id])
                        .sort(nameSortFunc)
                        .map(formatFullName)
                        .join(", ")}
                </Col>
            </Row>

            {(administrators.length > 0) && (
                <Row>
                    <Col md={2} className="label">Staff</Col>
                    <Col md={10}>
                        {administrators.map((user_id) => tournament.ref_user[user_id])
                            .sort(nameSortFunc)
                            .map(formatFullName)
                            .join(", ")}
                    </Col>
                </Row>
            )}

            {(tournament.entry_fee) && (
                <Row>
                    <Col md={2} className="label">Entry fee</Col>
                    <Col md={10}>
                        {formatMoney(tournament.entry_fee, "$")}
                    </Col>
                </Row>
            )}

            <Row>
                <Col md={2} className="label">Start time</Col>
                <Col md={4}>
                    {format(new Date(tournament.start_time), "PPpp")}
                </Col>
                <Col md={2} className="label">Game duration</Col>
                <Col md={1}>
                    {(tournament.duration) ? `${formatSecondsToReadable(tournament.duration)}` : "(unset)"}
                </Col>
                <Col md={2} className="label">Clock inc.</Col>
                <Col md={1}>
                    {formatSecondsToReadable(tournament.clock_increment)}
                </Col>
            </Row>
        </Container>
    );
};

export default ActiveTournamentSummaryDetails;
