import React from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {formatMoney} from "accounting";

import {TournamentExpense} from "../../models/TournamentExpense";
import {TournamentFinancials} from "../../models/TournamentFinancials";

interface Props {
    financials: TournamentFinancials | undefined
    isLoading: boolean
}

const ActiveTournamentFinancialExpenses: React.FC<Props> = ({financials, isLoading}) => {
    const columns: TableColumn<TournamentExpense>[] = [{
        name: "Description",
        selector: (row) => row.description,
        grow: 5,
        sortable: true
    }, {
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.amount, "$")
    }];

    return (
        <DataTable<TournamentExpense> columns={columns}
                                      data={financials?.expenses ?? []}
                                      progressPending={isLoading}/>
    );
};

export default ActiveTournamentFinancialExpenses;
