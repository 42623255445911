import {Tournament} from "../models/Tournament";
import {Game} from "../models/Game";

export const gameTabEventKeyFromGame = (gameId: Game["id"]) => (`game-${gameId}`);
export const tournamentTabEventKeyFromTournament = (tournamentKey: Tournament["key"]) => (`tournament/${tournamentKey}`);

const TOURNAMENT_TAB_EVENT_KEY_RE = /tournament\/([0-9]+)/;

export const isTournamentTabEventKey = (key: string) => (
    key.match(TOURNAMENT_TAB_EVENT_KEY_RE)
);

export const tournamentIdFromTabEventKey = (key: string) => {
    const m = key.match(TOURNAMENT_TAB_EVENT_KEY_RE);

    return (m) ? Number(m[1]) : undefined;
};