import React from "react";
import {useDispatch} from "react-redux";
import {
    useUpdateTournamentAddingPlayerMutation, useUpdateTournamentRemovingPlayerMutation
} from "../../api/chessMasterApi";
import {Tournament} from "../../models/Tournament";
import {UserInfo} from "../../models/User";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {formatFullName} from "../../util/formatFullName";
import {setApiError} from "../api/ApiSlice";
import Button from "react-bootstrap/Button";

interface AddPlayerToTournamentButtonProps {
    user: UserInfo
    tournament: Tournament
    isAdded: boolean
}

const AddPlayerToTournamentButton: React.FC<AddPlayerToTournamentButtonProps> = ({user, tournament, isAdded}) => {
    const dispatch = useDispatch();
    const [addPlayerToTournament, {isLoading: isAddingPlayer}] = useUpdateTournamentAddingPlayerMutation();
    const [removePlayerFromTournament, {isLoading: isRemovingPlayer}] = useUpdateTournamentRemovingPlayerMutation();
    const addPlayer = (key: Tournament["key"], userId: UserInfo["id"]) => {
        addPlayerToTournament({key, userId})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Player added",
                    body: `Added ${formatFullName(user)} to "${tournament.name}"`
                })));
            })
            .catch((error) => dispatch(setApiError(error.data.message)));
    };
    const removePlayer = (key: Tournament["key"], userId: UserInfo["id"]) => {
        removePlayerFromTournament({key, userId})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Player removed",
                    body: `Removed ${formatFullName(user)} from "${tournament.name}"`
                })));
            })
            .catch((error) => dispatch(setApiError(error.data.message)));
    };

    return (
        <Button size="sm"
                variant={(isAdded) ? "danger" : "primary"}
                onClick={() => (isAdded) ? removePlayer(tournament.key, user.id) : addPlayer(tournament.key, user.id)}
                disabled={isAddingPlayer || isRemovingPlayer}>
            {(!isAdded) ? "Add" : "Remove"}
        </Button>
    );
};

export default AddPlayerToTournamentButton;