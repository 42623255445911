import React from "react";
import {formatDistance, parseISO} from "date-fns";

import Toast from "react-bootstrap/Toast";
import ToastContainer, {ToastPosition} from "react-bootstrap/ToastContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {useAppDispatch, useAppSelector} from "../../store";
import {removeToast, selectToasts, ToastMessage, ToastPriority} from "./toastSlice";

const priorityToClass: Record<ToastPriority, string> = {
    "info": "bg-info text-white",
    "warning": "bg-warning text-white",
    "danger": "bg-danger text-white",
    "success": "bg-success text-white"
};

const shouldAutohideToast = (toast: ToastMessage) => (
    toast.priority !== "danger"
);

interface Props {
    position: ToastPosition
}

const ToastDisplay: React.FC<Props> = ({position}) => {
    const dispatch = useAppDispatch();
    const {toasts} = useAppSelector(selectToasts);
    const now = new Date();

    return (
        <ToastContainer position={position}>
            {toasts.map((toast: ToastMessage, index: number) => (
                <Toast className={priorityToClass[toast.priority]}
                       key={toast.id}
                       onClose={() => dispatch(removeToast(toast))}
                       autohide={shouldAutohideToast(toast)}>
                    <Toast.Header>
                        <Row className="w-100">
                            <Col>
                                <span className="fs-4 me-1">♙</span>
                                <strong>{toast.heading}</strong>&nbsp;
                                <small>{formatDistance(parseISO(toast.date), now)}</small>
                            </Col>
                            {(toast.count > 1) && (
                                <Col md="auto">
                                    <strong>x{toast.count}</strong>
                                </Col>
                            )}
                        </Row>
                    </Toast.Header>
                    <Toast.Body>
                        {toast.body}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
};

export default ToastDisplay;
