import React from "react";
import {useDispatch} from "react-redux";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import {isPlayMethodOnline, Tournament, TournamentLite} from "../../models/Tournament";
import {
    editTournament,
    abandonTournament,
    rollForTournament,
    deleteTournament, projectorViewTournament, contactDetailsViewTournament
} from "./tournamentTabSlice";
import {selectLogin, selectLoginCanEditTournament} from "../login/loginSlice";
import {
    useCloneTournamentMutation,
    useDownloadTournamentTRFMutation,
    useStartClocksForTournamentMutation,
    useUnabandonTournamentMutation,
    useUnendRoundTournamentMutation,
    useUnstartTournamentMutation
} from "../../api/chessMasterApi";
import {modifyTournamentStaff} from "../activeTournaments/activeTournamentSlice";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useAppSelector} from "../../store";

interface TournamentActionButtonProps {
    label: string
    action: () => void
}

const TournamentActionButton: React.FC<TournamentActionButtonProps> = ({label, action}) => (
    <Dropdown.Item size="sm"
                   onClick={(ev) => {
                       ev.stopPropagation();
                       action();
                   }}>
        {label}
    </Dropdown.Item>
);

interface Props {
    tournament: Tournament | TournamentLite
    className?: string
}

export const TournamentStatusAction: React.FC<Props> = ({tournament, className}) => {
    const dispatch = useDispatch();
    const {privs} = useAppSelector(selectLogin);
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));
    const [cloneTournament] = useCloneTournamentMutation();
    const [unabandonTournament] = useUnabandonTournamentMutation();
    const [unstartTournament] = useUnstartTournamentMutation();
    const [unendRound] = useUnendRoundTournamentMutation();
    const [startClocks] = useStartClocksForTournamentMutation();
    const [downloadTournamentTRF] = useDownloadTournamentTRFMutation();
    const adminButtonsForStatus = (status: Tournament["status"]) => {
        const buttons: React.ReactElement[] = [];

        switch (status) {
        case "Pending":
            if (tournament.controls?.director) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                    <TournamentActionButton label="Start"
                                            key="start"
                                            action={() => dispatch(rollForTournament(tournament))}/>,
                    <TournamentActionButton label="Delete"
                                            key="delete"
                                            action={() => dispatch(deleteTournament(tournament))}/>
                );
            } else if (privs.tourn_admin || privs.admin) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                    <TournamentActionButton label="Delete"
                                            key="delete"
                                            action={() => dispatch(deleteTournament(tournament))}/>
                );
            }
            buttons.push(
                <TournamentActionButton label="Projector view"
                                        key="projector"
                                        action={() => dispatch(projectorViewTournament(tournament))}/>
            );
            break;

        case "Playing":
            if (tournament.controls?.director) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                    <TournamentActionButton label="Take roll"
                                            key="roll"
                                            action={() => dispatch(rollForTournament(tournament))}/>,
                    <TournamentActionButton label="Unstart"
                                            key="unstart"
                                            action={() => unstartTournament({key: tournament.key})
                                                .unwrap()
                                                .then(() => dispatch(addToast(newToastSuccess({
                                                    heading: `Tournament unstarted.`,
                                                    body: `${tournament.name} unstarted.`
                                                }))))
                                            }/>,
                    <TournamentActionButton label="Abandon"
                                            key="abandon"
                                            action={() => dispatch(abandonTournament(tournament))}/>,
                );
                if (isPlayMethodOnline(tournament.play_method)) {
                    buttons.push(
                        <TournamentActionButton label="Start clocks"
                                                key="start-clocks"
                                                action={() => startClocks({key: tournament.key})
                                                    .unwrap()
                                                    .then(() => dispatch(addToast(newToastSuccess({
                                                        heading: `Tournament clocks started`,
                                                        body: `${tournament.name} clocks started.`
                                                    }))))
                                                }/>
                    );
                }
            } else if (canEditTournament) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                );
            }
            buttons.push(
                <TournamentActionButton label="Projector view"
                                        key="projector"
                                        action={() => dispatch(projectorViewTournament(tournament))}/>
            );
            break;

        case "Abandoned":
            if (tournament.controls?.director) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                    <TournamentActionButton label="Un-abandon"
                                            key="unabandon"
                                            action={() => unabandonTournament({key: tournament.key})
                                                .unwrap()
                                                .then(() => dispatch(addToast(newToastSuccess({
                                                    heading: `Tournament unabandoned`,
                                                    body: `${tournament.name} unabandoned.`
                                                }))))
                                            }/>
                );
            } else if (privs.tourn_admin || privs.admin) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>
                );
            }
            break;

        case "Completed":
            if (tournament.controls?.director) {

                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>,
                    <TournamentActionButton label="Un-end round"
                                            key="unend"
                                            action={() => unendRound({key: tournament.key})
                                                .unwrap()
                                                .then(() => dispatch(addToast(newToastSuccess({
                                                    heading: `Tournament round un-ended`,
                                                    body: `${tournament.name} round un-ended.`
                                                }))))
                                            }/>
                    // <TournamentActionButton label="Hide"
                    //                         key="hide"
                    //                         action={() => dispatch(api.hideTournament({tournament})}/>
                );
            } else if (privs.tourn_admin || privs.admin) {
                buttons.push(
                    <TournamentActionButton label="Edit"
                                            key="edit"
                                            action={() => dispatch(editTournament(tournament))}/>
                );
            }
            buttons.push(
                <TournamentActionButton label="Projector view"
                                        key="projector"
                                        action={() => dispatch(projectorViewTournament(tournament))}/>
            );
            break;

        default:
            console.error(`TournamentStatusAction: unknown status "${status}"`);
            return [];
        }
        if (privs.admin || tournament.controls?.director) {
            buttons.push(
                <TournamentActionButton label="Save TRF"
                                        key="save-trf"
                                        action={() => downloadTournamentTRF({key: tournament.key})
                                                    .unwrap()
                                                    .then(({filename}) => {
                                                        dispatch(addToast(newToastSuccess({
                                                            heading: "TRF downloaded",
                                                            body: `TRF for ${tournament.name} saved to "${filename}"`
                                                        })))
                                                    })
                                        }/>
            );
        }
        if (privs.admin) {
            buttons.push(
                <TournamentActionButton label="Clone"
                                        key="clone"
                                        action={() => cloneTournament({key: tournament.key})
                                                    .unwrap()
                                                    .then(() => dispatch(addToast(newToastSuccess({
                                                        heading: `Tournament cloned`,
                                                        body: `${tournament.name} cloned.`
                                                    }))))
                                        }/>
            );
        }
        return buttons;
    };
    const otherButtonsForStatus = (status: Tournament["status"]) => {
        const buttons: React.ReactElement[] = [];
        switch (status) {
        case "Pending":
        case "Playing":
            if (canEditTournament) {
                buttons.push(
                    <TournamentActionButton label="Modify staff ..."
                                            key="modifyStaff"
                                            action={() => dispatch(modifyTournamentStaff(tournament))}/>
                );
            }
            break;

        case "Abandoned":
        case "Completed":
            break;

        default:
            console.error(`TournamentStatusAction: unknown status "${status}"`);
            return [];
        }
        if (canEditTournament) {
            buttons.push(
                <TournamentActionButton label="Contact details"
                                        key="contactDetails"
                                        action={() => dispatch(contactDetailsViewTournament(tournament))}/>
            );
        }
        return buttons;
    };

    const adminButtons = (tournament.status) ? adminButtonsForStatus(tournament.status) : [];
    const otherButtons = (tournament.status) ? otherButtonsForStatus(tournament.status) : [];
    const buttons = [
        ...adminButtons,
        ...(adminButtons.length > 0 && otherButtons.length > 0) ?  [<Dropdown.Divider key={0}/>] : [],
        ...otherButtons
    ];

    if (!buttons.length) {
        return null;
    }

    return (
        <ButtonGroup size="sm" className={className}>
            <DropdownButton as={ButtonGroup}
                            key="adminButtons"
                            variant="secondary"
                            size="sm"
                            onClick={(ev) => ev.stopPropagation()}
                            title="Actions">
                {buttons}
            </DropdownButton>
        </ButtonGroup>
    );
};