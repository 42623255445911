import React, {useEffect} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {skipToken} from "@reduxjs/toolkit/query";
import UserForm, {UserRegistration, userRegistrationSchema} from "./UserForm";
import {isUser, NewUserWithRegistration} from "../models/User";
import {payloadChangesWithId} from "../util/payloadChangesWithId";
import {addToast, newToastSuccess} from "../features/toast/toastSlice";
import {createUserGroupUpdates} from "../util/createUserGroupUpdates";
import {displayServerErrorsInForm} from "../util/displayServerErrorsInForm";
import {FormProvider, useForm} from "react-hook-form";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {useGroupsForUserQuery, useUpdateGroupsForUserMutation, useUpdateUserMutation} from "../api/chessMasterApi";
import {useAppDispatch} from "../store";
import {
    newPasswordForm,
    optionalPasswordSchema,
} from "../util/passwordSchema";

interface Props {
    user: NewUserWithRegistration
    onClose: (didUpdate: boolean) => void
}

const CreateUser: React.FC<Props> = ({user, onClose}) => {
    const dispatch = useAppDispatch();
    const [updateUser] = useUpdateUserMutation();
    const [updateGroupsForUser] = useUpdateGroupsForUserMutation();
    const {data: userGroups} = useGroupsForUserQuery((isUser(user)) ? {userId: user.id} : skipToken);
    const formMethods = useForm<UserRegistration>({
        defaultValues: {...user, userGroups: [], password: newPasswordForm()},
        resolver: yupResolver(userRegistrationSchema({password: optionalPasswordSchema}))
    });
    const onSubmit = ({user: updatedUser, userGroups: updatedUserGroups, password, tournament_key, guardian_id}: UserRegistration) => {
        updatedUser.new_password = password.new_password;
        updateUser({user: (isUser(user)) ? payloadChangesWithId(user, updatedUser) : updatedUser, tournament_key, guardian_id})
            .unwrap()
            .then((user) => {
                dispatch(addToast(newToastSuccess({
                    heading: "User created",
                    body: `Created new user ${user.username}`
                })));
                return user;
            })
            .then((newChild) => {
                const groupUpdates = createUserGroupUpdates(userGroups ?? [], updatedUserGroups ?? []);
                if (groupUpdates.length > 0) {
                    updateGroupsForUser({userId: newChild.id, groupUpdates})
                        .then(() => {
                            dispatch(addToast(newToastSuccess({
                                heading: "Add groups",
                                body: `User "${newChild.username}" added to ${updatedUserGroups.length} groups`
                            })));
                        });
                }
            })
            .then(() => formMethods.reset())
            .then(() => onClose(true))
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch, "user");
            });
    };
    useEffect(() => {
        formMethods.reset({...formMethods.getValues(), ...user, userGroups});
    },
    [user, formMethods, userGroups]
    );

    return (
        <Container>
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Row>
                        <UserForm user={user} isCreating/>
                    </Row>

                    <Row>
                        <div className="text-end">
                            <Button variant="secondary"
                                    className="me-1"
                                    onClick={() => onClose(false)}>Cancel</Button>
                            <Button type="submit"
                                    variant="primary">Submit</Button>
                        </div>
                    </Row>
                </Form>
            </FormProvider>
        </Container>
    );
};

export default CreateUser;
