import React from "react";
import {BsCloudCheck} from "react-icons/bs";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const LichessOnlineTooltip: React.FC = () => (
    <Tooltip>Lichess user online</Tooltip>
);

const UserLichessOnlineSymbol: React.FC<{className?: string}> = ({className}) => (
    <OverlayTrigger overlay={<LichessOnlineTooltip/>}>
        <span className={`text-success fs-5 ${className ?? ""}`}><BsCloudCheck/></span>
    </OverlayTrigger>
);

export default UserLichessOnlineSymbol;