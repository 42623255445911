import {
    sortTournamentPlayersByPoints,
    TournamentPlayer
} from "../models/TournamentPlayer";

export interface PlayerGroupStanding {
    points: number
    groupRank: { min: number, max: number }
    players: TournamentPlayer[]
}

export const createPlayerStandings = (players: TournamentPlayer[]): PlayerGroupStanding[] => {
    const pointsToPlayers = new Map<TournamentPlayer["points"], TournamentPlayer[]>();
    let rank = 1;

    players.forEach((player) => {
        const playersAtPoints = pointsToPlayers.get(player.points);
        if (playersAtPoints) {
            playersAtPoints.push(player);
        } else {
            pointsToPlayers.set(player.points, [player]);
        }
    });
    const orderedPoints = Array.from(pointsToPlayers.keys()).sort().reverse();

    return orderedPoints.map((points) => {
        // note we sort the players within a rank to include the Buchholz number
        const playersAtRank = sortTournamentPlayersByPoints(pointsToPlayers.get(points)!);
        const standing: PlayerGroupStanding = {
            points,
            groupRank: {min: rank, max: rank + playersAtRank.length - 1},
            players: playersAtRank
        };
        rank += playersAtRank.length;

        return standing;
    })
};