import React from "react";
import useLocalStorageState from "use-local-storage-state";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import RatingList from "./RatingList";
import RatingSearch from "./RatingSearch";
import {TournamentType} from "../../models/TournamentType";
import {selectLogin} from "../login/loginSlice";
import {
    useFinaliseRatingsMutation,
    useUpdateProvisionalRatingsMutation
} from "../../api/chessMasterApi";
import {RatingPeriod} from "../../models/RatingPeriod";
import {useAppSelector} from "../../store";

interface Props {
    tournamentType: TournamentType
}

const RatingTab: React.FC<Props> = ({tournamentType}) => {
    const {privs} = useAppSelector(selectLogin);
    const [query, setQuery] = useLocalStorageState<string>(`ratingTab/query`, {defaultValue: ""});
    const [ratingPeriod, setRatingPeriod] = useLocalStorageState<RatingPeriod | undefined>(`ratingTab/${tournamentType.description}/period`, {defaultValue: undefined});
    const [updateProvisionalRatings] = useUpdateProvisionalRatingsMutation();
    const [finaliseRatings] = useFinaliseRatingsMutation();

    return (
        <Container>
            <Row className="my-3 mx-3">
                <RatingSearch tournamentType={tournamentType}
                              query={query}
                              setQuery={setQuery}
                              ratingPeriod={ratingPeriod}
                              setRatingPeriod={setRatingPeriod}/>
            </Row>
            <Row>
                <RatingList tournamentType={tournamentType} query={query}/>
            </Row>
            {(privs.admin) && (
                <Row className="float-end">
                    <Col className="me-3">
                        <DropdownButton id="dropdown-finalise-ratings-button"
                                        title="Finalise ratings">
                            <Dropdown.Item onClick={() => updateProvisionalRatings({tournamentTypeId: tournamentType.id})}>
                                Update provisional
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => finaliseRatings({tournamentTypeId: tournamentType.id})}>
                                Finalise period
                            </Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>
            )}

        </Container>
    );
};

export default RatingTab;
