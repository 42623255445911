import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {BsCaretDownFill, BsFillCaretRightFill} from "react-icons/bs";

import {
    setRunEditChild,
    setRunChangePasswordForUser
} from "./parentTabSlice";
import {UserInfo} from "../../models/User";
import {formatFullName} from "../../util/formatFullName";
import ChildTournamentList from "./ChildTournamentList";
import {TournamentLite} from "../../models/Tournament";

const ChildCardActionButton:React.FC<{label: string, action: () => void}> = ({label, action}) => (
    <Dropdown.Item size="sm"
                   onClick={(ev) => {
                       ev.stopPropagation();
                       action();
                   }}>
        {label}
    </Dropdown.Item>
);

interface ChildCardActionProps {
    child: UserInfo
    className?: string
}
const ChildCardAction: React.FC<ChildCardActionProps> = ({child, className}) => {
    const dispatch = useDispatch();
    const buttons = [
        <ChildCardActionButton key="edit" label="Edit" action={() => dispatch(setRunEditChild(child.id))}/>,
        <ChildCardActionButton key="change-password"
                               label="Change password"
                               action={() => dispatch(setRunChangePasswordForUser({userId: child.id, fullName: formatFullName(child)}))}/>,
    ];

    return (
        <ButtonGroup size="sm" className={className}>
            <DropdownButton as={ButtonGroup}
                            key="parentButtons"
                            variant="secondary"
                            size="sm"
                            onClick={(ev) => ev.stopPropagation()}
                            title="Actions">
                {buttons}
            </DropdownButton>
        </ButtonGroup>
    );

};

interface ChildCardProps {
    child: UserInfo
    index: number
    tournaments: TournamentLite[] | undefined
}

export const ChildCard: React.FC<ChildCardProps> = ({child, index, tournaments}) => {
    const [isShowing, setIsShowing] = useState(false);

    return (
        <Card key={index} className="mb-3 w-100">
            <Card.Header onClick={() => setIsShowing(!isShowing)}>
                <span className="fw-bold">
                    <span>
                        {(isShowing) ? <BsCaretDownFill/> : <BsFillCaretRightFill/>}
                    </span>
                    {formatFullName(child)}
                    <small className="ms-1 text-end text-muted">({child.username})</small>
                    <ChildCardAction child={child} className="ms-3"/>
                </span>
            </Card.Header>
            {(isShowing) && (
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <ChildTournamentList child={child}
                                                 tournaments={tournaments}/>
                        </Row>
                    </Container>
                </Card.Body>
            )}
        </Card>
    );
};