import React from "react";

import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import {isMatchStatusComplete, Match} from "../../models/Match";

export type MatchStatusFilterOptions = "all" | "pending" | "playing" | "completed"

interface Props {
    matches: Match[]
    statusFilter: MatchStatusFilterOptions
    setStatusFilter: (status: MatchStatusFilterOptions) => void
}

const MatchStatusFilter: React.FC<Props> = ({matches, statusFilter, setStatusFilter}) => {
    return (
        <ToggleButtonGroup size="sm"
                           type="radio"
                           name="options"
                           defaultValue={statusFilter}
                           onChange={(ev) => setStatusFilter(ev as MatchStatusFilterOptions)}>
            <ToggleButton id="match-status-all" value="all">
                All ({matches.length})
            </ToggleButton>
            <ToggleButton id="match-status-pending" value="pending">
                Pending ({matches.filter((match) => match.status === "Pending").length})
            </ToggleButton>
            <ToggleButton id="match-status-playing" value="playing">
                Playing ({matches.filter((match) => match.status === "Playing").length})
            </ToggleButton>
            <ToggleButton  id="match-status-completed" value="completed">
                Completed ({matches.filter((match) => isMatchStatusComplete(match)).length})
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default MatchStatusFilter;
