import React, {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import TournamentTeams from "../tournamentTab/TournamentTeams";
import {Team} from "../../models/Team";
import {Tournament} from "../../models/Tournament";

interface FormData {
    teams: Team[]
}

interface Props {
    tournament: Tournament
    onClose: () => void
}

const ActiveTournamentTeamBarringModal: React.FC<Props> = ({tournament, onClose}) => {
    const hasPairingStatusUnset = (teams: Team[]) => (
        teams.map((team) => team.no_pair).some((no_pair) => no_pair === null)
    );
    const onSubmit = ({teams: updatedTeams}: FormData) => {
        onClose();
    };
    const formMethods = useForm<FormData>({
        defaultValues: {teams: tournament.teams},
    });
    useEffect(
        () => (tournament) && formMethods.setValue("teams", tournament.teams),
        [formMethods, tournament]
    );

    return (
        <Modal show={true}
               onHide={onClose}
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Team barring</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <TournamentTeams tournamentKey={tournament.key}/>
                    </Modal.Body>

                    {(hasPairingStatusUnset(tournament.teams)) && (
                        <Modal.Body>
                            <div className="text-warning text-end">
                                You won't be able to pair a round until all teams have their
                                pairing status set.
                            </div>
                        </Modal.Body>
                    )}

                    <Modal.Footer>
                        <Button onClick={onClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default ActiveTournamentTeamBarringModal;
