import React from 'react';

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {format} from "date-fns";

import {useRatingPeriodsQuery} from "../../api/chessMasterApi";
import {TournamentType} from "../../models/TournamentType";
import {formControlOptions} from "../../util/formControl";
import {RatingPeriod} from "../../models/RatingPeriod";
import {useDebouncedCallback} from "use-debounce";

const PERIOD_FORMAT = "PP";

const makeTournamentPeriods = (ratingPeriods: RatingPeriod[]) => {
    const formatRatingPeriodDescription = (rp: RatingPeriod) => {
        const begin = (rp.begin) ? format(new Date(rp.begin), PERIOD_FORMAT) : "(big bang)";
        const end = (rp.end) ? format(new Date(rp.end), PERIOD_FORMAT) : "(open)";

        return `${begin} - ${end}`;
    };
    if (ratingPeriods.length > 0) {
        return ratingPeriods.map((rp) => ({
            id: rp.id,
            description: formatRatingPeriodDescription(rp)
        }));
    } else {
        return [{
            id: 0,
            description: "(No rating periods)"
        }];
    }
};

const findRatingPeriodWithId = (id: RatingPeriod["id"], ratingPeriods: RatingPeriod[]) => (
    ratingPeriods.find((rp) => rp.id === id)
);

interface Props {
    tournamentType: TournamentType
    query: string
    setQuery: (query: string) => void
    ratingPeriod: RatingPeriod | undefined
    setRatingPeriod: (ratingPeriod: RatingPeriod | undefined) => void
}

const RatingSearch: React.FC<Props> = ({tournamentType, query, setQuery, ratingPeriod, setRatingPeriod}) => {
    const {data: ratingPeriods} = useRatingPeriodsQuery({tournamentTypeId: tournamentType.id});
    const tournamentPeriods = makeTournamentPeriods(ratingPeriods ?? []);
    const setDebouncedQuery = useDebouncedCallback((value: string) => setQuery(value), 300);

    return (
        <InputGroup className="mb-3">
            <Form.Control type="search"
                          defaultValue={query}
                          onChange={(ev) => setDebouncedQuery(ev.target.value)}
                          placeholder="Search ..."/>
            <InputGroup.Text>
                <Form.Control as="select"
                              value={ratingPeriod?.id}
                              disabled={(ratingPeriods ?? []).length <= 0}
                              onChange={(ev) => {
                                  const ratingPeriod = findRatingPeriodWithId(Number(ev.target.value), ratingPeriods ?? []);
                                  (ratingPeriod) && setRatingPeriod(ratingPeriod)
                              }}>
                    {formControlOptions(tournamentPeriods)}
                </Form.Control>
            </InputGroup.Text>
        </InputGroup>
    );
};

export default RatingSearch;