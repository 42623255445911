import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import {Tournament} from "../models/Tournament";
import {formatFullName} from "../util/formatFullName";
import {
    setActiveTournamentPlayerSummary
} from "../features/activeTournaments/activeTournamentSlice";
import {selectLoginCanEditTournament} from "../features/login/loginSlice";
import ShowPlayerContactDetails from "./ShowPlayerContactDetails";
import UserLichessOnlineSymbol from "./UserLichessOnlineSymbol";
import {selectConstantsGenderById} from "../features/constants/constantsSlice";
import {useAppSelector} from "../store";
import {PlayerSearch, PlayerSearchPlayer} from "../models/PlayerSearch";
import PaidSymbol from "./PaidSymbol";

interface Props {
    player: PlayerSearchPlayer
    playerSearch: PlayerSearch
    tournament: Tournament
    online?: boolean
    showContactDetails?: boolean
}

const PlayerSearchAndTeam: React.FC<Props> = ({player, playerSearch, tournament,online = false, showContactDetails = true}) => {
    const dispatch = useDispatch();
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));
    const user = (player) ? tournament.ref_user[player.id] : undefined;
    const groups = player.group_ids.map((group_id) => playerSearch.ref_group[group_id].name).sort() ?? [];
    const genderById = useAppSelector(selectConstantsGenderById);
    const renderShortGender = (player: PlayerSearchPlayer) => {
        return genderById[player.gender_id].short;
    };
    const renderName = (player: PlayerSearchPlayer) => {
        const fullName = formatFullName(player);

        if (tournament) {
            return (
                <>
                    {(canEditTournament && showContactDetails) && (
                        <ShowPlayerContactDetails tournament={tournament} user={player}/>
                    )}
                    <Button variant="link"
                            className="p-0 text-start"
                            onClick={() => dispatch(setActiveTournamentPlayerSummary({
                                tournament,
                                player: {...player, groups}
                            }))}>
                        {fullName} ({renderShortGender(player)})
                    </Button>
                </>
            );
        } else {
            return <>
                {fullName}
            </>;
        }
    };

    return (
        <div className="w-100">
            <Stack direction="horizontal" gap={1}>
                <span className="text-nowrap">{renderName(player)}</span>
                {(player.paid) ? <PaidSymbol className="pt-2 ms-auto"/> : ""}
                {(online) ? <UserLichessOnlineSymbol/> : ""}
            </Stack>
            {(groups.length > 0) && (
                <div>
                    {groups.join(", ")}
                </div>
            )}
            <Stack direction="horizontal">
                {(user?.username) ? <small className="text-muted">({user.username})</small> : ""}
            </Stack>
        </div>
    );
};

export default PlayerSearchAndTeam;