import {UserInfo} from "./User";
import {Group} from "./Group";

export interface PlayerUpdate {
    participating: boolean
    user_id: UserInfo["id"]
    team_id: Group["id"] | null
    _delete: boolean
}

export const deletePlayerUpdate = (playerId: UserInfo["id"]): Partial<PlayerUpdate> => ({
    user_id: playerId,
    _delete: true
});

export const addPlayerToTeamUpdate = (playerId: UserInfo["id"], teamId: Group["id"]) => ({
    user_id: playerId,
    team_id: teamId
});

export const removePlayerFromTeamUpdate = (playerId: UserInfo["id"]) => ({
    user_id: playerId,
    team_id: null
});