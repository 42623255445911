import {User, UserInfo} from "./User";
import {Money} from "./types";
import {Tournament, TournamentMinSchema} from "./Tournament";
import {GroupMinSchema} from "./Group";

export type PaymentStatusDescription = "Preparing" | "Pending" | "Paid" | "Failed" |
                                       "Abandoned" | "Refunded" | "Disputed" | "Proforma";
export type PaymentSystem = "none" | "paypal-sandbox" | "paypal" |
                            "stripe-sandbox" | "stripe" |
                            "manual" | "proforma"

export interface PaymentStatus {
    id: number
    description: PaymentStatusDescription
}

export interface InterimPaymentItem {
    identifier: string | null
    amount: Money
    description: string
}

export type PaymentItemIdentifier = InterimPaymentItem["identifier"]

export interface PaymentItem extends InterimPaymentItem {
    payment_id: number
}

interface PaymentBase {
    id: number
    payer_id: User["id"] | null     // null == logged in user
    tournament_id: Tournament["id"]
    group_payer_id: GroupMinSchema["id"]
    max_items?: number | null
    amount: Money
    credit: Money
    status: PaymentStatusDescription
    reference: string | null
    includes_gst: boolean
    payment_system: PaymentSystem
    payment_system_id: number
    // payment_token: string
    failure_reason: string
    create_time: string // ISO date
    submit_time: string // ISO date
    paid_time: string // ISO date
    payer: UserInfo
    readonly group_payer: GroupMinSchema
    readonly tournament: TournamentMinSchema
}

export interface InitiatePayment extends PaymentBase {
    items: PaymentItemIdentifier[]
}

export interface Payment extends PaymentBase {
    payment_token: string   // ie, PayPal order_id
    items: PaymentItem[]
}

// we use this when we are laying out a list of PaymentItems from a Payment so we can
// easily track which items are headings

export interface ListPaymentItem {
    is_payment_heading: boolean
    paid_time?: string
    payment_system?: PaymentSystem
    status?: PaymentStatusDescription
    includes_gst?: boolean
    amount: Money
    description?: string
}

// flatten out the Payment and PaymentItems into a list so we can display them
export const createListPaymentItems = (payment: Payment): ListPaymentItem[] => {
    const heading = {
        is_payment_heading: true,
        ...payment
    };
    return [
        heading,
        ...payment.items.map((item) => ({is_payment_heading: false, ...item}))
    ];
};

export const newPaymentItem = (initFields: Partial<PaymentItem>): PaymentItem => ({
    payment_id: 0,
    identifier: "",
    amount: "0",
    description: "",
    ...initFields
});

export const isPayment = (obj: any): obj is Payment => {
    return obj.id !== undefined
};