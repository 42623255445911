import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

import {Payment} from "../../models/Payment";

interface FinanceTabState {
    runEditPaymentModal: Payment | "new" | null
    runEditProFormaModal: Payment | "new" | null       // XXX Payment may not be correct
}

const initialState: FinanceTabState = {
    runEditPaymentModal: null,
    runEditProFormaModal: null
};

export const slice = createSlice({
    name: "financeTab",
    initialState,
    reducers: {
        setRunEditPaymentModal: (state, action: PayloadAction<FinanceTabState["runEditPaymentModal"]>) => {
            state.runEditPaymentModal = action.payload;
        },
        setRunEditProFormaModal: (state, action: PayloadAction<FinanceTabState["runEditProFormaModal"]>) => {
            state.runEditProFormaModal = action.payload;
        }
    }
});

export const selectFinanceTab = (state: RootState) => state.financeTab;
export const {
    setRunEditPaymentModal, setRunEditProFormaModal
} = slice.actions;

export default slice.reducer;
