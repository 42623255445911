import React, {useCallback, useMemo, useState} from "react";
import {BsCaretDownFill, BsCaretUpFill} from "react-icons/bs";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import {PaymentItem} from "../../models/Payment";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import {UseSelection} from "../../hooks/useSelection";

interface Props {
    paymentItems: PaymentItem[]
    paymentItemSelection: UseSelection<PaymentItem["identifier"]>
    className?: string
}

const PaymentOptionList: React.FC<Props> = ({paymentItems, paymentItemSelection, className}) => {
    const [ascDesc, setAscDesc] = useState<"asc" | "desc">("asc");
    const sortedPaymentItems = useMemo(
        () => {
            const sortFunc = (ascDesc === "asc") ? (a: PaymentItem, b: PaymentItem) => (a.description.localeCompare(b.description))
                                                 : (a: PaymentItem, b: PaymentItem) => (b.description.localeCompare(a.description));
            return [...paymentItems].sort(sortFunc);
        },
        [ascDesc, paymentItems]
    );
    const toggleAllSelected = useCallback(() => paymentItemSelection.toggleAllSelected(), [paymentItemSelection]);

    return (
        <Table className={className}>
            <thead>
            <tr>
                <th>
                    <IndeterminateCheckbox checked={paymentItemSelection.isAllSelected}
                                           indeterminate={paymentItemSelection.isAnySelected}
                                           onChange={toggleAllSelected}/>
                </th>
                <th>
                    Description {(ascDesc === "asc") ? <BsCaretUpFill onClick={() => setAscDesc("desc")}/>
                                                     : <BsCaretDownFill onClick={() => setAscDesc("asc")}/>}
                </th>
            </tr>
            </thead>
            <tbody>
            {sortedPaymentItems.map((item, key) => (
                <tr key={key}>
                    <td>
                        <Form.Check checked={paymentItemSelection.selectedSet.has(item.identifier)}
                                    disabled={paymentItemSelection.disabledSet.has(item.identifier)}
                                    onChange={(ev) => paymentItemSelection.setSelected({item: item.identifier, isSelected: ev.currentTarget.checked})}/>
                    </td>
                    <td>{item.description}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default PaymentOptionList;