import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import MultiPersonPicker from "../../components/multiPersonPicker/MultiPersonPicker";

import {UserInfo} from "../../models/User";
import {ChildrenForUserResponse} from "../../api/chessMasterApi";

interface Props {
    guardian: UserInfo
    children: ChildrenForUserResponse
    setChildren: (children: ChildrenForUserResponse) => void
}

export const UserEditChildren: React.FC<Props> = ({guardian, children, setChildren}) => {
    const updateChildren = (children: UserInfo[]) => {
        setChildren(children.map((child) => (
            {guardian, child, guardian_type: null}
        )));
    };

    return (
        <Container>
            <Form.Group as={Row} controlId="userChildren">
                <Form.Label column md={2}
                            className="text-end">
                    Children
                </Form.Label>
                <Col md={10}>
                    <MultiPersonPicker onChange={updateChildren}
                                       selectedUserInfos={(children ?? []).map((cfu) => cfu.child)}
                                       isInvalid={false}/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default UserEditChildren;