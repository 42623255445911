import React, {useContext} from "react";
import useLocalStorageState from "use-local-storage-state";

import {useSearchPaymentQuery} from "../../api/chessMasterApi";
import PaymentList from "../paymentTab/PaymentList";
import {PaymentStatus, PaymentSystem} from "../../models/Payment";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

interface Props {
    query: string
}

const FinancePaymentList: React.FC<Props> = ({query}) => {
    const [paymentStatusFilter, setPaymentStatusFilter] = useLocalStorageState<PaymentStatus | undefined>("financeTab/paymentStatusFilter", undefined);
    const [paymentSystemFilter, setPaymentSystemFilter] = useLocalStorageState<PaymentSystem | undefined>("financeTab/paymentSysytemFilter", undefined);
    const {setSearchErrorMessage} = useContext(SearchErrorMessageContext);
    const {data: payments, isFetching, error} = useSearchPaymentQuery({
        query,
        status: paymentStatusFilter?.description,
        payment_system: paymentSystemFilter
    });

    setSearchErrorMessage?.((error) ? formatErrorMessage(error) : null);

    return (
        <PaymentList payments={payments ?? []}
                     allowEdit
                     isFetching={isFetching}
                     status={paymentStatusFilter}
                     onChangeStatus={(status) => setPaymentStatusFilter(status)}
                     paymentSystem={paymentSystemFilter}
                     onChangePaymentSystem={setPaymentSystemFilter}/>
    );
};

export default FinancePaymentList;
