import React, {useEffect, useState} from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// 3rd party
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import {useFormContext} from "react-hook-form";

import {Role} from "../../models/Role";
import {DroppableRoleList} from "./DroppableRoleList";
import {partitionByIds} from "../../util/partitionById";
import {useListRolesQuery} from "../../api/chessMasterApi";
import {NewUser, User} from "../../models/User";
import {isSetEqual} from "../../util/isSetEqual";

const UserEditRoles: React.FC = () => {
    const {register, setValue, watch} = useFormContext<{user: User | NewUser}>();
    const {data: allRoles} = useListRolesQuery();
    const [assignedRoles, setAssignedRoles] = useState<Role[]>([]);
    const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
    const role_ids: Role["id"][] = watch("user.role_ids", []);

    const onDragEnd = (result: DropResult) => {
        const {source, destination} = result;

        // XXX if source.droppableId === destination.droppableId, might be nice to reorder list
        if (!destination || source.droppableId === destination.droppableId) {
            return;
        }
        switch (source.droppableId) {
        case "availableRoles":
            setValue("user.role_ids", [...role_ids, availableRoles[source.index].id]);
            break;
        case "assignedRoles":
            const assignedRoleId = assignedRoles[source.index].id;
            setValue("user.role_ids", role_ids.filter((role_id) => role_id !== assignedRoleId));
            break;
        default:
            console.log(`unknown source droppableId ${source.droppableId}`);
        }
    };

    register("user.role_ids");
    useEffect(() => {
        const [updatedAssignedRoles, updatedAvailableRoles] = partitionByIds(allRoles ?? [], role_ids ?? []);

        if (!isSetEqual(new Set(assignedRoles), new Set(updatedAssignedRoles))) {
            setAssignedRoles(updatedAssignedRoles);
        }
        if (!isSetEqual(new Set(availableRoles), new Set(updatedAvailableRoles))) {
            setAvailableRoles(updatedAvailableRoles);
        }
    }, [role_ids, allRoles, assignedRoles, availableRoles]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Row className="g-0 p-2 border rounded">
                <Col md={6} className="pe-3">
                    <DroppableRoleList title="Assigned"
                                        droppableId="assignedRoles"
                                        roles={assignedRoles}/>
                </Col>
                <Col md={6}>
                    <DroppableRoleList title="Available"
                                        droppableId="availableRoles"
                                        roles={availableRoles}/>
                </Col>
            </Row>
        </DragDropContext>
    );
};

export default UserEditRoles;