import React from "react";
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {LoginAsFormInput} from "./LoginAsModal";

const LoginAsForm: React.FC = () => {
    const {register, formState: {errors}} = useFormContext<LoginAsFormInput>();

    return (
        <Container>

            <Form.Group as={Row} controlId="loginAsUser">
                <Form.Label column md={4}>Log in as user</Form.Label>
                <Col md={8}>
                    <Form.Control {...register("username")}
                                  placeholder="Name"
                                  autoComplete="username"
                                  isInvalid={!!errors.username}
                                  autoCapitalize="off"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  errors={errors}
                                  name="username"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="loginAsPassword" className="mt-2">
                <Form.Label column md={4}>Confirm your Password</Form.Label>
                <Col md={8}>
                    <Form.Control {...register("password")}
                                  placeholder="Password"
                                  autoComplete="current-password"
                                  type="password"
                                  isInvalid={!!errors.password}
                                  autoCapitalize="off"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  errors={errors}
                                  name="password"
                                  type="invalid"/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default LoginAsForm;