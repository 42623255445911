import React, {useContext} from "react";
import {FieldError} from "react-hook-form";
import {useDebouncedCallback} from "use-debounce";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {selectConstants} from "../constants/constantsSlice";
import {formControlOptions} from "../../util/formControl";
import {GroupType} from "../../models/GroupType";
import {Group} from "../../models/Group";
import {useAppSelector} from "../../store";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import {BsInfoCircle} from "react-icons/bs";
import {groupSearchHelpPopover} from "../../util/SearchHelpPopover";

interface GroupFilterInputProps {
    query: Group["name"]
    setQuery: (query: Group["name"]) => void
    groupTypeId: GroupType["id"]
    setGroupTypeId: (groupTypeId: GroupType["id"]) => void
    fieldError?: FieldError
}

const GroupFilterInput: React.FC<GroupFilterInputProps> = ({query, setQuery, groupTypeId, setGroupTypeId, fieldError}) => {
    const {group_types} = useAppSelector(selectConstants);
    const {searchErrorMessage} = useContext(SearchErrorMessageContext);
    const setDebouncedQuery = useDebouncedCallback(setQuery, 300);

    return (
        <div className="mb-3">
            <InputGroup className={(searchErrorMessage) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              name="query"
                              defaultValue={query}
                              onChange={(ev) => setDebouncedQuery(ev.target.value)}
                              placeholder="Search ..."
                              isInvalid={!!searchErrorMessage}/>
                <InputGroup.Text className="p-0">
                    <Form.Control as="select"
                                  value={groupTypeId}
                                  onChange={(ev) => setGroupTypeId(Number(ev.target.value))}
                                  aria-label="Group type filter"
                                  aria-describedby="group-type-filter-field">
                        <option value={-1}>(All)</option>
                        {formControlOptions(group_types)}
                    </Form.Control>
                </InputGroup.Text>
                <OverlayTrigger trigger="click" placement="bottom" overlay={groupSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {searchErrorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default GroupFilterInput;