import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {DevTool} from "@hookform/devtools";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {usePaymentInitiateMutation} from "../../api/chessMasterApi";
import {useAppDispatch} from "../../store";
import {InitiatePayment, Payment} from "../../models/Payment";
import {TournamentMinSchema} from "../../models/Tournament";
import FinanceProFormaPayment from "./FinanceProFormaPayment";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {formatMoney} from "accounting";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";
import {GroupMinSchema} from "../../models/Group";

export interface ProFormaPaymentForm {
    groupPayer?: GroupMinSchema
    tournament?: TournamentMinSchema
    max_items: Payment["max_items"]        // number of players, >= 0
    amount: Payment["amount"]
    reference: Payment["reference"]
}

const proFormaPaymentSchema = yup.object().shape({
    groupPayer: yup.object().required("No group selected"),
    tournament: yup.object().required("No tournament selected"),
    max_items: yup.number().min(0),
    amount: yup.number().min(0),
    reference: yup.string()
});

interface Props {
    payment?: Payment
    onClose: () => void
}

const FinanceProFormaPaymentModal: React.FC<Props> = ({payment, onClose}) => {
    const dispatch = useAppDispatch();
    const [paymentInitiate] = usePaymentInitiateMutation();
    const defaultValues: ProFormaPaymentForm = {
        groupPayer: undefined,
        tournament: undefined,
        max_items: 0,        // number of players, >= 0
        amount: "0.00",
        reference: ""
    };
    const formMethods = useForm<ProFormaPaymentForm>({
        defaultValues: payment ?? defaultValues,
        resolver: yupResolver(proFormaPaymentSchema)
    });
    const onSubmit = (form: ProFormaPaymentForm) => {
        const newPayment: Partial<InitiatePayment> = {
            payment_system: "proforma",
            tournament_id: form.tournament?.id ?? 0,
            group_payer_id: form.groupPayer?.id ?? 0,
            reference: form.reference,
            amount: form.amount,
            max_items: form.max_items
        };
        const payload = (payment) ? payloadChangesWithId({...payment, items: payment.items.map((item) => item.identifier)}, newPayment) : newPayment;
        paymentInitiate(payload)
            .unwrap()
            .then((payment) => {
                const tournament = formMethods.getValues("tournament");

                dispatch(addToast(newToastSuccess({
                    heading: `Pro forma payment ${(payload?.id) ? "updated" : "created"}`,
                    body: `Payment of ${formatMoney(payment.amount)} for ${payment.max_items} players in tournament "${tournament?.name}"`
                })));
            })
            .then(() => onClose());
    };
    formMethods.register("tournament");
    formMethods.register("groupPayer");

    return (
        <Modal show
               size="xl"
               backdrop="static"
               onHide={onClose}
               dialogClassName="w-75">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Team entry pro forma
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FinanceProFormaPayment payment={payment}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col className="text-end">
                                <Button className="me-1"
                                        variant="secondary"
                                        onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button type="submit">Save</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
                <DevTool control={formMethods.control}/>
            </FormProvider>
        </Modal>
    );
};

export default FinanceProFormaPaymentModal;
