import React from "react";
import {BsFilePerson} from "react-icons/bs";

import {
    showActiveTournamentPersonalDetails
} from "../features/activeTournaments/activeTournamentSlice";
import {Tournament, TournamentLite} from "../models/Tournament";
import {UserMinSchema} from "../models/User";
import {useAppDispatch} from "../store";

interface Props {
    tournament: Tournament | TournamentLite
    user: UserMinSchema
}

const ShowPlayerContactDetails: React.FC<Props> = ({tournament, user}) => {
    const dispatch = useAppDispatch();

    return (
        <BsFilePerson className="text-primary"
                      onClick={() => dispatch(showActiveTournamentPersonalDetails({tournament, user}))}/>
    );
};

export default ShowPlayerContactDetails;
