import React, {useContext, useEffect} from "react";
import {useDispatch} from "react-redux";

// 3rd party
import DataTable, {TableColumn} from "react-data-table-component";

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import {useSearchUserQuery} from "../../api/chessMasterApi";
import {selectConstantsGenderById} from "../constants/constantsSlice";
import {setEditUserId} from "./userTabSlice";
import {User} from "../../models/User";
import {Group} from "../../models/Group";
import UserEMailAddress from "../../components/UserEMailAddress";
import {useAppSelector} from "../../store";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {PlayerFilterErrorContext} from "../../components/PlayerFilterInput";

const UserName: React.FC<{user: User}> = ({user}) => {
    const dispatch = useDispatch();

    return (
        <>
            <Button variant="link"
                    className="p-0 text-start"
                    onClick={async () => {
                        dispatch(setEditUserId(user.id));
                    }}>
                {user.username}
            </Button>
            {(user.disabled) && <Badge pill bg="danger">Disabled</Badge>}
        </>
    );
};

interface UserListProps {
    query: string | null
    groupId: Group["id"]
}

export const UserList: React.FC<UserListProps> = ({query, groupId}) => {
    const dispatch = useDispatch();
    const {setUserSearchError} = useContext(PlayerFilterErrorContext);
    const {data: users, isFetching, error} = useSearchUserQuery(
        {query, ...(groupId >= 0) ? {group_id: groupId} : {}},
        {skip: (!query || query.length < 3) && groupId < 0}
    );
    const genderById = useAppSelector(selectConstantsGenderById);
    const columns: TableColumn<User>[] = [{
        selector: (row) => row.given_name ?? "",
        name: "Given name",
        sortable: true
    }, {
        selector: (row) => row.family_name ?? "",
        name: "Family name",
        sortable: true
    }, {
        selector: (row) => row.username,
        name: "Username",
        sortable: true,
        cell: (row) => <UserName user={row}/>,
        // XXX classes: "table-column-wrap-link"
    }, {
        selector: (row) => row.email_address ?? "",
        name: "E-mail",
        sortable: true,
        grow: 2,
        cell: (row) => <UserEMailAddress user={row}/>
        // XXX classes: "table-column-wrap"
        // XXX make e-mail clickable
    }, {
        selector: (row) => row.birthday ?? "",
        name: "Birthday",
        sortable: true
    }, {
        selector: (row) => row.gender_id,
        name: "Gender",
        sortable: true,
        format: (row) => genderById[row.gender_id]?.description ?? ""
    }];

    useEffect(
        () => setUserSearchError?.((error) ? formatErrorMessage(error) : null),
        [error, setUserSearchError]
    );

    return (
        <DataTable<User> data={users ?? []}
                         columns={columns}
                         keyField="id"
                         onRowClicked={(row) => dispatch(setEditUserId(row.id))}
                         progressPending={isFetching}
                         noDataComponent="(No users)"
                         pagination/>
    );
};
