import React from "react";

import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {Match} from "../../models/Match";
import {Tournament} from "../../models/Tournament";
import {useAppSelector} from "../../store";
import {selectTournamentTab} from "./tournamentTabSlice";
import ActiveTournamentRound from "../activeTournaments/ActiveTournamentRound";

interface Props {
    tournament: Tournament
    matches: Match[]
}

const ActiveTournamentRoundProjector: React.FC<Props> = ({tournament, matches}) => {
    const {projectorSettings: {columns, rows, carousel, offset}} = useAppSelector(selectTournamentTab);
    const nPages = Math.ceil(matches.length / (columns * rows));
    const renderRoundMatches = (offset: number) => (
        <Container fluid className="p-0">
            <Row>
                {new Array(columns).fill({}).map((_, column) => (
                    <Col key={column}>
                        <ActiveTournamentRound matches={matches.slice(offset + column * rows, offset + (column + 1) * rows)}
                                               tournament={tournament}
                                               projectorView/>
                    </Col>
                ))}
            </Row>
        </Container>
    );

    if (carousel) {
        return (
            <Carousel interval={carousel * 1000}>
                {Array.from(Array(nPages).keys()).map(page => {
                    const carouselPageOffset = page * columns * rows;

                    return (
                        <Carousel.Item key={page}>
                            {renderRoundMatches(carouselPageOffset)}
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        );
    } else {
        return renderRoundMatches(offset);
    }
};

export default ActiveTournamentRoundProjector;
