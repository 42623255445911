import React, {useCallback, useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-datepicker";
import {format, parseISO} from "date-fns";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
    selectConstants,
    selectConstantsGroupTypeByDescription
} from "../constants/constantsSlice";
import {formControlOptions} from "../../util/formControl";
import {ErrorMessage} from "@hookform/error-message";
import {selectLogin} from "../login/loginSlice";
import {DevTool} from "@hookform/devtools";
import UserEMailAddressConfirmedSymbol
    from "../../components/UserEMailAddressConfirmedSymbol";
import LinkLichessButton from "../../components/LinkLichessButton";
import {User} from "../../models/User";
import GroupPicker from "../../components/GroupPicker";
import {useGetGroupQuery} from "../../api/chessMasterApi";
import {UserForm} from "./UserEdit";
import {useAppSelector} from "../../store";
import {GroupMinSchema} from "../../models/Group";

const UserEditDetails: React.FC<{user: User | undefined}> = ({user}) => {
    const {register, control, setValue, watch, formState: {errors, dirtyFields}, setError, clearErrors} = useFormContext<UserForm>();
    const {genders} = useAppSelector(selectConstants);
    const {privs} = useAppSelector(selectLogin);
    const schoolGroupType = useAppSelector(selectConstantsGroupTypeByDescription)["School"];
    const [selectedSchool, setSelectedSchool] = useState<GroupMinSchema | undefined>(undefined);
    const {data: school} = useGetGroupQuery({id: user?.school_id ?? 0}, {skip: !user?.school_id});
    const email_address_confirmed: boolean = watch("user.email_address_confirmed");
    const setSchoolIdError = useCallback(
        (message: string | null) => (message) ? setError("user.school_id", {type: "invalid", message}) : clearErrors("user.school_id"),
        [clearErrors, setError]
    );

    register("user.email_address_confirmed");
    register("userGroups");
    useEffect(() => {(school) && setSelectedSchool(school)}, [school]);

    return (
        <Container fluid>
            <Form.Group as={Row} md={6} controlId="userEditUsername">
                <Form.Label column md={3}>
                    Username
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.username")}
                                  placeholder="Username"
                                  isInvalid={!!errors.user?.username}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.username"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            {(privs.admin) && (
                <Form.Group as={Row} md={6} controlId="userDisabled">
                    <Col md={{span:9, offset: 3}}>
                        <Form.Check>
                            <Form.Check.Input {...register("user.disabled")}
                                              isInvalid={!!errors.user?.disabled}/>
                            <Form.Check.Label>Disabled</Form.Check.Label>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="user.disabled"
                                          type="invalid"/>
                        </Form.Check>
                    </Col>
                </Form.Group>
            )}

            <Form.Group as={Row} md={6} controlId="userEditPassword" className="mb-1">
                <Form.Label column md={3}>
                    New password
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("password.new_password")}
                                  type="password"
                                  autoComplete="new-password"
                                  placeholder="Password"
                                  isInvalid={!!errors.password?.new_password}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="password.new_password"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditFirstName" className="mb-1">
                <Form.Label column md={3}>
                    Given name
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.given_name")}
                                  placeholder="Given name"
                                  isInvalid={!!errors.user?.given_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.given_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditPreferredName" className="mb-1">
                <Form.Label column md={3}>
                    Preferred name
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.preferred_name")}
                                  placeholder="Preferred name (if different from given name)"
                                  isInvalid={!!errors.user?.preferred_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.preferred_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditMiddleName" className="mb-1">
                <Form.Label column md={3}>
                    Middle name
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.middle_name")}
                                  placeholder="Middle name"
                                  isInvalid={!!errors.user?.middle_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.middle_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditLastName" className="mb-1">
                <Form.Label column md={3}>
                    Family name
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.family_name")}
                                  placeholder="Family name"
                                  isInvalid={!!errors.user?.family_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.family_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditEMail" className="mb-1">
                <Form.Label column md={3}>
                    E-mail address
                    {(email_address_confirmed && !dirtyFields.user?.email_address) && (
                        <UserEMailAddressConfirmedSymbol className="ms-1"/>
                    )}
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.email_address")}
                                  placeholder="user@chessideas.com.au"
                                  isInvalid={!!errors.user?.email_address}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.email_address"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditPhone" className="mb-1">
                <Form.Label column md={3}>
                    Phone number
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.phone_num")}
                                  placeholder="Please include area code"
                                  isInvalid={!!errors.user?.phone_num}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditPhoneNotes" className="mb-1">
                <Form.Label column md={3}>
                    Phone number notes
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.phone_num_note")}
                                  as="textarea"
                                  maxLength={2048}
                                  isInvalid={!!errors.user?.phone_num_note}
                                  placeholder="Phone number notes ..."/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num_note"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditMedicalNotes" className="mb-1">
                <Form.Label column md={3}>
                    Medical notes
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.medical_note")}
                                  as="textarea"
                                  maxLength={2048}
                                  isInvalid={!!errors.user?.medical_note}
                                  placeholder="Medical notes ..."/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.medical_note"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditGender" className="mb-1">
                <Form.Label column md={3}>
                    Gender
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.gender_id", {valueAsNumber: true})}
                                  as="select"
                                  isInvalid={!!errors.user?.gender_id}>
                        {formControlOptions(genders)}
                    </Form.Control>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.gender_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="userEditBirthday" className="mb-1">
                <Form.Label column md={3}>
                    Birthday
                </Form.Label>
                <Col md={9}>
                    <Controller
                        render={
                            ({field: {onChange, value, onBlur}}) => (
                                <DatePicker selected={(value) ? parseISO(value) : null}
                                            className="form-control"
                                            dateFormat="PP"     // long localised
                                            maxDate={new Date()}
                                            dropdownMode="select"
                                            shouldCloseOnSelect
                                            showYearDropdown
                                            wrapperClassName={(errors.user?.birthday) ? "is-invalid" : ""}
                                            onChange={(value: Date) => onChange((value) ? format(value, "yyyy-MM-dd") : null)}
                                            onBlur={onBlur}/>
                            )
                        }
                        control={control}
                        name="user.birthday"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.birthday"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1"
                        controlId="userEditSchool">
                <Form.Label column md={3}>
                    School
                </Form.Label>
                <Col md={9}>
                    <GroupPicker onChange={(school) => {
                                     setSelectedSchool(school);
                                     setValue("user.school_id", school?.id ?? null, {shouldDirty: true});
                                 }}
                                 selectedGroup={selectedSchool}
                                 placeholder="School ..."
                                 fixGroupTypeId={schoolGroupType.id}
                                 fieldError={errors.user?.school_id}
                                 setErrorMessage={setSchoolIdError}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.school_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1"
                        controlId="userEditSchoolYear">
                <Form.Label column md={3}>
                    School year
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.school_year")}
                                  type="number"
                                  min={0}
                                  isInvalid={!!errors.user?.school_year}
                                  placeholder="Empty or 0 if not a student"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.school_year"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            {(user) && (
                <Form.Group as={Row} className="mb-1"
                            controlId="userEditLichess">
                    <Form.Label column md={3}>
                        Online play
                    </Form.Label>
                    <Col md={9}>
                        <LinkLichessButton user={user}/>
                    </Col>
                </Form.Group>
            )}
            <Form.Group as={Row} className="mb-1"
                        controlId="userLichessPassword">
                <Form.Label column md={3}>
                    Lichess password
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("user.lichess_password")}
                                    placeholder="(Chess Ideas managed accounts only)"
                                    isInvalid={!!errors.user?.lichess_password}/>
                        <ErrorMessage as={<Form.Control.Feedback/>}
                                      name="user.lichess_password"
                                      type="invalid"/>
                </Col>
            </Form.Group>

            <DevTool control={control}/>
        </Container>
    );
};

export default UserEditDetails;