import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from "../../store";
import {Payment} from "../../models/Payment";

interface GroupEntryTabState {
    runEditProFormaPaymentModal: Payment | null
}

const initialState: GroupEntryTabState = {
    runEditProFormaPaymentModal: null,
};

export const slice = createSlice({
    name: 'groupEntryTab',
    initialState,
    reducers: {
        setRunEditProFormaPaymentModal: (state, action: PayloadAction<GroupEntryTabState["runEditProFormaPaymentModal"]>) => {
            state.runEditProFormaPaymentModal = action.payload;
        }
    }
});

export const selectGroupEntryTab = (state: RootState) => state.groupEntryTab;
export const {
    setRunEditProFormaPaymentModal
} = slice.actions;

export default slice.reducer;