import React from "react";
import UserEMailAddressConfirmedSymbol from "./UserEMailAddressConfirmedSymbol";

interface Props {
    user: {
        email_address: string | null
        email_address_confirmed: boolean
    };
}

const UserEMailAddress: React.FC<Props> = ({user}) => (
    <>
        {(user.email_address) ?
            <a href={`mailto:${user.email_address}`}>{user.email_address}</a>
            : <span className="text-muted">(No e-mail address)</span>}
        {(user.email_address_confirmed) && (
            <UserEMailAddressConfirmedSymbol className="ms-1"/>
        )}
    </>
);

export default UserEMailAddress;