import {UserInfo} from "./User";

export interface TournamentStaff {
    director: boolean
    user_id: UserInfo["id"]
}

export const newTournamentStaffFromUserInfo = (userInfo: UserInfo, isDirector=false): TournamentStaff => ({
    director: isDirector,
    user_id: userInfo.id
});

export interface TournamentStaffUpdate {
    user_id: TournamentStaff["user_id"]
    director?: boolean
    _delete?: boolean
}

export const newTournamentStaffUpdate = (user_id: TournamentStaff["user_id"], initFields: Partial<TournamentStaffUpdate> = {}): TournamentStaffUpdate => ({
    user_id,
    ...initFields
});