import React, {useCallback, useState} from "react";
import useLocalStorageState from "use-local-storage-state";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

import {chessMasterApi} from "../../api/chessMasterApi";
import {selectLogin} from "../login/loginSlice";
import FinanceSearch from "./FinanceSearch";
import FinancePaymentList from "./FinancePaymentList";
import FinanceUserPaymentModal from "./FinanceUserPaymentModal";
import FinanceReportModal from "./FinanceReportModal";
import {useAppDispatch, useAppSelector} from "../../store";
import {selectFinanceTab, setRunEditPaymentModal} from "./financeTabSlice";
import FinanceProFormaPaymentModal from "./FinanceProFormaPaymentModal";
import useRunModal from "../../hooks/useRunModal";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

const FinanceTab: React.FC = () => {
    const dispatch = useAppDispatch();
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const {privs} = useAppSelector(selectLogin);
    const {runEditPaymentModal} = useAppSelector(selectFinanceTab);
    const [query, setQuery] = useLocalStorageState("financeTab/query", {defaultValue: ""});
    const params = {query: {value: query, setter: setQuery, isEmpty: (v: string) => !v}};
    const [values, setters] = useLocalStorageSearchParams(params);
    const [reportModal, {run: runReportModal}] = useRunModal(FinanceReportModal);
    const [proFormaModal, {run: runProFormaModal}] = useRunModal(FinanceProFormaPaymentModal);
    const closeRunEditPaymentModal = useCallback(() => dispatch(setRunEditPaymentModal(null)), [dispatch]);
    const buttons = [
        ...(privs.finance_credit) ? [
            <Dropdown.Item variant="primary"
                    key="userPayment"
                    onClick={() => dispatch(setRunEditPaymentModal("new"))}>
                Manual payment ...
            </Dropdown.Item>] : [],
        ...(privs.finance_credit) ? [
            <Dropdown.Item variant="primary"
                    key="proFormaPayment"
                    // onClick={() => dispatch(setRunEditProFormaModal("new"))}>
                    onClick={() => runProFormaModal({payment: undefined})}>
                Team pro forma ...
            </Dropdown.Item>] : [],
        ...(privs.finance_view) ? [
            <Dropdown.Item variant="primary"
                    key="financeReport"
                    onClick={() => {
                        // force reload each time it's requested
                        dispatch(chessMasterApi.util.invalidateTags(["FinanceReport"]));
                        runReportModal({});
                    }}>
                Report ...
            </Dropdown.Item>] : []
    ];
    return (
        <>
            {reportModal}
            {proFormaModal}
            {(runEditPaymentModal) && (
                <FinanceUserPaymentModal payment={(runEditPaymentModal === "new") ? undefined : runEditPaymentModal}
                                         onClose={closeRunEditPaymentModal}/>
            )}
            <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                <Container fluid>
                    <Row className="mt-3 g-0">
                        <Col>
                            <FinanceSearch query={values.query}
                                           setQuery={setters.setQuery}/>
                        </Col>
                        <Col md="auto">
                            <DropdownButton as={ButtonGroup}
                                            variant="secondary"
                                            className="ms-2"
                                            onClick={(ev) => ev.stopPropagation()}
                                            title="Actions">
                                {buttons}
                            </DropdownButton>
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <FinancePaymentList query={values.query}/>
                    </Row>
                </Container>
            </SearchErrorMessageContext.Provider>
        </>
    );
};

export default FinanceTab;
