import React, {useCallback, useEffect, useState} from "react";
import useLocalStorageState from "use-local-storage-state";
import {useParams} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {chessMasterApi} from "../../api/chessMasterApi";
import {GroupList} from "./GroupList";
import {selectGroupsTab, editGroup} from "./groupsTabSlice";
import GroupEdit from "./GroupEdit";
import {Group, newGroup} from "../../models/Group";
import GroupFilterInput from "../activeTournaments/GroupFilterInput";
import {GroupType} from "../../models/GroupType";
import {selectLogin} from "../login/loginSlice";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import {useAppSelector, useAppDispatch} from "../../store";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

const GroupsTab = () => {
    const dispatch = useAppDispatch();
    const {privs} = useAppSelector(selectLogin);
    const [query, setQuery] = useLocalStorageState<Group["name"]>("groupTab/query", {defaultValue: ""});
    const [groupTypeId, setGroupTypeId] = useLocalStorageState("groupTab/groupTypeId", {defaultValue: "-1"});
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const [getGroupLazy] = chessMasterApi.endpoints.getGroup.useLazyQuery();
    const {group_id} = useParams();
    const params = {
        query: {value: query, setter: setQuery, isEmpty: (v: string) => !v},
        groupTypeId: {value: groupTypeId, setter: setGroupTypeId, isEmpty: (v: string) => v === "-1"}
    };
    const [values, setters] = useLocalStorageSearchParams(params);
    const closeGroupEdit = useCallback(() => dispatch(editGroup(null)), [dispatch]);
    const {groupEdit} = useAppSelector(selectGroupsTab);
    const convertSetGroupTypeId = useCallback(
        (groupTypeId: GroupType["id"]) => setters.setGroupTypeId(String(groupTypeId)),
        [setters]
    );

    useEffect(() => {
        if (group_id) {
            getGroupLazy({id: Number(group_id)})
                .unwrap()
                .then((group) => dispatch(editGroup(group)));
        }
    }, [dispatch, getGroupLazy, group_id]);

    return (
        <>
            {(groupEdit) ? <GroupEdit group={groupEdit} onClose={closeGroupEdit}/> : null}
            <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                <Container fluid>
                    <Row className="mt-3 g-0">
                        <Col>
                            <GroupFilterInput query={values.query}
                                              setQuery={setters.setQuery}
                                              groupTypeId={Number(values.groupTypeId)}
                                              setGroupTypeId={convertSetGroupTypeId}/>
                        </Col>
                        {(privs.admin) && (
                            <Col sm="auto" className="ms-3 float-end">
                                <Button onClick={() => dispatch(editGroup(newGroup()))}>
                                    New group
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row className="g-0">
                        <GroupList query={values.query}
                                   groupTypeId={Number(values.groupTypeId)}/>
                    </Row>
                </Container>
            </SearchErrorMessageContext.Provider>
        </>
    );
};

export default GroupsTab;