import React, {PropsWithChildren, useCallback} from "react";

import DataTable, {TableColumn} from "react-data-table-component";
import {useFormContext} from "react-hook-form";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {Match, newMatch} from "../../models/Match";
import UsernameAndGroups from "../../components/UsernameAndGroups";
import {UserInfo} from "../../models/User";
import {Tournament} from "../../models/Tournament";
import {RePairForm} from "./ActiveTournamentRePair";

const UNPAIRED_PLAYERS_ID = "unpairedPlayers";

const UnpairedPlayers: React.FC<{tournament: Tournament, unpairedPlayerIds: UserInfo["id"][]}> = ({tournament, unpairedPlayerIds}) => {
    const columns: TableColumn<UserInfo["id"]>[] = [{
        name: "Unpaired players",
        conditionalCellStyles: [{
            when: () => true,
            classNames: ["bg-light"]
        }],
        cell: (row, index) => (
            <DraggablePlayer player={tournament.ref_user[row]}
                             index={index}
                             unpaired/>
        )
    }];

    return (
        <Droppable droppableId={UNPAIRED_PLAYERS_ID}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                     className={(snapshot.isDraggingOver) ? "border border-dark" : ""}
                     {...provided.droppableProps}>
                        <DataTable<UserInfo["id"]> columns={columns}
                                                   data={unpairedPlayerIds}
                                                   noDataComponent={<small className="text-muted">
                                                        (drag unpaired players here)</small>}
                                                   />
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

const DraggablePlayer: React.FC<{player: UserInfo | null, index: number, unpaired?: boolean}> = ({player, index, unpaired}) => {
    return (
        <Draggable draggableId={`${(unpaired) ? "unpaired-" : ""}${index}`} index={index} key={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                      className={(snapshot.isDragging) ? "bg-light" : ""}>
                      {(player) ? <UsernameAndGroups userInfo={player}/> : <div className="empty">(empty)</div>}
                </div>
            )}
        </Draggable>
    );
};

const DroppablePlayer: React.FC<PropsWithChildren<{index: number}>> = ({index, children}) => {
    return (
        <Droppable droppableId={`${index}`} key={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}  {...provided.droppableProps}
                     className={(snapshot.isDraggingOver) ? "border border-dark" : ""}
                     {...provided.droppableProps}>
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

type UserId = UserInfo["id"] | null;

// note that when a match is re-paired its status is reset to "Pending"
export const replaceMatchPlayerWithPlayer = (match: Match, isBlack: number, playerId: UserId): Match => ({
    ...match,
    status: "Pending",
    ...(isBlack) ? {black_player_id: playerId} : {white_player_id: playerId}
});

export const matchesReplacingPlayerAtIndexWithPlayer = (matches: Match[], index: number, playerId: UserId) => {
    const matchIndex = Math.trunc(index / 2), 
          isBlack = index % 2;
    return matches.map((match, i) => (
        (i === matchIndex) ? replaceMatchPlayerWithPlayer(match, isBlack, playerId) : match)
    );
};

// note that the index refers to the table, ie, in the first match, index 0=white, 1=black;
// in match 2, index 2=white, 3=black; etc.

export const matchPlayerForIndex = (matches: Match[], index: number): UserId => {
    const matchIndex = Math.trunc(index / 2),
          isBlack = index % 2;

    if (matchIndex >= 0 && matchIndex < matches.length) {
        const match = matches[matchIndex];

        return (isBlack) ? match.black_player_id : match.white_player_id;
    }
    throw new Error(`ActiveTournamentRepairList.indexOfPlayerId: can't find index ${index}`);
};

const ActiveTournamentRePairList: React.FC<{tournament: Tournament}> = ({tournament}) => {
    const {setValue, watch} = useFormContext<RePairForm>();
    const matches: Match[] = watch("matches");
    const unpairedPlayerIds: UserInfo["id"][] = watch("unpairedPlayerIds");
    const addMatch = useCallback(
        () => {
            const board_num = Math.max(0, ...matches.map((match) => match.board_num)) + 1;
            const match = newMatch({
                tournament_id: tournament.id,
                board_num,
                status: "Pending",
                round_num: tournament.cur_round,
                id: undefined,
                white_player_id: null,
                black_player_id: null
            });

            setValue("matches", matches.concat(match));
        },
        [matches, setValue, tournament.cur_round, tournament.id]);
    const columns: TableColumn<Match>[] = [{
        name: "Board",
        selector: (row) => row.board_num,
        center: true,
        width: "4.2rem",
    }, {
        name: "White",
        conditionalCellStyles: [{
            when: (row) => !!row.white_player_id,
            classNames: ["bg-light"]
        }],
        cell: (row, rowIndex) => (
            <DroppablePlayer index={rowIndex * 2}>
                <DraggablePlayer player={(row.white_player_id) ? tournament.ref_user[row.white_player_id] : null}
                                 index={rowIndex * 2}/>
            </DroppablePlayer>
        )
    }, {
        name: "Status",
        selector: (row) => row.status || '',
        center: true,
        width: "6rem"
    }, {
        name: "Black",
        conditionalCellStyles: [{
            when: (row) => !!row.black_player_id,
            classNames: ["bg-light"]
        }],
        cell: (row, rowIndex) => (
            <DroppablePlayer index={rowIndex * 2 + 1}>
                <DraggablePlayer player={(row.black_player_id) ? tournament.ref_user[row.black_player_id] : null}
                                 index={rowIndex * 2 + 1}/>
            </DroppablePlayer>
        )
    }];
    const onDragEnd = (result: DropResult) => {
        if (!result.destination
        || (result.destination.droppableId === result.source.droppableId
        &&  result.destination.index === result.source.index)) {
            return;
        }
        if (result.source.droppableId === UNPAIRED_PLAYERS_ID && result.destination.droppableId === UNPAIRED_PLAYERS_ID) {
            // move entries in unpairedPlayers
            const players = unpairedPlayerIds.splice(result.source.index, 1);    // return an array of length 1

            unpairedPlayerIds.splice(result.destination.index, 0, players[0]);
            setValue("unpairedPlayerIds", unpairedPlayerIds);
        } else if (result.destination.droppableId === UNPAIRED_PLAYERS_ID) {
            // move from match to unpairedPlayers
            const playerId = matchPlayerForIndex(matches, result.source.index);
            if (playerId === null) {
                return;     // can't drop an empty player onto unpaired list
            }
            const updatedMatches = matchesReplacingPlayerAtIndexWithPlayer(matches, result.source.index, null);

            unpairedPlayerIds.push(playerId);
            setValue("matches", updatedMatches);
            setValue("unpairedPlayerIds", unpairedPlayerIds);
        } else if (result.source.droppableId === UNPAIRED_PLAYERS_ID) {
            // move from unpairedPlayers to match
            const unassignedUserId = unpairedPlayerIds[result.source.index];
            const playerId = matchPlayerForIndex(matches, result.destination.index);
            const updatedMatches = matchesReplacingPlayerAtIndexWithPlayer(matches, result.destination.index, unassignedUserId);
            if (playerId !== null) {
                unpairedPlayerIds.push(playerId);
            }
            setValue("matches", updatedMatches);
            unpairedPlayerIds.splice(result.source.index, 1);
            setValue("unpairedPlayerIds", unpairedPlayerIds);
        } else {
            // swap players within matches
            const srcPlayerId = matchPlayerForIndex(matches, result.source.index);
            const dstPlayerId = matchPlayerForIndex(matches, result.destination.index);
            if (!srcPlayerId) {
                return;     // dragging a bye, just return
            }
            let updatedMatches = matches;
            updatedMatches = matchesReplacingPlayerAtIndexWithPlayer(updatedMatches, result.source.index, dstPlayerId);
            updatedMatches = matchesReplacingPlayerAtIndexWithPlayer(updatedMatches, result.destination.index, srcPlayerId);
            setValue("matches", updatedMatches);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Container className="rePair">
                <Row>
                    <Col md={9} className="p-0">
                        <DataTable<Match> data={matches}
                                          columns={columns}
                                          keyField="id"
                                          className="bodyscroll"
                                          noDataComponent="(No players)"/>
                        <div >
                        <small className="text-muted">(Drag &amp; drop to re-pair)</small>

                            <Button className="float-end" variant="secondary" size="sm" onClick={addMatch}>
                                Add match
                            </Button>
                        </div>
                    </Col>
                    <Col md={3} className="p-0 unPaired">
                        <UnpairedPlayers tournament={tournament}
                                        unpairedPlayerIds={unpairedPlayerIds}/>
                    </Col>
                </Row>
            </Container>
        </DragDropContext>
    );
};

export default ActiveTournamentRePairList;
