import React, {useContext} from "react";
import {useDebouncedCallback} from "use-debounce";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {BsInfoCircle} from "react-icons/bs";

import {selectConstants} from "../constants/constantsSlice";
import {formControlOptions} from "../../util/formControl";
import {GroupType} from "../../models/GroupType";
import {useAppSelector} from "../../store";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {groupSearchHelpPopover} from "../../util/SearchHelpPopover";

interface Props {
query: string
setQuery: (query: string) => void
groupTypeId: GroupType["id"]
setGroupTypeId: (groupTypeId: GroupType["id"]) => void
}

export const GroupFilter: React.FC<Props> = ({query, setQuery, groupTypeId, setGroupTypeId}) => {
    const {group_types} = useAppSelector(selectConstants);
    const {searchErrorMessage} = useContext(SearchErrorMessageContext);
    const setDebouncedQuery = useDebouncedCallback(setQuery, 300);

    return (
        <div className="mb-3">
            <InputGroup className={(searchErrorMessage) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              placeholder="Search groups ..."
                              defaultValue={query}
                              onChange={(ev) => setDebouncedQuery(ev.target.value)}
                              isInvalid={!!searchErrorMessage}
                              aria-label="Search groups"
                              aria-describedby="group-filter-search-field"/>

                <InputGroup.Text>
                    <Form.Control as="select"
                                  value={groupTypeId}
                                  onChange={(ev) => {
                                      setGroupTypeId(Number(ev.target.value));
                                  }}
                                  aria-label="Group type filter"
                                  aria-describedby="group-type-filter-field">
                        <option value={-1}>(All)</option>
                        {formControlOptions(group_types)}
                    </Form.Control>
                </InputGroup.Text>
                <OverlayTrigger trigger="click"
                                placement="bottom"
                                overlay={groupSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {searchErrorMessage}
            </Form.Control.Feedback>
        </div>
    );
};
