import React, {useContext, useState} from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {
    useRoundEndTournamentMutation,
    useUnendRoundTournamentMutation
} from "../../api/chessMasterApi";
import {useAppDispatch, useAppSelector} from "../../store";
import {ActiveTournamentContext} from "./ActiveTournament";
import {selectLogin} from "../login/loginSlice";
import {isMatchStatusComplete, Match} from "../../models/Match";
import {Privileges} from "../../models/Privileges";
import {
    isPlayMethodOnline,
    isTournamentComplete,
    isTournamentPlaying,
    isTournamentStarted,
    Tournament
} from "../../models/Tournament";
import {TournamentRound} from "../../models/TournamentRound";
import {
    setActiveTournamentRePairMatches,
    setActiveTournamentTeamBarring
} from "./activeTournamentSlice";
import ActiveTournamentRound from "./ActiveTournamentRound";
import {UserInfo} from "../../models/User";
import MatchStatusFilter, {MatchStatusFilterOptions} from "./MatchStatusFilter";
import PollLichessButton from "./PollLichessButton";
import UpdateTournamentButton from "./UpdateTournamentButton";
import {addToast, newToastSuccess} from "../toast/toastSlice";

const teamBarringStatus = (round: TournamentRound) => {
    if (round.manual_pairing) {
        return "(Paired manually)"
    }
    return (
        (round.team_barring) ? "(No team pairing)" : "(Team pairing allowed)"
    );
};

const filterMatchesByStatus = (status: MatchStatusFilterOptions, matches: Match[]) => {
    switch (status) {
        case "all":
            return matches;
        case "pending":
            return matches.filter((match) => match.status === "Pending");
        case "playing":
            return matches.filter((match) => match.status === "Playing");
        case "completed":
            return matches.filter((match) => isMatchStatusComplete(match));
    }
};

interface TournamentRoundCardProps {
    isCurrentRound: boolean
    round: TournamentRound
    scores: Record<UserInfo["id"], number>
}

const ActiveTournamentRoundCard: React.FC<TournamentRoundCardProps> = ({isCurrentRound, round, scores}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const [statusFilter, setStatusFilter] = useState<MatchStatusFilterOptions>("all");
    const [roundEndTournament] = useRoundEndTournamentMutation();
    const [unendRoundTournament] = useUnendRoundTournamentMutation();
    const dispatch = useAppDispatch();
    const {privs} = useAppSelector(selectLogin);
    // ie, is the round ended so we can enable pairing and ranking for current round
    const isRoundEnded = (tournament: Tournament, matches: Match[]) => {
        switch (tournament.status) {
        case "Pending":
        case "Abandoned":
        case "Completed":
            return false;
        case "Playing":
            return matches.every((match) => isMatchStatusComplete(match))
        }
    };
    const roundEnd = (tournament: Tournament) => {
        roundEndTournament({key: tournament.key})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Round ended",
                    body: `Round ${tournament.cur_round} ended in ${tournament.name}`
                })))
            })
    };
    const roundUnend = (tournament: Tournament) => {
        unendRoundTournament({key: tournament.key})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Round un-ended",
                    body: `Round ${tournament.cur_round} un-ended in ${tournament.name}`
                })))
            })
    };
    const footer = (privs: Privileges, isCurrentRound: boolean) => {
        const rePairButton = () => (tournament.controls?.director) ? [
            <Button onClick={() => dispatch(setActiveTournamentRePairMatches(round))}
                    disabled={!isTournamentPlaying(tournament)}
                    key="repair">
                Re-pair ...
            </Button>
        ] : [];
        const teamBarringButton = () => (tournament.controls?.director) ? [
            <Button onClick={() => dispatch(setActiveTournamentTeamBarring(tournament))}
                    disabled={isTournamentComplete(tournament)}
                    key="barring">
                Team barring ...
            </Button>
        ] : [];
        const roundEndButton = () => (tournament.controls?.director) ? [
            <Button onClick={() => roundEnd(tournament)}
                    disabled={!isRoundEnded(tournament, round.matches)}
                    key="round-end">
                Round end
            </Button>
        ] : [];
        const unRoundEndButton = () => (tournament.controls?.director && isTournamentStarted(tournament)) ? [
            <Button onClick={() => roundUnend(tournament)}
                    disabled={isTournamentComplete(tournament)}
                    key="un-round-end">
                Round un-end
            </Button>
        ] : [];
        const buttons = [
            ...rePairButton(),
            ...teamBarringButton(),
            ...roundEndButton(),
            ...unRoundEndButton()
        ];

        if (isCurrentRound && buttons.length > 0) {
            return (
                <Card.Footer>
                    <ButtonToolbar className="float-end">
                        <ButtonGroup>
                            {buttons}
                        </ButtonGroup>
                        {(isPlayMethodOnline(tournament.play_method) &&
                            tournament.controls?.staff && isTournamentStarted(tournament) && tournament.cur_round !== undefined) && (
                                <ButtonGroup className="ms-1">
                                    <PollLichessButton tournament={tournament}/>
                                </ButtonGroup>
                            )}
                    </ButtonToolbar>
                </Card.Footer>
            );
        }
        return null;
    };
    const footerContent = footer(privs, isCurrentRound);


    return (
        <Card className="mt-3" border={(isCurrentRound) ? "dark" : ""}>
            <Card.Header>
                <MatchStatusFilter matches={round.matches}
                                   statusFilter={statusFilter}
                                   setStatusFilter={setStatusFilter}/>
                {(isCurrentRound) && (
                    <UpdateTournamentButton size="sm" className="ms-1"/>
                )}
                <span className={(isCurrentRound) ? "fw-bold" : ""}>
                    <span className="float-end small">
                        {teamBarringStatus(round)}
                    </span>
                </span>
            </Card.Header>
            <Card.Body className="p-0 ovfix">
                <ActiveTournamentRound tournament={tournament}
                                       matches={filterMatchesByStatus(statusFilter, round.matches)}
                                       scores={scores}
                                       showResultSelector={isCurrentRound}/>
            </Card.Body>
            {footerContent}
        </Card>
    );
};

export default ActiveTournamentRoundCard;