import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {yupResolver} from "@hookform/resolvers/yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";

import {addToast, newToastMessage} from "../toast/toastSlice";
import {
    chessMasterApi,
    useGetUserQuery,
    useGroupsForUserQuery,
    useUpdateUserMutation
} from "../../api/chessMasterApi";
import {User} from "../../models/User";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import UserForm, {
    UserRegistration,
    userRegistrationSchema,
} from "../../components/UserForm";
import {formatFullName} from "../../util/formatFullName";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";

interface Props {
    childId: User["id"]
    onClose: () => void
}

const ChildEditForm: React.FC<Props> = ({childId, onClose}) => {
    const dispatch = useDispatch();
    const {data: child} = useGetUserQuery(childId);
    const {data: userGroups} = useGroupsForUserQuery({userId: childId}, {skip: childId < 0});
    const [updateUser] = useUpdateUserMutation();
    const formMethods = useForm<UserRegistration>({
        defaultValues: {user: child, userGroups: []},
        resolver: yupResolver(userRegistrationSchema({}))
    });

    // force reload of child before editing
    useEffect(
        () => {
            dispatch(chessMasterApi.util.invalidateTags([{type: "User", id: childId}]))
        },
        [childId, dispatch]
    );
    // push updated values from child into form
    useEffect(() => {
            formMethods.reset({user: child, userGroups});
        },
        [child, formMethods, userGroups]
    );

    const onSubmit = ({user: childRegistration, userGroups}: UserRegistration) => {
        // note this is not used for creating new users so don't send new_password
        updateUser({user: payloadChangesWithId(child!, childRegistration)})
            .unwrap()
            .then((newChild) => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: "Details updated",
                    body: `Updated ${newChild.username}`
                })));
                onClose();
            })
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch,"user");
            });
    };

    return (
        <Modal show={true}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop="static">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Edit details &mdash; {(child) ? formatFullName(child) : ""}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(child) ? (
                            <UserForm user={{user: child}} isCreating={false}/>
                        ) : (
                            <Spinner className="text-center" animation="border"/>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar className="float-right">
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={onClose}>Cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        disabled={!formMethods.formState.isDirty}
                                        variant="primary">Submit</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default ChildEditForm;
