import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {skipToken} from "@reduxjs/toolkit/query";
import {FormProvider, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {yupResolver} from "@hookform/resolvers/yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/esm/Alert";

import {addToast, newToastSuccess} from "../toast/toastSlice";
import {
    useGroupsForUserQuery,
    useUpdateGroupsForUserMutation,
    useUpdateUserMutation
} from "../../api/chessMasterApi";
import {isUser, NewUserWithRegistration} from "../../models/User";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import UserForm, {
    UserRegistration,
    userRegistrationSchema
} from "../../components/UserForm";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";
import {newPasswordForm, passwordSchema} from "../../util/passwordSchema";
import {createUserGroupUpdates} from "../../util/createUserGroupUpdates";

interface Props {
    child: NewUserWithRegistration
    onClose: () => void
}

const ChildRegistrationForm: React.FC<Props> = ({child, onClose}) => {
    const dispatch = useDispatch();
    const {data: userGroups} = useGroupsForUserQuery((isUser(child)) ? {userId: child.id} : skipToken);
    const [updateUser] = useUpdateUserMutation();
    const [updateGroupsForUser] = useUpdateGroupsForUserMutation();
    const formMethods = useForm<UserRegistration>({
        defaultValues: {...child, userGroups: [], password: newPasswordForm()},
        resolver: yupResolver(userRegistrationSchema({password: passwordSchema}))
    });
    const onSubmit = ({user, userGroups: updatedUserGroups, password, guardian_id}: UserRegistration) => {
        const childRegistration = {
            ...user,
            new_password: password.new_password
        };
        console.log("ChildRegistrationForm: user ", childRegistration);
        updateUser({user: (isUser(child)) ? payloadChangesWithId(child, childRegistration) : childRegistration, guardian_id})
            .unwrap()
            .then((newChild) => {
                dispatch(addToast(newToastSuccess({
                    heading: "Child registered",
                    body: `You have new child ${newChild.username}`
                })));
                return newChild;
            })
            .then((newChild) => {
                const groupUpdates = createUserGroupUpdates(userGroups ?? [], updatedUserGroups ?? []);
                if (groupUpdates.length > 0) {
                    updateGroupsForUser({userId: newChild.id, groupUpdates})
                        .then(() => {
                            dispatch(addToast(newToastSuccess({
                                heading: "Add groups",
                                body: `User "${newChild.username}" added to ${updatedUserGroups.length} groups`
                            })));
                        });
                }
            })
            .then(onClose)
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch, "user");
            });
    };
    useEffect(() => {
            formMethods.reset({...formMethods.getValues(), ...child, userGroups});
        },
    [child, formMethods, userGroups]
    );

    return (
        <Modal show
               centered
               size="lg"
               backdrop="static">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Child registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="info">
                            Create an account for a child for which you will be the parent.
                        </Alert>
                        <UserForm user={child} isCreating/>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar className="float-right">
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={onClose}>Cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary">Submit</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default ChildRegistrationForm;
