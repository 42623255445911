import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";

import {
    isTournament,
    NewTournament,
    Tournament,
    TournamentLite
} from '../../models/Tournament';
import {TournamentPlayersSortOrder} from "../../models/TournamentPlayer";
// import {TournamentStatusDescription} from "../../models/TournamentStatus";
// import {TournamentType} from "../../models/TournamentType";
import {chessMasterApi, SearchTournamentRequest} from "../../api/chessMasterApi";
import {ProjectorSettings} from "../../models/ProjectorSettings";

interface TournamentTabState {
    tournamentEdit: NewTournament | Tournament | null,
    tournamentRoll: Tournament | TournamentLite | null
    tournamentAbandon: Tournament | TournamentLite | null
    tournamentDelete: Tournament | TournamentLite | null
    tournamentProjectorView: Tournament | TournamentLite | null
    tournamentPlayerContactView: Tournament | TournamentLite | null
    tournamentPlayersSortOrder: TournamentPlayersSortOrder
    searchRequest: SearchTournamentRequest
    projectorSettings: ProjectorSettings
}

const initialState: TournamentTabState = {
    tournamentEdit: null,
    tournamentRoll: null,
    tournamentAbandon: null,
    tournamentDelete: null,
    tournamentProjectorView: null,
    tournamentPlayerContactView: null,
    tournamentPlayersSortOrder: "name",
    searchRequest: {},
    projectorSettings: {
        rows: 10,
        columns: 2,
        offset: 0,
        fontSize: 12,
        carousel: 0
    }
};

export const slice = createSlice({
    name: 'tournamentTab',
    initialState,
    reducers: {
        editTournament: (state, action: PayloadAction<Tournament | NewTournament | null>) => {
            state.tournamentEdit = action.payload;
        },
        rollForTournament: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentRoll = action.payload;
        },
        setTournamentPlayersSortOrder: (state, action: PayloadAction<{tournament: Tournament, sortOrder: TournamentPlayersSortOrder}>) => {
            state.tournamentPlayersSortOrder = action.payload.sortOrder;
        },
        abandonTournament: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentAbandon = action.payload;
        },
        deleteTournament: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentDelete = action.payload;
        },
        projectorViewTournament: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentProjectorView = action.payload;
        },
        contactDetailsViewTournament: (state, action: PayloadAction<Tournament | TournamentLite | null>) => {
            state.tournamentPlayerContactView = action.payload;
        },
        setTournamentProjectorSettings: (state, action: PayloadAction<ProjectorSettings>) => {
            state.projectorSettings = {...action.payload};  // make sure we change the state to trigger render
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(chessMasterApi.endpoints.updateTournament.matchFulfilled, (state, action) => {
            if (isTournament(state.tournamentEdit)) {
                state.tournamentEdit = null;            // dismiss modal
            } else {
                state.tournamentEdit = action.payload;  // this is a new tournament so let the user edit it
            }
        });
        builder.addMatcher(chessMasterApi.endpoints.cloneTournament.matchFulfilled, (state, action) => {
            state.tournamentEdit = action.payload;
        });
    }
});

export const selectTournamentTab = (state: RootState) => state.tournamentTab;
export const {
    editTournament,
    abandonTournament, deleteTournament,
    projectorViewTournament,
    contactDetailsViewTournament,
    rollForTournament,
    setTournamentPlayersSortOrder,
    setTournamentProjectorSettings
} = slice.actions;

export default slice.reducer;