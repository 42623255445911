import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

import UsernameAndGroups from "./UsernameAndGroups";
import {TournamentPlayer} from "../models/TournamentPlayer";
import {Tournament} from "../models/Tournament";

const ListPlayers: React.FC<{tournament: Tournament, players: TournamentPlayer[]}> = ({tournament, players}) => (
    <ListGroup>
        {(players.length === 0) ?
            <span className="text-muted">(No players)</span>
            : (
                players.map((player, index) => (
                    <ListGroup.Item key={index}>
                        <UsernameAndGroups userInfo={tournament.ref_user[player.user_id]}
                                           isPaid={player.paid}/>
                    </ListGroup.Item>
            ))
        )}
    </ListGroup>
);

export default ListPlayers;