import React, {useEffect, useRef} from "react";

import {FormCheckProps} from "react-bootstrap";

const IndeterminateCheckbox = (props: {indeterminate: boolean, className?: string} & FormCheckProps) => {
    const {indeterminate, className = "", ...rest} = props;
    const checkRef = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        checkRef.current.indeterminate = !rest.checked && indeterminate
    }, [checkRef, indeterminate, rest.checked]);

    return (
        <input type="checkbox"
               ref={checkRef}
               className={className + " cursor-pointer"}
               {...rest}/>
    )
};

export default IndeterminateCheckbox;