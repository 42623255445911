import {ServerUpdateRejection} from "../Api";
import {Path, UseFormReturn} from "react-hook-form";

import {AppDispatch} from "../store";
import {addToast, newToastError} from "../features/toast/toastSlice";

/*
 * When we get an error response to an update (ie, POST) request from the
 * server, it includes a list of fields that have errors in payload.errors.json.
 * Each field has on object with a field name and the error text. We go
 * through these and use setError from the form to display the errors next
 * to the named field.
 *
 * If a section name is passed, then use that as a prefix for the field error so
 * that we can report errors in sections of the form. The server sends back errors with
 * just the field name but a lot of forms have sections, eg, the server will send back an
 * error on field "gender_id" but the form knows this as "user.gender_id".
 *
 * If there is an error for a field that was not in the form, we display this as an
 * error toast so that we at least try to display all errors.
 *
 * This isn't a perfect solution but it covers most of the cases we have at the moment.
 */

const createFieldNameMap = <T extends {}>(formMethods: UseFormReturn<T>, section?: Path<T>) => {
    const map: Record<string, string> = {};

    if (section) {
        const fieldNames = formMethods.getValues(section);

        Object.keys(fieldNames).forEach((fieldName) => {
            const sectionFieldName = `${section}.${fieldName}`;

            map[sectionFieldName] = sectionFieldName;
            map[fieldName] = sectionFieldName;
        });
    } else {
        const fieldNames = formMethods.getValues();

        Object.keys(fieldNames).forEach((fieldName) => {
            map[fieldName] = fieldName;
        });
    }
    return map;
};

export const displayServerErrorsInForm = <T extends {}>(payload: ServerUpdateRejection, formMethods: UseFormReturn<T>, dispatch: AppDispatch, section?: Path<T>): void => {
    const fieldNameMap = createFieldNameMap(formMethods, section);

    if (payload?.errors?.json) {
        const {errors: {json: fieldErrors}} = payload;

        Object.keys(fieldErrors).forEach((field) => {
            const fieldName = fieldNameMap[field];
            const message = fieldErrors[field].join('. ');

            if (fieldName) {
                formMethods.setError(fieldName as Path<T>, {
                        type: "server",
                        message
                    },
                    {shouldFocus: true}
                );
            } else {
                dispatch(addToast(newToastError({
                    heading: "Server error",
                    body: message
                })));
            }
        });
    }
};