import React, {useState} from "react";

import {BsX} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import PlayerFilterInput, {PlayerFilterErrorContext} from "../PlayerFilterInput";
import {Group} from "../../models/Group";
import {UserInfo} from "../../models/User";
import {SingleUserPickerList} from "./SingleUserPickerList";

interface Props {
    className?: string
    setSelected?: (user: UserInfo) => void
    onClose?: () => void
}

const SingleUserPicker: React.FC<Props> = ({className, setSelected, onClose}) => {
    const [query, setQuery] = useState<Group["name"]>("");
    const [groupId, setGroupId] = useState<Group["id"]>(-1);
    const [userSearchError, setUserSearchError] = useState<string | null>(null);
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);

    return (
        <PlayerFilterErrorContext.Provider value={{
            userSearchError, setUserSearchError,
            groupSearchError, setGroupSearchError
        }}>
            <Card className={className}>
                <Card.Header>
                    <Card.Title>
                        User
                        {(onClose) && (
                            <Button variant="outline-secondary"
                                    size="sm"
                                    className="float-end"
                                    onClick={onClose}>
                                <BsX/>
                            </Button>
                        )}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <PlayerFilterInput query={query} setQuery={setQuery} groupId={groupId}
                                       setGroupId={setGroupId}/>
                </Card.Body>
                <Card.Body>
                    <SingleUserPickerList query={query} groupId={groupId}
                                          setSelected={setSelected}/>
                </Card.Body>
            </Card>
        </PlayerFilterErrorContext.Provider>
    );
};

export default SingleUserPicker;
