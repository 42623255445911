
// split a list of object so that those with an id in includedIds are in an included list,
// the rest in excluded.

export const partitionByIds = <T extends {id: number}>(objectsWithId: T[], includedIds: number[]): [T[], T[]] => {
    const included: T[] = [], excluded: T[] = [];

    objectsWithId.forEach((obj) => (includedIds.includes(obj.id) ? included : excluded).push(obj));

    return [included, excluded];
};