import React, {useContext, useEffect, useMemo, useState} from "react";

// 3rd party
import DataTable, {TableColumn} from "react-data-table-component";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

import {useSearchAuditQuery} from "../../api/chessMasterApi";
import {Audit, AuditRow} from "../../models/Audit";
import AuditTargetDisplay from "./AuditTargetDisplay";
import AuditAuthUserDisplay from "./AuditAuthUserDisplay";
import {QueryStatus} from "@reduxjs/toolkit/query";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

export interface AuditRowWithAudit extends AuditRow {
    audit: Audit
}

const AuditList: React.FC<{query: string}> = ({query}) => {
    const [currentPage] = useState<number>(1);
    const [sizePerPage] = useState<number>(10);
    const {setSearchErrorMessage} = useContext(SearchErrorMessageContext);

    const {data: audit, error, isFetching, status} = useSearchAuditQuery({query, offset: (currentPage - 1) * sizePerPage, limit: sizePerPage * 100});
    const auditRowsWithAudit: AuditRowWithAudit[] = useMemo(
        () => (audit) ? audit.rows.map((row) => ({...row, audit})) : [],
        [audit]
    );
    const columns: TableColumn<AuditRowWithAudit>[] = [{
        selector: (row) => row.timestamp,
        name: "Timestamp",
        wrap: true,
        // XXX classes: "name-nowrap",
        format: (row) => format(utcToZonedTime(row.timestamp, tzName), "PP HH:mm:ss")
    }, {
        name: "Authorising user",
        wrap: true,
        cell: (row) => <AuditAuthUserDisplay auditRow={row}/>
    }, {
        name: 'Target(s)',
        grow: 3,
        // XXX classes: 'auditTargets',
        cell: (row) => <AuditTargetDisplay auditRow={row}/>
    }, {
        selector: (row) => row.description,
        name: "Description",
        grow: 3,
        wrap: true
        // XXX classes: "table-column-wrap"
    }];
    const formatStatus = (status: QueryStatus) => {
        switch (status) {
        case "pending":
            return <span className="text-muted">(Loading ...)</span>;
        case "uninitialized":
        case "fulfilled":
            return <span className="text-muted">(No audit records)</span>;
        }
    };

    useEffect(
        () => setSearchErrorMessage?.((error) ? formatErrorMessage(error) : null),
        [error, setSearchErrorMessage]
    );

    return (
        <DataTable<AuditRowWithAudit> data={auditRowsWithAudit}
                                      columns={columns}
                                      keyField="id"
                                      noDataComponent={formatStatus(status)}
                                      progressPending={isFetching}
                                      pagination/>
    );
};

export default AuditList;
