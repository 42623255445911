import React from "react";
import {FieldErrors, FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {DevTool} from "@hookform/devtools";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import {isTournament, NewTournament, Tournament} from "../../models/Tournament";
import TournamentDetails from "./TournamentDetails";
import {useAppDispatch} from "../../store";
import {useUpdateTournamentMutation} from "../../api/chessMasterApi";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {addActiveTournament} from "../activeTab/activeTabSlice";
import {tournamentTabEventKeyFromTournament} from "../../util/tabEventKey";
import {useNavigate} from "react-router-dom";

const lichessDurationError = "Online duration 15s increments between and 0s and 90s, whole minutes up to 180m";
const lichessDurations = [0, 15, 30, 45, 60, 90, ...[...Array(179).keys()].map((m) => (m + 1) * 60)];

export const tournamentFieldsSchema = yup.object().shape({
    name: yup.string().required().label('Name'),
    description: yup.string().nullable(),
    is_rated: yup.boolean(),
    entry_fee: yup.number().typeError("Please set an entry fee").min(0).label("Entry fee"),
    start_time: yup.string().nullable().label("Start time"),
    num_rounds: yup.number().min(1).label("Rounds"),
    max_players: yup.number().typeError("Minimum players is 2").min(2).label("Maximum players"),
    venue_id: yup.number().nullable().label("Venue"),
    venue_info: yup.string().nullable().label("Venue info"),
    clock_increment: yup.number().typeError("Please set clock increment").min(0).label("Clock increment"),
    duration: yup.number()
        .typeError("Please set a duration")
        .min(1)
        .when("play_method", {
            is: "LichessOnline",
            then: (schema) => schema.oneOf(lichessDurations, lichessDurationError)
        })
        .label("Duration"),
    tournament_type_id: yup.number().min(1, "You must specify tournament type"),
    staff_ids: yup.array().of(yup.number()).min(1, "You must have some staff"),
    player_ids: yup.array().of(yup.number())     //.required()
});

const tournamentSchema = yup.object().shape({
    tournament: tournamentFieldsSchema
});

const TournamentDetailsCard: React.FC = () => (
    <Card>
        <Card.Header>
            Details
        </Card.Header>
        <Card.Body>
            <TournamentDetails/>
        </Card.Body>
    </Card>
);

export interface TournamentForm {
    tournament: Tournament | NewTournament;
}

interface TournamentEditProps {
    tournament: Tournament | NewTournament;
    onClose: () => void;
}

const TournamentEdit: React.FC<TournamentEditProps> = ({onClose, ...props}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [updateTournament, {isLoading: isUpdating}] = useUpdateTournamentMutation();
    const formMethods = useForm<TournamentForm>({
        defaultValues: {
            tournament: props.tournament,
        },
        reValidateMode: "onChange",
        resolver: yupResolver(tournamentSchema)
    });
    const onSubmit = ({tournament}: TournamentForm) => {
        // if we're modifying a tournament, just send updated fields; for a new tournament, send them all
        const tournamentPayload = (isTournament(props.tournament)) ? payloadChangesWithId(props.tournament, tournament) : tournament;

        updateTournament({tournament: tournamentPayload})
            .unwrap()
            .then((tournament) => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: `Tournament updated`,
                    body: `Update “${tournament.name}” succeeded`
                })));
                return tournament;
            })
            .then((tournament) => {
                // if we've just created a new tournament then open it in a new tab
                if (!isTournament(props.tournament)) {
                    dispatch(addActiveTournament(tournament));
                    navigate(`/${tournamentTabEventKeyFromTournament(tournament.key)}`);
                }
            })
            .then(onClose)
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch, "tournament");
            });
    };
    const formatErrors = (errors: FieldErrors<TournamentForm>) => {
        return (
            <ul>
                {Object.entries(errors).map(([key, value], i) => (
                    <li key={i}><>{key}: {value?.message}</></li>
                ))}
            </ul>
        );
    };

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               keyboard={!formMethods.formState.isDirty}
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Header>
                        <Modal.Title>Edit tournament</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TournamentDetailsCard/>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar>
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={onClose}
                                        disabled={isUpdating}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary"
                                        disabled={isUpdating || Object.keys(formMethods.formState.errors).length > 0}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                    {(Object.keys(formMethods.formState.errors).length > 0) && (
                        <Modal.Footer>
                            {formatErrors(formMethods.formState.errors)}
                        </Modal.Footer>
                    )}
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );
};
export default TournamentEdit;