import Popover from "react-bootstrap/Popover";
import React from "react";

const logicalOperators = (
    <>
        You can also use logical operators:
        <ul>
            <li><code>&amp;</code> – and</li>
            <li><code>|</code> – or</li>
            <li><code>!</code> – not</li>
        </ul>
    </>
);

const groupingSyntax = (
    <>
        You can group text using double quotes, eg: <code>name:"Buddy Love"</code>
    </>
);

export const userSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>User search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>child:</code> – children of ...</li>
                <li><code>disabled:</code> – true/false</li>
                <li><code>email:</code> – e-mail address</li>
                <li><code>gender:</code> – genders</li>
                <li><code>group:</code> – group or school</li>
                <li><code>guardian:</code> – guardian of ...</li>
                <li><code>lichess:</code> – lichess username</li>
                <li><code>phone:</code> – number and notes</li>
                <li><code>role:</code> – role name</li>
                <li><code>team:</code> – team players</li>
                <li><code>tournament:</code> – players</li>
                <li><code>user:</code> – username</li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);

export const auditSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>Audit search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>user:</code> – relating to user</li>
                <li><code>tournament:</code> – relating to tournament</li>
                <li><code>group:</code> – relating to group</li>
                <li><code>role:</code> – relating to role</li>
                <li><code>auth:</code> – authorised by</li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);

// XXX I am not sure this is a complete list
export const financeSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>Finance search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>before:"yyyy-mm-dd"</code></li>
                <li><code>after:"yyyy-mm-dd"</code></li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);

export const tournamentSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>Tournament search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>after:</code> – start time after date (yyyy-mm-dd)</li>
                <li><code>before:</code> – start time before date (yyyy-mm-dd)</li>
                <li><code>description:</code> – matches description</li>
                <li><code>expired:</code> – true/false</li>
                <li><code>group:</code> – group name</li>
                <li><code>method:</code> – play method: LichessOnline/OverTheBoard</li>
                <li><code>name:</code> – tournament name</li>
                <li><code>player:</code> – includes player name</li>
                <li><code>rated:</code> – true/false</li>
                <li><code>staff:</code> – includes staff member</li>
                <li><code>status:</code> – pending/playing/completed/abandoned</li>
                <li><code>team:</code> – includes team name</li>
                <li><code>type:</code> – traditional/rapid/blitz/bullet</li>
                <li><code>venue:</code> – matches venue</li>
                <li><code>visibility:</code> – private/staff/players/community</li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);

export const groupSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>Group search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>address:</code> – address or postal address</li>
                <li><code>description:</code> – description</li>
                <li><code>name:</code> – group name</li>
                <li><code>phone:</code> – phone number</li>
                <li><code>type:</code> – school/club/company</li>
                <li><code>url:</code> – web address</li>
                <li><code>user:</code> – user in group</li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);

export const playerSearchHelpPopover = (
    <Popover id="search-help-popover">
        <Popover.Header>Player search tips</Popover.Header>
        <Popover.Body>
            Searching can use some special terms:
            <ul>
                <li><code>child:</code> – children of ...</li>
                <li><code>email:</code> – e-mail address</li>
                <li><code>gender:</code> – genders</li>
                <li><code>group:</code> – group or school</li>
                <li><code>guardian:</code> – guardian of ...</li>
                <li><code>lichess:</code> – lichess username</li>
                <li><code>paid:</code> – true/false</li>
                <li><code>participating:</code> – true/false</li>
                <li><code>team:</code> – tournament team</li>
                <li><code>user:</code> – username</li>
            </ul>
            {logicalOperators}
            {groupingSyntax}
        </Popover.Body>
    </Popover>
);
