import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {
    OnApproveActions,
    OnApproveData
} from "@paypal/paypal-js/types/components/buttons";
import {CaptureOrderResponseBody} from "@paypal/paypal-js/types/apis/orders";
import {Payment} from "../../models/Payment";
import {setPaymentTabPayer} from "./paymentTabSlice";
import {setApiError} from "../api/ApiSlice";
import {PayPalConfig, usePaymentCompletedMutation} from "../../api/chessMasterApi";
import {addToast, newToastSuccess} from "../toast/toastSlice";

interface Props {
    payment: Payment
    paypalConfig: PayPalConfig
    onClose: (isPaymentCompleted: boolean) => void
}

const PayUsingPayPalButton: React.FC<Props> = ({payment, paypalConfig, onClose}) => {
    const dispatch = useDispatch();
    const [paymentCompleted] = usePaymentCompletedMutation();
    const [{options}, paypalDispatch] = usePayPalScriptReducer();

    useEffect(() => {
        paypalDispatch({
            type: "resetOptions",
            value: {
                ...options,
                "client-id": paypalConfig.client_id,
                deferLoading: undefined,
            }
        });
        // @ts-ignore I do not understand why value: "pending" isn't accepted here
        paypalDispatch({type: "setLoadingStatus", value: "pending"});
    }, [options, paypalConfig, paypalDispatch]);

    return (
        <PayPalButtons style={{layout: "horizontal"}}
                       createOrder={(data, actions) => new Promise((resolve) => resolve(payment.payment_token))}
                       onApprove={(data: OnApproveData, actions: OnApproveActions) => {
                           return actions.order.capture()
                               .then((details: CaptureOrderResponseBody) => {
                                   const {payer} = details;
                                   dispatch(setPaymentTabPayer(payer));
                                   paymentCompleted({payment})
                                       .unwrap()
                                       .then(() => onClose(true))
                                       .then(() => {
                                           dispatch(addToast(newToastSuccess({
                                               heading: "PayPal",
                                               body: "Payment succeeded"
                                           })));
                                       })
                                       .catch((err) => setApiError(`PayPal payment failed: ${err.toString()}`));
                               })
                               .catch((err) => {
                                   dispatch(setApiError(`Something went wrong. ${JSON.stringify(err)}`));
                               });
                       }}/>
    )
};

export default PayUsingPayPalButton;