import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {Move} from '@lubert/chess.ts'
import {Square} from "@lubert/chess.ts/dist/types";

interface GameState {
    game_id: number
    fen: string
    moves: Move[]
}

interface BasicMove {
    from: Square
    to: Square
}

const initialState: GameState = {
    game_id: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
    moves: []
};

export const slice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setBoardFen: (state, action: PayloadAction<string>) => {
            state.fen = action.payload;
        },
        setMoves: (state, action: PayloadAction<Move[]>) => {
            state.moves = action.payload;
        },
        addMove: (state, action: PayloadAction<Move>) => {
            state.moves.push(action.payload);
        },
        myMove: (state, action: PayloadAction<BasicMove>) => {
            // XXX not sure what to do here
        },
        serverMove: (state, action: PayloadAction<Move>) => {
            // XXX not sure what to do here
        },
        connected: (state, action: PayloadAction<Move>) => {
            // XXX not sure what to do here
        }
    }
});

export const selectGame = (state: RootState) => state.game;
export const {
    setBoardFen, setMoves, addMove, serverMove, myMove, connected
} = slice.actions;

export default slice.reducer;
