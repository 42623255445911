import React from "react";
import {PlayMethodType} from "../../models/Tournament";
import Badge from "react-bootstrap/Badge";

interface Props {
    play_method: PlayMethodType;
}

export const ActiveTournamentPlayMethod: React.FC<Props> = ({play_method}) => {
    switch (play_method) {
    case "OverTheBoard":
        return null;
    case "Online":
        return <Badge pill bg="info" className="p-2">Online</Badge>;
    case "LichessOnline":
        return <Badge pill bg="info" className="p-2">Lichess</Badge>
    }
};