import React from "react";

import DataTable, {TableColumn} from "react-data-table-component";
import {formatMoney} from "accounting";

import {InterimPaymentItem} from "../../models/Payment";

interface InterimPaymentListProps {
    items: InterimPaymentItem[]
    selectionComponent: React.ComponentType    // component to display instead of checkbox
    onSelected?: (items: InterimPaymentItem[]) => void
}

const PaymentListItems: React.FC<InterimPaymentListProps> = ({items, selectionComponent, onSelected}) => {
    const Tag: any = selectionComponent;    // this is a bit ugly but it works (*must* start with a capital letter)
    const columns: TableColumn<InterimPaymentItem>[] = [{
        name: <span onClick={(row) => onSelected?.(items)}><Tag/></span>,
        grow: 1,
        cell: (row) => <span onClick={() => onSelected?.([row])}><Tag/></span>
    }, {
        selector: (row) => row.description,
        name: "Description",
        sortable: true,
        grow: 8
    }, {
        selector: (row) => row.amount,
        name: "Amount",
        right: true,
        sortable: true,
        format: (row) => formatMoney(row.amount, "$")
    }];

    return (
        <DataTable<InterimPaymentItem> data={items ?? []}
                                       columns={columns}
                                       keyField="identifier"
                                       noDataComponent="(No payments)"
                                       pagination/>
    );
};

export default PaymentListItems;