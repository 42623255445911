import {isRejectedWithValue, Middleware, MiddlewareAPI} from '@reduxjs/toolkit'

import {addToast, newToastMessage} from "../features/toast/toastSlice";
import {AnyAction} from "redux";

export const formatErrorMessage = (error: any) => {
    if (error.data?.message) {
        return error.data.message;
    }
    if (error.data?.status) {
        return error.data.status;
    }
    if (error.status) {
        if (error.error) {
            return `${error.status}: ${error.error}`;
        }
        return (error.message) ? `${error.status} ${error.message}` : error.status;
    }
    if (error.message) {
        return error.message;
    }
    if (error.response) {
        return error.response?.data?.message;
    }
    console.error(`formatErrorMessage: this error could have been formatted better ${JSON.stringify(error)}`);
    return JSON.stringify(error);
};

// search queries with these names have their errors reported inline so
// don't display a toast.
const searchQueries = [
    "searchAudit",
    "searchGroups",
    "searchGroupNames",
    "searchTournaments",
    "searchPayment",
    "searchPaymentEntry",
    "searchTournamentPlayers",
    "searchUser",
    "searchUserInfos"
];

/**
 * Log a warning and show a toast!
 */

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    const shouldIgnore = (action: AnyAction, actionName: string) => {
        // 401 errors are a trigger to refresh the JWT token, not an error
        if (action.payload.status === 401) {
            return true;
        } else if (actionName === "tokenRefresh" && action.payload.msg.match(/^Missing JWT/)) {
            return true;
        } else if (actionName === "updateUser" && action.payload.data.errors?.json) {
            // we will report errors from the server in the form
            return true;
        } else if (actionName === "getTournament" && action.payload.data.message.match(/Tournament ID \[([\da-fA-F]*)] not found/)) {
            return true;
        } else if (searchQueries.includes(actionName) && action.payload.status === 422) {
            return true;
        }
        return false;
    };
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
        const actionName = action.meta?.arg?.endpointName;

        console.warn('We got a rejected action!', action);
        if (!shouldIgnore(action, actionName)) {
            api.dispatch(addToast(newToastMessage({
                heading: actionName ?? "API error",
                body: formatErrorMessage(action.payload),
                priority: "danger"
            })));
        }
    }

    return next(action)
};