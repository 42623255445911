import React from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {yupResolver} from "@hookform/resolvers/yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";

import {addToast, newToastMessage} from "../toast/toastSlice";
import {useRegisterUserMutation} from "../../api/chessMasterApi";
import {NewUser, User} from "../../models/User";
import {refreshCredentials} from "./loginSlice";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import RegistrationForm, {
    ParentRegistration,
    registrationSchema
} from "../../components/RegistrationForm";
import {newPasswordForm} from "../../util/passwordSchema";

interface Props {
    parent: User | NewUser
    onClose: () => void
}

const ParentRegistrationForm: React.FC<Props> = ({parent, onClose}) => {
    const dispatch = useDispatch();
    const [registerUser] = useRegisterUserMutation();
    const formMethods = useForm<ParentRegistration>({
        defaultValues: {user: parent, password: newPasswordForm()},
        resolver: yupResolver(registrationSchema)
    });
    const onSubmit = ({user, password}: ParentRegistration) => {
        user.new_password = password.new_password;
        registerUser({user})
            .unwrap()
            .then((response) => {
                dispatch(refreshCredentials(response));
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: "Registration succeeded",
                    body: `Logged in as ${user.username}`
                })));
                onClose();
            })
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch, "user");
            });
    };

    return (
        <Modal show={true}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={!formMethods.formState.isDirty}
               backdrop="static">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Parent registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="info">
                            We need to create an account for a parent to
                            manage their children.
                        </Alert>
                        <RegistrationForm user={parent}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar className="float-right">
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={onClose}>Cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary">Submit</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default ParentRegistrationForm;
