import React, {useContext} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import {ActiveTournamentContext} from "./ActiveTournament";
import TeamName from "./TeamName";
import UsernameAndTeam from "../../components/UsernameAndTeam";
import UserLichessLink from "../../components/UserLichessLink";
import {isPlayMethodOnline} from "../../models/Tournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {nameSortFunc} from "../../util/nameSortFunc";
import {formatFullName} from "../../util/formatFullName";

const ActiveTournamentEntrants: React.FC = () => {
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const columns: TableColumn<TournamentPlayer>[] = [{
        selector: (row) => tournament.ref_user[row.user_id].lichess_id || '',
        name: "Lichess",
        omit: !isPlayMethodOnline(tournament.play_method),
        sortable: true,
        center: true,
        cell: (row) => <UserLichessLink user={tournament.ref_user[row.user_id]}/>
    }, {
        selector: (row) => formatFullName(tournament.ref_user[row.user_id]),
        name: "Name",
        sortable: true,
        grow: 8,
        sortFunction: (a, b) => nameSortFunc(tournament.ref_user[a.user_id], tournament.ref_user[b.user_id]),
        cell: (row) => (
            <UsernameAndTeam player={row}
                             tournament={tournament}
                             omitTeam
                             omitScore
                             online={onlinePlayerIds.has(row.user_id)}/>
        )
    }, {
        selector: (row) => (row.team_id) ? tournament.ref_group[row.team_id].name : "",
        name: "Team",
        sortable: true,
        grow: 8,
        cell: (row) => (
            <TeamName tournament={tournament}
                      team={(row.team_id) ? tournament.ref_group[row.team_id] : undefined}/>
        )
    }];

    return (
        <>
            <DataTable<TournamentPlayer> data={tournament.players}
                                         columns={columns}
                                         defaultSortFieldId={2}
                                         defaultSortAsc={false}
                                         keyField="id"
                                         noDataComponent="(No players)"
                                         className="w-100 mt-3"/>
        </>
    );
};

export default ActiveTournamentEntrants;
