import React, {useEffect, useRef} from "react";

import Button from "react-bootstrap/Button";

import {usePollLichessForTournamentRoundMutation} from "../../api/chessMasterApi";
import {isTournamentComplete, Tournament} from "../../models/Tournament";

// Note that this button will fire every 60s while it's mounted
const POLL_INTERVAL_SECONDS = 60;

interface Props {
    tournament: Tournament
}

const PollLichessButton: React.FC<Props> = ({tournament}) => {
    const [pollLichessForTournamentRound, {isLoading}] = usePollLichessForTournamentRoundMutation();
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            buttonRef.current?.click();
        }, POLL_INTERVAL_SECONDS * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [tournament.name]);

    return (
            <Button onClick={() => pollLichessForTournamentRound({key: tournament.key, round: tournament.cur_round ?? 0})}
                    variant="outline-primary"
                    disabled={isLoading || isTournamentComplete(tournament)}
                    ref={buttonRef}>
                Poll Lichess
            </Button>
    );
};

export default PollLichessButton;
