import React, {useCallback, useState} from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

import ChildList from './ChildList';
// import ChildSearch from "./ChildSearch";
import {selectLogin} from "../login/loginSlice";
import {newUser, NewUserWithRegistration} from "../../models/User";
import ChildRegistrationForm from "../login/ChildRegistrationForm";
import {useAppSelector} from "../../store";

const ParentTab: React.FC = () => {
    const {userId} = useAppSelector(selectLogin);
    const [runChildRegistration, setRunChildRegistration] = useState<NewUserWithRegistration | undefined>(undefined)
    const [query] = useState("");   // note: no setQuery because no search on useChildrenForUserQuery (yet)
    const addNewChild = useCallback(
        () => setRunChildRegistration({
            user: newUser({gender_id: -1, username: "-1"}),
            guardian_id: userId
        }),
        [userId]);
    const closeChildRegistration = () => setRunChildRegistration(undefined);

    return (
        <>
            {(runChildRegistration) && (
                <ChildRegistrationForm child={runChildRegistration} onClose={closeChildRegistration}/>
            )}
            <Container className="mt-3" fluid>
                {/* TODO searching on useChildrenForUserQuery() isn't supported ATM */}
                {/*<Row className="mt-3 g-0">*/}
                {/*    <ChildSearch query={query} setQuery={setQuery}/>*/}
                {/*</Row>*/}
                <Row className="g-0">
                    <ChildList guardianId={userId} query={query}/>
                </Row>
                <Row className="float-end">
                    <Col sm="auto" className="ms-3">
                        <Button onClick={addNewChild}>
                            Add child ...
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ParentTab;