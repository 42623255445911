import React from "react";
import {useDispatch} from "react-redux";
import DataTable, {TableColumn} from "react-data-table-component";
import {format} from "date-fns";
import {formatMoney} from "accounting";

import Form from "react-bootstrap/Form";

import TournamentType from "../tournamentTab/TournamentType";
import TournamentStatus from "../tournamentTab/TournamentStatus";
import TournamentName from "../../components/TournamentName";
import {addActiveTournament} from "../activeTab/activeTabSlice";
import {tournamentTabEventKeyFromTournament} from "../../util/tabEventKey";
import {formatSecondsToReadable} from "../../util/formatSecondsToReadable";
import {TournamentLite} from "../../models/Tournament";
import {UserMinSchema} from "../../models/User";
import {useNavigate} from "react-router-dom";

interface Props {
    child: UserMinSchema
    tournaments: TournamentLite[] | undefined
}

const ChildTournamentList: React.FC<Props> = ({child, tournaments}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rowClicked = (row: TournamentLite) => {
        dispatch(addActiveTournament(row));
        navigate(`/${tournamentTabEventKeyFromTournament(row.key)}`);
    };
    // const changeStatus = useCallback(
    //     (status: TournamentStatusDescription) => dispatch(setParentTabQueryStatus(status)),
    //     [dispatch]
    // );
    // const changeTypeId = useCallback(
    //     (typeId: Tournament["tournament_type_id"]) => dispatch(setParentTabQueryTypeId(typeId)),
    //     [dispatch]
    // );

    const columns: TableColumn<TournamentLite>[] = [{
            selector: (row) => row.name,
            name: "Name",
            wrap: true,
            grow: 6,
            format: (row) => <TournamentName tournament={row}/>
        }, {
            selector: (row) => row.description,
            name: "Description",
            wrap: true,
            grow: 8,
        }, {
            selector: (row) => row.is_rated,
            name: "Rated?",
            center: true,
            format: (row) => <Form.Check checked={row.is_rated} readOnly/>
        }, {
            selector: (row) => row.duration,
            name: "Duration",
            maxWidth: "8rem",
            right: true,
            format: (row) => formatSecondsToReadable(row.duration)
        }, {
            selector: (row) => row.entry_fee,
            name: "Entry fee",
            maxWidth: "8rem",
            format: (row) => formatMoney(row.entry_fee, "$"),
            right: true
        }, {
            selector: (row) => row.start_time,
            name: "Start",
            maxWidth: "12rem",
            format: (row) => format(new Date(row.start_time), "PPpp"),
            right: true
        }, {
            selector: (row) => row.tournament_type_id,
            // name: <TournamentTypeSelect onChangeTypeId={changeTypeId}/>,
            name: "Type",
            center: true,
            cell: (row) => <TournamentType tournament={row}/>,
        }, {
            // name: <TournamentStatusSelect onChangeStatus={changeStatus}/>,
            name: "Status",
            center: true,
            cell: (row) => <TournamentStatus tournament={row}/>
        }
    ];

    return (
        <DataTable<TournamentLite> data={tournaments ?? []}
                                   columns={columns}
                                   keyField="id"
                                   onRowClicked={rowClicked}
                                   noDataComponent="(No tournaments)"
                                   pagination
                                   className="w-100"/>
    );
};

export default ChildTournamentList;
