import React from "react";
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {Role} from "../../models/Role";
import {SwitchRoleFormInput} from "./SwitchRoleModal";

interface SwitchRoleFormProps {
    availableRoles: Role[]
}

const SwitchRoleForm = ({availableRoles}: SwitchRoleFormProps) => {
    const {register, formState: {errors}} = useFormContext<SwitchRoleFormInput>();

    return (
        <Container>
            <Form.Group as={Row}
                        className="mb-2"
                        controlId="switchRoleSelect">
                <Form.Label column md={3}>
                    New role
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("roleId")} as="select">
                        <option value="all">(All)</option>
                        {availableRoles.map((role) => (
                            <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="switchRolePassword">
                <Form.Label column md={3}>Password</Form.Label>
                <Col md={9}>
                    <Form.Control {...register("password")}
                                  placeholder="Password"
                                  autoComplete="current-password"
                                  type="password"
                                  isInvalid={!!errors.password}
                                  autoCapitalize="off"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  errors={errors}
                                  name="password"
                                  type="invalid"/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default SwitchRoleForm;