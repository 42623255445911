import React, {useState} from "react";
import {Tournament} from "../../models/Tournament";
import DataTable, {TableColumn} from "react-data-table-component";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ErrorMessage} from "@hookform/error-message";
import {formatMoney} from "accounting";

import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import {TournamentExpense, tournamentExpenseSchema} from "../../models/TournamentExpense";
import {
    useDeleteTournamentExpenseMutation,
    useGetTournamentExpensesQuery,
    useUpdateTournamentExpenseMutation
} from "../../api/chessMasterApi";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useAppDispatch} from "../../store";

const AddTournamentExpenseCard: React.FC<{tournament: Tournament, onClose: () => void}> = ({tournament, onClose}) => {
    const dispatch = useAppDispatch();
    const [updateTournamentExpense] = useUpdateTournamentExpenseMutation();
    const {register, handleSubmit, formState: {errors}} = useForm<TournamentExpense>({
        resolver: yupResolver(tournamentExpenseSchema)
    });
    const onSubmit = (expense: TournamentExpense) => {
        updateTournamentExpense({key: tournament.key, expense})
            .unwrap()
            .then(() => dispatch(addToast(newToastSuccess({
                heading: "Expense updated",
                body: "Expense added successfully"
            }))))
            .then(onClose);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Card.Header>
                    <Card.Title>
                        New expense
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <Form.Group as={Row} className="mb-3"
                                controlId="registerFirstName">
                        <Form.Label column md={4}>
                            Description
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("description")}
                                          isInvalid={!!errors.description}
                                          placeholder="Expense description"/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          errors={errors}
                                          name="description"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}
                                controlId="registerFirstName">
                        <Form.Label column md={4}>
                            Amount
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("amount")}
                                          type="number"
                                          step={0.01}
                                          isInvalid={!!errors.amount}
                                          placeholder="$0"/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          errors={errors}
                                          name="amount"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Card.Body>

                <Card.Footer>
                    <Row>
                        <Col className="text-end">
                            <Button size="sm"
                                    className="me-1"
                                    onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button size="sm"
                                    type="submit">
                                Add expense
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Form>
    );
};


interface Props {
    tournament: Tournament
    onClose: () => void
}

const ActiveTournamentFinancialsExpensesEditModal: React.FC<Props> = ({tournament, onClose}) => {
    const dispatch = useAppDispatch();
    const [runAddExpense, setRunAddExpense] = useState(false);
    const {data: expenses} = useGetTournamentExpensesQuery({key: tournament.key});
    const [deleteTournamentExpense] = useDeleteTournamentExpenseMutation();
    const addExpenseCallback = () => {
        setRunAddExpense(false);
    };
    const removeTournamentExpense = (expense: TournamentExpense) => {
        deleteTournamentExpense({key: tournament.key, expenseId: expense.id})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Expense deleted",
                    body: "Expense deleted successfully"
                })))
            })
    };
    const columns: TableColumn<TournamentExpense>[] = [{
        name: "Description",
        selector: (row) => row.description,
        grow: 5,
        sortable: true
    }, {
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
        right: true,
        format: (row) => formatMoney(row.amount, "$")
    }, {
        name: "Action",
        cell: (row) => (
            <Button size="sm"
                    variant="danger"
                    onClick={() => removeTournamentExpense(row)}>
                Delete
            </Button>
        )
    }];

    return (
        <Modal show
               size="lg"
               onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit tournament expenses
                </Modal.Title>
            </Modal.Header>

            {(runAddExpense) && (
                <Modal.Body>
                    <AddTournamentExpenseCard tournament={tournament}
                                              onClose={addExpenseCallback}/>
                </Modal.Body>
            )}

            <Modal.Body>
                <DataTable<TournamentExpense> columns={columns}
                                              data={expenses ?? []}
                                              keyField="id"
                                              actions={(runAddExpense) ? undefined : (
                                                  <Button size="sm"
                                                          variant="secondary"
                                                          onClick={() => setRunAddExpense(true)}>
                                                      Add expense ...
                                                  </Button>
                                              )}
                                              className="w-100"/>
            </Modal.Body>

            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button onClick={() => onClose()}>
                            Close
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveTournamentFinancialsExpensesEditModal;
