import React from "react";
import DataTable, {ExpanderComponentProps, TableColumn} from "react-data-table-component";
import {format} from "date-fns";
import {formatMoney} from "accounting";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import {InterimPaymentItem, Payment, PaymentStatus, PaymentSystem} from "../../models/Payment";
import {formatFullNameWithUsername} from "../../util/formatFullName";
import PaymentStatusSelect from "../financeTab/PaymentStatusSelect";
import {nameSortFunc} from "../../util/nameSortFunc";
import {selectLogin} from "../login/loginSlice";
import {useAppDispatch, useAppSelector} from "../../store";
import {
    setRunEditPaymentModal,
    setRunEditProFormaModal
} from "../financeTab/financeTabSlice";
import TournamentName from "../../components/TournamentName";
import PaymentSystemSelect from "../financeTab/PaymentSystemSelect";

const PaymentDetail: React.FC<{payment: Payment, allowEdit?: boolean}> = ({payment, allowEdit = false}) => {
    const dispatch = useAppDispatch();
    const {privs} = useAppSelector(selectLogin);
    const columns: TableColumn<InterimPaymentItem>[] = [{
        selector: (row) => row.description ?? "",
        name: "Description",
        grow: 8
    }, {
        selector: (row) => row.amount,
        name: "Amount",
        right: true,
        format: (row) => (row.amount !== null) ? formatMoney(row.amount, "$") : ""
    }];
    const editButtonFooter = () => {
        if (allowEdit && privs.finance_credit) {
            switch (payment.payment_system) {
            case "manual":
                return (
                    <Card.Footer className="text-end">
                        <Button size="sm"
                                onClick={() => dispatch(setRunEditPaymentModal(payment))}>
                            Edit
                        </Button>
                    </Card.Footer>
                );
            case "proforma":
                return (
                    <Card.Footer className="text-end">
                        <Button size="sm"
                                onClick={() => dispatch(setRunEditProFormaModal(payment))}>
                            Edit pro forma
                        </Button>
                    </Card.Footer>
                );
            default:
                break;
            }
        }
        return null;
    };

    return (
        <Card className="my-3 mx-5">
            <Card.Body className="p-2">
                <DataTable<InterimPaymentItem> columns={columns}
                                               dense
                                               data={payment.items}
                                               noDataComponent="(No payment items)"/>
            </Card.Body>
            {editButtonFooter()}
        </Card>
    )
};

const PaymentSystemDetail: React.FC<{payment: Payment}> = ({payment}) => {
    return (
        <div style={{textAlign: "center"}}>
            <div>{payment.payment_system}</div>
            {payment.payment_system_id ? <div>{payment.payment_system_id}</div> : null}
            {payment.reference ? <div>{payment.reference}</div> : null}
        </div>
    )
};
// data provides access to your row data

interface PaymentDetailComponentProps extends ExpanderComponentProps<Payment> {
    allowEdit?: boolean
}

const PaymentDetailComponent: React.FC<PaymentDetailComponentProps> = ({data: payment, allowEdit}) => (
    <PaymentDetail payment={payment} allowEdit={allowEdit}/>
);

interface Props {
    payments:  Payment[]
    allowEdit?: boolean
    isFetching: boolean
    status?: PaymentStatus
    onChangeStatus?: (paymentStatus: PaymentStatus | undefined) => void
    paymentSystem?: PaymentSystem
    onChangePaymentSystem?: (system: PaymentSystem | undefined) => void
}

const PaymentList: React.FC<Props> = ({payments, allowEdit = false, isFetching, status, onChangeStatus, paymentSystem, onChangePaymentSystem}) => {
    const columns: TableColumn<Payment>[] = [{
        selector: (row) => formatFullNameWithUsername(row.payer),
        name: "Payer",
        grow: 3,
        wrap: true,
        format: (row) => (row.payer) ? formatFullNameWithUsername(row.payer) : row.group_payer?.name,
        sortable: true,
        sortFunction: (a, b) => nameSortFunc(a.payer, b.payer)
    }, {
        selector: (row) => row.paid_time ?? row.create_time ?? "",
        name: "Paid time",
        grow: 3,
        wrap: true,
        format: (row) => (row.paid_time) ? format(new Date(row.paid_time), "PPpp") : "",
        sortable: true
    }, {
        selector: (row) => row.tournament?.name ?? "",
        name: "Tournament",
        grow: 3,
        wrap: true,
        sortable: true,
        format: (row) => (row.tournament) ? <TournamentName tournament={row.tournament}/> : null
    }, {
        selector: (row) => row.payment_system + (row.reference || row.payment_system_id || ''),
        name: (
            (onChangePaymentSystem) ? <PaymentSystemSelect system={paymentSystem} onChangeSystem={onChangePaymentSystem}/> : "Payment system"
        ),
        grow: 3,
        center: true,
        wrap: true,
        cell: (row) => <PaymentSystemDetail payment={row}/>,
        sortable: true
    }, {
        selector: (row) => row.status,
        name: (
            (onChangeStatus) ? <PaymentStatusSelect status={status} onChangeStatus={onChangeStatus}/> : "Status"
        ),
        grow: 2,
        center: true,
        sortable: true
    }, {
        selector: (row) => row.items.length,
        name: "Items",
        right: true
    }, {
        selector: (row) => row.amount,
        name: "Amount",
        right: true,
        format: (row) => formatMoney(row.amount, "$"),
        sortable: true
    }];

    return (
        <DataTable<Payment> data={payments ?? []}
                            columns={columns}
                            keyField="id"
                            defaultSortFieldId={2}
                            noDataComponent="(No matching payments)"
                            persistTableHead
                            progressPending={isFetching}
                            expandableRows
                            expandableRowsComponent={PaymentDetailComponent}
                            expandableRowsComponentProps={{allowEdit}}
                            pagination/>
    );
};

export default PaymentList;
