import React from "react";

import Tab from "react-bootstrap/Tab";

import {gameTabEventKeyFromGame} from "../../util/tabEventKey";
import ActiveGameTabTitle from "./ActiveGameTabTitle";
import {GameDescription} from "../../models/GameDescription";
import {Outlet} from "react-router-dom";

// XXX the Tab should be colour-coded for playing-white, playing-black, spectating (use tabClassName)

export const makeActiveGameTabs = (gameDescriptions: GameDescription[]) => (
    gameDescriptions.map((gd) => (
        <Tab key={`game-${gd.game.id}`}
             eventKey={gameTabEventKeyFromGame(gd.game.id)}
             title={<ActiveGameTabTitle game={gd.game}/>}>
            <Outlet/>
        </Tab>
    ))
);