import React from "react";

import Stack from "react-bootstrap/Stack";

import {UserInfo} from "../models/User";
import {Tournament} from "../models/Tournament";
import {formatFullName} from "../util/formatFullName";
import {TournamentPlayer} from "../models/TournamentPlayer";
import UserLichessOnlineSymbol from "./UserLichessOnlineSymbol";

interface Props {
    player?: TournamentPlayer
    tournament: Tournament
    scores?: Record<UserInfo["id"], number>
    omitTeam?: boolean
    online?: boolean
}

const ProjectorViewUser: React.FC<Props> = ({player, tournament, scores, omitTeam = false, online = false}) => {
    const user = (player) ? tournament.ref_user[player.user_id] : undefined;
    const score = (scores && player) ? scores[player.user_id] : player?.points;
    const team = (player?.team_id && !omitTeam) ? tournament?.ref_group[player.team_id]?.name : undefined;

    return (
        <div className="w-100">
            <Stack direction="horizontal">
                <big><strong>{(user) ? formatFullName(user) : "(bye)"}</strong></big>
                {(online) ? <UserLichessOnlineSymbol/> : ""}
                {(player?.points !== undefined) ? <span className="ms-auto text-nowrap">{" "}({score})</span> : ""}
            </Stack>
            <Stack direction="horizontal">
                {(team) ? <small className="text-muted">{team}</small> : null}
            </Stack>
        </div>
    );
};

export default ProjectorViewUser;