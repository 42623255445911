import React from "react";
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {formControlOptions} from "../../util/formControl";
import {selectConstants} from "../constants/constantsSlice";
import AdminOnlyLocationCombobox from "../../components/AdminOnlyLocationCombobox";
import {Location} from "../../models/Location";
import {Group} from "../../models/Group";
import {useAppSelector} from "../../store";

const GroupEditDetails: React.FC = () => {
    const {register, formState: {errors}, watch, setValue} = useFormContext<Group>();
    const {group_types} = useAppSelector(selectConstants);
    const address = watch("address");
    const setAddress = (location: Location | undefined) => {
        setValue("address", location ?? null, {shouldDirty: true});
        setValue("address_id", (location) ? location.id : null, {shouldDirty: true});
    };
    const postalAddress = watch("postal_address");
    const setPostalAddress = (location: Location | undefined) => {
        setValue("postal_address", location ?? null, {shouldDirty: true});
        setValue("postal_address_id", (location) ? location.id : null, {shouldDirty: true});
    };

    register("address");
    register("address_id");
    register("postal_address");
    register("postal_address_id");

    return (
        <Container fluid>
            <Form.Group as={Row} md={6} controlId="groupEditName" className="mb-1">
                <Form.Label column md={3}>
                    Name
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("name")} placeholder="Name" isInvalid={!!errors.name} />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="groupEditDescription" className="mb-1">
                <Form.Label column md={3}>
                    Description
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("description")}
                                  placeholder="Description"
                                  as="textarea"
                                  isInvalid={!!errors.description}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="description"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="groupEditUrl" className="mb-1">
                <Form.Label column md={3}>
                    URL
                </Form.Label>
                <Col md={9}>
                    <Form.Control {...register("url")}
                                  placeholder="URL"
                                  isInvalid={!!errors.url} />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="url"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="groupEditType" className="mb-1">
                <Form.Label column md={3}>
                    Type
                </Form.Label>
                <Col md={9}>
                    <Form.Select {...register("group_type_id", {valueAsNumber: true})}
                                 isInvalid={!!errors.group_type_id}>
                        {formControlOptions(group_types)}
                    </Form.Select>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="group_type_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="groupEditAddress" className="mb-1">
                <Form.Label column md={3}>
                    Address
                </Form.Label>
                <Col md={9}>
                    <AdminOnlyLocationCombobox onChange={setAddress}
                                               selectedLocation={address ?? undefined}
                                               fieldError={errors.address_id}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="groupEditPostalAddress">
                <Form.Label column md={3}>
                    Postal address
                </Form.Label>
                <Col md={9}>
                    <AdminOnlyLocationCombobox onChange={setPostalAddress}
                                               selectedLocation={postalAddress ?? undefined}
                                               fieldError={errors.postal_address_id}/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default GroupEditDetails;