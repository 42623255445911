import {formatISO} from "date-fns";
import {TournamentStatusDescription} from "./TournamentStatus";
import {Location} from "./Location";
import {TournamentPlayer} from "./TournamentPlayer";
import {TournamentStaff} from "./TournamentStaff";
import {TournamentRound} from "./TournamentRound";
import {Team} from "./Team"
import {Group} from "./Group";
import {UserInfo} from "./User";
import {TournamentType} from "./TournamentType";
import {Money, TournamentKey} from "./types";
import {isMatchStatusBye, Match} from "./Match";

// note "Online" isn't supported at the moment
export type PlayMethodType = "OverTheBoard" | "LichessOnline" | "Online"

export const isPlayMethodOnline = (play_method: PlayMethodType) => (
    play_method === "Online" || play_method === "LichessOnline"
);

export interface TournamentBase {
    name: string
    description: string
    // variant: string
    is_rated: boolean
    entry_fee: Money
    duration: number
    start_time: string
    end_time: string | null
    num_rounds: number
    cur_round?: number
    max_players: number
    team_score_top: number
    status?: TournamentStatusDescription
    visibility: "Community" | "Private"
    venue?: Location
    venue_id: Location["id"] | null
    venue_info: string
    conference_link?: string     // url for video conferencing
    play_method: PlayMethodType
    clock_increment: number
    // time_before_start: number
    tournament_type_id: TournamentType["id"]
    // starting_position_id: number
    controls: {
        staff: boolean
        director: boolean
    }
    // expired: boolean
    // end_time: string | null    // ISO date
}

export interface NewTournament extends TournamentBase {
}

export interface TournamentLite extends TournamentBase {
    id: Tournament["id"]
    readonly key: TournamentKey
    participating?: boolean
}

export interface Tournament extends TournamentBase {
    readonly id: number
    readonly key: TournamentKey
    staff: TournamentStaff[]
    players: TournamentPlayer[]
    teams: Team[]
    rounds: TournamentRound[]
    ref_group: Record<Group["id"], Group>
    ref_user: Record<UserInfo["id"], UserInfo>
}

export interface TournamentMinSchema {
    readonly id: Tournament["id"]
    readonly key: Tournament["key"]
    name: Tournament["name"]
}

export const newTournament = (initFields: Partial<Tournament> = {}): NewTournament => ({
    name: "",
    description: "",
    // variant: "standard",
    is_rated: true,
    entry_fee: "0.00",
    start_time: formatISO(new Date()),
    end_time: null,
    num_rounds: 0,
    max_players: 0,
    team_score_top: 4,
    status: "Pending", //
    visibility: "Community",
    venue: undefined,    //
    venue_id: null,
    venue_info: "",
    play_method: "OverTheBoard",
    clock_increment: 0,
    duration: 10,
    // time_before_start: 0,
    tournament_type_id: 1,
    // starting_position_id: 1
    controls: {
        director: false,
        staff: false
    },
    // expired: false,
    // end_time: null,
    cur_round: undefined,
    staff: [],
    players: [],
    teams: [],
    rounds: [],
    ...initFields
});

export const nActiveRoundsInTournament = (tournament: Tournament): number => {
    switch (tournament.status) {
    case "Pending":
        return 0;
    case "Playing":
    case "Abandoned":
        return tournament.cur_round ?? 0;
    case "Completed":
        return tournament.num_rounds;
    default:
        console.error(`nActiveRoundsInTournament: unknown status ${tournament.status}`);
        return tournament.num_rounds;
    }
};

export const isTournamentCurrentRound = (tournament: Tournament, round: number): boolean => {
    switch (tournament.status) {
    case "Pending":
        return false;
    case "Playing":
    case "Abandoned":
        return round === (tournament.cur_round ?? 0);
    case "Completed":
        return round === tournament.num_rounds + 1;
    default:
        console.error(`isTournamentCurrentRound: unknown status ${tournament.status}`);
        return false;
    }
};

export const isTournamentStarted = (tournament: Tournament) => (
    tournament.status && tournament.status !== "Pending"
);

export const isTournamentComplete = (tournament: Tournament) => (
    tournament.status && ["Abandoned", "Completed"].includes(tournament.status)
);

export const isTournament = (obj: any): obj is Tournament => {
    return obj.id !== undefined
};

export const isRoundBeginning = (tournament: Tournament, matches: Match[]) => (
    tournament.status === "Playing" && matches.every((match) => (match.status === "Pending" || isMatchStatusBye(match)))
);
export const isTournamentPlaying = (tournament: Tournament) => (
    isTournamentStarted(tournament) && !isTournamentComplete(tournament)
);
