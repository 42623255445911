import React, {useState} from "react";

import {FormProvider, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {isMatchStatusBye, Match, MatchStatusDescription} from "../../models/Match";
import ActiveTournamentRePairList from "./ActiveTournamentRePairList";
import {Tournament} from "../../models/Tournament";
import {TournamentRound} from "../../models/TournamentRound";
import {
    newMatchRePairFilter,
    updateMatchRePairFilter,
    useUpdateTournamentMatchMutation
} from "../../api/chessMasterApi";
import {UserInfo} from "../../models/User";
import {formatArrayJsonErrors} from "../../util/formatJsonErrors";
import Alert from "react-bootstrap/esm/Alert";

export interface RePairForm {
    matches: Match[]
    unpairedPlayerIds: UserInfo["id"][]
}

// if during re-pairing, both players in a match have been moved elsewhere, we need to
// tell the server to delete that match. This is a special case.
const rePairStatusForMatch = ({white_player_id, black_player_id}: Pick<Match, "white_player_id" | "black_player_id">): MatchStatusDescription => {
    if (!white_player_id && !black_player_id) {
        return "Delete";
    } else if (!white_player_id || !black_player_id) {
        return "FullPointBye";
    } else {
        return "Pending";
    }
};

const updateStatusForRepairedMatch = (match: Match) => {
    return ({
        ...match,
        status: rePairStatusForMatch(match)
    });
};

const unpairedPlayerIdsInRound = (tournament: Tournament, tournamentRound: TournamentRound) => {
    const participatingPlayerIds = tournament.players
        .filter((tp) => tp.participating && !tp.withdrawn)
        .map((tp) => tp.user_id);
    const unpairedPlayerIds = new Set(participatingPlayerIds);

    tournamentRound.matches.forEach(({white_player_id, black_player_id}) => {
        (white_player_id) && unpairedPlayerIds.delete(white_player_id);
        (black_player_id) && unpairedPlayerIds.delete(black_player_id);
    });

    return [...unpairedPlayerIds];
};

interface Props {
    tournament: Tournament
    tournamentRound: TournamentRound
    onClose: () => void
}

const ActiveTournamentRePair: React.FC<Props> = ({tournament, tournamentRound, onClose}) => {
    const [errorMessage, setErrorMessage] = useState<React.ReactElement | null>(null);
    const [updateTournamentMatch, {isLoading}] = useUpdateTournamentMatchMutation();
    const formMethods = useForm<RePairForm>({
        defaultValues: {
            matches: tournamentRound.matches,
            unpairedPlayerIds: unpairedPlayerIdsInRound(tournament, tournamentRound)
        },
    });
    const unpairedPlayerIds = formMethods.watch("unpairedPlayerIds");
    const areAnyMatchesPlayed = (matches: Match[]) => (
        matches.some((match) => match.status !== "Pending" || isMatchStatusBye(match))
    );
    const onSubmit = ({matches}: RePairForm) => {
        const changedMatches: Partial<Match>[] = [];

        // note that when matches are repaired, their status is reset to "Pending"
        for (let i = 0; i < matches.length; i++) {
            if (i >= tournamentRound.matches.length) {
                if (matches[i].black_player_id || matches[i].white_player_id) {
                    changedMatches.push(newMatchRePairFilter(updateStatusForRepairedMatch({
                    ...matches[i], status: "Pending"
                    })))
                }
            } else if (matches[i].white_player_id !== tournamentRound.matches[i].white_player_id
                   ||  matches[i].black_player_id !== tournamentRound.matches[i].black_player_id) {
                changedMatches.push(updateMatchRePairFilter(updateStatusForRepairedMatch({
                    ...matches[i], status: "Pending"
                })));
            }
        }
        if (changedMatches.length > 0) {
            updateTournamentMatch({
                key: tournament.key,
                matches: changedMatches
            })
                .unwrap()
                .then(onClose)
                .catch((error) => {
                    setErrorMessage(
                        <>
                            {error.data.message}
                            {formatArrayJsonErrors(error.data.errors?.json)}
                        </>
                    );
                });
        }
    };

    formMethods.register("matches");

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               size="xl">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>
                            Tournament “{tournament.name}” &mdash; Re-pair round {tournament.cur_round}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ActiveTournamentRePairList tournament={tournament}/>

                        {(unpairedPlayerIds.length > 0) && (
                            <Alert variant="warning" className="p-2 m-1">
                                Note:
                                You can only save when there are no unpaired players
                            </Alert>
                        )}

                        {(areAnyMatchesPlayed(tournamentRound.matches)) && (
                            <Alert variant="danger" className="p-2 m-1">
                                Warning:
                                match results have been posted.
                                Changing pairings will set those results back to "Pending".
                            </Alert>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar>
                            <Button variant="secondary"
                                    onClick={onClose}
                                    disabled={isLoading}
                                    className="me-1">
                                Cancel
                            </Button>
                            <Button type="submit"
                                    variant="primary"
                                    disabled={isLoading || unpairedPlayerIds.length > 0}>
                                Re-pair
                            </Button>
                        </ButtonToolbar>
                    </Modal.Footer>

                    {(errorMessage) && (
                        <Modal.Footer>
                            <div className="text-danger text-end">Error: {errorMessage}</div>
                        </Modal.Footer>
                    )}
                    <DevTool control={formMethods.control}/>
                </Form>
            </FormProvider>
        </Modal>
    );};

export default ActiveTournamentRePair;
