import {createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {Tournament, TournamentMinSchema} from "../../models/Tournament";
import {chessMasterApi} from "../../api/chessMasterApi";
import {setLoginIsLoggedIn} from "../login/loginSlice";

/*
 * If you have got here, I'm assuming that you're trying to work out isPreparedToDelete.
 * This is used when deleting the tournament to stop RTK query from holding a reference
 * to the tournament. When we delete the tournament and RTKQ has a reference to it, it
 * will be invalidated and RTKQ will immediately refetch it, which will, of course, fail.
 * By dropping the reference to the tournament, when it is invalidated it won't be
 * refetched. We drop the reference by turning on "skip" in the getTournament() query. If
 * the delete fails, we turn off "skip" and the show resumes.
 *
 * I don't think this is an elegant solution but it works.
 */

export interface ActiveTabDetail {
    name: string
    tournamentKey: Tournament["key"]
    isPreparedToDelete?: boolean
}

export const tabDetailFromTournament = (tournament: TournamentMinSchema): ActiveTabDetail => ({
    tournamentKey: tournament.key,
    name: tournament.name
});
export const doesTabForTournamentKeyExist = (activeTournamentTabDetails: ActiveTabDetail[], tournamentKey: Tournament["key"]): boolean => (
    !!activeTournamentTabDetails.find((detail) => detail.tournamentKey === tournamentKey)
);

export type ActiveTabStateKey = 'tournaments' | 'games' | 'settings' | string;
interface ActiveTabState {
    // key: ActiveTabStateKey
    tabDetails: ActiveTabDetail[]
}

const initialState: ActiveTabState = {
    // key: 'tournaments',
    tabDetails: []
};

export const slice = createSlice({
    name: 'activeTab',
    initialState,
    reducers: {
        // setActiveTabKey: (state, action: PayloadAction<ActiveTabStateKey>) => {
        //     state.key = action.payload;
        // },
        setActiveTournamentTabDetails: (state, action: PayloadAction<ActiveTabDetail[]>) => {
            state.tabDetails = action.payload;
        },
        addActiveTournament: (state, action: PayloadAction<TournamentMinSchema>) => {
            if (!doesTabForTournamentKeyExist(state.tabDetails, action.payload.key)) {
                state.tabDetails.push(tabDetailFromTournament(action.payload));
            }
        },
        removeActiveTournament: (state, action: PayloadAction<Tournament>) => {
            state.tabDetails = state.tabDetails.filter((detail) => detail.tournamentKey !== action.payload.key);
        },
        removeActiveTournamentKey: (state, action: PayloadAction<Tournament["key"]>) => {
            state.tabDetails = state.tabDetails.filter((td) => td.tournamentKey !== action.payload)
        },
        prepareToDeleteTournamentKey: (state, action: PayloadAction<{key: Tournament["key"], isPreparedToDelete: boolean}>) => {
            const tabDetail = state.tabDetails.find((td) => td.tournamentKey === action.payload.key);
            // if tabDetail is falsy, this tournament is not in a tab so we can ignore
            if (tabDetail) {
                tabDetail.isPreparedToDelete = action.payload.isPreparedToDelete;
            }
        },
    },
    extraReducers: (builder) => {
        // when the user logs out, clear the saved tabDetails so things are fresh when they next login
        builder.addCase(setLoginIsLoggedIn, (state, action) => {
            if (!action.payload) {
                state.tabDetails = [];
            }
        });
        // when a tournament is updated, its name might have changed. Reflect that in tabs and saved active tab state.
        builder.addMatcher(isAnyOf(
            chessMasterApi.endpoints.updateTournament.matchFulfilled,
            chessMasterApi.endpoints.getTournament.matchFulfilled
        ), (state, action) => {
            // update tab details if an open tournament has been updated, it's name might have changed
           state.tabDetails = state.tabDetails.map((detail) => (detail.tournamentKey === action.payload.key) ? tabDetailFromTournament(action.payload) : detail);
        });
        // when a tournament is started, add to activeTabs and make it active
        builder.addMatcher(chessMasterApi.endpoints.startTournament.matchFulfilled, (state, action) => {
            if (!doesTabForTournamentKeyExist(state.tabDetails, action.payload.key)) {
                state.tabDetails.push(tabDetailFromTournament(action.payload));
            }
            // state.key = tournamentTabEventKeyFromTournament(action.payload.id)
        });
        // when a tournament is deleted, check if it was the active tournament and change to another
        // XXX should be smarter about picking which tab to go to
        builder.addMatcher(chessMasterApi.endpoints.deleteTournament.matchFulfilled, (state, action) => {
            const tournamentKey = action.meta.arg.originalArgs.key;

            // if this is the currently open tournament close it
            // if (state.key === tournamentTabEventKeyFromTournament(tournamentId)) {
            //     state.key = "tournaments";
            // }
            // make sure we remove it from any open tournaments
            if (doesTabForTournamentKeyExist(state.tabDetails, tournamentKey)) {
                state.tabDetails = state.tabDetails.filter((detail) => detail.tournamentKey !== tournamentKey);
            }
        });
    }
});

export const selectActiveTab = (state: RootState) => state.activeTab;
export const {
    setActiveTournamentTabDetails,
    addActiveTournament, removeActiveTournamentKey,
    removeActiveTournament, prepareToDeleteTournamentKey
} = slice.actions;

export default slice.reducer;
