import {newUserGroupUpdate, UserGroup} from "../models/UserGroup";

export const createUserGroupUpdates = (oldUserGroups: UserGroup[], userGroups: UserGroup[]) => {
    const oldIds = Object.fromEntries(oldUserGroups.map((ug) => [ug.group.id, ug])),
          ids = Object.fromEntries(userGroups.map((ug) => [ug.group.id, ug]));
    const deletedGroups = oldUserGroups.filter((ug) => !ids[ug.group.id]);
    const changedGroups = userGroups.filter((ug) => !oldIds[ug.group.id] || ug.group_admin !== oldIds[ug.group.id].group_admin);

    return [
        ...deletedGroups.map((ug) => newUserGroupUpdate(ug.group.id, {_delete: true})),
        ...changedGroups.map((ug) => newUserGroupUpdate(ug.group.id, {group_admin: ug.group_admin})),
    ]
};
