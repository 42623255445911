import React from "react";
import {FieldError} from "react-hook-form";

import Form from "react-bootstrap/Form";

import {selectLogin} from "../features/login/loginSlice";
import {Location} from "../models/Location";
import {LocationCombobox, locationKey} from "./LocationCombobox";
import {useAppSelector} from "../store";

// Only show a LocationCombobox if the user is an admin otherwise it's just a text field.

interface Props {
    onChange: (location: Location) => void;
    selectedLocation: Location | undefined
    fieldError: FieldError | undefined
}

const AdminOnlyLocationCombobox: React.FC<Props> = ({onChange, selectedLocation, fieldError}) => {
    const {privs} = useAppSelector(selectLogin);

    if (privs.admin || privs.tourn_admin) {
        return (
            <LocationCombobox onChange={onChange}
                              selectedLocation={selectedLocation}
                              fieldError={fieldError}/>
        );
    } else {
        return (
            <Form.Control plaintext
                          readOnly
                          placeholder="(No location)"
                          value={(selectedLocation) ? locationKey(selectedLocation) : ""}/>
        );
    }
};

export default AdminOnlyLocationCombobox;
