import React, {useState} from "react";
import {useDispatch} from "react-redux";
import NewImprovedWindow from "react-new-improved-window";

import Button from "react-bootstrap/Button";

import {chessMasterApi, useUnlinkUserFromLichessMutation} from "../api/chessMasterApi";
import UserLichessLink from "./UserLichessLink";
import {isUser, User} from "../models/User";

interface Props {
    user: {
        id: User["id"]
        lichess_id: string | null
    }
}

const LinkLichessButton: React.FC<Props> = ({user}) => {
    const dispatch = useDispatch();
    const [unlinkUserFromLichess] = useUnlinkUserFromLichessMutation();
    const [popupBlockedMessage, setPopupBlockedMessage] = useState<string | undefined>(undefined);
    const [runLinkToLichess, setRunLinkToLichess] = useState(false);
    const [lichessErrorMessage, setLichessErrorMessage] = useState("");
    const lichessConnectClosed = async () => {
        dispatch(chessMasterApi.util.invalidateTags([{type: "User", id: user.id}]));
    };

    if (!isUser(user)) {    // don't render anything if user has no id
        return null;
    }

    if (user.lichess_id) {
        return (
            <>
                <Button variant="outline-secondary"
                        onClick={() => {
                            setLichessErrorMessage("");
                            unlinkUserFromLichess(user)
                                .unwrap()
                                .catch((e) => setLichessErrorMessage(e.data.message));
                        }}>
                    Unlink Lichess <UserLichessLink user={user}/>
                    {(lichessErrorMessage) && (
                        <div className="text-danger">{lichessErrorMessage}</div>
                    )}
                </Button>
            </>
        );
    } else {
        return (
            <>
                {(runLinkToLichess) && (
                    <NewImprovedWindow url={`/api/lichess/link/${user.id}`}
                                       title='Connect to lichess'
                                       onUnload={lichessConnectClosed}
                                       onBlock={() => setPopupBlockedMessage("Popups are blocked!")}
                                       features={{left: 200, top: 200, width: 620, height: 700}}>
                        <div>(Connection to lichess)</div>
                    </NewImprovedWindow>
                )}

                <Button variant="outline-secondary"
                        onClick={() => setRunLinkToLichess(true)}>Link to Lichess</Button>
                {(popupBlockedMessage) && (
                    <span className="text-danger">{popupBlockedMessage}</span>
                )}
            </>
        );
    }
};

export default LinkLichessButton;
