import React from "react";
import {useFormContext} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {ErrorMessage} from "@hookform/error-message";
import * as yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {formControlOptions} from "../util/formControl";
import {selectConstants} from "../features/constants/constantsSlice";
import {phoneRegexp} from "../util/validators";
import {NewUser, User} from "../models/User";
import RegistrationUserName from "./RegistrationUserName";
import {PasswordForm, passwordSchema} from "../util/passwordSchema";
import UserEMailAddressConfirmedSymbol from "./UserEMailAddressConfirmedSymbol";
import {useAppSelector} from "../store";

export const parentRegistrationSchema = yup.object().shape({
    given_name: yup.string().nullable().label("First name"),
    family_name: yup.string().required().min(2).label("Last name"),
    gender_id: yup.string().notOneOf(["-1"], "Gender not selected").label("Gender"),
    birthday: yup.string().nullable().label("Birthday"),
    username: yup.string().notOneOf(["-1"], "Please select a username").label("Username"),
    email_address: yup.string()
        .typeError("Please specify an e-mail address")
        .email()
        .required()
        .label("E-mail"),
    phone_num: yup.string()
        .typeError("Please specify a phone number")
        .matches(phoneRegexp, {message: "Invalid phone number", excludeEmptyString: true})
        .required()
        .label("Phone"),
    phone_num_note: yup.string().nullable().label('Phone note')
});

// if password form is present, validate it; if not, ignore it
export const registrationSchema = yup.object().shape({
    user: parentRegistrationSchema,
    password: passwordSchema
});

export interface ParentRegistration {
    user: User
    password: PasswordForm
}
// When creating, allow changes to the username and set password

interface RegistrationFormProps {
    user: User | NewUser
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({user}) => {
    const {register, control, formState: {errors, dirtyFields}, watch} = useFormContext<ParentRegistration>();
    const {genders} = useAppSelector(selectConstants);
    const given_name = watch("user.given_name");
    const family_name = watch("user.family_name");
    const email_address_confirmed = watch("user.email_address_confirmed");

    register("user.school_id");
    register("user.email_address_confirmed");

    return (
        <>
            <Form.Group as={Row} className="mb-3"
                        controlId="registerFirstName">
                <Form.Label column md={4}>
                    Given name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.given_name")}
                                  isInvalid={!!errors.user?.given_name}
                                  placeholder="First name"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.given_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPreferredName">
                <Form.Label column md={4}>
                    Preferred name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.preferred_name")}
                                  placeholder="(if different from given name)"
                                  isInvalid={!!errors.user?.preferred_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.preferred_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerLastName">
                <Form.Label column md={4}>
                    Family name
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.family_name")}
                                  isInvalid={!!errors.user?.family_name}
                                  placeholder="Last name"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.family_name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerUsername">
                <Form.Label column md={4}>
                    Username
                </Form.Label>
                <Col md={8}>
                    <RegistrationUserName givenName={given_name ?? ""} familyName={family_name}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.username"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerGender">
                <Form.Label column md={4}>
                    Gender
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.gender_id", {valueAsNumber: true})}
                                  as="select"
                                  isInvalid={!!errors.user?.gender_id}>
                        <option key={-1} value={-1}>(Please select)</option>
                        {formControlOptions(genders)}
                    </Form.Control>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.gender_id"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerEMail">
                <Form.Label column md={4}>
                    E-mail
                    {(email_address_confirmed && !dirtyFields.user?.email_address) && (
                        <UserEMailAddressConfirmedSymbol className="ms-1"/>
                    )}
                </Form.Label>
                <Col md={8}>
                    <Form.Control type="email"
                                  {...register("user.email_address")}
                                  isInvalid={!!errors.user?.email_address}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.email_address"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPhone">
                <Form.Label column md={4}>
                    Phone
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.phone_num")}
                                  placeholder="Please include area code"
                                  isInvalid={!!errors.user?.phone_num}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPhoneNotes">
                <Form.Label column md={4}>
                    Phone notes
                </Form.Label>
                <Col md={8}>
                    <Form.Control {...register("user.phone_num_note")}
                                  as="textarea"
                                  maxLength={2048}
                                  isInvalid={!!errors.user?.phone_num_note}
                                  placeholder="Contact notes ..."/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="user.phone_num_note"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerPassword">
                <Form.Label column md={4}>
                    Password
                </Form.Label>
                <Col md={8}>
                    <Form.Control type="password"
                                  {...register("password.new_password")}
                                  isInvalid={!!errors.password?.new_password}
                                  autoComplete="new-password"
                                  autoCapitalize="off"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="password.new_password"
                                  type="invalid"/>
                    <PasswordStrengthBar password={watch("password.new_password")}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3"
                        controlId="registerConfirmPassword">
                <Form.Label column md={4}>
                    Confirm password
                </Form.Label>
                <Col md={8}>
                    <Form.Control type="password"
                                  {...register("password.confirm_password")}
                                  isInvalid={!!errors.password?.confirm_password}
                                  autoComplete="new-password"
                                  autoCapitalize="off"/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="password.confirm_password"
                                  type="invalid"/>
                </Col>
            </Form.Group>
            <DevTool control={control}/>
        </>
    );
};

export default RegistrationForm;
