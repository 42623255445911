import React, {useCallback} from "react";
import {useFormContext} from "react-hook-form";

import Card from "react-bootstrap/Card";

import GroupPicker from "../../components/GroupPicker";
import {BulkUploadForm} from "./UserBulkUpload";
import {ErrorMessage} from "@hookform/error-message";
import Form from "react-bootstrap/Form";

const UserBulkUploadGroupCard: React.FC = () => {
    const {register, formState: {errors}, watch, setValue, setError, clearErrors} = useFormContext<BulkUploadForm>();
    const setGroupError = useCallback(
        (message: string | null) => (message) ? setError("group", {type: "invalid", message}) : clearErrors("group"),
        [clearErrors, setError]
    );

    const group = watch("group");

    register("group");

    return (
        <Card>
            <Card.Header>
                Group assignment
            </Card.Header>
            <Card.Body>
                <p>
                Assign the uploaded users to this group
                </p>

                <GroupPicker onChange={(group) => setValue("group", group ?? null)}
                             selectedGroup={group ?? undefined}
                             fieldError={errors.group}
                             setErrorMessage={setGroupError}/>
                <ErrorMessage as={<Form.Control.Feedback/>}
                              name="group"
                              type="invalid"/>
            </Card.Body>
        </Card>
    );
};

export default UserBulkUploadGroupCard;