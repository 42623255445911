import {useNavigate} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PermissionDenied = () => {
    const navigate = useNavigate();

    return (
        <Container className='text-start w-75'>
            <h1 className='text-info pb-2'>Permission denied</h1>
            <Row>
                <Col className='text-end'>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default PermissionDenied;
