import React, {useEffect, useMemo, useState} from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import {
    useOptionsForPaymentQuery,
    usePaymentInitiateMutation
} from "../../api/chessMasterApi";
import PaymentOptionList from "./PaymentOptionList";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {Payment, PaymentItem} from "../../models/Payment";
import {useAppDispatch} from "../../store";
import CreateUserAccordion from "../../components/CreateUserAccordion";
import {newUser, NewUserWithRegistration} from "../../models/User";
import useSelection from "../../hooks/useSelection";

const NPlayersSelectedRemain: React.FC<{nSelected: number, nRemain: number}> = ({nSelected, nRemain}) => {
    const selectedMessage = `${nSelected} ${(nSelected === 1) ? "player" : "players"} selected`;
    const remainMessage = `${nRemain} ${(nRemain === 1) ? "entry remains" : "entries remain"}`;

    return (
        <h5 className="text-end">
            <Badge className="text-end" bg={(nRemain < 0) ? "danger" : "secondary"}>
                {selectedMessage}, {remainMessage}
            </Badge>
        </h5>
    );
};

interface Props {
    payment: Payment
    onClose: () => void
}

const EditProFormaPaymentModal: React.FC<Props> = ({payment, onClose}) => {
    const dispatch = useAppDispatch();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const {data: paymentItems} = useOptionsForPaymentQuery({id: payment.id});
    const userPrototype = useMemo<NewUserWithRegistration>(() => ({
        user: newUser({
            gender_id: -1,
            username: "-1",
            group_ids: [payment.group_payer.id]
        })}), [payment]);
    const [paymentInitiate] = usePaymentInitiateMutation();
    const paymentItemSelection = useSelection<PaymentItem["identifier"]>({
        allItems: (paymentItems ?? []).map((item) => item.identifier),
        selectedItems: payment.items.map((item) => item.identifier),
    });
    const submitChanges = () => {
        const selectedItems = (paymentItems ?? []).filter((pi) => paymentItemSelection.selectedSet.has(pi.identifier));
        const proFormaPayment = {
            id: payment.id,
            items: selectedItems.map((pi) => pi.identifier)
        };
        paymentInitiate(proFormaPayment)
            .unwrap()
            .then(() => {
                const nPlayers = proFormaPayment.items.length;

                dispatch(addToast(newToastSuccess({
                    heading: "Pro forma payment successful",
                    body: `Entered ${nPlayers} ${(nPlayers === 1) ? "player" : "players"} in "${payment.tournament.name}"`
                })));
            })
            .then(onClose);
    };
    useEffect(() => {
        (paymentItems) && paymentItemSelection.setAllItems({items: paymentItems.map((item) => item.identifier)})
    }, [paymentItems, paymentItemSelection]);

    return (
        <Modal show
               size="lg"
               onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Group entries for “{payment.group_payer.name}” in tournament “{payment.tournament.name}”
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(payment.max_items !== undefined && payment.max_items !== null) && (
                    <NPlayersSelectedRemain nSelected={paymentItemSelection.selectedSet.size}
                                            nRemain={payment.max_items - paymentItemSelection.selectedSet.size}/>
                )}
                <PaymentOptionList paymentItems={paymentItems ?? []}
                                   paymentItemSelection={paymentItemSelection}/>
            </Modal.Body>

            <Modal.Body>
                <CreateUserAccordion userPrototype={userPrototype}
                                     setIsOpen={setIsAccordionOpen}>
                    Create user
                </CreateUserAccordion>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose}
                        disabled={isAccordionOpen}
                        variant="secondary">
                    Cancel
                </Button>
                <Button onClick={submitChanges}
                        disabled={isAccordionOpen}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );};

export default EditProFormaPaymentModal;
