
export type ServerFieldErrors = {
    [name: string]: string[]
}

export interface ServerUpdateRejection {
    code: number
    errors: {
        json: ServerFieldErrors
    }
    status: string
    message: string
}

export type JWTToken = string

export interface LoginRequest {
    username: string
    password: string
}

export interface LoginResponse {
    access_token: string
    message: string
}

export interface LogoutResponse {
    message: string
}

export interface TokenRefreshResponse {
    access_token: string,
    message: string
    refresh_interval: number    // seconds
}

export const GameServerSocketIOURL = "ws://127.0.0.1:3001";
