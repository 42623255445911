import React from "react";
import Badge from "react-bootstrap/Badge";
import {formatMoney} from "accounting";

// a symbol that is displayed when the player has paid for the service

interface Props {
    label?: string
    amount?: number | string | null
    className?: string
}

const PaidSymbol: React.FC<Props> = ({label= "Paid", amount, className}) => {
    const displayLabel = (amount !== undefined && amount !== null) ? formatMoney(amount, "$") : label;

    return (
        <h6 className={className}>
            <Badge className="float-end" bg="success">
                {displayLabel}
            </Badge>
        </h6>
    );
};

export default PaidSymbol;
