import React, {useState} from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import ActiveTournamentTeamsEditor from "./ActiveTournamentTeamsEditor";
import ActiveTournamentTeamsPlayers from "./ActiveTournamentTeamsPlayers";

import {GroupMinSchema} from "../../models/Group";

const ActiveTournamentTeams: React.FC = () => {
    const [selectedTeam, setSelectedTeam] = useState<GroupMinSchema | undefined>(undefined);

    return (
        <Container fluid className="p-0">
            <Row className="mt-2">
                <Col className="p-0">
                    <ActiveTournamentTeamsPlayers selectedTeam={selectedTeam}/>
                </Col>
                <Col className="p-0">
                    <ActiveTournamentTeamsEditor selectedTeam={selectedTeam}
                                                 setSelectedTeam={setSelectedTeam}/>
                </Col>
            </Row>
        </Container>
    );
};

export default ActiveTournamentTeams;
