import {Location} from "./Location";

export interface NewGroup {
    name: string,
    description: string,
    url: string
    group_type_id: number
    address_id: number | null
    address: Location | null
    postal_address_id: number | null
    postal_address: Location | null
    user_ids: number[]
}

export interface Group extends NewGroup {
    id: number
}

export interface GroupMinSchema {
    id: number
    name: string
}

export const newGroup = (initFields: Partial<Group> = {}): NewGroup => ({
    name: "",
    description: "",
    url: "",
    group_type_id: 0,
    address_id: null,
    address: null,
    postal_address_id: null,
    postal_address: null,
    user_ids: [],
    ...initFields
});

export const isGroup = (obj: any): obj is Group => {
    return obj.id !== undefined
};