import React, {useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

import {useUpdateUserMutation} from "../../api/chessMasterApi";
import {UserRegistration, userRegistrationSchema} from "../../components/UserForm";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import {newUser, User} from "../../models/User";
import {newPasswordForm} from "../../util/passwordSchema";
import {useAppDispatch, useAppSelector} from "../../store";
import UserEditDetails from "../userTab/UserEditDetails";
import {selectConstantsGenderByDescription} from "../constants/constantsSlice";
import {GroupMinSchema} from "../../models/Group";

interface Props {
    group: GroupMinSchema
    onClose?: (user: User) => void
}

const GroupMembershipCreateUser: React.FC<Props> = ({group, onClose}) => {
    const dispatch = useAppDispatch();
    const gendersByDescription = useAppSelector(selectConstantsGenderByDescription);
    const [updateUser] = useUpdateUserMutation();
    const defaultUser = useMemo(() => newUser({
        gender_id: gendersByDescription["Unspecified"].id ?? 4,
        username: "",
        group_ids: [group.id],
    }), [gendersByDescription, group]);
    const formMethods = useForm<UserRegistration>({
        defaultValues: {user: defaultUser, password: newPasswordForm()},
        resolver: yupResolver(userRegistrationSchema())
    });
    const onSubmit = ({user, password}: UserRegistration) => {
        user.new_password = password.new_password;
        updateUser({user})
            .unwrap()
            .then((user) => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: "User created",
                    body: `Created user ${user.username} in group ${group.name}`
                })));
                return user;
            })
            .then((user) => {
                onClose?.(user);
            })
            .then(() => {
                formMethods.reset({user: defaultUser, userGroups: []});
            })
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch,"user");
            });
    };

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Card>
                    <Card.Header>User details</Card.Header>
                    <Card.Body>
                        <UserEditDetails user={undefined}/>
                    </Card.Body>

                    <Card.Footer>
                        <ButtonToolbar className="float-end">
                            <ButtonGroup className="me-1">
                                <Button variant="outline-secondary"
                                        onClick={() => formMethods.reset({user: defaultUser})}>
                                    Reset
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={!formMethods.formState.isDirty}>
                                    Create
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Card.Footer>
                </Card>
            </Form>
        </FormProvider>
    );
};

export default GroupMembershipCreateUser;
