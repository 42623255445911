import React, {useCallback, useMemo, useState} from "react";

/*
 * This is a custom hook for running modal windows. The first argument is a React
 * component which displays a modal. The component must have an "onClose" prop that
 * takes a didUpdate argument which is called when the modal will close. The second
 * optional argument is a function to be called when the modal closes. It has a
 * single didUpdate argument so that clean up or whatever can happen.
 *
 * The hook returns an array: [component, {run, isRunning}]. The run function takes
 * a single argument which is the props to pass to the component. When the component is
 * showing, the component will contain the modal to show otherwise it will be null.
 * The isRunning flag is also set to true when the modal is visible.
 *
 * Usage:
 *
 * const MyModal = ({name: string, onClose: (didUpdate: boolean) => void}) => {
 *      return (
 *          <Modal show onHide={onClose}>
 *              <Modal.Header closeButton>
 *                  My name is {name}
 *              </Modal.Header>
 *          </Modal>
 *       );
 *  };
 *
 *     ...
 *     const [myModal, {run: runMyModal}] = useRunModal(MyModal);
 *
 *     {myModal}
 *     ...
 *     <Button onClick={() => runMyModal({name: "Ben"})>Run modal</Button>
 */

type OnCloseType = (didUpdate: boolean) => void

function useRunModal<T extends {onClose: OnCloseType}>(Component: React.FC<T>, onClose?: OnCloseType) {
    const [isShowingProps, setIsShowingProps] = useState<Omit<T, "onClose"> | undefined>(undefined);
    const close = useCallback((didUpdate: boolean) => {
        onClose?.(didUpdate);
        setIsShowingProps(undefined);
    }, [onClose]);
    const component = useMemo(() => (
        (isShowingProps) ? <Component {...{...isShowingProps, onClose: close} as T}/> : null
    ),
    [Component, close, isShowingProps]);
    const handlers = useMemo(
        () => ({
            run: (props: Omit<T, "onClose">) => {
                setIsShowingProps(props);
            },
            isRunning: !!isShowingProps
        }),
        [isShowingProps]
    );

    return [component, handlers] as const;
}

export type UseRunModal<T extends {onClose: OnCloseType}> = ReturnType<typeof useRunModal<T>>

export default useRunModal;