import React, {useContext, useMemo} from "react";
import {useDispatch} from "react-redux";
import DataTable, {TableColumn} from "react-data-table-component";

import Button from "react-bootstrap/Button";

import {sortTournamentPlayersByPoints, TournamentPlayer} from "../../models/TournamentPlayer";
import {setActiveTournamentPlayerSummary} from "./activeTournamentSlice";
import {nameSortFunc} from "../../util/nameSortFunc";
import {formatFullName} from "../../util/formatFullName";
import {CrossTableResultCell} from "./CrossTableResultCell";
import {classesForCrossTableResult, crossTableResultsForRounds} from "../../util/crossTableResults";
import {ActiveTournamentContext} from "./ActiveTournament";
import UserLichessOnlineSymbol from "../../components/UserLichessOnlineSymbol";

export const createPlayerIdToIndexInStandings = (players: TournamentPlayer[]) => (
    Object.fromEntries(sortTournamentPlayersByPoints(players).map((player, index) => [player.user_id, index + 1]))
);

interface TournamentPlayerWithStandingIndex extends TournamentPlayer {
    standingIndex: number
}

export const createTournamentPlayersWithStandingIndex = (players: TournamentPlayer[]): TournamentPlayerWithStandingIndex[] => {
    const playerIdToStandingIndex = createPlayerIdToIndexInStandings(players);

    return players.map((player) => ({
        ...player,
        standingIndex: playerIdToStandingIndex[player.user_id]
    }))
};

const ActiveTournamentCrossTable: React.FC = () => {
    const dispatch = useDispatch();
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const playerIdToStandingIndex = createPlayerIdToIndexInStandings(tournament.players);
    const tournamentPlayersWithStandingIndex = useMemo(
        () => createTournamentPlayersWithStandingIndex(tournament.players.filter((player) => player.participating)),
        [tournament.players]
    );
    const crossTableResults = useMemo(
        () => crossTableResultsForRounds(tournament.rounds),
        [tournament.rounds]
    );

    const columns: TableColumn<TournamentPlayerWithStandingIndex>[] = [{
        selector: (row) => row.standingIndex,
        name: "#",
        minWidth: '60px',
        right: true,
        sortable: true
    }, {
        selector: (row) => row.points,
        name: "Score",
        minWidth: '80px',
        right: true,
        sortable: true
    }, {
        selector: (row) => formatFullName(tournament.ref_user[row.user_id]),
        name: "Player",
        sortable: true,
        grow: 20,
        sortFunction: (tp1, tp2) => nameSortFunc(tournament.ref_user[tp1.user_id],
                                                         tournament.ref_user[tp2.user_id]),
        format: (row) => (
            <>
                <Button variant="link"
                        className="p-0"
                        onClick={() => dispatch(setActiveTournamentPlayerSummary({
                            player: tournament.ref_user[row.user_id],
                            tournament
                        }))}>
                    {formatFullName(tournament.ref_user[row.user_id])}
                </Button>
                {(onlinePlayerIds.has(row.user_id)) && (
                    <UserLichessOnlineSymbol/>
                )}
            </>
        )
    }, ...tournament.rounds.map((round) => ({
        name: `R${round.round_num}`,
        minWidth: '60px',
        button: true,
        cell: (row: TournamentPlayer) => {
            const result = crossTableResults[round.round_num][row.user_id];
            const classNames = result && classesForCrossTableResult(result, result.colour);

            // XXX I really want the classNames on the table cell, not the component
            if (result) {
                return (
                    <CrossTableResultCell playerId={result.opponentId}
                                          tournament={tournament}
                                          result={result}
                                          playerIdToIndexInStandings={playerIdToStandingIndex}
                                          className={classNames}/>
                );
            } else {
                return <div/>;
            }
        }
    }))];

    return (
        <DataTable<TournamentPlayerWithStandingIndex> data={tournamentPlayersWithStandingIndex}
                                                      columns={columns}
                                                      keyField="id"
                                                      defaultSortFieldId={2}
                                                      defaultSortAsc={false}
                                                      noDataComponent="(No players)"/>
    );
};

export default ActiveTournamentCrossTable;
