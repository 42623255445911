import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from "../../store";

interface GameTabState {
    showCreateGameModal: boolean
}

const initialState: GameTabState = {
    showCreateGameModal: false,
};

export const slice = createSlice({
    name: 'gameTab',
    initialState,
    reducers: {
        setGameTabShowCreateGameModal: (state, action: PayloadAction<boolean>) => {
            state.showCreateGameModal = action.payload;
        }
    }
});

export const selectGameTab = (state: RootState) => state.gameTab;
export const {
    setGameTabShowCreateGameModal
} = slice.actions;

export default slice.reducer;
