import * as yup from "yup";

import {Tournament} from "./Tournament";
import {Money} from "./types";

export interface TournamentExpense {
    id: number
    tournament_id: Tournament["id"]
    description: string
    amount: Money
}

export const tournamentExpenseSchema = yup.object().shape({
    description: yup.string().required().label("Description"),
    amount: yup.number().typeError("Please enter a number").required().label("Amount")
});