import React, {useContext, useMemo, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {ChildWithGuardian, useChildrenForUserQuery} from "../../api/chessMasterApi";
import {ActiveTournamentContext} from "./ActiveTournament";
import UsernameAndGroups from "../../components/UsernameAndGroups";
import AddPlayerToTournamentButton from "./AddPlayerToTournamentButton";
import CreateUserAccordion from "../../components/CreateUserAccordion";
import {selectLogin} from "../login/loginSlice";
import {formatFullName} from "../../util/formatFullName";
import {nameSortFunc} from "../../util/nameSortFunc";
import {newUser, NewUserWithRegistration} from "../../models/User";
import {useAppSelector} from "../../store";

interface Props {
    onClose: () => void;
}

const ActiveTournamentChildrenAdd: React.FC<Props> = ({onClose}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const {userId} = useAppSelector(selectLogin);
    const {data: children, isFetching} = useChildrenForUserQuery({id: userId});
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const userPrototype = useMemo<NewUserWithRegistration>(() => ({
            user: newUser({gender_id: -1}),
            guardian_id: userId,
            tournament_key: tournament.key
        }),
        [tournament, userId]);
    const tournamentPlayerIds = useMemo(
        () => new Set((tournament.players).map((tp) => tp.user_id)),
        [tournament.players]
    );
    const columns: TableColumn<ChildWithGuardian>[] = [{
        name: "Name",
        selector: (row) => formatFullName(row.child),
        sortable: true,
        sortFunction: (a, b) => nameSortFunc(a.child, b.child),
        cell: (row) => <UsernameAndGroups userInfo={row.child}/>
    }, {
        name: "Action",
        button: true,
        cell: (row) => (
            <AddPlayerToTournamentButton user={row.child}
                                         isAdded={tournamentPlayerIds.has(row.child.id)}
                                         tournament={tournament}/>
        )
    }];

    return (
        <Modal show
               size="lg"
               backdrop="static"
               onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Enter children in “{tournament.name}”
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DataTable<ChildWithGuardian> data={children ?? []}
                                              keyField="id"
                                              columns={columns}
                                              noDataComponent="(No children)"
                                              progressPending={isFetching}/>
            </Modal.Body>

            <Modal.Body>
                <CreateUserAccordion userPrototype={userPrototype}
                                     setIsOpen={setIsAccordionOpen}>
                    Register new child
                </CreateUserAccordion>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => onClose()}
                        disabled={isAccordionOpen}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveTournamentChildrenAdd;

