import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useLocalStorageState from 'use-local-storage-state'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {useSearchTournamentsQuery} from "../../api/chessMasterApi";
import TournamentSearch from "./TournamentSearch";
import TournamentList from "./TournamentList";
import {editTournament} from "./tournamentTabSlice";
import {newTournament} from "../../models/Tournament";
import {selectLogin} from "../login/loginSlice";
import {TournamentStatusDescription} from "../../models/TournamentStatus";
import {TournamentType} from "../../models/TournamentType";
import {useLocalStorageSearchParams} from "../../hooks/useLocalStorageSearchParams";
import {useAppSelector} from "../../store";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";

const TournamentTab = () => {
    const dispatch = useDispatch();
    const {privs} = useAppSelector(selectLogin);
    const [query, setQuery] = useLocalStorageState("tournamentTab/query", {defaultValue: ""});
    const [status, setStatus] = useLocalStorageState<TournamentStatusDescription | "">("tournamentTab/status", {defaultValue: ""});
    const [typeId, setTypeId] = useLocalStorageState<TournamentType["id"] | -1>("tournamentTab/typeId", {defaultValue: -1});
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const params = {
        query: {value: query, setter: setQuery, isEmpty: (v: string) => !v}
    };
    const [values, setters] = useLocalStorageSearchParams(params);
    const {data: tournamentList, error} = useSearchTournamentsQuery({query, status, typeId});
    const tournamentDefaults = {
        duration: 0,
        tournament_type_id: -1
    };

    useEffect(
        () => setSearchErrorMessage((error) ? formatErrorMessage(error) : null),
        [error]
    );

    return (
        <Container fluid className="m-0">
            <Row className="my-3 g-0">
                <Col>
                    <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                        <TournamentSearch query={values.query} setQuery={setters.setQuery}/>
                    </SearchErrorMessageContext.Provider>
                </Col>
                {(privs.admin || privs.tourn_admin) && (
                    <Col sm="auto" className="float-end ms-3">
                        <Button onClick={() => dispatch(editTournament(newTournament(tournamentDefaults)))}>
                            New tournament
                        </Button>
                    </Col>
                )}
            </Row>
            <Row className="g-0 ovfix">
                <TournamentList tournaments={tournamentList ?? []}
                                filterStatus={status}
                                setFilterStatus={setStatus}
                                filterTypeId={typeId}
                                setFilterTypeId={setTypeId}/>
            </Row>
        </Container>
    );
};

export default TournamentTab;