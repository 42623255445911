import React from "react";

import {Location} from "../models/Location";
import styles from "./DisplayAddress.module.css";

interface Props {
    address: Location | null | undefined
}

export const DisplayAddress: React.FC<Props> = ({address}) => {
    if (!address) {
        return <div/>;
    }
    return (
        <div>
            <div className={styles.address}>
                {address.address1}
            </div>
            <div className={styles.address}>
                {address.address2}
            </div>
            <div>
                <span className={styles.locality}>{address.locality}</span>{" "}
                <span className={styles.state}>{address.state}</span>{" "}
                <span className={styles.postcode}>{address.postcode}</span>
            </div>
            {(address.country) && <div className={styles.country}>{address.country}</div>}
        </div>
    );
};