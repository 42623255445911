import React, {useEffect} from "react";
import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";
import {useAppDispatch} from "../store";
import {addToast, newToastError} from "../features/toast/toastSlice";

const ErrorBoundary: React.FC = () => {
    const error = useRouteError();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    if (isRouteErrorResponse(error)) {
        dispatch(addToast(newToastError({
            heading: error.statusText,
            body: error.data ?? "(unknown error)"
        })));
    } else {
        dispatch(addToast(newToastError({
            heading: "Routing error",
            body: `Unknown error: ${JSON.stringify(error)}`
        })));
    }

    useEffect(() => {
        navigate("/tournaments");
    }, [navigate]);

    return <>Error: {JSON.stringify(error)}</>;
};

export default ErrorBoundary;