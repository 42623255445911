import React, {useContext} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import {ActiveTournamentContext} from "./ActiveTournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import UsernameAndTeam from "../../components/UsernameAndTeam";

export const ActiveTournamentTeamStandingsPlayersHeading: React.FC = () => {
    const columns: TableColumn<[string, string]>[] = [{
        selector: (row) => row[0],
        center: true,
        grow: 0
    }, {
        selector: (row) => row[1]
    }];

    return <DataTable<[string, string]> columns={columns}
                                        data={[['Score', 'Player']]}
                                        noTableHead/>
};

export const ActiveTournamentTeamStandingsPlayersTable: React.FC<{players: TournamentPlayer[]}> = ({players}) => {
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const columns: TableColumn<TournamentPlayer>[] = [{
        selector: (row) => row.points,
        center: true,
        grow: 0
    }, {
        cell: (row) => (
            <UsernameAndTeam tournament={tournament}
                             player={row}
                             online={onlinePlayerIds.has(row.user_id)}
                             omitTeam
                             omitScore/>
        )
    }];

    return <DataTable<TournamentPlayer> columns={columns}
                                        data={players}
                                        noTableHead/>
};