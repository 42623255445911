import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

import Login from "./Login";
import {selectLogin, setIsRegistering} from "./loginSlice";
import ParentRegistrationForm from "./ParentRegistrationForm";
import {newUser} from "../../models/User";
import RequestPasswordReset from "./RequestPasswordReset";
import {useAppSelector} from "../../store";


const LoginOrRegister: React.FC = () => {
    const dispatch = useDispatch();
    const {isRegistering, isRequestingPasswordReset} = useAppSelector(selectLogin);
    const closeRegistrationForm = useCallback(() => dispatch(setIsRegistering(false)), [dispatch]);

    if (isRegistering) {
        return (
            <ParentRegistrationForm parent={newUser({gender_id: -1, username: "-1"})}
                                    onClose={closeRegistrationForm}/>
        );
    } else if (isRequestingPasswordReset) {
        return (
            <RequestPasswordReset/>
        );
    } else{
        return (
            <Login/>
        );
    }
};

export default LoginOrRegister;
