import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {Tournament, TournamentLite} from "../../models/Tournament";
import {useAbandonTournamentMutation} from "../../api/chessMasterApi";

interface Props {
    tournament: Tournament | TournamentLite
    onClose: () => void
}

const TournamentAbandon: React.FC<Props> = ({tournament, onClose}) => {
    const [abandonTournament, {isLoading: isUpdating}] = useAbandonTournamentMutation();

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               size="lg">
            <Modal.Header>
                <Modal.Title>Cancel tournament &mdash; {tournament.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Do you want to cancel tournament “{tournament.name}”?</p>
                <p className="font-italic mx-5">
                    {tournament.description}
                </p>
            </Modal.Body>

            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup className="me-1">
                        <Button variant="secondary"
                                onClick={onClose}
                                disabled={isUpdating}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button type="submit"
                                variant="primary"
                                onClick={() => abandonTournament({key: tournament.key})
                                    .unwrap()
                                    .then(onClose)
                                }
                                disabled={isUpdating}>
                            Abandon
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default TournamentAbandon;