import React, {useCallback} from "react";

import DataTable, {TableColumn} from "react-data-table-component";
import {formatMoney} from "accounting";

import {InterimPaymentItem, Payment} from "../../models/Payment";

interface InterimPaymentListProps {
    interimPayment?: Payment
    setSelectedPayments: (items: InterimPaymentItem[]) => void
}

const InterimPaymentList: React.FC<InterimPaymentListProps> = ({interimPayment, setSelectedPayments}) => {
    const handleSelectedPayments = useCallback(
        ({selectedRows}: {selectedRows: InterimPaymentItem[]}) => setSelectedPayments(selectedRows),
        [setSelectedPayments]
    );
    const columns: TableColumn<InterimPaymentItem>[] = [{
        selector: (row) => row.description,
        name: "Description",
        sortable: true,
        grow: 8
    }, {
        selector: (row) => row.amount,
        name: "Amount",
        right: true,
        sortable: true,
        format: (row) => formatMoney(row.amount, "$")
    }];

    return (
        <DataTable<InterimPaymentItem> data={interimPayment?.items ?? []}
                                       columns={columns}
                                       keyField="identifier"
                                       selectableRows
                                       onSelectedRowsChange={handleSelectedPayments}
                                       noDataComponent="(No payments outstanding)"/>
    );
};

export default InterimPaymentList;
