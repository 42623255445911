import React, {PropsWithChildren, useState} from "react";

import Accordion from "react-bootstrap/Accordion";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

import CreateUser from "./CreateUser";
import {NewUserWithRegistration} from "../models/User";

interface Props {
    userPrototype: NewUserWithRegistration
    setIsOpen?: (isOpen: boolean) => void
}

const CreateUserAccordion: React.FC<PropsWithChildren<Props>> = ({userPrototype, setIsOpen, children}) => {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>(null);

    return (
        <Accordion activeKey={activeKey}
                   onSelect={(eventKey) => {
                       setActiveKey(eventKey);
                       setIsOpen?.(eventKey === "create-user");
                   }}>
            <Accordion.Item eventKey="create-user">
                <Accordion.Header>{children}</Accordion.Header>
                <Accordion.Body>
                    <CreateUser user={userPrototype}
                                onClose={() => {
                                    setActiveKey(null);
                                    setIsOpen?.(false);
                                }}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default CreateUserAccordion;
