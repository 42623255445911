import React, {useCallback} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {useFormContext} from "react-hook-form";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import GroupPicker from "../../components/GroupPicker";
import TournamentPicker from "../../components/TournamentPicker";
import {ProFormaPaymentForm} from "./FinanceProFormaPaymentModal";
import {Payment} from "../../models/Payment";

interface Props {
    payment?: Payment
}

const FinanceProFormaPayment: React.FC<Props> = ({payment}) => {
    const {watch, setValue, register, formState: {errors}, setError, clearErrors} = useFormContext<ProFormaPaymentForm>();
    const setGroupPayerError = useCallback(
        (message: string | null) => (message) ? setError("groupPayer", {type: "invalid", message}) : clearErrors("groupPayer"),
        [clearErrors, setError]
    );
    const tournament = watch("tournament");
    const groupPayer = watch("groupPayer");

    return (
        <Container>
            <Form.Group as={Row} className="mb-2" controlId="proFormaTournament">
                <Form.Label column md={2}>
                    Tournament
                </Form.Label>
                <Col md={10}>
                    {(payment) ? (
                        <Form.Control readOnly
                                      disabled
                                      value={tournament?.name ?? ""}/>
                    ) : (
                        <>
                            <TournamentPicker value={tournament}
                                              onChange={(ev) => {
                                                  setValue("tournament", ev.target.value);
                                                  clearErrors("tournament");
                                              }}
                                              placeholder={(tournament) ? tournament.name : "(Please select a tournament/class)"}
                                              isInvalid={!!errors.tournament}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament"
                                          type="invalid"/>
                        </>
                    )}
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="proFormaAmount">
                <Form.Label column md={2}>
                    Group
                </Form.Label>
                <Col md={10}>
                    <GroupPicker onChange={(group) => setValue("groupPayer", group)}
                                 placeholder={groupPayer?.name ?? "(Please select a group)"}
                                 fieldError={errors.groupPayer}
                                 setErrorMessage={setGroupPayerError}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="groupPayer"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="proFormaAmount">
                <Form.Label column md={2}>
                    Max players
                </Form.Label>
                <Col md={4}>
                    <Form.Control {...register("max_items")}
                                  type="number"
                                  min={0}
                                  step={1}
                                  isInvalid={!!errors.max_items}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="max_items"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={2}>
                    Team fee
                </Form.Label>
                <Col md={4}>
                    <Form.Control {...register("amount")}
                                  type="number"
                                  className="text-end"
                                  min={0}
                                  step={0.01}
                                  isInvalid={!!errors.amount}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="amount"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2" md={2} controlId="proFormaReference">
                <Form.Label column md={2}>
                    Reference
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("reference")}
                                  isInvalid={!!errors.reference}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="reference"
                                  type="invalid"/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default FinanceProFormaPayment;
