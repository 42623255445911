import React, {PropsWithChildren} from "react";
import {Navigate} from "react-router-dom";

import {selectLogin} from "../features/login/loginSlice";
import {Role} from "../models/Role";
import {selectConstantsRoleTypeByName} from "../features/constants/constantsSlice";
import {useAppSelector} from "../store";

interface Props {
    role: Role["name"] | Role["name"][]
    not: boolean
}

const RequireRole: React.FC<PropsWithChildren<Props>> = ({role, not, children}) => {
    const {role_ids} = useAppSelector(selectLogin);
    const rolesByName = useAppSelector(selectConstantsRoleTypeByName);
    const requestedRoles = (typeof role === "string") ? [role] : role;
    const requestedRoleIds = requestedRoles.map((role) => rolesByName[role]?.id);
    const hasRequestedRole = (roleIds: Role["id"][], requestedRoleIds: Role["id"][]) => (
        requestedRoleIds.some((roleId) => roleIds.includes(roleId))
    );

    if (children === undefined) {
        console.error('RequireRole: children is undefined');
    } else if (requestedRoles.length === 0) {
        console.error('RequireRole: no privileges requested');
    } else if (not && !hasRequestedRole(role_ids, requestedRoleIds)) {
        return <>{children}</>;
    } else if (!not && hasRequestedRole(role_ids, requestedRoleIds)) {
        return <>{children}</>;
    }
    return <Navigate to="/PermissionDenied"  />
};

export default RequireRole;