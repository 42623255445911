import React from "react";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import {useRequestPasswordResetMutation} from "../../api/chessMasterApi";
import {setIsRequestingPasswordReset} from "./loginSlice";
import {addToast, newToastMessage} from "../toast/toastSlice";
import styles from "./Login.module.css";
import {ErrorMessage} from "@hookform/error-message";

const requestPasswordResetSchema = yup.object().shape({
    eMail: yup.string().required().email().label("E-mail address"),
});

const RequestPasswordReset: React.FC = () => {
    const dispatch = useDispatch();
    const [requestPasswordReset, {isLoading}] = useRequestPasswordResetMutation();
    const defaultValues = {eMail: ""};
    const onSubmit = ({eMail}: {eMail: string}) => {
        requestPasswordReset({email_address: eMail})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastMessage({
                    heading: "Password reset",
                    body: "If your e-mail address is known, " +
                          "you should shortly receive a message " +
                          "inviting you to reset your password.",
                    priority: "success"
                })));
                dispatch(setIsRequestingPasswordReset(false));
            })
            .catch((error) => {
                dispatch(addToast(newToastMessage({
                    heading: "Password reset",
                    body: `Error requesting password reset: ${error.data.message || error.data.msg}`,
                    priority: "danger"
                })));
            });
    };
    const {register, handleSubmit, formState: {errors}} = useForm< {eMail: string}>({
        defaultValues,
        resolver: yupResolver(requestPasswordResetSchema)
    });
    const handleKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    return (
        <Modal show={true}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop="static">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Request password reset
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="login-form">
                        <Form.Group as={Row} controlId="resetEMail">
                            <Form.Label column md={4}
                                        className={styles['username-title']}>E-mail address</Form.Label>
                            <Col md={8}>
                                <Form.Control {...register("eMail")}
                                              placeholder="E-mail address"
                                              autoComplete="email"
                                              onKeyDown={handleKeyPress}
                                              isInvalid={!!errors.eMail}
                                              autoCapitalize="off"/>
                                <ErrorMessage as={<Form.Control.Feedback/>}
                                              name="eMail"
                                              type="invalid"/>
                            </Col>
                        </Form.Group>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonToolbar className="float-right">
                        <ButtonGroup className="me-1">
                            <Button variant="secondary"
                                    onClick={() => dispatch(setIsRequestingPasswordReset(false))}
                                    disabled={isLoading}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button type="submit"
                                    variant="primary"
                                    disabled={isLoading}>
                                Request password reset
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RequestPasswordReset;
