import React, {ChangeEvent, useCallback} from "react";

import Form from "react-bootstrap/Form";

import {selectConstantsPaymentSystems} from "../constants/constantsSlice";
import {formControlOptionsFromStrings} from "../../util/formControl";
import {PaymentSystem} from "../../models/Payment";
import {useAppSelector} from "../../store";

interface Props {
    system?: PaymentSystem
    onChangeSystem: (system: PaymentSystem | undefined) => void;
}

const PaymentSystemSelect: React.FC<Props> = ({system, onChangeSystem}) => {
    const paymentSystems = useAppSelector(selectConstantsPaymentSystems);
    const changeSystem = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            const system = (ev.target.value === "") ? undefined : ev.target.value as PaymentSystem;

            onChangeSystem(system);
        },
        [onChangeSystem]
    );

    return (
        <Form.Control as="select"
                      value={system ?? ""}
                      size="sm"
                      onChange={changeSystem}>
            <option key="" value="">(All systems)</option>
            {formControlOptionsFromStrings(paymentSystems)}
        </Form.Control>
    );
};

export default PaymentSystemSelect;