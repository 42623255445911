import React, {useCallback, useContext, useState} from "react";

import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import {
    useDownloadTournamentFinancialsCSVMutation,
    useFinancialsForTournamentQuery
} from "../../api/chessMasterApi";
import ActiveTournamentFinancialsList from "./ActiveTournamentFinancialsList";
import ActiveTournamentGroupFinancials from "./ActiveTournamentGroupFinancials";
import ActiveTournamentFinancialSummary from "./ActiveTournamentFinancialSummary";
import {ActiveTournamentContext} from "./ActiveTournament";
import ActiveTournamentFinancialsExpensesEditModal from "./ActiveTournamentFinancialsExpensesEditModal";
import ActiveTournamentFinancialExpenses from "./ActiveTournamentFinancialExpenses";
import {Tournament} from "../../models/Tournament";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useAppDispatch} from "../../store";

const ActiveTournamentFinancials: React.FC = () => {
    const dispatch = useAppDispatch();
    const {tournament} = useContext(ActiveTournamentContext);
    const [runEditExpenses, setRunEditExpenses] = useState<Tournament | undefined>(undefined);
    const closeEditExpenses = useCallback(() => setRunEditExpenses(undefined), []);
    const {data: financials, isLoading} = useFinancialsForTournamentQuery({key: tournament.key});
    const [downloadTournamentFinancialsCSV] = useDownloadTournamentFinancialsCSVMutation();
    const runSaveFinancialsCSV = (tournament: Tournament) => {
        downloadTournamentFinancialsCSV({key: tournament.key})
            .unwrap()
            .then(({filename}) => {
                dispatch(addToast(newToastSuccess({
                    heading: "CSV download complete",
                    body: `CSV file downloaded to "${filename}"`
                })))
            });
    };

    return (
        <>
            {(runEditExpenses) && (
                <ActiveTournamentFinancialsExpensesEditModal tournament={runEditExpenses}
                                                             onClose={closeEditExpenses}/>
            )}
            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>
                        Summary
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    {(financials) ? (
                        <ActiveTournamentFinancialSummary financials={financials}/>
                    ) : (
                        <Spinner className="text-center" animation="border"/>
                    )}
                </Card.Body>
            </Card>

            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>Expenses</Col>
                            <Col className="ms-auto text-end">
                                <Button variant="secondary"
                                        size="sm"
                                        onClick={() => setRunEditExpenses(tournament)}>
                                    Edit ...
                                </Button>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <ActiveTournamentFinancialExpenses financials={financials}
                                                       isLoading={isLoading}/>
                </Card.Body>
            </Card>

            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>
                        Group entries
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <ActiveTournamentGroupFinancials financials={financials}
                                                     isLoading={isLoading}/>
                </Card.Body>
            </Card>

            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Players
                            </Col>
                            <Col className="ms-auto text-end">
                                <Button variant="secondary"
                                        size="sm"
                                        onClick={() => runSaveFinancialsCSV(tournament)}>
                                    Save CSV ...
                                </Button>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <ActiveTournamentFinancialsList financials={financials}
                                                    isLoading={isLoading}/>
                </Card.Body>
            </Card>
        </>
    );
};

export default ActiveTournamentFinancials;
