import React from "react";
import {useNavigate} from "react-router-dom";

import DataTable, {TableColumn, ConditionalStyles} from "react-data-table-component";

import Form from "react-bootstrap/Form";

import {Tournament, TournamentLite} from "../../models/Tournament";
import {addActiveTournament} from "../activeTab/activeTabSlice";
import {tournamentTabEventKeyFromTournament} from "../../util/tabEventKey";
import TournamentName from "../../components/TournamentName";
import {TournamentStatusAction} from "./TournamentStatusAction";
import TournamentStatus from "./TournamentStatus";
import {TournamentStatusDescription} from "../../models/TournamentStatus";
import TournamentStatusSelect from "../../components/TournamentStatusSelect";
import TournamentTypeSelect from "../../components/TournamentTypeSelect";
import {formatSecondsToReadable} from "../../util/formatSecondsToReadable";
import TournamentType from "./TournamentType";
import {selectConstantsNamedRoleInRoleIds} from "../constants/constantsSlice";
import {selectLogin} from "../login/loginSlice";
import DisplayAsTextBlock from "../../components/DisplayAsTextBlock";
import {useAppSelector, useAppDispatch} from "../../store";

interface Props {
    tournaments: TournamentLite[]
    filterStatus: TournamentStatusDescription | ""
    setFilterStatus: (status: TournamentStatusDescription | "") => void
    filterTypeId: Tournament["tournament_type_id"] | -1
    setFilterTypeId: (typeId: Tournament["tournament_type_id"] | -1) => void
}

const TournamentList: React.FC<Props> = ({tournaments, setFilterTypeId, filterTypeId, setFilterStatus, filterStatus}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {role_ids} = useAppSelector(selectLogin);
    const isChild = useAppSelector(selectConstantsNamedRoleInRoleIds("Child/Student", role_ids));
    const rowClicked = (tournament: TournamentLite) => {
        dispatch(addActiveTournament(tournament));
        navigate(`/${tournamentTabEventKeyFromTournament(tournament.key)}`);
    };
    const conditionalRowStyles: ConditionalStyles<TournamentLite>[] = [{
        when: (row) => (!!row.participating),
        classNames: ["bg-straw", "bg-gradient"]
    }];
    const columns: TableColumn<TournamentLite>[] = [{
        selector: (row) => row.name,
        name: "Name",
        wrap: true,
        sortable: true,
        grow: 6,
        format: (row) => <TournamentName tournament={row}/>
    }, {
        selector: (row) => row.description,
        name: "Description",
        sortable: true,
        wrap: true,
        grow: 8,
        cell: (row) => <DisplayAsTextBlock text={row.description}/>
    }, {
        selector: (row) => row.is_rated,
        name: "Rated?",
        center: true,
        sortable: true,
        format: (row) => <Form.Check checked={row.is_rated} readOnly/>
    }, {
        selector: (row) => row.duration,
        name: "Duration",
        sortable: true,
        right: true,
        format: (row) => formatSecondsToReadable(row.duration)
    }, {
        selector: (row) => row.tournament_type_id,
        name: <TournamentTypeSelect onChangeTypeId={setFilterTypeId} typeId={filterTypeId}/>,
        format: (row) => <TournamentType tournament={row}/>,
        grow: 2,
        center: true,
        compact: true
    }, {
        selector: (row) => row.status ?? "(unknown)",
        name: <TournamentStatusSelect onChangeStatus={setFilterStatus} status={filterStatus}/>,
        grow: 2,
        center: true,
        compact: true,
        format: (row) => <TournamentStatus tournament={row}/>
    }, {
        name: "Actions",
        omit: isChild,
        grow: 2,
        center: true,
        cell: (row) => <TournamentStatusAction tournament={row}/>
    }
];

    return (
        <DataTable<TournamentLite> data={tournaments}
                                   columns={columns}
                                   keyField="id"
                                   conditionalRowStyles={conditionalRowStyles}
                                   onRowClicked={rowClicked}
                                   persistTableHead
                                   noDataComponent="(No tournaments)"
                                   pagination/>
    );
};

export default TournamentList;