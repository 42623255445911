import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// 3rd party
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import {useFormContext} from "react-hook-form";

import {useSearchGroupsQuery} from "../../api/chessMasterApi";
import {DroppableGroupList} from "./DroppableGroupList";
import {partitionByIds} from "../../util/partitionById";
import {GroupFilter} from "./GroupFilter";
import {Group} from "../../models/Group";
import {NewUser, User} from "../../models/User";
import {selectLogin} from "../login/loginSlice";
import GroupAdminCard from "./GroupAdminCard";
import {newUserGroup, UserGroup} from "../../models/UserGroup";
import {useAppSelector} from "../../store";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";

interface Created {
    user: User | NewUser
}

const UserEditGroups: React.FC<Created> = ({user}) => {
    const dispatch = useDispatch();
    const {privs} = useAppSelector(selectLogin);
    const {setValue, watch} = useFormContext<{userGroups: UserGroup[]}>();
    const [query, setQuery] = useState("");
    const [groupTypeId, setGroupTypeId] = useState(-1);
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
    const {data: filteredGroups, error} = useSearchGroupsQuery({query, group_type_id: groupTypeId}, {skip: query.length < 3});
    const userGroups = watch("userGroups", []);
    const [availableGroups, setAvailableGroups] = useState<Group[]>([]);

    const onDragEnd = (result: DropResult) => {
        const {source, destination} = result;

        // XXX if source.droppableId === destination.droppableId, might be nice to reorder list
        if (!destination || source.droppableId === destination.droppableId) {
            return;
        }
        switch (source.droppableId) {
        case "availableGroups":
            const userGroup = newUserGroup(availableGroups[source.index], false);
            userGroups.splice(destination.index, 0, userGroup);
            setValue("userGroups", userGroups);
            break;
        case "assignedGroups":
            userGroups.splice(source.index, 1);
            setValue("userGroups", userGroups);
            break;
        default:
            console.log(`unknown source droppableId ${source.droppableId}`);
        }
    };

    useEffect(() => {
        const groupIds = (userGroups ?? []).map((ug) => ug.group.id);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, availableGroups] = partitionByIds((filteredGroups ?? []), groupIds);
        setAvailableGroups(availableGroups);
    }, [dispatch, filteredGroups, userGroups]);

    useEffect(
        () => setSearchErrorMessage((error) ? formatErrorMessage(error) : null),
        [error]
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <SearchErrorMessageContext.Provider value={{searchErrorMessage, setSearchErrorMessage}}>
                <GroupFilter query={query} setQuery={setQuery}
                             groupTypeId={groupTypeId}
                             setGroupTypeId={setGroupTypeId}/>
            </SearchErrorMessageContext.Provider>
            <Row className="g-0 p-2 border rounded">
                <Col md={6} className="pe-3">
                    <DroppableGroupList title="Assigned"
                                        user={user}
                                        droppableId="assignedGroups"
                                        userGroups={userGroups}/>
                </Col>
                <Col md={6}>
                    <DroppableGroupList title="Available"
                                        user={user}
                                        droppableId="availableGroups"
                                        userGroups={availableGroups.map((g) => newUserGroup(g, false))}/>
                </Col>
            </Row>
            {(privs.admin) && (
                <GroupAdminCard className="mt-3"
                                userGroups={userGroups}
                                setUserGroups={(userGroups) => setValue("userGroups", userGroups)}/>
            )}
        </DragDropContext>
    );
};

export default UserEditGroups;