import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {chessMasterApi, useLoginAsMutation} from "../../api/chessMasterApi";
import {setLoginCredentials} from "../login/loginSlice";
import {selectSettings} from "./settingsSlice";
import LoginAsForm from "./LoginAsForm";
import {useAppSelector} from "../../store";

const LoginAsSchema = yup.object().shape({
    password: yup.string().required().label("Password"),
    username: yup.string().required().label("Username"),
});

export interface LoginAsFormInput {
    password: string
    username: string
}

export const LoginAsModal: React.FC<{onClose: () => void}> = ({onClose}) => {
    const dispatch = useDispatch();
    const [loginAs, {isLoading}] = useLoginAsMutation();
    const {isShowingLoginAsModal} = useAppSelector(selectSettings);
    const [loginAsError, setLoginAsError] = useState('');

    const formMethods = useForm<LoginAsFormInput>({
        resolver: yupResolver(LoginAsSchema)
    });
    const onSubmit = ({username, password}: LoginAsFormInput) => {
        loginAs({username, password})
            .unwrap()
            .then((credentials) => {
                dispatch(setLoginCredentials(credentials));
                dispatch(chessMasterApi.util.resetApiState());
                onClose();
            })
            .catch((error) => setLoginAsError(error.data.message));
    };

    return (
        <Modal show={isShowingLoginAsModal}
               onHide={onClose}
               backdrop="static"
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Card.Title>Login As ...</Card.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <LoginAsForm />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={onClose}
                                disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit"
                                variant="primary"
                                disabled={isLoading}>
                            Login As ...
                        </Button>
                    </Modal.Footer>

                    {(loginAsError) && (
                        <Modal.Footer>
                            <span className="text-danger">
                                Login As failed: {loginAsError}
                            </span>
                        </Modal.Footer>
                    )}
                </Form>
            </FormProvider>
        </Modal>
    );
};