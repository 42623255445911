import React, {useCallback, useContext, useMemo, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {ActiveTournamentContext} from "./ActiveTournament";
import ActiveTournamentPlayersAdd from "./ActiveTournamentPlayersAdd";
import ActiveTournamentPlayersDelete from "./ActiveTournamentPlayersDelete";
import UsernameAndTeam from "../../components/UsernameAndTeam";
import UserLichessLink from "../../components/UserLichessLink";
import PaidSymbol from "../../components/PaidSymbol";
import TeamName from "./TeamName";
import {createTournamentPlayersWithStandingIndex} from "./ActiveTournamentCrossTable";
import {selectLoginCanEditTournament} from "../login/loginSlice";
import {
    isPlayMethodOnline,
    isTournamentComplete,
    Tournament
} from "../../models/Tournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {UserInfo} from "../../models/User";
import {Group} from "../../models/Group";
import {nameSortFunc} from "../../util/nameSortFunc";
import {formatFullName} from "../../util/formatFullName";
import {shallowCompareArrays} from "../../util/shallowCompareArrays";
import {useAppSelector} from "../../store";

interface AddPlayerButtonProps {
    setRunAddPlayersModal: (runModal: boolean) => void
    className?: string
}

const AddPlayerButton: React.FC<AddPlayerButtonProps> = ({setRunAddPlayersModal, className}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));

    if (canEditTournament) {
        return (
            <Button onClick={() => setRunAddPlayersModal(true)}
            className={className}>
                Enter players ...
            </Button>
        );
    } else {
        return null;
    }
};

interface ActiveTournamentPlayer extends TournamentPlayer {
    tournament: Tournament
    user: UserInfo
    team: Group | null
    standingIndex: number
}

// We include the tournament in ActiveTournamentPlayer because when we use it in <DataTable columns>,
// it gets captured in a closure in react-data-table (useColumns).  By including it in the
// data, we can access the current version in the table.  This is not good or clear.

const ActiveTournamentPlayers: React.FC = () => {
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const canEditTournament = useAppSelector(selectLoginCanEditTournament(tournament));
    const [runDeletePlayersModal, setRunDeletePlayersModal] = useState(false);
    const [runAddPlayersModal, setRunAddPlayersModal] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState<TournamentPlayer[]>([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const resetSelected = useCallback(() => {
        setToggleCleared((toggleCleared) => !toggleCleared);    // reset rdtc table state
        setSelectedPlayers([]);                                 // reset our state
    }, []);
    const closeDeletePlayersModal = useCallback((didUpdate: boolean) => {
        if (didUpdate) { resetSelected(); }
        setRunDeletePlayersModal(false);
    }, [resetSelected]);
    const handlePlayersSelected = useCallback((
            {selectedRows}: {selectedRows: TournamentPlayer[]}) => {
            if (!shallowCompareArrays(selectedPlayers, selectedRows)) {
                setSelectedPlayers(selectedRows);
            }
        },
        [selectedPlayers]);
    const activeTournamentPlayers: ActiveTournamentPlayer[] = useMemo(() => createTournamentPlayersWithStandingIndex(tournament.players).map((player) => ({
        ...player,
        tournament,
        user: tournament.ref_user[player.user_id],
        team: (player.team_id) ? tournament.ref_group[player.team_id] : null
    })), [tournament]);
    const playerAddRemoveButtons = () => (
        <ButtonToolbar className="float-end mt-3">
            {(canEditTournament) && (
                <Button onClick={() => setRunDeletePlayersModal(true)}
                        className="me-1"
                        disabled={selectedPlayers.length === 0}>
                    Remove selected ...
                </Button>
            )}
            <AddPlayerButton setRunAddPlayersModal={() => setRunAddPlayersModal(true)}/>
        </ButtonToolbar>
    );
    const columns: TableColumn<ActiveTournamentPlayer>[] = [{
        selector: (row) => row.standingIndex,
        name: "#",
        right: true,
        sortable: true
    }, {
        selector: (row) => row.participating ? row.points : 'n/p',
        name: "Points",
        right: true,
        sortable: true
    }, {
        omit: !canEditTournament,
        name: "Paid",
        center: true,
        cell: (row) => (row.paid) && <PaidSymbol className="mt-1"/>
    }, {
        selector: (row) => tournament.ref_user[row.user_id].lichess_id || '',
        name: "Lichess",
        omit: !isPlayMethodOnline(tournament.play_method),
        sortable: true,
        center: true,
        cell: (row) => <UserLichessLink user={tournament.ref_user[row.user_id]}/>
    }, {
        selector: (row) => formatFullName(row.user),
        name: "Name",
        sortable: true,
        grow: 8,
        sortFunction: (a, b) => nameSortFunc(a.user, b.user),
        cell: (row) => <UsernameAndTeam player={row}
                                                             tournament={row.tournament}
                                                             omitTeam
                                                             online={onlinePlayerIds.has(row.user.id)}/>
    }, {
        selector: (row) => (row.team) ? row.team.name : "",
        name: "Team",
        sortable: true,
        grow: 8,
        cell: (row) => (
            <TeamName tournament={tournament}
                      team={(row.team) ? tournament.ref_group[row.team.id] : undefined}/>
        )
    }];

    return (
        <>
            {(runAddPlayersModal) && (
                <ActiveTournamentPlayersAdd onClose={() => setRunAddPlayersModal(false)}/>
            )}
            {(runDeletePlayersModal) && (
                <ActiveTournamentPlayersDelete players={selectedPlayers}
                                               onClose={closeDeletePlayersModal}/>
            )}
            {(!isTournamentComplete(tournament)) && playerAddRemoveButtons()}
            <DataTable<ActiveTournamentPlayer> data={activeTournamentPlayers}
                                               columns={columns}
                                               defaultSortFieldId={2}
                                               defaultSortAsc={false}
                                               selectableRows={canEditTournament && !isTournamentComplete(tournament)}
                                               onSelectedRowsChange={handlePlayersSelected}
                                               clearSelectedRows={toggleCleared}
                                               keyField="id"
                                               noDataComponent="(No players)"
                                               className="w-100 mt-3"/>
        </>
    );
};

export default ActiveTournamentPlayers;
