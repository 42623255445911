import React, {useEffect} from "react";

import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";

import {TournamentType} from "../../models/TournamentType";
import {selectConstants} from "../constants/constantsSlice";
import RatingTournamentType from "./RatingTournamentType";
import useLocalStorageState from "use-local-storage-state";
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../store";

const DEFAULT_RATING_TAB = "traditional";

const createTabs = (tournamentTypes: TournamentType[]): {tabPanes: React.ReactElement[], navItems: React.ReactElement[]} => {
    const navItems = tournamentTypes.map((tt, index) => (
        <Nav.Item key={index}>
            <Nav.Link eventKey={tt.description.toLowerCase()}>{tt.description}</Nav.Link>
        </Nav.Item>
    ));
    const tabPanes = tournamentTypes.map((tt) => (
        <Tab.Pane key={tt.id} eventKey={`${tt.description.toLowerCase()}`}>
            <RatingTournamentType tournamentType={tt}/>
        </Tab.Pane>
    ));

    return {navItems, tabPanes};
};

const RatingTab: React.FC = () => {
    const navigate = useNavigate();
    const {tab_id} = useParams();
    const {tournament_types} = useAppSelector(selectConstants);
    const {tabPanes, navItems} = createTabs(tournament_types);
    const [activeTabKey, setActiveTabKey] = useLocalStorageState<string>(
        "ratingTab/activeTabKey", {defaultValue: DEFAULT_RATING_TAB});
    const onSelect = (key: string) => {
        navigate(`/rating/${key}`);
    };

    useEffect(() => {
        if (tab_id !== activeTabKey) {
            setActiveTabKey(tab_id ?? DEFAULT_RATING_TAB);
        }
    }, [activeTabKey, setActiveTabKey, tab_id]);

    return (
        <Tab.Container id="ratings-for-tournament-type"
                       activeKey={activeTabKey}
                       mountOnEnter
                       unmountOnExit
                       onSelect={(k) => (k) && onSelect(k)}>
            <Row>
                <Col sm={2}>
                    <Nav variant="pills" className="flex-column mx-3 mt-3">
                        {navItems}
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content className="flex-column me-3">
                        {tabPanes}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default RatingTab;
