import React, {useMemo, useState} from "react";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {skipToken} from "@reduxjs/toolkit/query";

import {TournamentMinSchema} from "../models/Tournament";
import {useSearchTournamentsQuery} from "../api/chessMasterApi";

interface Props {
    className?: string
    value: TournamentMinSchema | undefined
    name?: string
    onChange: (ev: {target: {value: TournamentMinSchema | undefined, name: string | undefined}}) => void
    placeholder?: string
    isInvalid?: boolean
}

const TournamentPicker: React.FC<Props> = ({value, onChange, name, className, placeholder, isInvalid}) => {
    const [query, setQuery] = useState("");
    const {
        data: tournaments,
        isLoading
    } = useSearchTournamentsQuery((query) ? {query} : skipToken);
    const selectedTournamentArray = useMemo(
        () => (value) ? [value] : [],
        [value]
    );

    return (
        <div className={(isInvalid) ? "is-invalid" : ""}>
            <AsyncTypeahead<TournamentMinSchema> id="tournamentPicker"
                                                 className={className}
                                                 options={tournaments ?? []}
                                                 labelKey="name"
                                                 onChange={(options) => onChange({
                                                     target: {
                                                         value: (options.length > 0) ? options[0] : undefined,
                                                         name
                                                     }
                                                 })}
                                                 selected={selectedTournamentArray}
                                                 onSearch={setQuery}
                                                 isLoading={isLoading}
                                                 placeholder={placeholder}
                                                 isInvalid={isInvalid}
                                                 filterBy={() => true}
                                                 renderMenuItemChildren={(option, props) => (
                                                     <Highlighter
                                                         search={props.text ?? ""}>
                                                         {(props.labelKey) && (
                                                             (typeof props.labelKey === "string") ? option[props.labelKey] : props.labelKey(option)
                                                         )}
                                                     </Highlighter>)}/>
        </div>
    );
};

export default TournamentPicker;
