import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";

import {UserInfo} from "../models/User";
import {Tournament} from "../models/Tournament";
import {formatFullName} from "../util/formatFullName";
import {setActiveTournamentPlayerSummary} from "../features/activeTournaments/activeTournamentSlice";
import Stack from "react-bootstrap/Stack";
import PaidSymbol from "./PaidSymbol";

interface Props {
    userInfo: UserInfo
    tournament?: Tournament
    score?: number
    isPaid?: boolean
}
const UsernameAndGroups: React.FC<Props> = ({userInfo, score, tournament, isPaid}) => {
    const dispatch = useDispatch();
    const renderName = (userInfo: UserInfo) => {
        const fullName = formatFullName(userInfo);

        if (tournament) {
            return (
                <Button variant="link"
                        className="p-0 text-start"
                        onClick={() => dispatch(setActiveTournamentPlayerSummary({tournament, player: userInfo}))}>
                    {fullName}
                </Button>
            );
        } else {
            return <>
                {fullName}
            </>;
        }
    };

    if (!userInfo) {
        return <span>(bye)</span>
    }
    return (
        <div className="w-100">
            <Stack direction="horizontal">
                {renderName(userInfo)}
                {(isPaid) ? <PaidSymbol className="ms-1"/> : null}
                {(score) ? <span className="ms-1">{score}</span> : null}
                {(score !== undefined) ? <span className="ms-auto">{" "}({score})</span> : ""}
            </Stack>
            {(userInfo.username) ? <small className="text-muted float-end">({userInfo.username})</small> : ""}
            {(userInfo.groups.length > 0) ?
                <div className="small">{userInfo.groups.join(", ")}</div> : null}
        </div>
    );
};

export default UsernameAndGroups;