import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConstantsResponse, PayPalConfig, StripeConfig} from '../../api/chessMasterApi';
import {RootState} from "../../store";
import {TournamentType} from '../../models/TournamentType';
import {MatchStatus, MatchStatusDescription} from "../../models/Match";
import {Role} from "../../models/Role";

const createLookupMapByFieldName = <T extends {}, F extends keyof T>(entries: T[], field: F) => {
    return Object.fromEntries(entries.map((entry) => [entry[field], entry]));
};

const initialState: ConstantsResponse = {
    titles: [],
    states: [],
    genders: [],
    game_types: [],
    group_types: [],
    play_methods: [],
    tournament_types: [],
    tournament_status: [],
    tournament_visibility: [],
    match_status: [],
    payment_systems: undefined,
    payment_status: [],
    roles: [],
    lichess_url: undefined
};

export const slice = createSlice({
    name: 'constants',
    initialState,
    reducers: {
        setConstants: (state, action: PayloadAction<ConstantsResponse>) => {
            Object.assign(state, action.payload);
        }
    }
});

export const selectConstants = (state: RootState) => state.constants;
export const selectConstantsGenderById = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.genders, "id")
));
export const selectConstantsGroupTypeById = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.group_types, "id")
));
export const selectConstantsGroupTypeByDescription = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.group_types, "description")
));
export const selectConstantsPaymentStatusById = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.payment_status, "id")
));
export const selectConstantsPaymentSystems = createSelector([selectConstants], (constants) => (
    (constants.payment_systems) ? Object.keys(constants.payment_systems).sort() : []
));
export const selectConstantsTournamentTypeById = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.tournament_types, "id")
));
export const selectConstantsRoleTypeByName = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.roles, "name")
));
export const selectConstantsMatchStatuses = createSelector([selectConstants], ({match_status}) => ({
    bye: match_status.filter((status) => status.description.match(/Bye/)),
    match: match_status.filter((status) => !status.description.match(/Bye/))
}));
export const selectConstantsMatchStatusByDescription = createSelector([selectConstants], ({match_status}) => (
    Object.fromEntries(match_status.map((ms) => [ms.description, ms])) as Record<MatchStatusDescription, MatchStatus>
));
export const selectConstantsRoleWithName = (name: string) => createSelector([selectConstants], ({roles}) => (
    roles.find((role) => role.name === name)
));
export const selectConstantsNamedRoleInRoleIds = (roleName: Role["name"], roleIds: Role["id"][]) => createSelector([selectConstants], ({roles}) => {
    const role = roles.find((role) => role.name === roleName);

    return role && roleIds.includes(role?.id)
});
export const selectConstantsGenderByDescription = createSelector([selectConstants], (constants) => (
    createLookupMapByFieldName(constants.genders, "description")
));


// XXX there must be a way to derive this
export interface ConstantsFilterOptions {
    tournamentStatus: Record<string, string>,
    tournamentTypes: Record<TournamentType["id"], string>
}

export const selectConstantsFilterOptions = createSelector([selectConstants], ({tournament_status, tournament_types}): ConstantsFilterOptions => ({
    tournamentStatus: Object.fromEntries(tournament_status.map((ts) => [ts.description, ts.description])),
    tournamentTypes: Object.fromEntries(tournament_types.map((tt) => [tt.id, tt.description]))
}));

export interface ConstantsPaymentSystem {
    paypalConfig: PayPalConfig | undefined,
    stripeConfig: StripeConfig | undefined
}

export const selectConstantsPaymentSystem = createSelector([selectConstants], ({payment_systems}): ConstantsPaymentSystem => ({
    paypalConfig: (payment_systems) ? (payment_systems['paypal'] || payment_systems['paypal-sandbox']) as PayPalConfig : undefined,
    stripeConfig: (payment_systems) ? (payment_systems['stripe'] || payment_systems['stripe-sandbox']) as StripeConfig : undefined
}));

export const {setConstants} = slice.actions;

export default slice.reducer;
