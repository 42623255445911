import React, {useContext, useMemo, useState} from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {ActiveTournamentContext} from "./ActiveTournament";
import PlayerFilterInput, {PlayerFilterErrorContext} from "../../components/PlayerFilterInput";
import PlayerFilterList from "../../components/PlayerFilterList";
import CreateUserAccordion from "../../components/CreateUserAccordion";
import {Group} from "../../models/Group";
import {newUser, NewUserWithRegistration} from "../../models/User";

interface Props {
    onClose: () => void;
}

const ActiveTournamentPlayersAdd: React.FC<Props> = ({onClose}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const newUserPrototype = useMemo<NewUserWithRegistration>(() => ({
            user: newUser({gender_id: -1}),
            tournament_key: tournament.key,
        }),
        [tournament]
    );
    const [query, setQuery] = useState<Group["name"]>("");
    const [groupId, setGroupId] = useState<Group["id"]>(-1);
    const [userSearchError, setUserSearchError] = useState<string | null>(null);
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    return (
        <PlayerFilterErrorContext.Provider value={{userSearchError, setUserSearchError, groupSearchError, setGroupSearchError}}>
            <Modal show
                   size="lg"
                   onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Enter players in “{tournament.name}”
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <PlayerFilterInput query={query} setQuery={setQuery}
                                       groupId={groupId} setGroupId={setGroupId}/>
                </Modal.Body>

                <Modal.Body>
                    <PlayerFilterList tournament={tournament}
                                      query={query}
                                      groupId={groupId}/>
                </Modal.Body>

                {(tournament.controls?.director) && (
                    <Modal.Body>
                        <CreateUserAccordion userPrototype={newUserPrototype}
                                             setIsOpen={setIsAccordionOpen}>
                            Create user
                        </CreateUserAccordion>
                    </Modal.Body>
                )}

                <Modal.Footer>
                    <Button disabled={isAccordionOpen}
                            onClick={() => onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </PlayerFilterErrorContext.Provider>
    );
};

export default ActiveTournamentPlayersAdd;