import React, {useContext} from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import {
    formatMatchStatusDescription,
    isMatchStatusComplete,
    Match,
    MatchStatusDescription
} from "../../models/Match";
import {selectConstantsMatchStatuses} from "../constants/constantsSlice";
import {
    updateMatchStatusFilter,
    useUpdateTournamentMatchMutation
} from "../../api/chessMasterApi";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {formatFullName} from "../../util/formatFullName";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ActiveTournamentContext} from "./ActiveTournament";
import {useAppDispatch, useAppSelector} from "../../store";

const isMatchBye = (match: Match) => (
    match.white_player_id === null || match.black_player_id === null
);

interface Props {
    match: Match
    onClose?: () => void
}

const MatchResultSelector: React.FC<Props> = ({match, onClose}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const dispatch = useAppDispatch();
    const matchStatuses = useAppSelector(selectConstantsMatchStatuses);
    const matchStatus = (isMatchBye(match)) ? matchStatuses.bye : matchStatuses.match;
    const [updateTournamentMatch] = useUpdateTournamentMatchMutation();
    const submit = (match: Match, status: MatchStatusDescription) => {
        const updatedMatch = Object.assign({}, match, {status});

        updateTournamentMatch({
            matches: [updatedMatch],
            key: tournament.key,
            matchPayloadFilter: updateMatchStatusFilter
        })
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: `Match status updated`,
                    body: `White ${(match.white_player_id) ? formatFullName(tournament.ref_user[match.white_player_id]) : "(empty)"}, ` +
                          `black ${(match.black_player_id) ? formatFullName(tournament.ref_user[match.black_player_id]) : "(empty)"}: ` +
                          status
                })));
                onClose?.();
            });
    };
    const swapPlayers = (match: Match) => {
        const swappedMatch = {
            id: match.id,
            white_player_id: match.black_player_id,
            black_player_id: match.white_player_id,
            round_num: match.round_num
        };
        updateTournamentMatch({key: tournament.key, matches: [swappedMatch]})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: `Players swapped`,
                    body: `Swapped ${formatFullName(tournament.ref_user[match.white_player_id!])} with ` +
                          `${formatFullName(tournament.ref_user[match.black_player_id!])}: `
                })));
                onClose?.();
            });
    };
    const swapPlayersItem = (match: Match) => {
        // only allow swap if match is not a bye
        if (match.white_player_id && match.black_player_id) {
            return (
                <>
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={() => swapPlayers(match)}>Swap players</Dropdown.Item>
                </>
            );
        } else {
            return null;
        }
    };
    const playingStatusButtonGroup = () => (
            <ButtonGroup className="lessPad">
                <Button onClick={() => submit(match, "WhiteWin")}>White win</Button>
                <Button onClick={() => submit(match, "Draw")}>Draw</Button>
                <Button onClick={() => submit(match, "BlackWin")}>Black win</Button>
                <DropdownButton as={ButtonGroup} title="">
                    {matchStatus.map((ms, i) => (
                        <Dropdown.Item eventKey={`${i}`} key={i} onClick={() => submit(match, ms.description)}>
                            {formatMatchStatusDescription(ms.description)}
                        </Dropdown.Item>
                    ))}
                    {swapPlayersItem(match)}
                </DropdownButton>
            </ButtonGroup>
    );
    const changeResultButtonGroup = () => (
        <ButtonToolbar>
            <ButtonGroup>
                <Button>{match.status}</Button>
                <DropdownButton as={ButtonGroup} title="">
                    {matchStatus.map((ms, i) => (
                        <Dropdown.Item eventKey={`${i}`}
                                       key={i}
                                       onClick={() => submit(match, ms.description)}>
                            {formatMatchStatusDescription(ms.description)}
                        </Dropdown.Item>
                    ))}
                    {swapPlayersItem(match)}
                </DropdownButton>
            </ButtonGroup>
            <ButtonGroup>
                {(onClose) && (
                    <Button className="btn-close" onClick={onClose}/>
                )}
            </ButtonGroup>
        </ButtonToolbar>
    );

    return (
        (!isMatchStatusComplete(match)) ? playingStatusButtonGroup() : changeResultButtonGroup()
    );
};

export default MatchResultSelector;