import {UserInfo} from "./User";
import {formatISO} from "date-fns";
import {Group} from "./Group";

const sortOrders = ["name", "lastNameFirstName", "dateAdded"] as const;
export type TournamentPlayersSortOrder = (typeof sortOrders)[number];
export const isTournamentPlayersSortOrder = (sortOrder: string): sortOrder is TournamentPlayersSortOrder => (
    sortOrders.includes(sortOrder as TournamentPlayersSortOrder)
);

export interface TournamentPlayer {
    pairing_id: number
    paid: boolean
    participating: boolean
    withdrawn: boolean
    initial_rating: number
    rating: number
    points: number
    buchholz_score: number
    user_id: UserInfo["id"]
    add_time: string    // DateTime
    team_id: Group["id"] | null
}

export const newTournamentPlayer = (initFields: Partial<TournamentPlayer> = {}): TournamentPlayer => ({
    pairing_id: 0,
    paid: false,
    participating: false,
    withdrawn: false,
    initial_rating: 0,
    rating: 0,
    points: 0,
    buchholz_score: 0,
    user_id: 0,
    add_time: formatISO(new Date()),
    team_id: null,
    ...initFields
});

export const sortTournamentPlayersByPoints = (players: TournamentPlayer[]) => (
    [...players].sort((a, b) => (b.points - a.points || b.buchholz_score - a.buchholz_score))
);