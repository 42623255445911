import React, {PropsWithChildren} from "react";
import {selectConstantsPaymentSystem} from "../features/constants/constantsSlice";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {useAppSelector} from "../store";

// Only wrap children in PayPalScriptProvider when the paypalConfig has arrived from the
// server. This saves an error from PayPal when the app loads.

const OptionalPayPalScriptProvider: React.FC<PropsWithChildren<{}>> = ({children}) => {
    const {paypalConfig} = useAppSelector(selectConstantsPaymentSystem);

    if (paypalConfig) {
        const paypalOptions = {
            "client-id": paypalConfig.client_id,
            deferLoading: true,
            currency: "AUD"
        };

        return (
            <PayPalScriptProvider options={paypalOptions}>
                {children}
            </PayPalScriptProvider>
        );
    } else {
        return (
            <>
                {children}
            </>
        );
    }
};

export default OptionalPayPalScriptProvider;