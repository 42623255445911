import {Middleware, MiddlewareAPI, AnyAction, Dispatch} from "redux";

import {AppDispatch, RootState} from "../store";
import {addSocketListener, removeSocketListener} from "./socketIO";
import {serverMove, connected} from "../features/game/gameSlice";
import {removeActiveGame} from "../features/activeGames/activeGamesSlice";
import {chessMasterApi} from "../api/chessMasterApi";

const activeGames: Middleware = <S extends RootState>(store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => <A extends AnyAction>(action: A): A => {
    const dispatch: AppDispatch = store.dispatch;

    switch (action.type) {
    case chessMasterApi.endpoints.joinGame.matchFulfilled:
    case chessMasterApi.endpoints.spectateGame.matchFulfilled:
        dispatch(addSocketListener(action.payload.ws_url, "move", serverMove.type, {gameId: action.payload.game.id}));
        dispatch(addSocketListener(action.payload.ws_url, "connect", connected.type, {gameId: action.payload.game.id}));
        break;

    case removeActiveGame:
        dispatch(removeSocketListener(action.payload.ws_url, "move"));
        dispatch(removeSocketListener(action.payload.ws_url, "connect"));
        break;

    default:
        break;
    }
    return next(action);
};

export default activeGames;