import {Group} from "./Group";

// return type from /api/users/<id>/groups

export interface UserGroup {
    group: Group
    group_admin: boolean
}

export const newUserGroup = (group: Group, group_admin: boolean): UserGroup => ({
    group,
    group_admin
});

export interface UserGroupUpdate {
    group_id: Group["id"]
    group_admin?: boolean
    _delete?: boolean
}

export const newUserGroupUpdate = (group_id: Group["id"], initFields: Partial<UserGroupUpdate> = {}): UserGroupUpdate => ({
    group_id,
    ...initFields
});