export interface NewLocation {
    address1: string,
    address2: string,
    locality: string
    state: string
    postcode: string
    country: string
}

export interface Location extends NewLocation {
    id: number
}

export const newLocation = (): NewLocation => ({
    address1: "",
    address2: "",
    locality: "",
    state: "",
    postcode: "",
    country: ""
});