import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from "../../store";
import {Group, NewGroup} from '../../models/Group';

interface GroupsTabState {
    groupEdit: NewGroup | Group | null
}

const initialState: GroupsTabState = {
    groupEdit: null,
};

export const slice = createSlice({
    name: 'groupsTab',
    initialState,
    reducers: {
        editGroup: (state, action: PayloadAction<NewGroup | Group | null>) => {
            state.groupEdit = action.payload;
        }
    }
});

export const selectGroupsTab = (state: RootState) => state.groupsTab;
export const {
    editGroup
} = slice.actions;

export default slice.reducer;