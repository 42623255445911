import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {GameDescription} from "../../models/GameDescription";
import {Game} from "../../models/Game";
import {chessMasterApi} from "../../api/chessMasterApi";

interface ActiveGamesState {
    gameDescriptions: GameDescription[]
}

const initialState: ActiveGamesState = {
    gameDescriptions: []
};

export const slice = createSlice({
    name: 'activeGames',
    initialState,
    reducers: {
        removeActiveGame: (state, action: PayloadAction<Game>) => {
            state.gameDescriptions = state.gameDescriptions.filter((gd) => gd.game.id !== action.payload.id);
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(chessMasterApi.endpoints.joinGame.matchFulfilled, (state, action) => {
            state.gameDescriptions.push(action.payload);
        });
        builder.addMatcher(chessMasterApi.endpoints.spectateGame.matchFulfilled, (state, action) => {
            state.gameDescriptions.push(action.payload);
        });
    }
});

export const selectActiveGames = (state: RootState) => state.activeGames;
export const {
    removeActiveGame
} = slice.actions;

export default slice.reducer;