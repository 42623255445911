import React, {useCallback, useContext, useEffect} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import {useSearchUserInfosQuery} from "../../api/chessMasterApi";
import {UserInfo} from "../../models/User";
import {Group} from "../../models/Group";
import {formatFullName} from "../../util/formatFullName";
import {nameSortFunc} from "../../util/nameSortFunc";
import {PlayerFilterErrorContext} from "../../components/PlayerFilterInput";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";

interface Props {
    query: string
    groupId: Group["id"]
    assignedUsers: UserInfo[]
    setSelectedUsers: (users: UserInfo[]) => void
}

const GroupMembershipAddUsersTable: React.FC<Props> = ({query, groupId, assignedUsers, setSelectedUsers}) => {
    const assignedUserIds = new Set(assignedUsers.map((user) => user.id));
    const {setUserSearchError} = useContext(PlayerFilterErrorContext);
const handleUsersSelected = useCallback(
        ({selectedRows}: {selectedRows: UserInfo[]}) => setSelectedUsers(selectedRows),
        [setSelectedUsers]
    );
    const {data: users, isFetching, error} = useSearchUserInfosQuery({query, group_id: groupId}, {skip: query.length < 3 && groupId < 0});
    const columns: TableColumn<UserInfo>[] = [{
        selector: (row) => formatFullName(row),
        name: "Name",
        sortable: true,
        grow: 8,
        sortFunction: (a, b) => nameSortFunc(a, b),
        format: (row) => formatFullName(row)
    }, {
        selector: (row) => (row.age) ?? "",
        name: "Age",
        sortable: true,
        grow: 8
    }, {
        name: "Groups",
        grow: 8,
        format: (row) => row.groups.sort().join(", ")
    }];

    useEffect(
        () => setUserSearchError?.((error) ? formatErrorMessage(error) : null),
        [error, setUserSearchError]
    );

    return (
        <DataTable<UserInfo> data={users ?? []}
                             columns={columns}
                             selectableRows
                             onSelectedRowsChange={handleUsersSelected}
                             selectableRowDisabled={(row) => assignedUserIds.has(row.id)}
                             keyField="id"
                             noDataComponent="(No members)"
                             progressPending={isFetching}
                             className="w-100 mt-3"
                             pagination/>
    );
};

export default GroupMembershipAddUsersTable;
