export const formatSecondsToReadable = (seconds: number) => {
    const h = Math.floor(seconds / 3600),
        m = Math.floor((seconds % 3600) / 60),
        s = Math.floor(seconds % 60);

    if (h === 0 && m === 0 && s === 0) {
        return "0s";
    } else {
        return [
            (h > 0) ? `${h}h` : "",
            (m > 0) ? `${m}m` : "",
            (s > 0) ? `${s}s` : ""
        ].join("");
    }
};