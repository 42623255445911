import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from "../../store";

interface RatingTabState {
    activeTabKey: string | undefined
}

const initialState: RatingTabState = {
    activeTabKey: "1",
};

export const slice = createSlice({
    name: 'auditTab',
    initialState,
    reducers: {
        setRatingActiveTabKey: (state, action: PayloadAction<string | undefined>) => {
            state.activeTabKey = action.payload;
        }
    }
});

export const selectRatingTab = (state: RootState) => state.ratingTab;
export const {
    setRatingActiveTabKey
} = slice.actions;

export default slice.reducer;