import React, {useCallback, useContext} from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {useUpdateTournamentRemovingPlayersMutation} from "../../api/chessMasterApi";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import ListPlayers from "../../components/ListPlayers";
import {ActiveTournamentContext} from "./ActiveTournament";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useDispatch} from "react-redux";

interface Props {
    players: TournamentPlayer[]
    onClose: (didUpdate: boolean) => void
}

const ActiveTournamentPlayersDelete: React.FC<Props> = ({players, onClose}) => {
    const dispatch = useDispatch();
    const {tournament} = useContext(ActiveTournamentContext);
    const [updateTournamentRemovingPlayers] = useUpdateTournamentRemovingPlayersMutation();
    const isAnyPlayerPaid = players.some((player) => player.paid);
    const apply = useCallback((players: TournamentPlayer[]) => {
        updateTournamentRemovingPlayers({key: tournament.key, userIds: players.map((player) => player.user_id)})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Players removed",
                    body: `Removed ${players.length} ${(players.length === 1) ? "user" : "users"} from tournament “${tournament.name}”`
                })));
            })
            .then(() => {
                onClose(true);
            });
    }, [dispatch, onClose, tournament, updateTournamentRemovingPlayers]);

    return (
        <Modal show
               onHide={() => onClose(false)}
               size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Remove players from tournament “{tournament.name}”
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ListPlayers tournament={tournament}
                             players={players}/>
            </Modal.Body>

            {(isAnyPlayerPaid) && (
                <Modal.Footer>
                    <div className="text-danger">
                        Note: Some players have already paid. Removing them will generate credits.
                    </div>
                </Modal.Footer>
            )}

            <Modal.Footer>
                <Button variant="secondary"
                        className="me-1"
                        onClick={() => onClose(false)}>
                    Cancel
                </Button>
                <Button onClick={() => apply(players)}>
                    Remove {players.length} {(players.length === 1) ? "player" : "players"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveTournamentPlayersDelete;