import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {formatISO} from "date-fns";
import {findMatchingToast} from "../../util/findMatchingToast";

type PartialWithRequired<T, K extends keyof T> = Pick<T, K> & Partial<T>;

export const newToastMessage = (message: PartialWithRequired<ToastMessage, "heading" | "body">): ToastMessage => {
    return {
        priority: "info",
        date: formatISO(new Date()),
        timeout: 4000,
        count: 1,
        ...message
    }
};

export const newToastSuccess = (message: PartialWithRequired<ToastMessage, "heading" | "body">): ToastMessage => {
    return newToastMessage({
        priority: "success",
        ...message
    });
};

export const newToastError = (message: PartialWithRequired<ToastMessage, "heading" | "body">): ToastMessage => {
    return newToastMessage({
        priority: "danger",
        ...message
    });
};

export type ToastPriority = "info" | "warning" | "danger" | "success";

export interface ToastMessage {
    id?: number
    priority: ToastPriority
    date: string    // ISO date time
    timeout: number
    heading: string
    body: string
    count: number
}

interface ToastState {
    toasts: Required<ToastMessage>[]
    toastId: number
}

const initialState: ToastState = {
    toasts: [],
    toastId: 0
};

export const slice = createSlice({
    name: "toasts",
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<ToastMessage>) => {
            const matchingToast = findMatchingToast(state.toasts, action.payload);

            if (matchingToast) {
                // duplicate so move toast to bottom of list and bump count
                state.toasts = [
                    ...state.toasts.filter((toast) => toast.id !== matchingToast.id),
                    {...action.payload, count: matchingToast.count + 1, id: matchingToast.id}
                ]
            } else {
                state.toasts.push({...action.payload, id: state.toastId++});
            }
        },
        removeToast: (state, action: PayloadAction<ToastMessage>) => {
            state.toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);
        }
    }
});

export const selectToasts = (state: RootState) => state.toasts;

export const {
    addToast, removeToast
} = slice.actions;

export default slice.reducer;
