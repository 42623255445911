import React, {useContext, useMemo} from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Collapse from "react-bootstrap/Collapse";
import {BsCaretDownFill, BsFillCaretRightFill} from "react-icons/bs";

import {Tournament} from "../../models/Tournament";
import {selectConstantsTournamentTypeById} from "../constants/constantsSlice";
import {TournamentStatusAction} from "../tournamentTab/TournamentStatusAction";
import {formatTournamentStatus} from "../../util/formatTournamentStatus";
import {ActiveTournamentPlayMethod} from "./ActiveTournamentPlayMethod";
import {ActiveTournamentConferenceLink} from "./ActiveTournamentConferenceLink";
import {ActiveTournamentContext} from "./ActiveTournament";
import {selectLogin} from "../login/loginSlice";
import {UserInfo} from "../../models/User";
import LinkToActiveMatch from "../../components/LinkToActiveMatch";
import {useAppSelector} from "../../store";
import ActiveTournamentSummaryDetails from "./ActiveTournamentSummaryDetails";
import ActiveTournamentSummaryActions from "./ActiveTournamentSummaryActions";

const findActiveMatchWithUserId = (tournament: Tournament, userId: UserInfo["id"]) => {
    if (tournament.status === "Playing" && tournament.cur_round) {
        const match = tournament.rounds[tournament.cur_round - 1].matches
            .find((match) => match.white_player_id === userId || match.black_player_id === userId);
        if (match) {
            return match;
        }
    }
    return null;
};

const ActiveTournamentSummaryCard: React.FC = () => {
    const {tournament, options, setOptions} = useContext(ActiveTournamentContext);
    const {userId} = useAppSelector(selectLogin);
    const tournamentTypeById = useAppSelector(selectConstantsTournamentTypeById);
    const activeMatch = useMemo(
        () => findActiveMatchWithUserId(tournament, userId),
        [tournament, userId]
    );
    const opponentId = useMemo(
        () => {
            if (activeMatch) {
                return (activeMatch.white_player_id === userId) ? activeMatch.black_player_id : activeMatch.white_player_id;
            } else {
                return null;
            }
        },
        [activeMatch, userId]
    );
    const formatTournamentTitle = (tournament: Tournament) => (
        <span className="ico-allow">
            <span className="h5 fw-bolder my-0">
                {(options.isHidingSummary) ? <BsCaretDownFill/> : <BsFillCaretRightFill/>}
                {tournament.name}
            </span>
            &mdash; {tournamentTypeById[tournament.tournament_type_id].description}, {tournament.is_rated ? "rated" : "not rated"}
        </span>
    );

    return (
        <Card className="mt-3 tournSummary">
            <Card.Header onClick={() => setOptions({...options, isHidingSummary: !options.isHidingSummary})}
                         className="px-2">
                <Stack direction="horizontal" gap={3}>
                    {formatTournamentTitle(tournament)}
                    <div className="tt-btns">

                        <TournamentStatusAction tournament={tournament}/>
                        {(tournament.controls.director) && (
                            <Badge pill bg="warning" className="p-2">Director</Badge>
                        )}
                        <ActiveTournamentPlayMethod play_method={tournament.play_method}/>
                        <ActiveTournamentConferenceLink tournament={tournament}/>
                    </div>
                    <span className="ms-auto">
                        {(activeMatch && opponentId) ? (
                            <LinkToActiveMatch tournament={tournament}
                                               match={activeMatch}
                                               opponentId={opponentId}/>
                        ) : (
                            <>{formatTournamentStatus(tournament)}</>
                        )}
                    </span>
                </Stack>
            </Card.Header>
            <Collapse in={options.isHidingSummary}>
                <Card.Body className="px-0">
                    <Container fluid className="p-0">
                        <Row>
                            <Col md={10} className="pe-0">
                                <ActiveTournamentSummaryDetails/>
                            </Col>
                            <Col md={2}>
                                <ActiveTournamentSummaryActions/>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default ActiveTournamentSummaryCard;
