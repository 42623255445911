
// update an item with an id in a list with a new item. If addIfMissing is truthy, then
// add it.

export const updateItemListWithItem = <T extends {id: number}>(itemList: T[], item: T, addIfMissing=false): T[] => {
    const index = itemList.findIndex((u) => u.id === item.id);

    if (index >= 0) {
        itemList[index] = item;
    } else if (addIfMissing) {
        itemList.push(item);
    } else
        throw new Error(`updateItemListWithItem: unknown item: id ${item.id}`);
    return itemList;
};