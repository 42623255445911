import React from "react";

import {TournamentBase} from "../../models/Tournament";

interface Props {
    tournament: TournamentBase
}

const TournamentStatus: React.FC<Props> = ({tournament}) => {
    return (
        <div>
            <div>{tournament.status}</div>
            {(tournament.status === 'Playing') && <div>Round {tournament.cur_round}</div>}
        </div>
    );
};

export default TournamentStatus;