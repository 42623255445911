import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
// XXX restore this when we move to react-bootstrap-typeahead@6.x
// import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import './index.css';
import store from './store';
import App from './App';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from "react-router-dom";
import ActiveTournament from "./features/activeTournaments/ActiveTournament";
import TournamentTab from "./features/tournamentTab/TournamentTab";
import RatingTab from "./features/ratingTab/RatingTab";
import ParentTab from "./features/parentTab/ParentTab";
import UserTab from "./features/userTab/UserTab";
import PaymentTab from "./features/paymentTab/PaymentTab";
import GroupsTab from "./features/groupsTab/GroupsTab";
import GroupEntryTab from "./features/groupEntryTab/GroupEntryTab";
import AuditTab from "./features/auditTab/AuditTab";
import FinanceTab from "./features/financeTab/FinanceTab";
import RequireAuth from "./components/RequireAuth";
import RequireRole from "./components/RequireRole";
import PermissionDenied from "./components/PermissionDenied";
import ErrorBoundary from "./components/ErrorBoundary";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App/>} errorElement={<ErrorBoundary/>}>
            <Route index element={<TournamentTab/>}/>
            <Route path="tournament/:tournament_key?/:tab_id?" element={<ActiveTournament/>}/>,
            <Route path="tournaments" element={<TournamentTab/>}/>
            {/*<Route path="game/:game_id?" element={<GameView/>}/>,*/}
            {/*<Route path="games" element={<GameTab/>}/>*/}
            <Route path="rating" element={<RatingTab/>}>
                <Route index element={<RatingTab/>}/>
                <Route path=":tab_id?" element={<RatingTab/>}/>
            </Route>
            <Route path="children" element={<RequireAuth priv="guardian"><ParentTab/></RequireAuth>}/>
            <Route path="payment" element={<RequireRole not role="Child/Student"><PaymentTab/></RequireRole>}/>
            <Route path="users/:user_id" element={<RequireAuth priv={["admin", "group_admin"]}><UserTab/></RequireAuth>}/>
            <Route path="users" element={<RequireAuth priv={["admin", "group_admin"]}><UserTab/></RequireAuth>}/>
            <Route path="groups/:group_id" element={<RequireAuth priv={["admin", "group_admin"]}><GroupsTab/></RequireAuth>}/>
            <Route path="groups" element={<RequireAuth priv={["admin", "group_admin"]}><GroupsTab/></RequireAuth>}/>
            <Route path="groupEntry" element={<RequireAuth priv="group_admin"><GroupEntryTab/></RequireAuth>}/>
            <Route path="audit" element={<RequireAuth priv="audit_view"><AuditTab/></RequireAuth>}/>
            <Route path="finance" element={<RequireAuth priv="finance_view"><FinanceTab/></RequireAuth>}/>
            <Route path="PermissionDenied" element={<PermissionDenied/>}/>
        </Route>
    )
);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
