import React, {useContext} from "react";
import {useDebouncedCallback} from "use-debounce";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import {BsInfoCircle} from "react-icons/bs";

import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {tournamentSearchHelpPopover} from "../../util/SearchHelpPopover";


interface Props {
    query: string
    setQuery: (query: string) => void
}

const TournamentSearch: React.FC<Props> = ({query, setQuery}) => {
    const {searchErrorMessage} = useContext(SearchErrorMessageContext);
    const setDebouncedQuery = useDebouncedCallback(setQuery, 300);

    return (
        <div>
            <InputGroup className={(searchErrorMessage) ? "is-invalid" : ""}>
                <Form.Control type="search"
                              defaultValue={query}
                              onChange={((ev) => setDebouncedQuery(ev.target.value))}
                              isInvalid={!!searchErrorMessage}
                              placeholder="Search ..."/>
                <OverlayTrigger trigger="click" placement="bottom"
                                overlay={tournamentSearchHelpPopover}>
                    <Button variant="outline-primary">
                        <BsInfoCircle/>
                    </Button>
                </OverlayTrigger>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {searchErrorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default TournamentSearch;
