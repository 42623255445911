import * as React from 'react';
import {useCallback} from 'react';

import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import DatePicker from "react-datepicker";
import {formatISO, parseISO} from "date-fns";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {selectConstants} from "../constants/constantsSlice";
import {formControlOptions, formControlOptionsFromStrings} from "../../util/formControl";
import {Location} from "../../models/Location";
import {PlayMethodType} from "../../models/Tournament";
import {formatSecondsToReadable} from "../../util/formatSecondsToReadable";
import TimeDurationInput from "../../components/TimeDurationInput";
import AdminOnlyLocationCombobox from "../../components/AdminOnlyLocationCombobox";
import {TournamentForm} from "./TournamentEdit";
import {useAppSelector} from "../../store";

const checkTournamentTypeAndDuration = (tournament_type_id: number, duration: number) => {
    if (duration < 3 * 60) {
        if (tournament_type_id !== 4) {
            return `"Bullet" recommended for a game duration of ${formatSecondsToReadable(duration)}`;
        }
    } else if (duration < 10 * 60) {
        if (tournament_type_id !== 3) {
            return `"Blitz" recommended for a game duration of ${formatSecondsToReadable(duration)}`;
        }
    } else if (duration < 30 * 60) {
        if (tournament_type_id !== 2) {
            return `"Rapid" recommended for a game duration of ${formatSecondsToReadable(duration)}`;
        }
    } else {
        if (tournament_type_id !== 1) {
            return `"Traditional" recommended for a game duration of ${formatSecondsToReadable(duration)}`;
        }
    }
    return null;
};

const TournamentDetails: React.FC = () => {
    const {register, formState: {errors}, control, setValue, setError, clearErrors, watch} = useFormContext<TournamentForm>();
    const {play_methods, tournament_types, tournament_visibility} = useAppSelector(selectConstants);
    // need this to trigger rerender of LocationCombobox after setValue("tournament.venue")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const venue: Location | undefined = watch("tournament.venue");
    const setVenue = (venue: Location | undefined) => {
        setValue("tournament.venue_id", (venue) ? venue.id : null, {shouldDirty: true});
        setValue("tournament.venue", venue, {shouldDirty: true});
    };
    const play_method: PlayMethodType = watch("tournament.play_method");
    const handleFieldError = useCallback((fieldName: FieldPath<TournamentForm>, message: string | undefined) => {
        if (message) {
            setError(fieldName, {message});
        } else {
            clearErrors(fieldName);
        }}, [clearErrors, setError]
    );
    register("tournament.venue");
    register("tournament.venue_id");
    register("tournament.duration");
    register("tournament.clock_increment");

    return (
        <Container fluid>
            <Form.Group as={Row} controlId="tournamentName" className="mb-1">
                <Form.Label column md={2}
                            className="text-end">
                    Name
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("tournament.name")}
                                  isInvalid={!!errors.tournament?.name} />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.name"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} md={6} controlId="tournamentDescription" className="mb-1">
                <Form.Label column md={2}
                            className="text-end">
                    Description
                </Form.Label>
                <Col md={10}>
                    <Form.Control{...register("tournament.description")}
                                 as="textarea"
                                 isInvalid={!!errors.tournament?.description}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.description"
                                  type="invalid"/>
                </Col>
            </Form.Group>

            <Row className="mb-1">
                <Form.Label column md={2}
                            className="text-end">
                    Start time
                </Form.Label>
                <Col md={4}>
                    <Controller
                        render={
                            ({field: {onChange, value, onBlur}}) => (
                                <DatePicker
                                    selected={(value) ? parseISO(value) : null}
                                    className="form-control"
                                    dateFormat="PPpp"     // long localised w time
                                    dropdownMode="select"
                                    shouldCloseOnSelect
                                    showTimeInput
                                    showYearDropdown
                                    wrapperClassName={(errors.tournament?.start_time) ? "is-invalid" : ""}
                                    onChange={(value: Date) => onChange((value) ? formatISO(value) : null)}
                                    onBlur={onBlur}/>
                            )
                        }
                        control={control}
                        name="tournament.start_time"
                    />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.start_time"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={2}
                            className="text-end">
                    End time
                </Form.Label>
                <Col md={4}>
                    <Controller
                        render={
                            ({field: {onChange, value, onBlur}}) => (
                                <DatePicker
                                    selected={(value) ? parseISO(value) : null}
                                    className="form-control"
                                    dateFormat="PPpp"     // long localised w time
                                    minDate={new Date()}
                                    dropdownMode="select"
                                    shouldCloseOnSelect
                                    showTimeInput
                                    showYearDropdown
                                    wrapperClassName={(errors.tournament?.end_time) ? "is-invalid" : ""}
                                    onChange={(value: Date) => onChange((value) ? formatISO(value) : null)}
                                    onBlur={onBlur}/>
                            )
                        }
                        control={control}
                        name="tournament.end_time"
                    />
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.end_time"
                                  type="invalid"/>
                </Col>
            </Row>

            <Row className="mb-1">
                <Form.Label column md={2}
                            className="text-end">
                    Rounds
                </Form.Label>
                <Col md={2}>
                    <Form.Control {...register("tournament.num_rounds")}
                                    type="number"
                                    min={1}
                                    isInvalid={!!errors.tournament?.num_rounds}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.num_rounds"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={2}
                            className="text-end">
                    Max. players
                </Form.Label>
                <Col md={2}>
                    <Form.Control {...register("tournament.max_players")}
                                    type="number"
                                    min={2}
                                    isInvalid={!!errors.tournament?.max_players}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.max_players"
                                  type="invalid"/>
                </Col>

                <Form.Label column md={3}
                                className="text-end">
                        Is rated?
                </Form.Label>
                <Col md={1}>
                    <Form.Check {...register("tournament.is_rated")}
                                className="big-check"
                                isInvalid={!!errors.tournament?.is_rated}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.is_rated"
                                  type="invalid"/>
                </Col>
            </Row>

            <Row className="mb-1">
                <Col md={6}>
                    <Form.Group as={Row} controlId="tournamentPlayMethod">
                        <Form.Label column md={4}
                                    className="text-end">
                            Play method
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("tournament.play_method")}
                                          as="select"
                                          isInvalid={!!errors.tournament?.play_method}>
                                {formControlOptionsFromStrings(play_methods)}
                            </Form.Control>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.play_method"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group as={Row} controlId="tournamentTeamScoreTop">
                        <Form.Label column md={4}
                                    className="text-end">
                            Teams - top
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("tournament.team_score_top")}
                                          type="number"
                                          min={2} max={10}
                                          isInvalid={!!errors.tournament?.team_score_top}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.team_score_top"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            {(play_method === "OverTheBoard") && (
                <>
                    <Form.Group as={Row} controlId="tournamentVenue" className="mb-1">
                        <Form.Label column md={2}
                                    className="text-end">
                            Venue
                        </Form.Label>
                        <Col md={10}>
                            <AdminOnlyLocationCombobox onChange={setVenue}
                                                       selectedLocation={venue}
                                                       fieldError={errors.tournament?.venue_id}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.venue_id"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="tournamentVenueInfo" className="mb-1">
                        <Form.Label column md={2}
                                    className="text-end">
                            Venue info
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control {...register("tournament.venue_info")}
                                          as="textarea"
                                          isInvalid={!!errors.tournament?.venue_info}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.venue_info"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </>
            )}

            {(play_method.includes("Online")) && (
                <>
                    <Form.Group as={Row} controlId="tournamentConferenceLink" className="mb-1">
                        <Form.Label column md={2}
                                    className="text-end">
                            Conference link
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control {...register("tournament.conference_link")}
                                          isInvalid={!!errors.tournament?.conference_link}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.conference_link"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </>
            )}

            <Row className="mb-1">
                <Col md={6}>
                    <Form.Group as={Row}
                                controlId="tournamentClockIncrement">
                        <Form.Label column md={4}
                                    className="text-end">
                            Clock inc.
                        </Form.Label>
                        <Col md={8}>
                            <TimeDurationInput value={Number(watch("tournament.clock_increment"))}
                                               className="form-control"
                                               scale="s"
                                               onChange={(s: number) => setValue("tournament.clock_increment", s, {shouldDirty: true})}
                                               onError={(message) => handleFieldError("tournament.clock_increment", message)}
                                               isInvalid={!!errors.tournament?.clock_increment}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.clock_increment"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group as={Row} controlId="tournamentDuration">
                        <Form.Label column md={4}
                                    className="text-end">
                            Duration
                        </Form.Label>
                        <Col md={8}>
                            <TimeDurationInput value={Number(watch("tournament.duration"))}
                                               className="form-control"
                                               scale="s"
                                               onChange={(s: number) => setValue("tournament.duration", s, {shouldDirty: true})}
                                               onError={(message) => handleFieldError("tournament.duration", message)}
                                               isInvalid={!!errors.tournament?.duration}/>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.duration"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-1">
                <Col md={6}>
                    <Form.Group as={Row} controlId="tournamentType">
                        <Form.Label column md={4}
                                    className="text-end">
                            Type
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("tournament.tournament_type_id", {valueAsNumber: true})}
                                          as="select"
                                          isInvalid={!!errors.tournament?.tournament_type_id}>
                                <option key={-1} value={-1}>(Not selected)</option>
                                {formControlOptions(tournament_types)}
                            </Form.Control>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.tournament_type_id"
                                          type="invalid"/>
                            <small className="text-end text-info">
                                {checkTournamentTypeAndDuration(Number(watch("tournament.tournament_type_id")),
                                    Number(watch("tournament.duration")))}
                            </small>
                        </Col>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group as={Row} controlId="tournamentVisibility">
                        <Form.Label column md={4}
                                    className="text-end">
                            Visibility
                        </Form.Label>
                        <Col md={8}>
                            <Form.Control {...register("tournament.visibility")}
                                          as="select"
                                          isInvalid={!!errors.tournament?.visibility}>
                                {formControlOptionsFromStrings(tournament_visibility)}
                            </Form.Control>
                            <ErrorMessage as={<Form.Control.Feedback/>}
                                          name="tournament.visibility"
                                          type="invalid"/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group as={Row} controlId="tournamentEntryFee">
                <Form.Label column md={2}
                            className="text-end">
                    Entry fee
                </Form.Label>
                <Col md={10}>
                    <Form.Control {...register("tournament.entry_fee")}
                                  type="number"
                                  step="any"
                                  min={0}
                                  isInvalid={!!errors.tournament?.entry_fee}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="tournament.entry_fee"
                                  type="invalid"/>
                </Col>
            </Form.Group>
        </Container>
    );
};

export default TournamentDetails;
