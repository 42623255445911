import {Group} from "../../models/Group";
import React, {useEffect, useState} from "react";
import {UserInfo} from "../../models/User";
import {
    chessMasterApi,
    useSearchUserInfosQuery,
    useUpdateGroupAddingUserMutation,
    useUpdateGroupRemovingUserMutation
} from "../../api/chessMasterApi";
import {partitionChanges} from "../../util/partitionChanges";
import Modal from "react-bootstrap/Modal";
import GroupMembershipEditor from "./GroupMembershipEditor";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import GroupMembershipAddUsers from "./GroupMembershipAddUsers";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {useDispatch} from "react-redux";

interface Props {
    group: Group
    onClose: () => void
}

const GroupMembershipAddUsersModal: React.FC<Props> = ({group, onClose}) => {
    const dispatch = useDispatch();
    const [assignedUsers, setAssignedUsers] = useState<UserInfo[]>([]);
    const [updateGroupAddingUser] = useUpdateGroupAddingUserMutation();
    const [updateGroupRemovingUser] = useUpdateGroupRemovingUserMutation();
    const {data: groupMembers, isLoading} = useSearchUserInfosQuery({group_id: group.id});
    useEffect(() => setAssignedUsers(groupMembers ?? []), [groupMembers]);
    // the addToGroup flag is needed because when GroupMembershipAddUsers creates a new
    // user with the group_id already set. On the other hand when users are selected from the list
    // they need to be added to the group.
    const updateAssignedUsers = (group: Group, users: UserInfo[], addToGroup=false) => {
        const {added, removed} = partitionChanges(assignedUsers.map((ui) => ui.id), users.map((u) => u.id));

        if (addToGroup) {
            added.forEach((userId) => updateGroupAddingUser({id: group.id, userId}));
        }
        removed.forEach((userId) => updateGroupRemovingUser({id: group.id, userId}));

        if ((added.length > 0 && addToGroup) || removed.length > 0) {
            const body = [
                ...(added.length > 0) ? [`Added ${added.length} ${(added.length === 1) ? "member" : "members"}`] : [],
                ...(removed.length > 0) ? [`Removed ${removed.length} ${(removed.length === 1) ? "member" : "members"}`] : [],
            ].join(", ");

            dispatch(addToast(newToastMessage({
                priority: "success",
                heading: `Group ${group.name} updated`,
                body
            })));
        }
        setAssignedUsers(users);
    };
    useEffect(() => {
            // force reload of users from server
            dispatch(chessMasterApi.util.invalidateTags(["UserInfo"]))
        },
        [dispatch, group]
    );

    return (
        <Modal show
               size="lg"
               onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Edit members in group “{group.name}”</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GroupMembershipAddUsers assignedUsers={assignedUsers}
                                         group={group}
                                         addUsers={(users: UserInfo[], addToGroup = false) => updateAssignedUsers(group, [...assignedUsers, ...users], addToGroup)}/>
            </Modal.Body>
            <Modal.Body>
                <GroupMembershipEditor group={group}
                                       assignedUsers={assignedUsers}
                                       setAssignedUsers={(users) => updateAssignedUsers(group, users)}
                                       isLoading={isLoading}/>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup className="me-1">
                        <Button variant="secondary"
                                onClick={onClose}>
                            Close
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default GroupMembershipAddUsersModal;