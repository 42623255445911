import React from "react";
import {Tournament} from "../../models/Tournament";
import {formatMatchStatusDescription, Match} from "../../models/Match";
import Button from "react-bootstrap/Button";
import {BsPencilSquare} from "react-icons/bs";

interface Props {
    tournament: Tournament
    match: Match
    setUpdateResultForMatch: (match: Match) => void
    allowMatchUpdate?: boolean
}

const MatchResultStatus: React.FC<Props> = ({tournament, match, setUpdateResultForMatch, allowMatchUpdate = true}) => (
    <>
        {formatMatchStatusDescription(match.status)}
        {(tournament.controls?.director && allowMatchUpdate) && (
            <Button variant="link"
                    onClick={() => setUpdateResultForMatch(match)}>
                <BsPencilSquare/>
            </Button>
        )}
    </>
);

export default MatchResultStatus;