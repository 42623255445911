import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {formatMoney} from "accounting";
import {TournamentFinancials} from "../../models/TournamentFinancials";
import {TournamentLite} from "../../models/Tournament";


interface Props {
    financials: TournamentFinancials
}

const ActiveTournamentFinancialSummary: React.FC<Props> = ({financials}) => {
    const formatStatus = (tournament: TournamentLite) => (
        (tournament.status === 'Playing') ?
            <small>{tournament.status} (round {tournament.cur_round} of {tournament.num_rounds})</small> :
            <small>{tournament.status} ({tournament.num_rounds} rounds)</small>
    );

    return (
        <Container className="w-50">
            <Row>
                <Col md={6} className="text-muted">Status</Col>
                <Col md={6} className="text-end">{formatStatus(financials.tournament)}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Players entered</Col>
                <Col md={6} className="text-end">{financials?.n_players}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Players participating</Col>
                <Col md={6} className="text-end">{financials?.n_participating}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Players paid</Col>
                <Col md={6} className="text-end">{financials?.n_paid}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Expected fees</Col>
                <Col md={6} className="text-end">
                    {formatMoney(financials?.expected_fees, "$")}
                </Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Paid fees</Col>
                <Col md={6} className="text-end">{formatMoney(financials.paid_fees, "$")}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Outstanding fees</Col>
                <Col md={6} className="text-end">{formatMoney(financials?.outstanding_fees, "$")}</Col>
            </Row>
            <Row>
                <Col md={6} className="text-muted">Total expenses</Col>
                <Col md={6} className="text-end">{formatMoney(financials?.total_expenses, "$")}</Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col md={6} className="text-muted">Expected profit</Col>*/}
            {/*    <Col md={6} className="text-end">(unknown)</Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col md={6} className="text-muted">Current profit</Col>*/}
            {/*    <Col md={6} className="text-end">(unknown)</Col>*/}
            {/*</Row>*/}
        </Container>
    );
};

export default ActiveTournamentFinancialSummary;
