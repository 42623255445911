import React, {useEffect, useMemo, useState} from "react";
import {FieldError} from "react-hook-form";
import {AsyncTypeahead, TypeaheadResult} from "react-bootstrap-typeahead";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import {BsInfoCircle} from "react-icons/bs";

import {useSearchGroupNamesQuery} from "../api/chessMasterApi";
import {selectConstants} from "../features/constants/constantsSlice";
import {formControlOptions} from "../util/formControl";
import {GroupType} from "../models/GroupType";
import {useAppSelector} from "../store";
import {formatErrorMessage} from "../middleware/RTKQueryErrorLogger";
import {groupSearchHelpPopover} from "../util/SearchHelpPopover";
import {GroupMinSchema} from "../models/Group";

interface Props {
    onChange: (group: TypeaheadResult<GroupMinSchema> | undefined) => void;
    selectedGroup?: GroupMinSchema
    fieldError?: FieldError | {id?: FieldError, name?: FieldError}
    fixGroupTypeId?: GroupType["id"]
    placeholder?: string
    defaultGroups?: GroupMinSchema[]
    setErrorMessage?: (message: string | null) => void
    actions?: [React.ReactElement]
    allowNew?: boolean | ((results: Array<Object|string>, props: Object) => boolean)
    newSelectionPrefix?: string
}

const GroupPicker: React.FC<Props> = ({onChange, selectedGroup, fixGroupTypeId, fieldError, placeholder = "Group search ...", defaultGroups = [], setErrorMessage, actions, allowNew, newSelectionPrefix}) => {
    const {group_types} = useAppSelector(selectConstants);
    const selected = useMemo(() => (selectedGroup) ? [selectedGroup] : [], [selectedGroup]);  // needs to be an array
    const [query, setQuery] = useState("");
    const [groupTypeId, setGroupTypeId] = useState(fixGroupTypeId);
    const {data: groups, isLoading, error} = useSearchGroupNamesQuery({query, group_type_id: groupTypeId}, {skip: query.length < 3});

    useEffect(
        () => setErrorMessage?.((error) ? formatErrorMessage(error) : null),
        [error, setErrorMessage]
    );

    return (
        <InputGroup className={(fieldError || error) ? "is-invalid" : ""}>
            <AsyncTypeahead<GroupMinSchema> id="Group-picker"
                                            placeholder={placeholder}
                                            onChange={(values) => {
                                                if (values.length === 0) {
                                                    setQuery("");
                                                }
                                                onChange(values[0] as TypeaheadResult<GroupMinSchema>);
                                            }}
                                            labelKey="name"
                                            onSearch={setQuery}
                                            isLoading={isLoading}
                                            isInvalid={!!fieldError}
                                            clearButton
                                            filterBy={() => true}
                                            minLength={(defaultGroups.length > 0) ? 0 : 2}
                                            options={(groups && query) ? groups : defaultGroups}
                                            selected={selected}
                                            allowNew={allowNew}
                                            newSelectionPrefix={newSelectionPrefix}/>
            <OverlayTrigger trigger="click"
                            placement="bottom"
                            overlay={groupSearchHelpPopover}>
                <Button variant="outline-primary">
                    <BsInfoCircle/>
                </Button>
            </OverlayTrigger>
            {(!fixGroupTypeId) && (
                <InputGroup.Text className="p-0">
                    <Form.Control as="select"
                                  value={groupTypeId}
                                  onChange={(ev) => {
                                      const selectedGroupTypeId = parseInt(ev.target.value, 10);
                                      setGroupTypeId(selectedGroupTypeId);
                                  }}
                                  disabled={!!fixGroupTypeId}
                                  aria-label="Group type filter"
                                  aria-describedby="group-type-filter-field">
                        <option value={-1}>(All)</option>
                        {formControlOptions(group_types)}
                    </Form.Control>
                </InputGroup.Text>
            )}
            {actions}
        </InputGroup>
    );
};

export default GroupPicker;