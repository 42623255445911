import React, {useContext, useMemo} from "react";

import {ActiveTournamentContext} from "./ActiveTournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {Group} from "../../models/Group";
import DataTable, {TableColumn} from "react-data-table-component";
import {
    ActiveTournamentTeamStandingsPlayersHeading,
    ActiveTournamentTeamStandingsPlayersTable
} from "./ActiveTournamentTeamStandingsPlayersTable";
import {Team} from "../../models/Team";
import {groupPlayersByTeam} from "../../util/groupPlayersByTeam";
import {sortPlayersInTeamsByPoints} from "../../util/sortPlayersInTeamsByPoints";
import TeamName from "./TeamName";

interface TeamIdAndPlayers {
    teamId: Group["id"]
    players: TournamentPlayer[]
}

const sortTeamsByPoints = (playersByTeam: Map<Group["id"], TournamentPlayer[]>, teamsByTeamId: Record<Group["id"], Team>, maxPlayers = 4) => {
    const teamsAndPlayers = Array.from(playersByTeam.entries()).map(([teamId, players]) => ({
        teamId,
        players: players.slice(0, maxPlayers)   // only show first maxPlayers players
    }));
    teamsAndPlayers.sort((a: TeamIdAndPlayers, b: TeamIdAndPlayers) => teamsByTeamId[b.teamId].points - teamsByTeamId[a.teamId].points);

    return teamsAndPlayers;
};

const ActiveTournamentTeamStandings: React.FC = () => {
    const {tournament} = useContext(ActiveTournamentContext);
    const teamsByTeamId = useMemo(
        () => Object.fromEntries(tournament.teams.map((team) => ([team.group_id, team]))),
        [tournament]
    );
    const orderedTeams = useMemo(
        () => {
            const playersByTeam = sortPlayersInTeamsByPoints(groupPlayersByTeam(tournament.players));
            return sortTeamsByPoints(playersByTeam, teamsByTeamId, tournament.team_score_top);
        },
        [teamsByTeamId, tournament]
    );
    const columns: TableColumn<TeamIdAndPlayers>[] = [{
        name: <span className="text-center">Team<br/>Score</span>,
        selector: (row) => teamsByTeamId[row.teamId].points,
        sortable: true,
        center: true,
        grow: 1,
    }, {
        selector: (row) => tournament.ref_group[row.teamId].name,
        name: "Team",
        sortable: true,
        wrap: true,
        grow: 4,
        cell: (row) => (
            <TeamName tournament={tournament}
                      team={(row.teamId) ? tournament.ref_group[row.teamId] : undefined}/>
        )
    }, {
        name: <ActiveTournamentTeamStandingsPlayersHeading/>,
        cell: (row) => <ActiveTournamentTeamStandingsPlayersTable players={row.players}/>,
        grow: 7
    }];

    return (
        <DataTable<TeamIdAndPlayers> columns={columns}
                                     data={orderedTeams}
                                     keyField="teamId"
                                     noDataComponent="(No teams)"/>
    );
};

export default ActiveTournamentTeamStandings;
