import {Role} from "./Role";
import {Group} from "./Group";
import {Gender} from "./Gender";
import {Tournament} from "./Tournament";

interface UserName {
    username: string
    given_name: string | null
    family_name: string
    gender_id: Gender["id"]
}

interface UserBase extends UserName {
    lichess_id: string | null
}

export interface NewUser extends UserName {
    new_password?: string
    disabled: boolean
    email_address: string | null
    lichess_password: string | null
    phone_num: string | null
    phone_num_note: string | null
    medical_note: string | null
    middle_name: string | null
    preferred_name: string | null
    birthday: string | null    // YYYY-MM-DD
    school_id: Group["id"] | null
    school_year: number | null
    group_ids: Group["id"][]
}

export interface NewUserWithRegistration {
    user: NewUser,
    guardian_id?: User["id"]    // write only, adds user as child of guardian
    tournament_key?: Tournament["key"]    // write only, registers user in tournament
}

export interface User extends NewUser {
    id: number
    email_address_confirmed: boolean
    lichess_id: string | null
    role_ids: Role["id"][]
}

export const newUser = (initFields: Partial<User> = {}): NewUser => ({
    username: "",
    disabled: false,
    lichess_password: null,
    new_password: "",
    email_address: null,
    phone_num: null,
    phone_num_note: null,
    medical_note: null,
    given_name: "",
    middle_name: "",
    family_name: "",
    preferred_name: "",
    birthday: null,
    school_id: null,
    school_year: null,
    gender_id: 4,
    group_ids: [],
    ...initFields
});

// This is a summary user object that's returned in a few places
export interface UserMinSchema extends UserBase {
    id: User["id"]
}

export interface UserInfo extends UserBase {
    id: User["id"]
    age: number
    groups: string[]
}

export const newUserInfo = (initFields: Partial<UserInfo> = {}): UserInfo => ({
    id: 0,
    username: "",
    gender_id: 4,
    lichess_id: null,
    given_name: "",
    family_name: "",
    age: 0,
    groups: [],
    ...initFields
});

export const isUser = (obj: any): obj is User => {
    return obj.id !== undefined
};

export interface ChildOfGuardianUpdate {
    child_id: UserInfo["id"]
    _delete?: boolean
}

export const newChildOfGuardianUpdate = (child_id: UserInfo["id"], initFields: Partial<ChildOfGuardianUpdate> = {}): ChildOfGuardianUpdate => ({
    child_id,
    ...initFields
});

export interface GuardianOfChildUpdate {
    guardian_id: UserInfo["id"]
    _delete?: boolean
}

export const newGuardianOfChildUpdate = (guardian_id: UserInfo["id"], initFields: Partial<GuardianOfChildUpdate> = {}): GuardianOfChildUpdate => ({
    guardian_id,
    ...initFields
});