
export const formatFullName = <T extends {given_name: string | null, family_name: string} | null>(user: T) => {
    if (user) {
        return (user.given_name) ? `${user.given_name} ${user.family_name}` : user.family_name;
    } else {
        return "";
    }
};

export const formatFullNameWithUsername = <T extends {given_name: string | null, family_name: string, username: string} | null>(user: T) => {
    if (user) {
        return [
            `${formatFullName(user)}`,
            ...(user.username) ? [`(${user.username})`] : []
        ].join(" ");
    } else {
        return "";
    }
};

// this is useful for sorting

export const formatFamilyGivenName = <T extends {given_name: string | null, family_name: string} | null>(user: T) => {
    if (user) {
        return (user.given_name) ? `${user.family_name}, ${user.given_name}` : user.family_name;
    } else {
        return "";
    }
};