import React from "react";

import * as yup from "yup";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {Group, isGroup, NewGroup} from "../../models/Group";
import {editGroup} from "./groupsTabSlice";
import GroupEditDetails from "./GroupEditDetails";
import {useUpdateGroupMutation} from "../../api/chessMasterApi";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";
import {payloadChangesWithId} from "../../util/payloadChangesWithId";
import {addToast, newToastMessage} from "../toast/toastSlice";

const GroupEditDetailsCard: React.FC = () => (
    <Card>
        <Card.Header>
            Details
        </Card.Header>
        <Card.Body>
            <GroupEditDetails/>
        </Card.Body>
    </Card>
);

export const groupSchema = yup.object().shape({
    name: yup.string().required().label("Name"),
    description: yup.string().nullable().label("Description"),
    address_id: yup.number().nullable().label("Address"),
    postal_address_id: yup.number().nullable().label("Postal address"),
    group_type_id: yup.number().typeError("Group type is required").label("Group type")
});

interface GroupEditProps {
    group: NewGroup | Group
    onClose: (group: Group | undefined) => void
}

const GroupEdit: React.FC<GroupEditProps> = (props) => {
    const dispatch = useDispatch();
    const [updateGroup, {isLoading: isUpdating}] = useUpdateGroupMutation();
    const hideEditGroup = () => {
        props.onClose(undefined);
    };
    const formMethods = useForm<Group>({
        defaultValues: props.group,
        resolver: yupResolver(groupSchema)
    });
    const onSubmit = (group: Group) => {
        const updatedGroup = Object.assign({}, props.group, group);

        updateGroup({group: isGroup(props.group) ? payloadChangesWithId(props.group, updatedGroup) : updatedGroup})
            .unwrap()
            .then((group) => {
                dispatch(addToast(newToastMessage({
                    priority: "success",
                    heading: `Group ${group.name} updated`,
                    body: "Update successful"
                })));
                return group;
            })
            .then((group) => props.onClose(group))
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch);
            });
    };

    return (
        <Modal show={editGroup !== undefined}
               onHide={() => hideEditGroup()}
               backdrop="static"
               keyboard={!formMethods.formState.isDirty}
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}
                      autoComplete="off"
                      noValidate={true}>
                    <Modal.Header>
                        <Modal.Title>Edit group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <GroupEditDetailsCard/>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar>
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={hideEditGroup}
                                        disabled={isUpdating}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary"
                                        disabled={!formMethods.formState.isDirty || isUpdating}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default GroupEdit;