import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import {Tournament} from "../models/Tournament";
import {UserMinSchema} from "../models/User";
import {formatFullName} from "../util/formatFullName";
import {useGetTournamentPlayerDetailsQuery} from "../api/chessMasterApi";
import {selectConstantsGenderById} from "../features/constants/constantsSlice";
import {UserContactDetail} from "../models/UserContactDetails";
import {Group, GroupMinSchema} from "../models/Group";
import UserEMailAddress from "./UserEMailAddress";
import {useAppSelector} from "../store";
import UserLichessLink from "./UserLichessLink";

const fullName = (detail: UserContactDetail) => (
    [
        detail.given_name,
        ...(detail.preferred_name) ? [`(${detail.preferred_name})`] : [],
        ...(detail.middle_name) ? [detail.middle_name] : [],
        ...(detail.family_name) ? [detail.family_name] : []
    ].join(" ")
);

interface PersonalDetailCardProps {
    guardian?: boolean
    detail: UserContactDetail
    ref_group: Record<Group["id"], GroupMinSchema>
    index: number
}

export const PersonalDetailCard: React.FC<PersonalDetailCardProps> = ({guardian = false, detail, index, ref_group}) => {
    const genderById = useAppSelector(selectConstantsGenderById);
    const groups = detail.group_ids.map((id) => ref_group[id].name);
    // const groups = [...detail.group_ids];

    return (
        <Card key={index} className={(index > 0) ? "mt-2" : undefined}>
            <Card.Header>
                {(guardian) ? "Guardian" : "Player"} &mdash; {fullName(detail)}
            </Card.Header>

            <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col md={4}>School year</Col>
                            <Col md={8}>{detail.school_year || <span className="text-muted">(Not specified)</span>}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Username</Col>
                            <Col md={8}>{detail.username}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>E-mail</Col>
                            <Col md={8}>
                                <UserEMailAddress user={detail}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>Gender</Col>
                            <Col md={8}>{genderById[detail.gender_id].description}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Phone</Col>
                            <Col md={8}>{detail.phone_num || <span className="text-muted">(No phone)</span>}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Phone notes</Col>
                            <Col md={8}>{detail.phone_num_note || <span className="text-muted">(No notes)</span>}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>Medical notes</Col>
                            <Col md={8}>{detail.medical_note || <span className="text-muted">(No notes)</span>}</Col>
                        </Row>
                        {(!guardian && detail.lichess_id) && (
                            <>
                                <Row>
                                    <Col md={4}>Lichess username</Col>
                                    <Col md={8}><UserLichessLink user={detail}/></Col>
                                </Row>
                                <Row>
                                    <Col md={4}>Lichess password</Col>
                                    <Col md={8}>
                                        {detail.lichess_password || <span className="text-muted">
                                            (No Lichess password)
                                        </span>}
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col md={4}>Groups</Col>
                            <Col md={8}>
                                {(groups.length > 0) ? groups.sort().join(", ") : <span className="text-muted">(No groups)</span>}
                            </Col>
                        </Row>
                    </Container>
            </Card.Body>
        </Card>
    );
};


interface Props {
    tournament: {key: Tournament["key"]}
    user: UserMinSchema
    onClose: () => void
}

const PersonalDetails: React.FC<Props> = ({tournament, user, onClose}) => {
    const {data: details} = useGetTournamentPlayerDetailsQuery({key: tournament.key, userId: user.id});
    const player = details?.ref_user[details.player_ids[0]];

    return (
        <Modal show
               onHide={onClose}
               size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Contact details for {formatFullName(user)}
                </Modal.Title>
            </Modal.Header>


            <Modal.Body>
                {(player) ? (
                    <PersonalDetailCard index={0}
                                        detail={player}
                                        ref_group={details.ref_group}/>
                ) : (
                    <Spinner className="text-center" animation="border"/>
                )}
            </Modal.Body>

            <Modal.Body>
                {(player) ? player.guardian_ids.map((guardian_id) => (
                    <PersonalDetailCard index={guardian_id}
                                        guardian
                                        detail={details.ref_user[guardian_id]}
                                        ref_group={details.ref_group}/>
                )) : (
                    <Spinner className="text-center" animation="border"/>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PersonalDetails;
