import React, {useState} from "react";

import Button from "react-bootstrap/Button";
import {InputGroup} from "react-bootstrap";
import Card from "react-bootstrap/Card";

import {BsPlus} from "react-icons/bs";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {FieldError} from "react-hook-form";

import {Location, NewLocation, newLocation} from "../models/Location";
import {formControlFeedback} from "../util/formControl";
import {LocationForm} from "./LocationForm";
import {mergeUsingId} from "../util/mergeUsingId";
import {useSearchLocationsQuery, useUpdateLocationMutation} from "../api/chessMasterApi";

interface LocationInputCardProps {
    onSubmit: (location: NewLocation) => void
    onCancel: () => void
}
const LocationInputCard: React.FC<LocationInputCardProps> = ({onSubmit, onCancel}) => (
    <Card className="m-2">
        <Card.Body>
            <Card.Title>New location</Card.Title>
            <LocationForm location={newLocation()} onSubmit={onSubmit} onCancel={onCancel}/>
        </Card.Body>
    </Card>
);

export const locationKey = (location: Location) => (
    `${location.address1} ${location.address2} ${location.locality} ${location.state} ${location.postcode}`
);

interface LocationComboboxProps {
    onChange: (location: Location) => void
    selectedLocation: Location | undefined
    fieldError: FieldError | undefined
}

export const LocationCombobox: React.FC<LocationComboboxProps> = ({onChange, selectedLocation, fieldError}) => {
    const [updateLocation] = useUpdateLocationMutation();
    const selected = (selectedLocation) ? [selectedLocation] : [];  // needs to be an array
    const [query, setQuery] = useState("");
    const [showLocationInputForm, setShowLocationInputForm] = useState(false);
    const createLocation = (location: NewLocation) => {
        updateLocation({location})
            .unwrap()
            .then((location) => {
                onChange(location);
                setShowLocationInputForm(false);
            });
    };
    const {data: locations, isFetching} = useSearchLocationsQuery({query});

    return (
        <>
            <InputGroup className={(fieldError) ? "w-100 is-invalid" : "w-100"}>
                {/* shouldn't be needed: Feedback is only shown when it's immediately
                  * preceded by an "is-invalid" class. That should be set by isInvalid prop but other
                  * AsyncTypeahead components get in between.
                  */}
                <AsyncTypeahead<Location> id="Location-search"
                                          placeholder="Location search ..."
                                          onChange={(values) => onChange(values[0])}
                                          labelKey={locationKey}
                                          onSearch={setQuery}
                                          isLoading={isFetching}
                                          isInvalid={!!fieldError}
                                          clearButton
                                          filterBy={() => true}
                                          options={(locations) ? mergeUsingId(locations, selected) : []}
                                          selected={selected}/>
                <Button onClick={() => setShowLocationInputForm(true)}
                        variant="outline-secondary">
                    <BsPlus/>
                </Button>
            </InputGroup>
            {formControlFeedback(fieldError)}
            {(showLocationInputForm) &&
                <LocationInputCard onSubmit={(location) => createLocation(location)}
                                   onCancel={() => setShowLocationInputForm(false)}/>}
        </>
    );
};