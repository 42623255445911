import React, {useCallback, useContext, useState} from "react";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
    useUpdateTournamentAddingPlayerMutation,
    useUpdateTournamentRemovingPlayerMutation
} from "../../api/chessMasterApi";
import {selectConstantsNamedRoleInRoleIds} from "../constants/constantsSlice";
import {selectLogin} from "../login/loginSlice";
import ActiveTournamentChildrenAdd from "./ActiveTournamentChildrenAdd";
import {isTournamentComplete, Tournament} from "../../models/Tournament";
import {UserInfo} from "../../models/User";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {useAppDispatch, useAppSelector} from "../../store";
import {ActiveTournamentContext} from "./ActiveTournament";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {formatMoney} from "accounting";

interface Props {
    onClose: (didConfirm: boolean) => void;
}

const ConfirmRemoveUserFromTournament: React.FC<Props> = ({onClose}) => {
    const {tournament} = useContext(ActiveTournamentContext);
    const {fullName} = useAppSelector(selectLogin);

    return (
        <Modal show
               onHide={() => onClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm remove from tournament</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Please confirm removing {fullName} from the tournament “{tournament.name}”.
                </p>
                {(Number(tournament.entry_fee) > 0) && (
                    <p>This will generate a credit of {formatMoney(tournament.entry_fee)}.</p>
                )}
            </Modal.Body>

            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup className="me-1">
                        <Button variant="secondary"
                                onClick={() => onClose(false)}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant="danger"
                                onClick={() => onClose(true)}>
                            Remove
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

const isUserInTournament = (players: TournamentPlayer[], playerId: UserInfo["id"]) => (
    players.some((player) => player.user_id === playerId)
);

const ActiveTournamentSummaryActions: React.FC = () => {
    const dispatch = useAppDispatch();
    const {tournament} = useContext(ActiveTournamentContext);
    const {userId, role_ids, fullName} = useAppSelector(selectLogin);
    const isGuardian = useAppSelector(selectConstantsNamedRoleInRoleIds("Adult/Parent", role_ids));
    const [runConfirmRemovePlayerFromTournament, setRunConfirmRemovePlayerFromTournament] = useState(false);
    const [runAddChildrenModal, setRunAddChildrenModal] = useState(false);
    const [updateTournamentAddingPlayer] = useUpdateTournamentAddingPlayerMutation();
    const [updateTournamentRemovingPlayer] = useUpdateTournamentRemovingPlayerMutation();
    const addPlayerToTournament = (tournament: Tournament, userId: UserInfo["id"]) => {
        updateTournamentAddingPlayer({key: tournament.key, userId})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Player added",
                    body: `Added ${fullName} to ${tournament.name}`
                })));
            });
    };
    const removePlayerFromTournament = useCallback((tournament: Tournament, userId: UserInfo["id"]) => {
        updateTournamentRemovingPlayer({key: tournament.key, userId})
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastSuccess({
                    heading: "Player removed",
                    body: `Removed ${fullName} from ${tournament.name}`
                })));
            });
    }, [dispatch, fullName, updateTournamentRemovingPlayer]);
    const closeConfirmRemovePlayerModal = useCallback((didConfirm: boolean) => {
        if (didConfirm) {
            removePlayerFromTournament(tournament, userId);
        }
        setRunConfirmRemovePlayerFromTournament(false);
    }, [removePlayerFromTournament, tournament, userId]);

    return (
        <>
            {(runAddChildrenModal) && (
                <ActiveTournamentChildrenAdd
                    onClose={() => setRunAddChildrenModal(false)}/>
            )}
            {(runConfirmRemovePlayerFromTournament) && (
                <ConfirmRemoveUserFromTournament onClose={closeConfirmRemovePlayerModal}/>
            )}

            <Stack gap={1}>
                {(isGuardian) && (
                    <Button onClick={() => setRunAddChildrenModal(true)}
                            disabled={isTournamentComplete(tournament)}>
                        Enter children ...
                    </Button>
                )}
                {(!isUserInTournament(tournament.players, userId)) ? (
                    <Button onClick={() => addPlayerToTournament(tournament, userId)}
                            disabled={isTournamentComplete(tournament)}>
                        Enter me
                    </Button>
                ) : (
                    <Button variant="danger"
                            onClick={() => setRunConfirmRemovePlayerFromTournament(true)}
                            disabled={isTournamentComplete(tournament)}>
                        Remove me ...
                    </Button>
                )}
            </Stack>
        </>
    );
};

export default ActiveTournamentSummaryActions;
