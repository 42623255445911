import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery
} from "@reduxjs/toolkit/dist/query/react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {Mutex, withTimeout} from "async-mutex";
import {LoginRequest, LoginResponse, LogoutResponse, TokenRefreshResponse} from "../Api";
import {Group, GroupMinSchema} from "../models/Group";
import {
    ChildOfGuardianUpdate,
    GuardianOfChildUpdate,
    User,
    UserInfo
} from "../models/User";
import {RootState} from "../store";
import {Audit} from "../models/Audit";
import {Role} from "../models/Role";
import {GameDescription} from "../models/GameDescription";
import {Location} from "../models/Location";
import {
    InitiatePayment,
    Payment,
    PaymentItem,
    PaymentStatus,
    PaymentStatusDescription,
    PaymentSystem
} from "../models/Payment";
import {PlayerRatingHistory, Rating} from "../models/Rating";
import {TournamentType} from "../models/TournamentType";
import {RatingPeriod} from "../models/RatingPeriod";
import {Tournament, TournamentLite} from "../models/Tournament";
import {
    addPlayerToTeamUpdate,
    deletePlayerUpdate,
    removePlayerFromTeamUpdate
} from "../models/PlayerUpdate";
import {TournamentStaffUpdate} from "../models/TournamentStaff";
import {Team} from "../models/Team";
import {Match, MatchStatus} from "../models/Match";
import {TournamentStatus, TournamentStatusDescription} from "../models/TournamentStatus";
import {UserGroup, UserGroupUpdate} from "../models/UserGroup";
import {GroupType} from "../models/GroupType";
import {Title} from "../models/Title";
import {Gender} from "../models/Gender";
import {TournamentFinancials} from "../models/TournamentFinancials";
import {
    JwtClaims,
    refreshCredentials,
    setLoginIsLoggedIn
} from "../features/login/loginSlice";
import {Game, NewGame} from "../models/Game";
import {Color} from "@lubert/chess.ts";
import {UserContactDetails} from "../models/UserContactDetails";
import {GroupUser} from "../models/GroupUser";
import {FinanceReport} from "../models/FinanceReport";
import {TournamentExpense} from "../models/TournamentExpense";
import {saveResponseUsingHiddenElement} from "../util/saveResponseUsingHiddenElement";
import {PlayerSearch} from "../models/PlayerSearch";

// import {ResultTypeFrom} from "@reduxjs/toolkit/dist/query/endpointDefinitions";

export interface JoinGameRequest {
    gameId: Game["id"]
    colour: Color
}

export interface SpectateGameRequest {
    gameId: Game["id"]
}

export interface PayPalConfig {
    client_id: string
    name: "paypal" | "paypal-sandbox"
}

export interface StripeConfig {
    public_key: string
    name: "stripe" | "stripe-sandbox"
}

type PaymentConfig = PayPalConfig | StripeConfig

export interface ConstantsResponse {
    titles: Title[]
    states: string[]
    genders: Gender[]
    game_types: string[]
    group_types: GroupType[]
    play_methods: string[]
    tournament_types: TournamentType[]
    tournament_status: TournamentStatus[]
    tournament_visibility: string[]
    match_status: MatchStatus[]
    payment_systems: {[system in PaymentSystem]?: PaymentConfig} | undefined
    payment_status: PaymentStatus[]
    roles: Role[]
    lichess_url?: string
}

export interface SearchUserRequest {
    query?: string | null
    group_id?: Group["id"]
}

export interface SearchTournamentRequest {
    query?: string
    status?: string
}

export interface TournamentForUser {
    add_time: string // timestamp
    initial_rating: number | null
    pairing_id: number | null
    participating: boolean
    points: number
    tournament: TournamentLite
    user: UserInfo
}

export interface TournamentOnlinePlayers {
    online_players: UserInfo["id"][]
}

export type SaveSettingsResponse = void        // XXX fill this in with something useful

export interface SaveSettingsRequest {
    settings: string        // XXX fill this in with something useful
}

export interface CreateGameResponse {
    match_server_url: string
    game_id: Game["id"]
}

export type ChildWithGuardian = {child: UserInfo, guardian: UserInfo, guardian_type: string | null}
export type ChildrenForUserResponse = ChildWithGuardian[];

export const noPairPayloadFilter = (team: Team) => (
    ({group_id: team.group_id, no_pair: team.no_pair})
);
// extract just the attributes we need when updating match status
export const updateMatchStatusFilter = (match: Partial<Match>) => (
    ({id, status, round_num}) => ({id, status, round_num}))(match);

// if the user has a white_player empty and a black player set, then flip the black player to white
// so that on a bye the black_player is empty
const makeWhitePlayerSetOnBye = ({white_player_id, black_player_id}: Pick<Match, "white_player_id" | "black_player_id">) => {
    return (!white_player_id && black_player_id) ? {white_player_id: black_player_id, black_player_id: white_player_id}
                                                 : {white_player_id, black_player_id};
};
export const newMatchRePairFilter = ({id, white_player_id, black_player_id, status}: Match) => {
    return ({
        id,
        ...makeWhitePlayerSetOnBye({white_player_id, black_player_id}),
        status
    });
};
export const updateMatchRePairFilter = (match: Match) => (
    ({id, white_player_id, black_player_id, status}) => ({
        id,
        ...makeWhitePlayerSetOnBye(match),
        status
    }))(match);

// const updateCacheWithResult = <T>(endpointName: Parameters<typeof chessMasterApi.util.updateQueryData>[0], endpointCacheTag: (object: T) => any) => async ({object, ...patch}: any, {dispatch, queryFulfilled}: any): Promise<void> => {
//     const {data: updatedObject} = await queryFulfilled;
//
//     dispatch(chessMasterApi.util.updateQueryData(endpointName, endpointCacheTag(updatedObject), (draft: any/* T */) => {
//         Object.assign(draft, patch);
//     }));
// };

const doesTokenNeedRefresh = (exp: JwtClaims["exp"] | null) => {
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    return (exp !== null) ? exp <= secondsSinceEpoch : false;
};

// nicked from https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const baseQuery = fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, {getState}) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const jwtToken = (getState() as RootState).login.jwtToken;
        if (jwtToken) {
            headers.set('Authorization', `Bearer ${jwtToken}`);
        }
        return headers;
    }
});

// 5s timeout, don't know whether that's right
const refreshMutex = withTimeout(new Mutex(), 5000);

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    await refreshMutex.runExclusive(async () => {
        const exp = (api.getState() as RootState).login.exp;

        if (doesTokenNeedRefresh(exp)) {
            // try to get a new token (we use fetch to avoid "Authorization" header on req)
            const response = await fetch(`/api/refresh`);

            if (response.ok) {
                // store the new token
                api.dispatch(refreshCredentials(await response.json()));
            } else {
                api.dispatch(setLoginIsLoggedIn(false));
            }
        }
    });
    return baseQuery(args, api, extraOptions);
};

export const chessMasterApi = createApi({
    reducerPath: "chessMasterApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        "Audit",
        "Group", "GroupType", "GroupUser", "GroupMinSchema", "UserGroup",
        "Game", "GameDescription",
        "UserContactDetail",
        "Location",
        "Payment", "PaymentItem",
        "PlayerSearch",
        "Rating", "RatingPeriod", "PlayerRatingHistory",
        "Role",
        "Tournament", "TournamentLite", "TournamentForUser", "TournamentFinancials",
        "TournamentExpense", "TournamentOnlinePlayers",
        "Match",
        "User", "UserInfo",
        "ChildWithGuardian",
        "FinanceReport"
    ],
    keepUnusedDataFor: 5,
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: ({username, password}) => ({
                url: "/login",
                method: "POST",
                body: {username, password}
            })
        }),
        logout: builder.mutation<LogoutResponse, void>({
            query: () => ({
                url: "/logout",
                method: "DELETE"
            })
        }),
        changeRole: builder.mutation<LoginResponse, {password: string, roleId: Role["id"] | null}>({
            query: ({roleId, password}) => ({
                url: "/login/role",
                method: "POST",
                body: {password, role_id: roleId}
            }),
            invalidatesTags: (result) => (result) ? [
                "Tournament", "Audit"
            ] : []
        }),
        loginAs: builder.mutation<LoginResponse, {password: string, username: string | null}>({
            query: ({username, password}) => ({
                url: "/login/as",
                method: "POST",
                body: {password, username}
            }),
            invalidatesTags: (result) => (result) ? [
                "Tournament", "TournamentLite", "Audit"
            ] : []
        }),
        cancelLoginAs: builder.mutation<LoginResponse, void>({
            query: () => ({
                url: "/login/as/cancel",
                method: "POST",
            }),
            invalidatesTags: (result) => (result) ? [
                "Tournament", "TournamentLite", "Audit"
            ] : []
        }),
        saveSettings: builder.mutation<SaveSettingsResponse, SaveSettingsRequest>({
            query: ({settings}) => ({
                url: "/settings/save",
                method: "POST",
                body: settings
            })
        }),
        confirmEMail: builder.mutation<void, {token: string}>({
            query: ({token}) => ({
                url: `/confirm/${token}`,
                method: "POST"
            })
        }),
        requestPasswordReset: builder.mutation<void, {email_address: string}>({
            query: ({email_address}) => ({
                url: "/reqpwdreset",
                method: "POST",
                body: {email_address}
            })
        }),
        passwordReset: builder.mutation<LoginResponse, {token: string, new_password: string}>({
            query: ({token, new_password}) => ({
                url: "/pwdreset",
                method: "POST",
                body: {token, new_password}
            })
        }),
        // we bypass baseQuery because the refresh request can't have an authorization
        // header. This lets us make the request without that.
        tokenRefresh: builder.query<TokenRefreshResponse, void>({
            queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
                const response = await fetch(`/api/refresh`);
                return (response.ok) ? {data: await response.json()}
                                     : {error: await response.json()};
            },
            keepUnusedDataFor: 60,
        }),
        constants: builder.query<ConstantsResponse, void>({
            query: () => "/constants",
            keepUnusedDataFor: 60,
        }),
        registerUser: builder.mutation<TokenRefreshResponse, {user: Partial<User>}>({
            query: ({user}) => ({
                url: "/register",
                method: "POST",
                body: user
            })
        }),
        changePassword: builder.mutation<void, {user_id: User["id"], auth_password: string, new_password: string}>({
            query: ({user_id, auth_password, new_password}) => ({
                url: "/chgpwd",
                method: "POST",
                body: {user_id, auth_password, new_password}
            })
        }),
        unlinkUserFromLichess: builder.mutation<User, {id: User["id"]}>({
            query: ({id}) => ({
                url: `/lichess/link/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "User", id: result.id},
                {type: "UserInfo", id: result.id},
                "Audit"
            ] : ["Group"],
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        getGroup: builder.query<Group, {id: Group["id"]}>({
            query: ({id}) => `/group/${id}`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    {type: "Group", id : arg.id} as const,
                ] : ["Group"]
            )
        }),
        searchGroups: builder.query<Group[], {query?: string, group_type_id?: GroupType["id"], offset?: number, limit?: number, editable?:boolean}>({
            query: ({query, group_type_id, offset, limit, editable}) => ({
                url: "/group",
                params: {
                    ...(query && query.length > 0) ? {query} : {},
                    ...(group_type_id && group_type_id >= 0) ? {group_type_id} : {},
                    ...(offset !== undefined) ? {offset} : {},
                    ...(limit !== undefined) ? {limit} : {},
                    ...(editable) ? {editable} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "Group", id} as const)),
                    "Group"
                ] : ["Group"]
            )
        }),
        searchGroupNames: builder.query<GroupMinSchema[], {query?: string, group_type_id?: GroupType["id"], offset?: number, limit?: number}>({
            query: ({query, group_type_id, offset, limit}) => ({
                url: "/group/name",
                params: {
                    ...(query && query.length > 0) ? {query} : {},
                    ...(group_type_id && group_type_id >= 0) ? {group_type_id} : {},
                    ...(offset !== undefined) ? {offset} : {},
                    ...(limit !== undefined) ? {limit} : {},
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "GroupMinSchema", id} as const)),
                    "GroupMinSchema"
                ] : ["GroupMinSchema"]
            )
        }),
        searchTournamentGroups: builder.query<Group[], {tournamentKey: Tournament["key"], query?: string, group_type_id?: GroupType["id"]}>({
            query: ({tournamentKey, query, group_type_id}) => ({
                url: `/tournament/${tournamentKey}/group`,
                params: {
                    ...(query && query.length > 0) ? {query} : {},
                    ...(group_type_id && group_type_id >= 0) ? {group_type_id} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "Group", id} as const)),
                    "Group"
                ] : ["Group"]
            )
        }),
        searchTournamentPlayers: builder.query<PlayerSearch, {tournamentKey: Tournament["key"], query?: string}>({
            query: ({tournamentKey, query}) => ({
                url: `/tournament/${tournamentKey}/player`,
                params: {
                    ...(query && query.length > 0) ? {query} : {}
                }
            }),
            providesTags: (result, error, args) => (
                (result) ? [
                    {type: "PlayerSearch", id: `${args.tournamentKey}/${args.query}`} as const,
                ] : ["PlayerSearch"]
            )
        }),
        updateGroup: builder.mutation<Group, {group: Partial<Group>}>({
            query: ({group}) => ({
                url: "/group",
                method: "POST",
                body: group
            }),
            invalidatesTags: (result) => (
                (result) ? [
                    // {type: "Group", id: result.id} as const,
                    "Group", "Audit"
                ] : ["Group"]
            ),
            onQueryStarted: async ({group, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedGroup} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getGroup", {id: updatedGroup.id}, (draft: Group) => {
                    Object.assign(draft, updatedGroup);
                }));
            }
        }),
        groupUsersForGroup: builder.query<GroupUser[], {groupId: Group["id"]}>({
            query: ({groupId}) => `/group/${groupId}/users`,
            providesTags: (result, error, args) => (
                (result) ? [
                    ({type: "GroupUser", id: args.groupId} as const),
                    "GroupUser"
                ] : ["GroupUser"]
            )
        }),
        updateGroupAddingUser: builder.mutation<Group, {id: Group["id"], userId: User["id"]}>({
            query: ({id, userId}) => ({
                url: `/group/${id}/users/${userId}`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // NOTE: we do not invalidate the Group object here, instead we update it in
                // onQueryStarted so that it is up to date. If we invalidate it, it will just
                // be fetched again. I have left these commented out as a reminder.
                // {type: "Group", id: result.id},
                "UserInfo", "Audit", "PaymentItem"
            ] : ["Group"],
            onQueryStarted: async ({id, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedGroup} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getGroup", {id}, (draft: Group) => {
                    Object.assign(draft, updatedGroup);
                }));
            }
        }),
        updateGroupRemovingUser: builder.mutation<Group, {id: Group["id"], userId: User["id"]}>({
            query: ({id, userId}) => ({
                url: `/group/${id}/users/${userId}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Group", id: result.id},
                "UserInfo", "Audit"
            ] : ["Group"],
            onQueryStarted: async ({id, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedGroup} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getGroup", {id}, (draft: Group) => {
                    Object.assign(draft, updatedGroup);
                }));
            }
        }),
        createGroupType: builder.mutation<GroupType, {description: string}>({
            query: ({description}) => ({
                url: "/group/type",
                method: "POST",
                body: {description}
            }),
            invalidatesTags: (result) => (
                (result) ? [
                    {type: "GroupType", id: result.id} as const,
                    "Audit"
                ] : ["GroupType"]
            )
        }),
        groupsForUser: builder.query<UserGroup[], {userId: User["id"], query?: string}>({
            query: ({userId, query}) => ({
                url: `/user/${userId}/groups`,
                params: (query && query.length > 0) ? {query} : {}
            }),
            providesTags: (result) => (result) ? [
                ...result.map(({group}) => ({type: "UserGroup", id: group.id} as const)),
                "UserGroup"
            ] : ["UserGroup"]
        }),
        updateGroupsForUser: builder.mutation<User, {userId: User["id"], groupUpdates: UserGroupUpdate[]}>({
            query: ({userId, groupUpdates}) => ({
               url: `/user/${userId}/groups`,
                method: "POST",
                body: groupUpdates
            }),
            invalidatesTags: (result) => (result) ? [
                {type: "User", id: result.id} as const,
                "UserGroup",
                "GroupUser",
                "PaymentItem"
            ] : ["UserGroup"],
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        commonGroupsForUserIds: builder.query<GroupMinSchema[], {userIds: User["id"][]}>({
            query: ({userIds}) => ({
                url: "/group/common",
                params: userIds.map((id) => ["id", id])
            }),
            providesTags: (result) => (result) ? [
                ...result.map(({id}) => ({type: "GroupMinSchema", id} as const)),
                "GroupMinSchema"
            ] : ["GroupMinSchema"]
        }),
        searchAudit: builder.query<Audit, {query?: string, offset?: number, limit?: number}>({
            query: ({query, offset, limit}) => ({
                url: "/audit",
                params: {
                    ...(query) ? {query} : {},
                    ...(offset !== undefined) ? {offset} : {},
                    ...(limit !== undefined) ? {limit} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    // ...result.map(({id}) => ({type: "Audit", id} as const)),
                    "Audit"
                ] : ["Audit"]
            )
        }),
        createGame: builder.mutation<CreateGameResponse, {game: NewGame}>({
            query: ({game}) => ({
                url: "/game",
                method: "POST",
                body: game
            }),
            invalidatesTags: ["Game"]
        }),
        listGames: builder.query<Game[], void>({
            query: () => '/game',
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "Game", id} as const)),
                    "Game"
                ] : ["Game"]
            )
        }),
        joinGame: builder.mutation<GameDescription, JoinGameRequest>({
            query: ({gameId, colour}) => ({
                url: `/game/join/${gameId}`,
                method: "POST",
                body: colour
            }),
            invalidatesTags: [{type: 'GameDescription', id: 'LIST'}],
        }),
        spectateGame: builder.mutation<GameDescription, SpectateGameRequest>({
            query: ({gameId}) => ({
                url: `/game/${gameId}`,
                method: "POST"
            }),
            invalidatesTags: [{type: 'GameDescription', id: 'LIST'}],
        }),
        searchLocations: builder.query<Location[], {query?: string}>({
            query: ({query}) => ({
                url: "/location",
                params: {query}
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "Location", id} as const)),
                    "Location"
                ] : ["Location"]
            )
        }),
        updateLocation: builder.mutation<Location, {location: Partial<Location>}>({
            query: ({location}) => ({
                url: "/location",
                method: "POST",
                body: location
            }),
            invalidatesTags: (result) => (
                (result) ? [
                    {type: "Location", id: result.id} as const,
                    "Audit"
                ] : []
            )
        }),
        searchPayment: builder.query<Payment[], {query?: string, status?: PaymentStatusDescription, offset?: number, limit?: number, orderBy?: string, payment_system?: PaymentSystem, group_payer_id?: Group["id"]}>({
            query: ({query, status, offset, limit, orderBy, payment_system, group_payer_id}) => ({
                url: "/payment",
                params: {
                    ...(query) ? {query} : {},
                    ...(status) ? {status} : {},
                    ...(offset) ? {offset} : {},
                    ...(limit ) ? {limit} : {},
                    ...(payment_system) ? {payment_system} : {},
                    ...(group_payer_id) ? {group_payer_id} : {},
                    ...(orderBy) ? {orderby: orderBy} : {}
                }
            }),
            providesTags: (result) => (result) ? [
                ...result.map(({id}) => ({type: "Payment", id} as const)),
                "Payment"
            ] : ["Payment"]
        }),
        searchPaymentEntry: builder.query<Payment, {query: string, status?: PaymentStatusDescription, offset?: number, limit?: number, orderBy?: string}>({
            query: ({query, status, offset, limit, orderBy}) => ({
                url: "/payment/entry",
                params: {
                    ...(query) ? {query} : {},
                    ...(status) ? {status} : {},
                    ...(offset) ? {offset} : {},
                    ...(limit ) ? {limit} : {},
                    ...(orderBy) ? {orderby: orderBy} : {}
                }
            }),
            providesTags: (result) => (result) ? [
                {type: "Payment", id: result.id} as const,
            ] : ["Payment"]
        }),
        optionsForPayment: builder.query<PaymentItem[], {id: Payment["id"]}>({
            query: ({id}) => ({
                url: `/payment/${id}/options`,
            }),
            providesTags: (result) => (result) ? [
                ...result.map(({identifier}) => ({type: "PaymentItem", identifier}) as const),
                "PaymentItem"
            ] : ["PaymentItem"]
        }),
        paymentInitiate: builder.mutation<Payment, Partial<InitiatePayment>>({
            query: (payment) => ({
                url: "/payment",
                method: "POST",
                body: payment
            }),
            invalidatesTags: (result) => (result) ? [
                {type: "Payment", id: result.id} as const,
                ...(result.tournament_id) ? [{type: "Tournament", id: result.tournament.key} as const] : [],
                "Payment", "Audit", "Tournament"
            ] : ["Payment"]
        }),
        paymentCompleted: builder.mutation<PaymentItem, {payment: Payment}>({
            query: ({payment}) => ({
                url: `/payment/${payment.id}/completed`,
                method: "POST"
            }),
            invalidatesTags: (result, error, arg) => (result) ? [
                {type: "Payment", id: arg.payment.id} as const
            ] : ["Payment"]
        }),
        interimPaymentForUser: builder.query<Payment, {id: User["id"]}>({
            query: ({id}) => `/payment/user/${id}`,
            providesTags: (result) => (result) ? [
                {type: "Payment", id: result.id}
            ] : ["Payment"]
        }),
        pastPaymentsForUserId: builder.query<Payment[], {userId: User["id"], status?: PaymentStatusDescription}>({
            query: ({userId, status}) => ({
                url: `/user/${userId}/payments`,
                params: (status) ? {status} : {}
            }),
            providesTags: (result) => (result) ? [
                ...result.map(({id}) => ({type: "Payment", id}) as const),
                "Payment"
            ] : ["Payment"]
        }),
        searchRating: builder.query<Rating[], {query?: string, tournamentTypeId: TournamentType["id"]}>({
            query: ({tournamentTypeId, query}) => ({
                url: `/rating/${tournamentTypeId}`,
                params: (query) ? {query} : {}
            }),
            providesTags: (result, error, arg) => (
                (result) ? [
                    ...result.map((rating) => ({
                        type: "Rating",
                        id: `tournamentType-${arg.tournamentTypeId}/user-${rating.user.id}`
                    } as const)),
                    "Rating"
                ] : ["Rating"]
            )
        }),
        ratingPeriods: builder.query<RatingPeriod[], {tournamentTypeId: TournamentType["id"]}>({
            query: ({tournamentTypeId}) => `/rating/${tournamentTypeId}/period`,
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "RatingPeriod", id} as const)),
                    "RatingPeriod"
                ] : ["RatingPeriod"]
            )
        }),
        ratingsForPeriod: builder.query<Rating[], {tournamentTypeId: TournamentType["id"], periodId: RatingPeriod["id"]}>({
            query: ({tournamentTypeId, periodId}) => `/rating/${tournamentTypeId}/period/${periodId}`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    ...result.map((rating) => ({
                        type: "Rating",
                        id: `tournamentType-${arg.tournamentTypeId}/user-${rating.user.id}`
                    } as const)),
                    "Rating"
                ] : ["Rating"]
            )
        }),
        updateProvisionalRatings: builder.mutation<RatingPeriod, {tournamentTypeId: TournamentType["id"]}>({
            query: ({tournamentTypeId}) => ({
                url: `/rating/${tournamentTypeId}/rate`,
                method: "POST"
            }),
            invalidatesTags: ["Rating", "Audit"]
        }),
        finaliseRatings: builder.mutation<RatingPeriod, {tournamentTypeId: TournamentType["id"]}>({
            query: ({tournamentTypeId}) => ({
                url: `/rating/${tournamentTypeId}/ratefinal`,
                method: "POST"
            }),
            invalidatesTags: ["Rating", "Audit"]
        }),
        ratingsHistoryForUserId: builder.query<PlayerRatingHistory[], {tournamentTypeId: TournamentType["id"], userId: User["id"]}>({
            query: ({tournamentTypeId, userId}) => `/rating/${tournamentTypeId}/history/${userId}`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    ...result.map((prh) => ({
                        type: "PlayerRatingHistory",
                        id: `userId-${arg.userId}/date-${prh.date}`
                    } as const)),
                    "PlayerRatingHistory"
                ] : ["PlayerRatingHistory"]
            )
        }),
        listRoles: builder.query<Role[], void>({
            query: () => '/role',
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "Role", id} as const)),
                    "Role"
                ] : ["Role"]
            )
        }),
        getTournament: builder.query<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => `/tournament/${key}`,
            providesTags: (result) => (
                (result) ? [
                    {type: "Tournament", id: result.key},
                ] : ["Tournament"]
            )
        }),
        searchTournaments: builder.query<TournamentLite[], {query?: string, status?: TournamentStatusDescription | "", typeId?: TournamentType["id"]}>({
            query: ({query, status, typeId}) => ({
                url: "/tournament",
                params: {
                    ...(query) ? {query} : {},
                    ...(status) ? {status} : {},
                    ...(typeId && typeId >= 0) ? {tournament_type_id: typeId} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({key}) => ({type: "TournamentLite", key} as const)),
                    "TournamentLite"
                ] : ["TournamentLite"]
            )
        }),
        financialsForTournament: builder.query<TournamentFinancials, {key: Tournament["key"]}>({
            query: ({key}) => `/tournament/${key}/financials`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    ({type: "TournamentFinancials", id: arg.key} as const),
                ] : ["TournamentFinancials"]
            )
        }),
        getTournamentPlayerDetails: builder.query<UserContactDetails, {key: Tournament["key"], userId: User["id"]}>({
            query: ({key, userId}) => `/tournament/${key}/player/${userId}/detail`,
            providesTags: (result, error, args) => (
                (result) ? [
                    ...result.player_ids.map((id) => ({type: "UserContactDetail", id} as const)),
                ] : ["UserContactDetail"]
            )
        }),
        getTournamentContactDetails: builder.query<UserContactDetails, {key: Tournament["key"]}>({
            query: ({key}) => `/tournament/${key}/player/detail`,     // XXX wrong!!
            providesTags: (result, error, args) => (
                (result) ? [
                    ...result.player_ids.map((id) => ({type: "UserContactDetail", id} as const)),
                ] : ["UserContactDetail"]
            )
        }),
        downloadTournamentContactsCSV: builder.mutation<{filename: string}, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/player/detail.csv`,
                responseHandler: async (response) => {
                    if (response.ok) {
                        return {filename: await saveResponseUsingHiddenElement(response)}
                    } else {
                        return {message: response.statusText}
                    }
                },
                cache: "no-cache"
            })
        }),
        tournamentsForUser: builder.query<TournamentForUser[], {userId: User["id"], query?: string, status?: TournamentStatusDescription | "", typeId?: TournamentType["id"]}>({
            query: ({userId, query, status, typeId}) => ({
                url: `/user/${userId}/tournament`,
                params: {
                    ...(query) ? {query} : {},
                    ...(status) ? {status} : {},
                    ...(typeId && typeId >= 0) ? {tournament_type_id: typeId} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({tournament, user}) => (
                        {
                            type: "TournamentForUser",
                            id: `tournament-${tournament.key}/user-${user.id}`
                        } as const
                    )),
                    "TournamentForUser"
                ] : ["TournamentForUser"]
            )
        }),
        updateTournament: builder.mutation<Tournament, {tournament: Partial<Tournament>}>({
            query: (request) => {
                // strip out these fields from tournament payload
                // XXX we really should just send fields that have changed (and id)
                const omitAttrs = [
                    "status", "cur_round", "venue", "controls", "expired",
                    "players", "staff", "ref_group", "ref_user", "rounds", "teams"
                ];
                const tournamentUpdate: Partial<Tournament> = Object.fromEntries(
                    Object.entries(request.tournament).filter(([k, _]) => !omitAttrs.includes(k))
                );

                return ({
                    url: "/tournament",
                    method: "POST",
                    body: tournamentUpdate
                });
            },
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "TournamentLite", "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({tournament, ...patch}, {dispatch, queryFulfilled}) => {
                try {
                    const {data: updatedTournament} = await queryFulfilled;

                    dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                        Object.assign(draft, updatedTournament);
                    }));
                } catch {
                    return;
                }
            }
        }),
        updateTournamentAddingPlayer: builder.mutation<Tournament, {key: Tournament["key"], userId: User["id"]}>({
            query: ({key, userId}) => ({
                url: `/tournament/${key}/player/${userId}`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        addPlayersToTournament: builder.mutation<Tournament, {key: Tournament["key"], players: {user_id: UserInfo["id"], participating?: boolean}[]}>({
            query: ({key, players}) => ({
                url: `/tournament/${key}/player`,
                method: "POST",
                body: players
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateTournamentAddingPlayersToTeam: builder.mutation<Tournament, {key: Tournament["key"], teamId: Group["id"], userIds: User["id"][]}>({
            query: ({key, teamId, userIds}) => ({
                url: `/tournament/${key}/player`,
                method: "POST",
                body: userIds.map((userId) => addPlayerToTeamUpdate(userId, teamId))
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "PlayerSearch",
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateTournamentRemovingPlayersFromTeam: builder.mutation<Tournament, {key: Tournament["key"], userIds: User["id"][]}>({
            query: ({key, userIds}) => ({
                url: `/tournament/${key}/player`,
                method: "POST",
                body: userIds.map((userId) => removePlayerFromTeamUpdate(userId))
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "PlayerSearch",
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateTournamentRemovingPlayer: builder.mutation<Tournament, {key: Tournament["key"], userId: User["id"]}>({
            query: ({key, userId}) => ({
                url: `/tournament/${key}/player/${userId}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateTournamentRemovingPlayers: builder.mutation<Tournament, {key: Tournament["key"], userIds: User["id"][]}>({
            query: ({key, userIds}) => ({
                url: `/tournament/${key}/player`,
                method: "POST",
                body: userIds.map((userId) => deletePlayerUpdate(userId))
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateStaffInTournament: builder.mutation<Tournament, {key: Tournament["key"], staffUpdates: TournamentStaffUpdate[]}>({
            query: ({key, staffUpdates}) => ({
                url: `/tournament/${key}/staff`,
                method: "POST",
                body: staffUpdates
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        addTeamToTournament: builder.mutation<Tournament, {key: Tournament["key"], team: Group}>({
            query: ({key, team}) => ({
                url: `/tournament/${key}/group/${team.id}`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        removeTeamFromTournament: builder.mutation<Tournament, {key: Tournament["key"], team: Team}>({
            query: ({key, team}) => ({
                url: `/tournament/${key}/group/${team.group_id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        updateTeamsInTournament: builder.mutation<Tournament, {key: Tournament["key"], teams: Team[], payloadFilter?: (team: Team) => {}}>({
            query: ({key, teams, payloadFilter}) => ({
                url: `/tournament/${key}/team`,
                method: "POST",
                body: (payloadFilter) ? teams.map((team) => payloadFilter(team)) : teams
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        startTournament: builder.mutation<Tournament, {key: Tournament["key"], players: {user_id: UserInfo["id"], participating?: boolean}[]}>({
            query: ({key, players}) => ({
                url: `/tournament/${key}/start`,
                method: "POST",
                body: players
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : [],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                try {
                    const {data: updatedTournament} = await queryFulfilled;

                    dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                        Object.assign(draft, updatedTournament);
                    }));
                } catch {
                    return;
                }
            }
        }),
        unstartTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/unstart`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : [],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        roundEndTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/roundend`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        unendRoundTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/unendround`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        abandonTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/abandon`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        unabandonTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/unabandon`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        cloneTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/clone`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        startClocksForTournament: builder.mutation<Tournament, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/startclocks`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        deleteTournament: builder.mutation<void, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, args) => (!error) ? [
                {type: "Tournament", id: args.key},
                {type: "TournamentLite", id: args.key},
                "Audit"
            ] : ["Tournament"]
        }),
        pollLichessForTournamentRound: builder.mutation<Tournament, {key: Tournament["key"], round: number}>({
            query: ({key, round}) => ({
                url: `/tournament/${key}/round/${round}/lichesspoll`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: args.key},
                {type: "TournamentLite", id: result.key},
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        onlinePlayersForTournament: builder.query<TournamentOnlinePlayers, {key: Tournament["key"]}>({
            query: ({key}) => `/tournament/${key}/player/online`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    ({type: "TournamentOnlinePlayers", id: arg.key} as const),
                ] : ["TournamentOnlinePlayers"]
            )
        }),
        getTournamentExpenses: builder.query<TournamentExpense[], {key: Tournament["key"]}>({
            query: ({key}) => `/tournament/${key}/expense`,
            providesTags: (result, error, arg) => (
                (result) ? [
                    ({type: "TournamentExpense", id: arg.key} as const),
                ] : ["TournamentExpense"]
            )
        }),
        updateTournamentExpense: builder.mutation<TournamentExpense[], {key: Tournament["key"], expense: TournamentExpense}>({
            query: ({key, expense}) => ({
                    url: `/tournament/${key}/expense`,
                    method: "POST",
                    body: expense
                }),
            invalidatesTags: (result, error, arg) => (
                (result) ? [
                    ({type: "TournamentExpense", id: arg.key} as const),
                    ({type: "TournamentFinancials", id: arg.key} as const)
                ] : ["TournamentExpense"]
            ),
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournamentExpense} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournamentExpenses", {key}, (draft: TournamentExpense[]) => {
                    Object.assign(draft, updatedTournamentExpense);
                }));
            }
        }),
        deleteTournamentExpense: builder.mutation<TournamentExpense[], {key: Tournament["key"], expenseId: TournamentExpense["id"]}>({
            query: ({key, expenseId}) => ({
                    method: "DELETE",
                    url: `/tournament/${key}/expense/${expenseId}`
                }),
            invalidatesTags: (result, error, arg) => (
                (result) ? [
                    ({type: "TournamentExpense", id: arg.key} as const),
                    ({type: "TournamentFinancials", id: arg.key} as const)
                ] : ["TournamentExpense"]
            ),
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournamentExpense} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournamentExpenses", {key}, (draft: TournamentExpense[]) => {
                    Object.assign(draft, updatedTournamentExpense);
                }));
            }
        }),
        downloadTournamentFinancialsCSV: builder.mutation<{filename: string}, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/${key}/financials.csv`,
                responseHandler: async (response) => {
                    if (response.ok) {
                        return {filename: await saveResponseUsingHiddenElement(response)}
                    } else {
                        return {message: response.statusText}
                    }
                },
                cache: "no-cache"
            })
        }),
        downloadTournamentTRF: builder.mutation<{filename: string}, {key: Tournament["key"]}>({
            query: ({key}) => ({
                url: `/tournament/trf/${key}`,
                responseHandler: async (response) => {
                    if (response.ok) {
                        return {filename: await saveResponseUsingHiddenElement(response)};
                    } else {
                        return {message: response.statusText};
                    }
                },
                cache: "no-cache"
            })
        }),
        updateTournamentMatch: builder.mutation<Tournament, {matches: Partial<Match>[], key: Tournament["key"], matchPayloadFilter?: (match: Partial<Match>) => Partial<Match>}>({
            query: ({matches, key, matchPayloadFilter}) => {
                const matchesPayload = (matchPayloadFilter) ? matches.map(matchPayloadFilter) : matches;

                return ({
                    url: `/tournament/${key}/match`,
                    method: "POST",
                    body: matchesPayload
                });
            },
            invalidatesTags: (result) => (result) ? [
                // {type: "Tournament", id: result.key} as const,
                {type: "TournamentLite", id: result.key} as const,
                "Audit"
            ] : ["Tournament"],
            onQueryStarted: async ({key, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedTournament} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getTournament", {key: updatedTournament.key}, (draft: Tournament) => {
                    Object.assign(draft, updatedTournament);
                }));
            }
        }),
        searchUser: builder.query<User[], SearchUserRequest>({
            query: ({query, group_id}) => ({
                url: "/user",
                params: {
                    ...(query && query.length > 0) ? {query} : {},
                    ...(group_id && group_id >= 0) ? {'user_groups.group_id': group_id} : {}
                }
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "User", id} as const)),
                    "User"
                ] : ["User"]
            )
        }),
        getUser: builder.query<User, User["id"]>({
            query: (id) => `/user/${id}`,
            providesTags: (result) => (
                (result) ? [
                    ({type: "User", id: result.id} as const)
                ] : ["User"]
            )
        }),
        updateUser: builder.mutation<User, {user: Partial<User>, tournament_key?: Tournament["key"], guardian_id?: User["id"]}>({
            query: ({user, tournament_key, guardian_id}) => ({
                url: "/user",
                method: "POST",
                body: {...user, tournament_id: tournament_key, guardian_id}
            }),
            invalidatesTags: (result, error, args) => (result) ? [
                // {type: "User", id: result.id} as const,
                {type: "UserInfo", id: result.id} as const,
                "User",
                "UserInfo",
                {type: "UserContactDetail", id: result.id} as const,
                ...(args.guardian_id) ? [
                    {type: "User", id: args.guardian_id} as const,
                    {type: "UserInfo", id: args.guardian_id} as const,
                ] : [],
                ...(args.tournament_key) ? [
                    {type: "Tournament", id: args.tournament_key} as const,
                    {type: "TournamentLite", id: args.tournament_key} as const
                ] : [],
                ...(args.user.group_ids) ? [
                    ...args.user.group_ids.map((id) => ({type: "Group", id} as const)),
                    ...args.user.group_ids.map((id) => ({type: "GroupMinSchema", id} as const))
                ] : [],
                "ChildWithGuardian", "Audit", "PaymentItem"
                ] : ["Audit"],
            onQueryStarted: async ({user, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        updateUserAddingGroup: builder.mutation<User, {userId: User["id"], groupId: Group["id"]}>({
            query: ({userId, groupId}) => ({
                url: `/user/${userId}/groups/${groupId}`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "User", id: result.id} as const,
                {type: "UserGroup", id: result.id} as const,
                {type: "UserInfo", id: result.id} as const,
                "UserGroup",
                "Audit",
            ] : ["Audit"],
            onQueryStarted: async ({userId, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        updateUserRemovingGroup: builder.mutation<User, {userId: User["id"], groupId: Group["id"]}>({
            query: ({userId, groupId}) => ({
                url: `/user/${userId}/groups/${groupId}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "User", id: result.id} as const,
                {type: "UserGroup", id: result.id} as const,
                {type: "UserInfo", id: result.id} as const,
                "UserGroup",
                "Audit",
            ] : ["Audit"],
            onQueryStarted: async ({userId, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        updateUserAddingRole: builder.mutation<User, {userId: User["id"], roleId: Role["id"]}>({
            query: ({userId, roleId}) => ({
                url: `/user/${userId}/roles/${roleId}`,
                method: "POST"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "User", id: result.id} as const,
                {type: "UserInfo", id: result.id} as const,
                "Audit"
            ] : ["Audit"],
            onQueryStarted: async ({userId, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        updateUserRemovingRole: builder.mutation<User, {userId: User["id"], roleId: Role["id"]}>({
            query: ({userId, roleId}) => ({
                url: `/user/${userId}/roles/${roleId}`,
                method: "DELETE"
            }),
            invalidatesTags: (result) => (result) ? [
                // {type: "User", id: result.id} as const,
                {type: "UserInfo", id: result.id} as const,
                "Audit"
            ] : ["Audit"],
            onQueryStarted: async ({userId, ...patch}, {dispatch, queryFulfilled}) => {
                const {data: updatedUser} = await queryFulfilled;

                dispatch(chessMasterApi.util.updateQueryData("getUser", updatedUser.id, (draft: User) => {
                    Object.assign(draft, updatedUser);
                }));
            }
        }),
        searchUserInfos: builder.query<UserInfo[], {query?: string, group_id?: Group["id"], id?: User["id"]}>({
            query: ({query, group_id, id}) => ({
                url: "/user/info",
                params: ({
                    ...(query && query.length > 0) ? {query} : {},
                    ...(group_id && group_id >= 0) ? {'user_groups.group_id': group_id} : {},
                    ...(id !== undefined) ? {id} : {}
                })
            }),
            providesTags: (result) => (
                (result) ? [
                    ...result.map(({id}) => ({type: "UserInfo", id} as const)),
                    "UserInfo"
                ] : ["UserInfo"]
            )
        }),
        childrenForUser: builder.query<ChildrenForUserResponse, {id: User["id"], query?: string}>({
            query: ({id, query}) => ({
                url: `/user/${id}/child`,
                params: (query && query.length > 0) ? {query} : {}
            }),
            providesTags: (result, error, {id: guardian_id}) => (
                (result) ? [
                    {type: "ChildWithGuardian", guardian_id} as const,
                ] : ["ChildWithGuardian"]
            )
        }),
        updateChildrenForUser: builder.mutation<void, {userId: User["id"], guardianUpdates: ChildOfGuardianUpdate[]}>({
            query: ({userId, guardianUpdates}) => ({
                url: `/user/${userId}/child`,
                method: "POST",
                body: guardianUpdates
            }),
            invalidatesTags: (result, error, arg) => (!error) ? [
                {type: "UserInfo", id: arg.userId} as const,
                {type: "User", id: arg.userId} as const,
                ...arg.guardianUpdates.map((gu) => ({type: "ChildWithGuardian", id: gu.child_id}) as const),
                ...arg.guardianUpdates.map((gu) => ({type: "User", id: gu.child_id}) as const),
                ...arg.guardianUpdates.map((gu) => ({type: "UserInfo", id: gu.child_id}) as const),
                "Audit", "ChildWithGuardian", "UserContactDetail"
            ] : ["Audit"]
        }),
        guardiansForUser: builder.query<ChildWithGuardian[], {id: User["id"], query?: string}>({
            query: ({id, query}) => ({
                url: `/user/${id}/guardian`,
                params: (query && query.length > 0) ? {query} : {}
            }),
            providesTags: (result, error, {id: guardian_id}) => (
                (result) ? [
                    {type: "ChildWithGuardian", guardian_id} as const,
                ] : ["ChildWithGuardian"]
            )
        }),
        updateGuardiansForUser: builder.mutation<void, {userId: User["id"], guardianUpdates: GuardianOfChildUpdate[]}>({
            query: ({userId, guardianUpdates}) => ({
                url: `/user/${userId}/guardian`,
                method: "POST",
                body: guardianUpdates
            }),
            invalidatesTags: (result, error, arg) => (!error) ? [
                {type: "UserInfo", id: arg.userId} as const,
                {type: "User", id: arg.userId} as const,
                ...arg.guardianUpdates.map((gu) => ({type: "ChildWithGuardian", id: gu.guardian_id}) as const),
                ...arg.guardianUpdates.map((gu) => ({type: "User", id: gu.guardian_id}) as const),
                ...arg.guardianUpdates.map((gu) => ({type: "UserInfo", id: gu.guardian_id}) as const),
                "Audit", "ChildWithGuardian", "UserContactDetail"
            ] : ["Audit"]
        }),
        financeReport: builder.query<FinanceReport[], void>({
            query: () => "/finance",
            providesTags: (result) => (
                (result) ? [
                    ...result.map((fr) => ({type: "FinanceReport", id: fr.period} as const)),
                ] : ["FinanceReport"]
            )
        }),

    })
});

export const {
    useLoginMutation, useLogoutMutation, useChangeRoleMutation, useLoginAsMutation, useCancelLoginAsMutation,
    useTokenRefreshQuery, useConstantsQuery, useRegisterUserMutation, useChangePasswordMutation, useSaveSettingsMutation,
    useConfirmEMailMutation, useRequestPasswordResetMutation, usePasswordResetMutation,
    useUnlinkUserFromLichessMutation,
    useGetGroupQuery, useSearchGroupsQuery, useSearchGroupNamesQuery, useSearchTournamentGroupsQuery,
    useSearchTournamentPlayersQuery, useUpdateGroupMutation,
    useCreateGroupTypeMutation, useCommonGroupsForUserIdsQuery,
    useGroupUsersForGroupQuery, useUpdateGroupAddingUserMutation, useUpdateGroupRemovingUserMutation,
    useGroupsForUserQuery, useUpdateGroupsForUserMutation,
    useSearchAuditQuery,
    useCreateGameMutation, useListGamesQuery, useJoinGameMutation, useSpectateGameMutation,
    useSearchLocationsQuery, useUpdateLocationMutation,
    useSearchPaymentQuery, useSearchPaymentEntryQuery, usePaymentInitiateMutation, usePaymentCompletedMutation, useInterimPaymentForUserQuery,
    usePastPaymentsForUserIdQuery, useOptionsForPaymentQuery,
    useListRolesQuery,
    useSearchRatingQuery, useUpdateProvisionalRatingsMutation, useFinaliseRatingsMutation,
    useRatingPeriodsQuery, useRatingsForPeriodQuery,
    useGetTournamentQuery, useFinancialsForTournamentQuery, useDeleteTournamentMutation, useSearchTournamentsQuery,
    useTournamentsForUserQuery, useUpdateTournamentMutation,
    useGetTournamentPlayerDetailsQuery, useGetTournamentContactDetailsQuery,
    useAddTeamToTournamentMutation,
    useRemoveTeamFromTournamentMutation, useUpdateTeamsInTournamentMutation,
    useUpdateStaffInTournamentMutation,
    useUpdateTournamentAddingPlayerMutation, useAddPlayersToTournamentMutation,
    useUpdateTournamentAddingPlayersToTeamMutation, useUpdateTournamentRemovingPlayerMutation,
    useUpdateTournamentRemovingPlayersMutation,
    useUpdateTournamentRemovingPlayersFromTeamMutation, useStartTournamentMutation,
    useUnstartTournamentMutation, useRoundEndTournamentMutation,
    useUnendRoundTournamentMutation, useAbandonTournamentMutation,
    useUnabandonTournamentMutation, useCloneTournamentMutation,
    useStartClocksForTournamentMutation,
    usePollLichessForTournamentRoundMutation, useOnlinePlayersForTournamentQuery,
    useGetTournamentExpensesQuery, useUpdateTournamentExpenseMutation, useDeleteTournamentExpenseMutation,
    useDownloadTournamentFinancialsCSVMutation, useDownloadTournamentContactsCSVMutation,
    useDownloadTournamentTRFMutation,
    useUpdateTournamentMatchMutation,
    useSearchUserQuery, useGetUserQuery, useUpdateUserMutation,
    useUpdateUserAddingGroupMutation, useUpdateUserRemovingGroupMutation,
    useUpdateUserAddingRoleMutation, useUpdateUserRemovingRoleMutation,
    useUpdateChildrenForUserMutation,
    useSearchUserInfosQuery, useLazySearchUserInfosQuery, useChildrenForUserQuery,
    useGuardiansForUserQuery, useUpdateGuardiansForUserMutation,
    useFinanceReportQuery
} = chessMasterApi;
