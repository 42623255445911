
// Take an old and updated version of an object and return only the changed
// fields between them. If the objects have an id field, we always include that.
// We use this to only send changed fields to update methods in the api.

import _ from "lodash";

const allKeys = <T extends {}>(old: T, updated: T) => {
    const keys = new Set(Object.keys(old));

    Object.keys(updated).map((k) => keys.add(k));

    return [...keys] as Array<keyof T>;
};

export const payloadChangesWithId = <T extends {id: number}>(old: T, updated: Partial<T>): Partial<T> & {id: number} => {
    const fields = allKeys(old, updated).map<Partial<T>>((prop) => (!_.isEqual(old[prop], updated[prop]) ? {[prop]: updated[prop]} : {}) as Partial<T>);

    return Object.assign({}, ...fields, ("id" in old) ? {id: old.id} : {}, ("id" in updated) ? {id: updated.id} : {});
};
