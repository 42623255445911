import React, {useContext} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {formatMoney} from "accounting";
import {format} from "date-fns";

import Form from "react-bootstrap/Form";

import {
    TournamentFinancials,
    TournamentPlayerFinancials
} from "../../models/TournamentFinancials";
import {formatFullName} from "../../util/formatFullName";
import UserLichessLink from "../../components/UserLichessLink";
import {isPlayMethodOnline} from "../../models/Tournament";
import {UsernameForFinancials} from "../../components/UsernameForFinancials";
import PaidSymbol from "../../components/PaidSymbol";
import {ActiveTournamentContext} from "./ActiveTournament";
import TeamName from "./TeamName";


interface Props {
    financials: TournamentFinancials | undefined
    isLoading: boolean
}

const ActiveTournamentFinancialsList: React.FC<Props> = ({financials, isLoading}) => {
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const columns: TableColumn<TournamentPlayerFinancials>[] = [{
        name: "User",
        selector: (row) => formatFullName(row.user),
        grow: 2,
        sortable: true,
        cell: (row) => (
            <UsernameForFinancials tournament={tournament}
                                   user={row.user}
                                   online={onlinePlayerIds.has(row.user.id)}/>
        )
    }, {
        name: "Lichess",
        center: true,
        omit: financials && !isPlayMethodOnline(financials.tournament.play_method),
        cell: (row) => <UserLichessLink user={row.user}/>,
    }, {
        name: "Participating?",
        selector: (row) => row.participating,
        center: true,
        sortable: true,
        format: (row) => <Form.Check readOnly checked={row.participating}/>
    }, {
        name: "Team",
        selector: (row) => tournament.ref_group[row.team.id].name,
        sortable: true,
        cell: (row) => (
            <TeamName tournament={tournament}
                      team={(row.team) ? tournament.ref_group[row.team.id] : undefined}/>
        )
    }, {
        name: "Due",
        selector: (row) => row.due ?? "",
        sortable: true,
        right: true,
        format: (row) => (row.due && Number(row.due) > 0) ? (
            formatMoney(row.due, "$")
        ) : ""
    }, {
        name: "Paid",
        selector: (row) => row.paid ?? "",
        sortable: true,
        center: true,
        format: (row) => row.paid ? (
            <PaidSymbol amount={row.payment}/>
        ) : ""
    }, {
        name: "Payment date",
        selector: (row) => (row.paid_time) ? format(new Date(row.paid_time), "PPpp") : "",
        grow: 2,
        sortable: true
    }];

    return (
        <DataTable<TournamentPlayerFinancials> data={financials?.players ?? []}
                                               columns={columns}
                                               keyField="id"
                                               noDataComponent="(No payments found)"
                                               progressPending={isLoading}/>
    );
};

export default ActiveTournamentFinancialsList;
