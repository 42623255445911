import React, {useContext} from "react";
import {BsArrowClockwise} from "react-icons/bs";

import Button, {ButtonProps} from "react-bootstrap/Button";

import {chessMasterApi} from "../../api/chessMasterApi";
import {ActiveTournamentContext} from "./ActiveTournament";
import {useAppDispatch} from "../../store";

interface Props {
    className?: string;
    size: ButtonProps["size"]
}

const UpdateTournamentButton: React.FC<Props> = ({className, size}) => {
    const dispatch = useAppDispatch();
    const {tournament} = useContext(ActiveTournamentContext);
    const refreshTournament = () => {
        dispatch(chessMasterApi.util.invalidateTags([{
            type: "Tournament",
            id: tournament.id
        }]));
    };

    return (
        <Button variant="outline-primary"
                size={size}
                className={className}
                onClick={() => refreshTournament()}>
            <BsArrowClockwise/>
        </Button>
    );
};

export default UpdateTournamentButton;
