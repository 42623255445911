import React, {useCallback} from "react";
import {useFormContext} from "react-hook-form";
import {BsX} from "react-icons/bs";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

import SingleUserPicker from "../../components/singleUserPicker/SingleUserPicker";
import {UserInfo} from "../../models/User";
import {formatFullName} from "../../util/formatFullName";
import { ErrorMessage } from "@hookform/error-message";
import {UserPaymentForm} from "./FinanceUserPayment";

const UserPaymentDetails: React.FC<{itemTotal: number}> = ({itemTotal}) => {
    const {register, setValue, watch, resetField, formState: {errors, dirtyFields}} = useFormContext<UserPaymentForm>();
    const payer = watch("payer");
    const [runPayAsSelector, setRunPayAsSelector] = React.useState(false);
    const setPayer = useCallback((userInfo: UserInfo | null) => {
        setValue("payer", userInfo);
        setRunPayAsSelector(false);
    }, [setValue]);
    const resetAmountFieldIfEmpty  = (amount: string) => {
        // if the user completely empties the amount field reset the dirty flag and set to itemTotal
        if (amount === "") {
            resetField("amount");
            setValue("amount", String(itemTotal));
        }
    };

    return (
        <Container fluid className="p-0">
            <Row>
                <Form.Group as={Col}
                            md={5}
                            controlId="financeUserPaymentDate">
                    <Form.Label column>
                        Pay on behalf of
                    </Form.Label>
                    {(runPayAsSelector) ? (
                        <SingleUserPicker setSelected={setPayer}
                                          onClose={() => setRunPayAsSelector(false)}/>
                    ) : (
                        <InputGroup>
                            <InputGroup.Text className="flex-fill"
                                             onClick={() => setRunPayAsSelector(true)}>
                                    {(payer) ? formatFullName(payer) :
                                        <span className="text-danger">(Click to select)</span>}
                            </InputGroup.Text>
                            <Button variant="outline-dark"
                                    onClick={() => {
                                        setValue("payer", null);
                                        setValue("credit", "0");
                                    }}>
                                <BsX/>
                            </Button>
                        </InputGroup>
                    )}
                </Form.Group>
                <Form.Group as={Col}
                            md={5}
                            controlId="financeUserPaymentReference">
                    <Form.Label column>
                        Reference
                    </Form.Label>
                    <Form.Control {...register("reference")}
                                  placeholder="Reference"
                                  isInvalid={!!errors.reference}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="reference"
                                  type="invalid"/>
                </Form.Group>
                <Form.Group as={Col}
                            md={2}
                            controlId="financeUserPaymentAmount">
                    <Form.Label column className="float-end me-3">
                        Amount
                    </Form.Label>
                    {/* make the background of the amount field different if it's not */}
                    {/* dirty because the payment items will auto-add to it in that state */}
                    <Form.Control {...register("amount")}
                                  onBlur={(ev) => resetAmountFieldIfEmpty(ev.target.value)}
                                  placeholder="Amount"
                                  type="number"
                                  step="0.01"
                                  min={0}
                                  className={"text-end " + (dirtyFields.amount ? "" : "bg-light text-muted")}
                                  isInvalid={!!errors.amount}/>
                    <ErrorMessage as={<Form.Control.Feedback/>}
                                  name="amount"
                                  type="invalid"/>
                </Form.Group>
            </Row>
        </Container>
    );
};

export default UserPaymentDetails;