import React from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import {Group} from "../../models/Group";
import {UserGroup} from "../../models/UserGroup";
import {NewUser, User} from "../../models/User";

interface UserGroupItemProps {
    userGroup: UserGroup
    index: number
    isAdmin: boolean
}

const GroupItem: React.FC<UserGroupItemProps> = ({userGroup, index, isAdmin}) => (
    <Draggable draggableId={`${userGroup.group.id}`} index={index}>
        {(provided, snapshot) => {
            const className = [
                (snapshot.isDragging) ? "bg-light" : "",
                (isAdmin) ? "border border-success" : ""
            ].join(" ");
            return (
                <ListGroup.Item {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className={className}>
                    {userGroup.group.name}
                </ListGroup.Item>
            );
        }}
    </Draggable>
);

interface UserGroupListProps {
    userGroups: UserGroup[]
    placeholder?: React.ReactNode | null
    groupIdToUserGroup: Record<Group["id"] , UserGroup>;
}

const UserGroupList: React.FC<UserGroupListProps> = ({userGroups, placeholder, groupIdToUserGroup}) => {
    return (
        <ListGroup className="scrolling-list-group">
            {(userGroups ?? []).map((ug, i) => (
                <GroupItem key={ug.group.id}
                           userGroup={ug}
                           index={i}
                           isAdmin={groupIdToUserGroup[ug.group.id]?.group_admin}/>
            ))}
            {placeholder}
        </ListGroup>
    );
};

interface DroppableGroupListProps {
    user: User | NewUser
    droppableId: string
    title: string
    userGroups: UserGroup[]
}

export const DroppableGroupList: React.FC<DroppableGroupListProps> = ({user, droppableId, title, userGroups}) => {
    const groupIdToUserGroup = Object.fromEntries((userGroups ?? []).map((ug) => [ug.group.id, ug]));

    return (
        <Droppable droppableId={droppableId} type="Group">
            {(provided, snapshot) => (
                <Card ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={(snapshot.isDraggingOver) ? "border border-dark" : ""}
                      data-testid={droppableId}>
                    <Card.Header>
                        <Card.Title>{title}</Card.Title>
                        <small>Drag and drop</small>
                    </Card.Header>
                    <Card.Body>
                        <UserGroupList userGroups={userGroups}
                                       placeholder={provided.placeholder}
                                       groupIdToUserGroup={groupIdToUserGroup}/>
                    </Card.Body>
                </Card>
            )}
        </Droppable>
    );
};
