import React, {ChangeEvent, useCallback} from "react";

import Form from "react-bootstrap/Form";

import {
    selectConstants,
    selectConstantsPaymentStatusById
} from "../constants/constantsSlice";
import {formControlOptions} from "../../util/formControl";
import {PaymentStatus} from "../../models/Payment";
import {useAppSelector} from "../../store";

interface Props {
    status?: PaymentStatus
    onChangeStatus: (status: PaymentStatus | undefined) => void;
}

const PaymentStatusSelect: React.FC<Props> = ({status, onChangeStatus}) => {
    const {payment_status} = useAppSelector(selectConstants);
    const paymentStatusById = useAppSelector(selectConstantsPaymentStatusById);
    const changeStatus = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            const status = (ev.target.value === "-1") ? undefined : paymentStatusById[ev.target.value];

            onChangeStatus(status);
        },
        [onChangeStatus, paymentStatusById]
    );

    return (
        <Form.Control as="select"
                      value={status?.id}
                      size="sm"
                      onChange={changeStatus}>
            <option key={-1} value={-1}>(All statuses)</option>
            {formControlOptions(payment_status)}
        </Form.Control>
    );
};

export default PaymentStatusSelect;