import React, {useState, useCallback, FocusEvent} from 'react'
// import PropTypes from 'prop-types'
import {parseDuration} from "../util/parseDuration";

type Scale = 'd' | 'h' | 'm' | 's' | 'ms'

interface Props {
    value: number
    scale?: Scale
    onChange: (v: number) => void
    onError?: (m: string | undefined) => void
    className?: string
    isInvalid?: boolean
    errorClassName?: string
}

export const TimeDurationInput: React.FC<Props> = ({value, scale = "ms", onChange, className, onError, isInvalid = false, errorClassName = "is-invalid"}) => {
    const [duration, setDuration] = useState(convertFromValue(value, scale));
    const [hasError, setHasError] = useState(isInvalid);
    const onInputChange = useCallback(({target}: FocusEvent<HTMLInputElement>) => {
        const newValue = convertToValue(target.value, scale);
        if (isNaN(newValue)) {
            onError?.("Invalid duration");
            setHasError(true);
        } else {
            onError?.(undefined);
            setHasError(false);
            onChange(newValue);
            setDuration(convertFromValue(newValue, scale));
        }
    }, [onChange, onError, scale]);

    return (
        <input type="text"
               className={`${className} ${(hasError || isInvalid) ? errorClassName : ""}`}
               value={duration}
               onChange={(ev) => setDuration(ev.target.value)}
               onBlur={onInputChange}
               data-testid="duration-input" />
    )
};

// TimeDurationInput.propTypes = {
//   value: PropTypes.number,
//   scale: PropTypes.oneOf(['d', 'h', 'm', 's', 'ms']),
//   onChange: PropTypes.func,
//   className: PropTypes.string
// };

TimeDurationInput.defaultProps = {
    scale: 'ms',
    onChange: () => {}
};

export const SCALE_CONVERSIONS: Record<Scale, number> = {
    ms: 1,
    s: 1 * 1000,
    m: 60 * 1000,
    h: 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000
};

export const convertValueFromScale = (value: number, scale: Scale) => value * (SCALE_CONVERSIONS[scale] || 1);

export const convertValueToScale = (value: number, scale: Scale) => value / (SCALE_CONVERSIONS[scale] || 1);

export const convertValueToDuration = (value: number, defaultScale: Scale) => {
    const milliseconds = Math.round(value % 1000);
    const seconds = Math.floor(value / 1000 % 60);
    const minutes = Math.floor(value / 60000 % 60);
    const hours = Math.floor(value / 3600000 % 24);
    const days = Math.floor(value / 86400000);
    if (!value) {
        return `0${defaultScale}`;
    }
    return [
        (days) && `${days}d`,
        (hours) && `${hours}h`,
        (minutes) && `${minutes}m`,
        (seconds) && `${seconds}s`,
        (milliseconds) && `${milliseconds}ms`
    ].filter(x => !!x).join(" ");
};

export const convertDurationToValue = (duration: string, scale: Scale = "ms") => {
    const matches = Array.from(duration.trim().toLowerCase().matchAll(/(?<number>(:?\d+\.?\d*|\d*\.\d+))\s*(?<unit>d|h|ms|m|s)/g));
     if (duration.match(/:/)) {
        return convertValueFromScale(parseDuration("", duration), "s");
    } else if (matches.length) {
        let ms = 0;

        matches.forEach((m) => {
            ms += convertValueFromScale(Number(m.groups?.number), m.groups?.unit as Scale);
        });
        return ms;
    } else {
        return convertValueFromScale(parseFloat(duration), scale);
    }
};

export const convertFromValue = (value: number, scale: Scale) => (
    convertValueToDuration(convertValueFromScale(value, scale), scale)
);

export const convertToValue = (duration: string, scale: Scale) => (
    convertValueToScale(convertDurationToValue(duration, scale), scale)
);

export default TimeDurationInput