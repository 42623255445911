import React, {useMemo, useState} from "react";

import DataTable, {TableColumn} from "react-data-table-component";

import {Match} from "../../models/Match";
import {UserInfo} from "../../models/User";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import MatchResultSelector from "./MatchResultSelector";
import {nameSortFunc} from "../../util/nameSortFunc";
import {formatFullName} from "../../util/formatFullName";
import UsernameAndTeam from "../../components/UsernameAndTeam";
import MatchResultStatus from "./MatchResultStatus";
import ActiveTournamentBoardNumber from "./ActiveTournamentBoardNumber";
import {useAppSelector} from "../../store";
import {selectTournamentTab} from "../tournamentTab/tournamentTabSlice";
import {isPlayMethodOnline, Tournament} from "../../models/Tournament";
import {useOnlinePlayersForTournamentQuery} from "../../api/chessMasterApi";
import ProjectorViewUser from "../../components/ProjectorViewUser";

// Just note that this component can be loaded from the projector view which is outside
// active tournament so we can't use the ActiveTournamentContext

interface Props {
    tournament: Tournament
    matches: Match[]
    showResultSelector?: boolean
    scores?: Record<UserInfo["id"], number>
    projectorView?: boolean
}

const ActiveTournamentRound: React.FC<Props> = ({tournament, matches, showResultSelector = false, scores, projectorView}) => {
    const {data: onlinePlayers} = useOnlinePlayersForTournamentQuery({key: tournament.key}, {skip: !isPlayMethodOnline(tournament.play_method)});
    const {projectorSettings} = useAppSelector(selectTournamentTab);
    const Username = (projectorView) ? ProjectorViewUser : UsernameAndTeam;
    const [updateResultForMatch, setUpdateResultForMatch] = useState<Match | undefined>(undefined);
    const canSortColumns = !projectorView;  // hides sort direction arrows in projector view
    const whitePlayerSort = (a: Match, b: Match) => nameSortFunc((a.white_player_id) ? tournament.ref_user[a.white_player_id] : null,
                                                                 (b.white_player_id) ? tournament.ref_user[b.white_player_id] : null)
    const blackPlayerSort = (a: Match, b: Match) => nameSortFunc((a.black_player_id) ? tournament.ref_user[a.black_player_id] : null,
                                                                 (b.black_player_id) ? tournament.ref_user[b.black_player_id] : null);
    const tournamentPlayerForPlayerId = (players: TournamentPlayer[], playerId: UserInfo["id"] | null) => {
        if (!playerId) {      // this is a bye
            return undefined;
        }
        return players.find((p) => p.user_id === playerId);
    };
    const onlinePlayerIds = useMemo(() => (
        new Set<UserInfo["id"]>(onlinePlayers?.online_players ?? [])
    ), [onlinePlayers]);
    const columns: TableColumn<Match>[] = [{
        selector: (row) => row.board_num,
        name: "#",
        minWidth: "3em",
        grow: 1,
        center: true,
        compact: true,
        sortable: canSortColumns,
        cell: (row) => (
            <ActiveTournamentBoardNumber tournament={tournament}
                                         match={row}
                                         projectorView={projectorView}/>
        )
    }, {
        selector: (row) => (row.white_player_id) ? formatFullName(tournament.ref_user[row.white_player_id]) : "",
        name: "White",
        sortable: canSortColumns,
        grow: 6,
        sortFunction: (canSortColumns) ? whitePlayerSort : undefined,
        cell: (row) => (
            <Username player={tournamentPlayerForPlayerId(tournament.players, row.white_player_id)}
                      tournament={tournament}
                      scores={scores}
                      online={row.white_player_id !== null && onlinePlayerIds.has(row.white_player_id)}
                      showContactDetails={!projectorView}/>
        )
    }, {
        selector: (row) => row.status,
        name: "Status",
        sortable: canSortColumns,
        center: true,
        grow: 1,
        compact: true,
        style: {textAlign: 'center'},
        // if we're showing the result selector, make the field bigger
        minWidth: ((tournament.controls?.staff && showResultSelector) || updateResultForMatch) ? "20em" : "6em",
        cell: (row) => {
            if (tournament.controls?.staff && showResultSelector) {
                return <MatchResultSelector key={row.id}
                                            match={row}/>;
            } else if (updateResultForMatch?.id === row.id) {
                return <MatchResultSelector key={row.id}
                                            match={row}
                                            onClose={() => setUpdateResultForMatch(undefined)}/>;
            } else {
                return <MatchResultStatus tournament={tournament}
                                          match={row}
                                          setUpdateResultForMatch={setUpdateResultForMatch}
                                          allowMatchUpdate={!projectorView}/>;
            }
        }
    }, {
        selector: (row) => (row.black_player_id) ? formatFullName(tournament.ref_user[row.black_player_id]) : "",
        name: "Black",
        sortable: canSortColumns,
        grow: 6,
        sortFunction: (canSortColumns) ? blackPlayerSort : undefined,
        cell: (row) => (
            <Username player={tournamentPlayerForPlayerId(tournament.players, row.black_player_id)}
                      tournament={tournament}
                      scores={scores}
                      online={row.black_player_id !== null && onlinePlayerIds.has(row.black_player_id)}
                      showContactDetails={!projectorView}/>
        )
    }];

    return (
        <DataTable<Match> data={matches}
                          columns={columns}
                          keyField="id"
                          striped
                          dense={projectorView}
                          noDataComponent={(projectorView && projectorSettings.columns > 1) ? null : "(No matches)"}
                          className="layout-auto m-0"/>
    );
};

export default ActiveTournamentRound;