import React from "react";
import {useDispatch} from "react-redux";
import {useFormContext} from "react-hook-form";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {setUserBulkUploadFile} from "./userTabSlice";
import {ErrorMessage} from "@hookform/error-message";
import {BulkUploadForm} from "./UserBulkUpload";

const UserBulkUploadFileCard: React.FC = () => {
    const dispatch = useDispatch();
    const {register, formState: {errors}, setError} = useFormContext<BulkUploadForm>();

    const readFile = (csvFile: string) => {
        if (!csvFile || csvFile.length === 0) {
            return;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            dispatch(setUserBulkUploadFile(reader.result));
            console.log("onload: file loaded", e);
        };
        reader.onerror = (e) => {
            console.log("onload: file error", reader.error, e);
            dispatch(setUserBulkUploadFile(null));
            setError("csvFile", {message: reader.error?.toString() ?? "(unknown error)"});
        };
        reader.readAsDataURL(new File([csvFile], csvFile));
    };

    return (
        <Card>
            <Card.Header>
                File to Upload
            </Card.Header>
            <Card.Body>
                <p>
                    File can be a <i>.csv</i> or <i>.xls</i>.
                </p>
                <Form.Group as={Row} controlId="userBulkUploadFile">
                    <Form.Label column sm="4">
                        File to Upload
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="file"
                                      {...register("csvFile",
                                          {onChange: (ev) => readFile(ev.target.value)})}
                                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      isInvalid={!!errors.csvFile}/>
                        <ErrorMessage as={<Form.Control.Feedback/>}
                                      name="csvFile"
                                      type="invalid"/>
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default UserBulkUploadFileCard;