import React from "react";
import {useDispatch} from "react-redux";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {isMatchStatusBye, Match} from "../../models/Match";
import {formatFullName} from "../../util/formatFullName";
import {
    CrossTableResult,
} from "../../util/crossTableResults";
import {UserInfo} from "../../models/User";
import {setActiveTournamentPlayerSummary} from "./activeTournamentSlice";
import {Tournament} from "../../models/Tournament";

const overlayForMatch = (tournament: Tournament, match: Match) => {
    const white_player = (match.white_player_id) ? tournament.ref_user[match.white_player_id] : null;
    const black_player = (match.black_player_id) ? tournament.ref_user[match.black_player_id] : null;

    return (
        <Tooltip id={`match-${match.id}`}>
            <div>
                {(isMatchStatusBye(match)) ? formatFullName((white_player) ? white_player : black_player) :
                    <>
                        <div>W: {formatFullName(white_player)}</div>
                        <div>B: {formatFullName(black_player)}</div>
                    </>
                }
            </div>
            <div>{match.status}</div>
        </Tooltip>
    );
};

interface Props {
    playerId: UserInfo["id"] | "BYE"
    tournament: Tournament
    result: CrossTableResult
    playerIdToIndexInStandings: Record<UserInfo["id"], number>
    className: string
}

export const CrossTableResultCell: React.FC<Props> = ({result, playerId, tournament, playerIdToIndexInStandings, className}) => {
    const dispatch = useDispatch();
    const opponentName = (user: UserInfo) => (
        playerIdToIndexInStandings[user.id]
    );

    return (
        <OverlayTrigger overlay={overlayForMatch(tournament, result.match)}>
            <div className={["text-center", "w-100", "h-100", "py-2", className].join(" ")}
                 onClick={() => {
                     if (playerId !== "BYE") {
                         dispatch(setActiveTournamentPlayerSummary({tournament, player: tournament.ref_user[playerId]}));
                     }
                 }}>
                <div className="fw-bolder">
                    {(result.opponentId === "BYE") ? result.opponentId : opponentName(tournament.ref_user[result.opponentId])}
                </div>
                <div>
                    {result.result}
                </div>
            </div>
        </OverlayTrigger>
    );
};

