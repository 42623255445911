import {Tournament} from "../../models/Tournament";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import React from "react";
import {useDispatch} from "react-redux";
import {
    useAddPlayersToTournamentMutation,
    useStartTournamentMutation
} from "../../api/chessMasterApi";
import useSelection from "../../hooks/useSelection";
import {UserInfo} from "../../models/User";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import Modal from "react-bootstrap/Modal";
import TournamentPlayerList from "../../components/TournamentPlayerList";
import Spinner from "react-bootstrap/Spinner";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

const playerChange = (tp: TournamentPlayer, updatedParticipating: boolean) => (
    Object.assign({}, {user_id: tp.user_id},
        (((tp.participating && !tp.withdrawn) !== updatedParticipating)) ? {participating: updatedParticipating} : {})
);

interface TournamentRollListProps {
    tournament: Tournament
    onClose: () => void
}

const TournamentRollList: React.FC<TournamentRollListProps> = ({tournament, onClose}) => {
    const dispatch = useDispatch();
    const [startTournament, {isLoading: isStarting}] = useStartTournamentMutation();
    const [addPlayersToTournament, {isLoading: isAddingPlayers}] = useAddPlayersToTournamentMutation();
    const participatingPlayerIdSelection = useSelection<TournamentPlayer["user_id"]>({
        allItems: tournament.players.map((player) => player.user_id),
        selectedItems: tournament.players.filter((tp) => (tp.participating && !tp.withdrawn)).map((tp) => tp.user_id)
    });
    const enrolPlayersInTournament = (tournament: Tournament, participatingPlayerIds: Set<UserInfo["id"]>) => {
        const playerChanges = tournament.players
            .map((tp) => playerChange(tp, participatingPlayerIds.has(tp.user_id)))
            .filter((pc) => "participating" in pc);

        if (tournament.status === "Pending") {
            startTournament({key: tournament.key, players: playerChanges})
                .unwrap()
                .then(() => {
                    dispatch(addToast(newToastSuccess({
                        heading: "Tournament started",
                        body: `${tournament.name} has been started with ${participatingPlayerIds.size} players.`
                    })));
                })
                .then(onClose);
        } else {
            addPlayersToTournament({key: tournament.key, players: playerChanges})
                .unwrap()
                .then(() => {
                    dispatch(addToast(newToastSuccess({
                        heading: "Tournament roll updated",
                        body: `${tournament.name} roll updated with ${participatingPlayerIds.size} players.`
                    })));
                })
                .then(onClose);
        }
    };

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               size="lg">
            <Modal.Header>
                <Modal.Title>Tournament roll for “{tournament.name}”</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(tournament) ? <TournamentPlayerList players={tournament.players}
                                                      tournament={tournament}
                                                      showAddedTime
                                                      showTeam
                                                      playerIdSelection={participatingPlayerIdSelection}/>
                    : <Spinner className="text-center" animation="border"/>}
            </Modal.Body>

            <Modal.Footer>
                <ButtonToolbar>
                    <ButtonGroup className="me-1">
                        <Button variant="secondary"
                                onClick={onClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant="primary"
                                onClick={() => tournament && participatingPlayerIdSelection.selectedSet.size > 0 && enrolPlayersInTournament(tournament, participatingPlayerIdSelection.selectedSet)}
                                disabled={!tournament || isStarting || isAddingPlayers}>
                            {(tournament.status === "Pending") ? "Start" : "Update roll"}
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default TournamentRollList;