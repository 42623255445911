import React, {useCallback} from "react";

import Button from "react-bootstrap/Button";
import {BsChevronDoubleRight} from "react-icons/bs";

import {UserInfo} from "../../models/User";
import {addToast, newToastError, newToastSuccess} from "../toast/toastSlice";
import {useUpdateTournamentAddingPlayersToTeamMutation} from "../../api/chessMasterApi";
import {useAppDispatch} from "../../store";
import {Tournament} from "../../models/Tournament";
import {UseSelection} from "../../hooks/useSelection";

import {GroupMinSchema} from "../../models/Group";

interface Props {
    tournament: Tournament
    team: GroupMinSchema | undefined
    playerIdSelection: UseSelection<UserInfo["id"]>
    className?: string
}

const AddSelectedPlayersToTeamButton: React.FC<Props> = ({tournament, team, playerIdSelection, className}) => {
    const dispatch = useAppDispatch();
    const [updateTournamentAddingPlayersToTeam] = useUpdateTournamentAddingPlayersToTeamMutation();
    const addPlayersToTeamId = useCallback(() => {
        if (!team) {
            dispatch(addToast(newToastError({
                heading: "Internal error",
                body: "Cannot add players without a team"
            })));
            return;
        }
        updateTournamentAddingPlayersToTeam({
            key: tournament.key,
            teamId: team.id,
            userIds: [...playerIdSelection.selectedSet]
        })
            .unwrap()
            .then((tournament) => {
                const nPlayers = playerIdSelection.selectedSet.size;

                dispatch(addToast(newToastSuccess({
                    heading: "Team updated",
                    body: `Added ${nPlayers} ${(nPlayers) ? "player" : "players"} to team "${team.name}"`
                })));
            })
            .then(() => playerIdSelection.setSelectedItems({items: []}));
        },
        [dispatch, playerIdSelection, team, tournament, updateTournamentAddingPlayersToTeam]
    );
    return (
        <Button onClick={addPlayersToTeamId}
                disabled={!team || playerIdSelection.selectedSet.size === 0}
                className={className}>
            Add selected to team <BsChevronDoubleRight/>
        </Button>
    );
};

export default AddSelectedPlayersToTeamButton;
