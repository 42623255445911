import React, {useCallback, useEffect} from 'react';
import {useDispatch} from "react-redux";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

import logo from "./pawnbroker.png";
import "./App.css";

import {useConstantsQuery, useTokenRefreshQuery} from "./api/chessMasterApi";
import LoginOrRegister from "./features/login/LoginOrRegister";
import PawnBrokerTabs from "./components/PawnBrokerTabs";
import {
    selectLogin,
    selectLoginRoles,
    setLoginCredentials
} from "./features/login/loginSlice";
import {ApiErrorModal} from "./features/api/ApiErrorModal";
import {selectApi} from "./features/api/ApiSlice";
import {
    selectSettings,
    setSettingsShowChangeRoleModal,
    setSettingsShowLoginAsModal, setSettingsShowModal
} from "./features/settings/settingsSlice";
import {Settings} from "./features/settings/Settings";
import {UserActionDropdown} from "./components/UserActionDropdown";
import {SwitchRoleModal} from "./features/settings/SwitchRoleModal";
import {LoginAsModal} from "./features/settings/LoginAsModal";
import OptionalPayPalScriptProvider from "./components/OptionalPayPalScriptProvider";
import ToastDisplay from "./features/toast/ToastDisplay";
import {setConstants} from "./features/constants/constantsSlice";

import {UserInfo} from "./models/User";
import {Role} from "./models/Role";
import TournamentRoll from "./features/tournamentTab/TournamentRoll";
import TournamentAbandon from "./features/tournamentTab/TournamentAbandon";
import TournamentDelete from "./features/tournamentTab/TournamentDelete";
import TournamentProjectorView from "./features/tournamentTab/TournamentProjectorView";
import {
    abandonTournament, contactDetailsViewTournament, deleteTournament, editTournament,
    projectorViewTournament, rollForTournament, selectTournamentTab
} from "./features/tournamentTab/tournamentTabSlice";
import {
    modifyTournamentStaff,
    selectActiveTournaments,
    setActiveTournamentPlayerSummary,
    setActiveTournamentTeamBarring,
    setActiveTournamentTeamSummary, showActiveTournamentPersonalDetails,
    showFinancialsForUser
} from "./features/activeTournaments/activeTournamentSlice";
import {ActiveTournamentUpdateStaff} from "./features/activeTournaments/ActiveTournamentUpdateStaff";
import ActiveTournamentTeamBarringModal
    from "./features/activeTournaments/ActiveTournamentTeamBarringModal";
import ActiveTournamentTeamSummary
    from "./features/activeTournaments/ActiveTournamentTeamSummary";
import ActiveTournamentPlayerSummary
    from "./features/activeTournaments/ActiveTournamentPlayerSummary";
import TournamentEdit from "./features/tournamentTab/TournamentEdit";
import ChildEditForm from "./features/login/ChildEditForm";
import {
    selectParentTab,
    setRunChangePasswordForUser,
    setRunEditChild
} from "./features/parentTab/parentTabSlice";
import ActiveTournamentFinancialsForUser
    from "./features/activeTournaments/ActiveTournamentFinancialsForUser";
import ChangePasswordModal from "./features/settings/ChangePasswordModal";
import PersonalDetails from "./components/PersonalDetails";
import ConfirmEMail from "./components/ConfirmEMail";
import CheckPasswordReset from "./features/login/CheckPasswordReset";
import ResetPassword from "./features/login/ResetPassword";
import TournamentPlayerContactDetailView
    from "./features/tournamentTab/TournamentPlayerContactDetailView";
import {useAppSelector} from "./store";

const renderUserRoles = (username: UserInfo["username"], selectedRoles: Role[]) => {
    const user = `User: ${username}`;
    const roles = (selectedRoles.length > 0) ? [
        [
            (selectedRoles.length === 1) ? "Role:" : "Roles:",
            selectedRoles.map((role) => role.name).join(", ")
        ].join(" ")
    ] : [];

    return [user, ...roles].join(", ");
};

const PawnBrokerNavBar: React.FC = () => {
    const {isLoggedIn, username} = useAppSelector(selectLogin);
    const {selectedRoles} = useAppSelector(selectLoginRoles);

    return (
        <Navbar>
            <Container fluid>
                <Navbar.Brand>
                    <Image src={logo} alt="Chess Ideas logo" className="navbar-icon" />
                    <div className="navbar-title">
                        <div className="navbar-company">Chess Ideas</div>
                        <div className="navbar-name">Pawnbroker</div>
                    </div>
                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="text-end">
                        {(isLoggedIn) && (
                            <>
                                <UserActionDropdown/>
                                <small className="text-muted">
                                    ({renderUserRoles(username, selectedRoles)})
                                </small>
                            </>
                        )}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

/*
 * To make the token refresh by timeout, you could use (now it is done on demand in baseQuery):
 *   const {data: credentials, error: tokenRefreshError, refetch: refreshToken} = useTokenRefreshQuery(undefined);
 *   useEffect(() => {
 *         dispatch(setLoginCredentials(credentials));
 *         const id = setTimeout(refreshToken, refreshInterval * 1000);
 *         return () => {
 *             clearTimeout(id);
 *         }
 *      },
 *      [credentials, dispatch, refreshToken, refreshInterval]
 *   );
 */

const App: React.FC = () => {
    const dispatch = useDispatch();
    const {isLoggedIn, isResettingPassword, constantRefreshInterval} = useAppSelector(selectLogin);
    const {error} = useAppSelector(selectApi);
    const {
        tournamentEdit, tournamentRoll, tournamentAbandon, tournamentDelete, tournamentProjectorView, tournamentPlayerContactView
    } = useAppSelector(selectTournamentTab);
    const {tournamentTeamBarring, tournamentPlayerSummary, tournamentTeamSummary, showPersonalDetails} = useAppSelector(selectActiveTournaments);
    const {tournamentModifyStaff, financialsForUser} = useAppSelector(selectActiveTournaments);
    const {isShowingSettingsModal, isShowingSwitchRoleModal, isShowingLoginAsModal} = useAppSelector(selectSettings);
    const {runEditChild, runChangePasswordForUser} = useAppSelector(selectParentTab);
    const closeModifyTournamentStaff = useCallback(() => dispatch(modifyTournamentStaff(null)), [dispatch]);
    const closeFinancialsForUser = useCallback(() => dispatch(showFinancialsForUser(undefined)), [dispatch]);
    const closeChangeRoleModal = useCallback(() => dispatch(setSettingsShowChangeRoleModal(false)), [dispatch]);
    const closeLoginAsModal = useCallback(() => dispatch(setSettingsShowLoginAsModal(false)), [dispatch]);
    const closeSettingsModal = useCallback(() => dispatch(setSettingsShowModal(false)), [dispatch]);
    const closeTournamentDelete = useCallback(() => dispatch(deleteTournament(null)), [dispatch]);
    const closeTournamentRoll = useCallback(() => dispatch(rollForTournament(null)), [dispatch]);
    const closeTournamentAbandon = useCallback(() => dispatch(abandonTournament(null)), [dispatch]);
    const closeTournamentProjectorView = useCallback(() => dispatch(projectorViewTournament(null)), [dispatch]);
    const closeTournamentContactDetailView = useCallback(() => dispatch(contactDetailsViewTournament(null)), [dispatch]);
    const closeActiveTournamentTeamSummary = useCallback(() => dispatch(setActiveTournamentTeamSummary(null)), [dispatch]);
    const closeActiveTournamentPlayerSummary = useCallback(() => dispatch(setActiveTournamentPlayerSummary(null)), [dispatch]);
    const closeTournamentTeamBarring = useCallback(() => dispatch(setActiveTournamentTeamBarring(null)), [dispatch]);
    const closeTournamentEdit = useCallback(() => dispatch(editTournament(null)), [dispatch]);
    const closeEditChild = useCallback(() => dispatch(setRunEditChild(undefined)), [dispatch]);
    const closeChangePasswordForUser = useCallback(() => dispatch(setRunChangePasswordForUser(undefined)), [dispatch]);
    const closePersonalDetails = useCallback(() => dispatch(showActiveTournamentPersonalDetails(null)), [dispatch]);

    const {data: credentials} = useTokenRefreshQuery();
    useEffect(
        () => {
            (credentials) && dispatch(setLoginCredentials(credentials))
        },
        [credentials, dispatch]
    );
    const {data: constants} = useConstantsQuery(undefined, {pollingInterval: constantRefreshInterval * 1000});
    useEffect(
        () => {
            (constants) && dispatch(setConstants(constants))
        },
        [constants, dispatch]
    );
    const renderPageContent = () => {
        if (isResettingPassword) {
            return <ResetPassword token={isResettingPassword}/>;
        } else if (!isLoggedIn) {
            return <LoginOrRegister/>;
        } else {
            return (
                <>
                    <ConfirmEMail/>
                    <PawnBrokerTabs/>
                </>
            );
        }
    };

    return (
        <OptionalPayPalScriptProvider>
            <ToastDisplay position="top-end"/>
            <PawnBrokerNavBar/>
            <CheckPasswordReset/>
            {(error) && <ApiErrorModal error={error}/>}
            {renderPageContent()}
            {(isShowingSettingsModal) && <Settings onClose={closeSettingsModal}/>}
            {(isShowingSwitchRoleModal) && <SwitchRoleModal onClose={closeChangeRoleModal}/>}
            {(tournamentRoll) && <TournamentRoll tournamentKey={tournamentRoll.key} onClose={closeTournamentRoll}/>}
            {(tournamentAbandon) && <TournamentAbandon tournament={tournamentAbandon} onClose={closeTournamentAbandon}/>}
            {(tournamentDelete) && <TournamentDelete tournament={tournamentDelete} onClose={closeTournamentDelete}/>}
            {(tournamentModifyStaff) && (
                <ActiveTournamentUpdateStaff tournamentKey={tournamentModifyStaff.key}
                                             onClose={closeModifyTournamentStaff}/>
            )}
            {(financialsForUser) && (
                <ActiveTournamentFinancialsForUser user={financialsForUser}
                                                   onClose={closeFinancialsForUser}/>
            )}
            {(tournamentProjectorView) && <TournamentProjectorView tournamentLite={tournamentProjectorView} onClose={closeTournamentProjectorView}/>}
            {(tournamentPlayerContactView) && <TournamentPlayerContactDetailView tournament={tournamentPlayerContactView} onClose={closeTournamentContactDetailView}/>}
            {/*{(tournamentRePairTournamentRound) &&*/}
            {/*    <ActiveTournamentRePair tournament={tournament}*/}
            {/*                            tournamentRound={tournamentRePairTournamentRound}*/}
            {/*                            onClose={closeTournamentRePair}/>*/}
            {/*}*/}
            {(tournamentTeamBarring) && (
                <ActiveTournamentTeamBarringModal tournament={tournamentTeamBarring}
                                                  onClose={closeTournamentTeamBarring}/>
            )}
            {(tournamentTeamSummary) && (
                <ActiveTournamentTeamSummary tournament={tournamentTeamSummary.tournament}
                                             team={tournamentTeamSummary.team}
                                             onClose={closeActiveTournamentTeamSummary}/>
            )}
            {(tournamentPlayerSummary) &&
                <ActiveTournamentPlayerSummary tournament={tournamentPlayerSummary.tournament}
                                               player={tournamentPlayerSummary.player}
                                               onClose={closeActiveTournamentPlayerSummary}/>
            }
            {(tournamentEdit) && <TournamentEdit tournament={tournamentEdit} onClose={closeTournamentEdit}/>}
            {(runEditChild) && <ChildEditForm childId={runEditChild} onClose={closeEditChild}/>}
            {(runChangePasswordForUser) && <ChangePasswordModal userId={runChangePasswordForUser.userId}
                                                               fullName={runChangePasswordForUser.fullName}
                                                               onClose={closeChangePasswordForUser}/>}
            {(showPersonalDetails) && <PersonalDetails tournament={showPersonalDetails.tournament}
                                                       user={showPersonalDetails.user}
                                                       onClose={closePersonalDetails}/>}

            {(isShowingLoginAsModal) && <LoginAsModal onClose={closeLoginAsModal}/>}
        </OptionalPayPalScriptProvider>
    );
};

export default App;
