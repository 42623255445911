import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {TypeaheadResult} from "react-bootstrap-typeahead";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";

import {ActiveTournamentContext} from "./ActiveTournament";
import {useAppDispatch, useAppSelector} from "../../store";
import TournamentPlayerList from "../../components/TournamentPlayerList";
import useSelection from "../../hooks/useSelection";
import {TournamentPlayer} from "../../models/TournamentPlayer";
import {setActiveTournamentTeamBarring} from "./activeTournamentSlice";
import RemoveSelectedPlayersFromTeamButton from "./RemoveSelectedPlayersFromTeamButton";
import GroupPicker from "../../components/GroupPicker";
import {selectConstantsGroupTypeByDescription} from "../constants/constantsSlice";
import {GroupMinSchema, newGroup} from "../../models/Group";
import {useUpdateGroupMutation} from "../../api/chessMasterApi";
import {addToast, newToastError, newToastSuccess} from "../toast/toastSlice";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {isSetEqual} from "../../util/isSetEqual";


const TeamBarringButton: React.FC<{className?: string}> = ({className}) => {
    const dispatch = useAppDispatch();
    const {tournament} = useContext(ActiveTournamentContext);

    return (
        <Button onClick={() => dispatch(setActiveTournamentTeamBarring(tournament))}
                className={className}>
            Team barring ...
        </Button>
    );
};

interface Props {
    selectedTeam: GroupMinSchema | undefined
    setSelectedTeam: (team: GroupMinSchema | undefined) => void
}

const ActiveTournamentTeamsEditor: React.FC<Props> = ({selectedTeam, setSelectedTeam}) => {
    const dispatch = useAppDispatch();
    const {tournament} = useContext(ActiveTournamentContext);
    const teamGroupType = useAppSelector(selectConstantsGroupTypeByDescription)["Team"];
    const teams = tournament.teams.map((team) => tournament.ref_group[team.group_id]).sort((a, b) => a.name.localeCompare(b.name));
    const [updateGroup] = useUpdateGroupMutation();
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);
    const teamPlayers = useMemo(
        () => tournament.players.filter((player) => player.team_id === selectedTeam?.id),
        [selectedTeam, tournament]);
    const playerIdSelection = useSelection<TournamentPlayer["user_id"]>({
        allItems: teamPlayers.map((player) => player.user_id)
    });
    const updateSelectedTeam = useCallback((team: TypeaheadResult<GroupMinSchema> | undefined) => {
        if (team?.customOption) {
            updateGroup({group: newGroup({name: team.name, group_type_id: teamGroupType.id})})
                .unwrap()
                .then((group) => {
                    dispatch(addToast(newToastSuccess({
                        heading: "New team created",
                        body: `Successfully created new team "${group.name}"`
                    })));
                    return group;
                })
                .then((group) => {
                    setSelectedTeam(group);
                })
                .catch((error) => {
                    dispatch(addToast(newToastError({
                        heading: "Create team failed",
                        body: formatErrorMessage(error)
                    })));
                });
        } else {
            setSelectedTeam(team);
        }
        playerIdSelection.setSelectedItems({items: []});
    }, [dispatch, setSelectedTeam, teamGroupType, playerIdSelection, updateGroup]);

    useEffect(() => {
        const teamPlayerIds = teamPlayers.map((player) => player.user_id);

        if (!isSetEqual(playerIdSelection.allItemsSet, new Set(teamPlayerIds))) {
            playerIdSelection.setAllItems({items: teamPlayerIds});
        }
    }, [playerIdSelection, teamPlayers]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <GroupPicker selectedGroup={selectedTeam}
                                     onChange={updateSelectedTeam}
                                     placeholder="Select a team ..."
                                     defaultGroups={teams}
                                     fieldError={(groupSearchError) ? {type: "invalid"} : undefined}
                                     setErrorMessage={setGroupSearchError}
                                     allowNew
                                     newSelectionPrefix="Create new team: "/>
                        <Form.Control.Feedback type="invalid">
                            {groupSearchError}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row>
                    <Col className="py-1">
                        <RemoveSelectedPlayersFromTeamButton tournament={tournament}
                                                             team={selectedTeam}
                                                             playerIdSelection={playerIdSelection}/>
                        <TeamBarringButton className="float-end"/>
                    </Col>
                </Row>
                {(selectedTeam) ? (
                    <Row className="py-1">
                        <TournamentPlayerList players={teamPlayers}
                                              tournament={tournament}
                                              noPlayersMessage={`(Team “${selectedTeam.name}” has no players)`}
                                              playerIdSelection={playerIdSelection}/>
                    </Row>
                ) : (
                    <Badge bg="info" pill className="w-100">
                        No team selected
                    </Badge>
                )}
            </Container></>
    );
};

export default ActiveTournamentTeamsEditor;
