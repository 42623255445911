import React, {useEffect, useRef, useState} from "react";
import {useWakeLock} from "react-screen-wake-lock";
import {BsGear} from "react-icons/bs";
import {format} from "date-fns";

import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import {useGetTournamentQuery} from "../../api/chessMasterApi";
import {isTournamentComplete, Tournament, TournamentLite} from "../../models/Tournament";
import {formatTournamentStatus} from "../../util/formatTournamentStatus";
import TournamentProjectorSettings from "./TournamentProjectorSettings";
import ActiveTournamentRoundProjector from "./ActiveTournamentRoundProjector";

const activeRound = (tournament: Tournament) => {
    if (isTournamentComplete(tournament)) {
        return tournament.rounds[tournament.rounds.length - 1];
    } else if (tournament.cur_round) {
        return tournament.rounds[tournament.cur_round - 1];
    } else {
        return undefined;
    }
};

const TournamentTitle: React.FC<{tournament: Tournament | TournamentLite}> = ({tournament}) => {
    return (
        <span>“{tournament.name}” &mdash; {formatTournamentStatus(tournament)}</span>
    );
};

interface Props {
    tournamentLite: Tournament | TournamentLite
    onClose: () => void
}

const TournamentProjectorView: React.FC<Props> = ({tournamentLite, onClose}) => {
    const {data: tournament, error} = useGetTournamentQuery({key: tournamentLite.key}, {pollingInterval: 20 * 1000});
    const [showSettings, setShowSettings] = useState(false);
    const settingsTarget = useRef<HTMLButtonElement>(null);
    const round = tournament && activeRound(tournament);
    const time = format(new Date(), "kk:mm:ss");
    const {request, release} = useWakeLock();
    useEffect(
        () => {
            request().then(() => console.debug("wakeLock: requested"));
            return () => {
                release().then(() => console.debug("wakeLock: released"));
            };
        },
    [release, request]);

    return (
        <Modal show
               onHide={onClose}
               backdrop="static"
               fullscreen
               enforceFocus={false}
               dialogClassName="w-100">
            <Modal.Header closeButton>
                <Modal.Title className="w-100">
                    <Stack direction="horizontal" gap={5}>
                        <TournamentTitle tournament={(tournament) ? tournament : tournamentLite}/>
                        <OverlayTrigger trigger="click"
                                        placement="auto"
                                        show={showSettings}
                                        overlay={<TournamentProjectorSettings onClose={() => setShowSettings(false)}/>}>
                            <Button size='sm' variant='outline-dark'
                                    ref={settingsTarget}
                                    onClick={() => setShowSettings(true)}>
                                <BsGear/>
                            </Button>
                        </OverlayTrigger>
                        <span className="fs-6 text-muted ms-auto">({time})</span>
                    </Stack>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(tournament && round) ? (
                    <ActiveTournamentRoundProjector tournament={tournament}
                                                    matches={round.matches}/>
                ) : (
                    "(Tournament not playing)"
                )}
            </Modal.Body>

            {(error) && (
                <Modal.Footer>
                    <span className="text-danger">{JSON.stringify(error)}</span>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default TournamentProjectorView;
