import React, {useCallback, useMemo} from 'react';

import DataTable, {TableColumn} from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {Team} from "../../models/Team";
import {teamChanges} from "../../util/teamChanges";
import {Tournament} from "../../models/Tournament";
import {useDispatch} from "react-redux";
import {
    noPairPayloadFilter,
    useGetTournamentQuery,
    useUpdateTeamsInTournamentMutation
} from "../../api/chessMasterApi";
import {setApiError} from "../api/ApiSlice";

interface Status {
    value: boolean | null
    description: string
}

interface PairingStatusProps {
    team: Team
    onChange: (team: Team, noPair: boolean | null) => void
}

const PairingStatus: React.FC<PairingStatusProps> = ({team, onChange}) => {
    const statuses: Status[] = [
        {value: true, description: "Cannot pair"},
        {value: false, description: "Can pair"},
        {value: null, description: "Not set"}
    ];
    const noPairVariant = (no_pair: boolean | null, status: Status) => {
        if (team.no_pair === status.value) {
            return (status.value !== null) ? "primary" : "danger";
        } else {
            return "outline-secondary";
        }
    };

    return (
        <ButtonGroup size="sm">
            {statuses.map((status, i) => (
                <Button key={i}
                        variant={noPairVariant(team.no_pair, status)}
                        onClick={() => onChange(team, status.value)}>
                    {status.description}
                </Button>
            ))}
        </ButtonGroup>
    );
};

interface TournamentTeamsProps {
    tournamentKey: Tournament["key"]
}

const TournamentTeams: React.FC<TournamentTeamsProps> = ({tournamentKey}) => {
    const dispatch = useDispatch();
    const [updateTeamsInTournament] = useUpdateTeamsInTournamentMutation();
    const {data: tournament} = useGetTournamentQuery({key: tournamentKey});
    const teams = useMemo(() => (tournament) ? tournament.teams : [], [tournament]);
    const setTeamPairingStatus = useCallback((team: Team, noPair: boolean | null) => {
        const updateTeam = (team: Team, noPair: boolean | null) => ({...team, no_pair: noPair});
        const newTeams = teams.map((t) => (t.group_id === team.group_id) ? updateTeam(t, noPair) : t);

        updateTeamsInTournament({
            key: tournamentKey,
            teams: teamChanges(teams, newTeams),
            payloadFilter: noPairPayloadFilter
        })
            .unwrap()
            .catch((error) => dispatch(setApiError(`updateTeamsInTournament: ${JSON.stringify(error)}`)));
    }, [dispatch, teams, tournamentKey, updateTeamsInTournament]);
    const columns: TableColumn<Team>[] = [{
        name: "Team",
        selector: (row) => (tournament) ? tournament.ref_group[row.group_id].name : "",
        sortable: true
    }, {
        name: "Pairing status",
        center: true,
        cell: (row) => <PairingStatus team={row}
                                      onChange={setTeamPairingStatus}/>
    }];

    return (
        <DataTable<Team> data={teams ?? []}
                         columns={columns}
                         keyField="group.id"
                         defaultSortFieldId={1}
                         noDataComponent="(No teams)"/>
    );
};

export default TournamentTeams;
