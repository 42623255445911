import React, {useCallback, useState} from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import {
    chessMasterApi,
    useInterimPaymentForUserQuery,
    usePaymentInitiateMutation
} from "../../api/chessMasterApi";
import {Payer, selectPaymentTab, setSelectedPaymentItems} from "./paymentTabSlice";
import {InterimPaymentItem, Payment} from "../../models/Payment";
import {formatMoney} from "accounting";
import PaymentModal from "./PaymentModal";
import InterimPaymentList from "./InterimPaymentList";
import {selectConstantsPaymentSystem} from "../constants/constantsSlice";
import {setApiError} from "../api/ApiSlice";
import PastPaymentModal from "./PastPaymentModal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {selectLogin} from "../login/loginSlice";
import Badge from "react-bootstrap/Badge";
import {addToast, newToastSuccess} from "../toast/toastSlice";
import {useAppDispatch, useAppSelector} from "../../store";

const PayerCard: React.FC<{payer: Payer}> = ({payer}) => (
    <Card>
        <Card.Header>Payment successful</Card.Header>
        <Card.Body>
            <Card.Title>PayPal payment details</Card.Title>
            <Card.Text>Name: {payer.given_name} {payer.surname}</Card.Text>
            <Card.Text>E-mail address: {payer.email_address}</Card.Text>
            <Card.Text>Payer id: {payer.payer_id}</Card.Text>
        </Card.Body>
    </Card>
);

const amountPayable = (items: InterimPaymentItem[], credit: number) => {
    const totalPayable = items.reduce((acc, item) => acc + parseFloat(item.amount), 0);

    return (totalPayable > credit) ? totalPayable - credit : 0;
};

const PaymentTab: React.FC = () => {
    const dispatch = useAppDispatch();
    const {userId} = useAppSelector(selectLogin);
    const {payer, selectedPaymentItems} = useAppSelector(selectPaymentTab);
    const {data: interimPayment} = useInterimPaymentForUserQuery({id: userId});
    const [paymentInitiate] = usePaymentInitiateMutation();
    const [runPaymentModal, setRunPaymentModal] = useState<Payment | null>(null);
    const [pastPaymentModal, setPastPaymentModal] = useState(false);
    const {paypalConfig, stripeConfig} = useAppSelector(selectConstantsPaymentSystem);
    const closePaymentModal = useCallback((isPaymentCompleted: boolean) => {
        setRunPaymentModal(null);
        if (isPaymentCompleted) {
            dispatch(setSelectedPaymentItems([]));
            // force refetch of InterimPayment after modal closes
            dispatch(chessMasterApi.util.invalidateTags(["Payment"]));
        }
    }, [dispatch]);
    const closePastPaymentModal = useCallback(() => {
        setPastPaymentModal(false);
        // discard cached payment records so that they are refetched next time
        dispatch(chessMasterApi.util.invalidateTags(["Payment"]));
    }, [dispatch]);
    const payForSelected = (selectedItems: InterimPaymentItem[]) => {
        const paymentSystem = [paypalConfig, stripeConfig].find((config => !!config));

        if (!paymentSystem) {
            dispatch(setApiError("No active payment system"));
            return;
        }
        paymentInitiate({
            items: selectedItems.map((payment) => payment.identifier),
            payment_system: paymentSystem.name,
            amount: amountPayable(selectedPaymentItems, credit).toFixed(2),
            reference: null,
            payer_id: null  // ie, as logged in user
        })
            .unwrap()
            .then((payment) => {
                switch (payment.status) {
                case "Paid":
                    dispatch(addToast(newToastSuccess({
                        heading: "Payment successful",
                        body: `Paid ${formatMoney(payment.amount, "$")} for ${payment.items.length} ${(payment.items.length === 1) ? "item" : "items"}`
                    })));
                    closePaymentModal(true);
                    break;
                case "Pending":
                    setRunPaymentModal(payment);
                    break;
                default:
                    dispatch(setApiError(`Unexpected payment status: ${payment.status}`));
                }
            })
            .catch((error) => dispatch(setApiError(error.data.message)));
    };
    const credit = (interimPayment?.credit) ? parseFloat(interimPayment?.credit) : 0;
    const buttonLabel = (total: string | undefined, selectedItems: InterimPaymentItem[]) => {
        if (selectedItems.length > 0) {
            return (
                <span>
                    Pay {formatMoney(total ?? 0, "$")} for {" "}
                    {selectedItems.length} {(selectedItems.length === 1) ? "item" : "items"}
                </span>
            );
        } else {
            return <span>(No selection)</span>
        }
    };

    return (
        <>
            {(runPaymentModal) && (
                <PaymentModal payment={runPaymentModal}
                              amountPayable={amountPayable(selectedPaymentItems, credit)}
                              onClose={closePaymentModal}/>
            )}
            {(pastPaymentModal) && (
                <PastPaymentModal onClose={closePastPaymentModal}/>
            )}

            <Container className="right-menu">
                <Row className="g-0">
                    {(credit > 0) && (
                        <h4 className="w-100">
                            <Badge bg="success" className="w-100 mb-2">
                                Credit: {formatMoney(credit, "$")}
                            </Badge>
                        </h4>
                    )}
                    <ButtonToolbar className="w-100">
                        <ButtonGroup className="w-100">
                            <Button onClick={() => payForSelected(selectedPaymentItems)}
                                    className="w-100 mb-1"
                                    disabled={selectedPaymentItems.length === 0}>
                                {buttonLabel(amountPayable(selectedPaymentItems, credit).toFixed(2), selectedPaymentItems)}
                            </Button>
                        </ButtonGroup>
                        <Button onClick={() => setPastPaymentModal(true)}
                                className="w-100 mb-1"
                                variant="secondary">
                            Past payments ...
                        </Button>
                    </ButtonToolbar>
                </Row>
            </Container>

            <Container fluid className="mt-3">
                <Row>
                    <Col>
                        <InterimPaymentList interimPayment={interimPayment}
                                            setSelectedPayments={(items) => dispatch(setSelectedPaymentItems(items))}/>
                    </Col>
                </Row>

                {(payer) && (
                    <Row className="translate-middle">
                        <PayerCard payer={payer}/>
                    </Row>
                )}
            </Container>
        </>
    );
};

export default PaymentTab;
