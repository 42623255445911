import React from "react";
import {useDispatch} from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {selectApi, setApiError, setApiErrorRepairTournament} from "./ApiSlice";
import {setActiveTournamentTeamBarring} from "../activeTournaments/activeTournamentSlice";
import {Tournament} from "../../models/Tournament";
import {useAppSelector} from "../../store";

interface ApiErrorModalProps {
    error: string
}

export const ApiErrorModal: React.FC<ApiErrorModalProps> = ({error}) => {
    const dispatch = useDispatch();
    const {repairTournament} = useAppSelector(selectApi);
    const clearError = () => {
        dispatch(setApiError(undefined));
        dispatch(setApiErrorRepairTournament(null));
    };
    const rePairButton = (tournament: Tournament) => {
        return (
            <Button onClick={() => {
                clearError();
                dispatch(setActiveTournamentTeamBarring(tournament));
            }}>
                Team barring ...
            </Button>
        )
    };
    const errorTitle = (
        (repairTournament) ? "Pairing error" : "API Error"
    );

    return (
        <Modal show
               onHide={clearError}>
            <Modal.Header>
                <Modal.Title>{errorTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {error}
            </Modal.Body>

            <Modal.Footer>
                {(repairTournament) && (rePairButton(repairTournament))}
                <Button onClick={clearError}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};