import React from "react";

import {usePastPaymentsForUserIdQuery} from "../../api/chessMasterApi";
import {User} from "../../models/User";
import PaymentList from "./PaymentList";

const PastPaymentList: React.FC<{user: {id: User["id"]}}> = ({user}) => {
    const {data: pastPayments, isFetching} = usePastPaymentsForUserIdQuery({userId: user.id});

    return (
        <PaymentList payments={pastPayments ?? []}
                     isFetching={isFetching}/>
    );
};

export default PastPaymentList;
