import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {chessMasterApi, useChangeRoleMutation} from "../../api/chessMasterApi";
import {selectLoginRoles, setLoginCredentials} from "../login/loginSlice";
import {Role} from "../../models/Role";
import SwitchRoleForm from "./SwitchRoleForm";
import {useAppSelector} from "../../store";

const SwitchRoleSchema = yup.object().shape({
    password: yup.string().required().label("Password"),
});

export interface SwitchRoleFormInput {
    roleId: string
    password: string
}

export const SwitchRoleModal: React.FC<{onClose: () => void}> = ({onClose}) => {
    const dispatch = useDispatch();
    const [changeRole, {isLoading}] = useChangeRoleMutation();
    const [switchRoleError, setSwitchRoleError] = useState("");
    const {selectedRoles, availableRoles} = useAppSelector(selectLoginRoles);
    const defaultRoleId = (availableRoles.length > 0) ? `${availableRoles[0].id}` : "";
    const roleForRoleId = (roleId: Role["id"]) => (
        availableRoles.find((r) => r.id === roleId)
    );

    const formMethods = useForm<SwitchRoleFormInput>({
        defaultValues: {roleId: defaultRoleId, password: ""},
        resolver: yupResolver(SwitchRoleSchema)
    });
    const onSubmit = ({roleId, password}: SwitchRoleFormInput) => {
        const id = (roleId === "all") ? null : roleForRoleId(Number(roleId))?.id;
        if (id === undefined) {
            alert(`switchUserRole: unknown role id ${roleId}`);
            return;
        }
        changeRole({roleId: id, password})
            .unwrap()
            .then((credentials) => {
                dispatch(setLoginCredentials(credentials));
                dispatch(chessMasterApi.util.resetApiState());
                onClose();
            })
            .catch((error) => setSwitchRoleError(error.data.message));
    };

    return (
        <Modal show
               onHide={onClose}
               backdrop="static">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Card.Title>Switch roles</Card.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {(selectedRoles.length === 1) ? "Current role:" : "Current roles:"}{" "}
                        {selectedRoles.map((role) => role.name).sort().join(", ")}
                    </Modal.Body>

                    <Modal.Body>
                        <SwitchRoleForm availableRoles={availableRoles}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={onClose}
                                disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit"
                                variant="primary"
                                disabled={isLoading}>
                            Switch roles
                        </Button>
                    </Modal.Footer>

                    {(switchRoleError) && (
                        <Modal.Footer>
                            <span className="text-danger">
                                Switch roles failed: {switchRoleError}
                            </span>
                        </Modal.Footer>
                    )}
                </Form>
            </FormProvider>
        </Modal>
    );
};