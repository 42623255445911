import React, {createContext, useCallback, useMemo} from "react";
import useLocalStorageState from "use-local-storage-state";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ActiveTournamentSummaryCard from "./ActiveTournamentSummaryCard";
import {isPlayMethodOnline, isTournamentCurrentRound, isTournamentStarted, Tournament} from "../../models/Tournament";
import ActiveTournamentPlayers from "./ActiveTournamentPlayers";
import {selectActiveTournaments, setActiveTournamentRePairMatches,} from "./activeTournamentSlice";
import {
    addActiveTournament, removeActiveTournamentKey, selectActiveTab
} from "../activeTab/activeTabSlice";
import ActiveTournamentRePair from "./ActiveTournamentRePair";
import {TournamentRound} from "../../models/TournamentRound";
import ActiveTournamentRoundCard from "./ActiveTournamentRoundCard";
import ActiveTournamentStandings from "./ActiveTournamentStandings";
import ActiveTournamentCrossTable from "./ActiveTournamentCrossTable";

import "./ActiveTournament.css";
import ActiveTournamentTeams from "./ActiveTournamentTeams";
import {chessMasterApi, useGetTournamentQuery, useOnlinePlayersForTournamentQuery,} from "../../api/chessMasterApi";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {selectConstantsMatchStatusByDescription} from "../constants/constantsSlice";
import {makeScoresForTournament} from "../../util/makeScoresForTournament";
import ActiveTournamentFinancials from "./ActiveTournamentFinancials";
import {selectLogin} from "../login/loginSlice";
import {UserInfo} from "../../models/User";
import ActiveTournamentTeamStandings from "./ActiveTournamentTeamStandings";
import {useAppDispatch, useAppSelector} from "../../store";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import ActiveTournamentEntrants from "./ActiveTournamentEntrants";
import {useNavigate, useParams} from "react-router-dom";

export interface ActiveTournamentOptions {
    isHidingSummary: boolean
    activeTabKey: string | null
}

interface ActiveTournamentContextType {
    tournament: Tournament
    onlinePlayerIds: Set<UserInfo["id"]>
    options: ActiveTournamentOptions
    setOptions: (options: ActiveTournamentOptions) => void
}

export const ActiveTournamentContext = createContext<ActiveTournamentContextType>({onlinePlayerIds: new Set<UserInfo["id"]>()} as ActiveTournamentContextType);

interface Props {
    isPreparedToDelete?: boolean
}

const ActiveTournament: React.FC<Props> = ({isPreparedToDelete}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {tournament_key, tab_id} = useParams() as {tournament_key: Tournament["key"], tab_id: string};
    const {privs} = useAppSelector(selectLogin);
    const {data: tournament, error: getTournamentError} = useGetTournamentQuery(
        {key: tournament_key},
        {skip: isPreparedToDelete, pollingInterval: 60 * 1000}
    );
    const {data: onlinePlayers} = useOnlinePlayersForTournamentQuery({key: tournament_key}, {skip: isPreparedToDelete || !tournament || !isPlayMethodOnline(tournament.play_method)});
    const [options, setOptions] = useLocalStorageState<ActiveTournamentOptions>(
        `activeTournament/${tournament_key}/options`,
        {defaultValue: {isHidingSummary: true, activeTabKey: null}}
    );
    const rounds = tournament?.rounds ?? [];
    const {tournamentRePairTournamentRound} = useAppSelector(selectActiveTournaments);
    const {tabDetails} = useAppSelector(selectActiveTab);
    const matchResultByDescription = useAppSelector(selectConstantsMatchStatusByDescription);
    const closeTournamentRePair = useCallback(() => dispatch(setActiveTournamentRePairMatches(null)), [dispatch]);
    const roundTitle = (tournament: Tournament, round: TournamentRound) => (
        <span className={(tournament.cur_round === round.round_num) ? "fw-bold" : ""}>R{round.round_num}</span>
    );
    const scoresForRounds = useMemo(
        () => (tournament) ? makeScoresForTournament(tournament, matchResultByDescription) : {},
        [matchResultByDescription, tournament]
    );
    const onSelect = (eventKey: string | null) => {
        if (eventKey === "financials") {
            // when changing to financials tab, flush cache
            dispatch(chessMasterApi.util.invalidateTags(["TournamentFinancials"]));
        }
        setOptions({...options, activeTabKey: eventKey});
        navigate(`/tournament/${tournament_key}/${eventKey}`)
    };

    if (!tournament_key) {
        navigate("/tournaments");
    }
    if (tab_id && options.activeTabKey !== tab_id) {
        onSelect(tab_id);
    }
    if (getTournamentError) {
        const message = formatErrorMessage(getTournamentError);

        if (message && /Tournament ID \[([\da-fA-F]*)] not found/.test(message)) {
            dispatch(removeActiveTournamentKey(tournament_key));
            dispatch(addToast(newToastMessage({
                priority: "warning",
                heading: `Tournament ${tournament_key} not found`,
                body: `Tournament not found.`
            })));
            console.log(`ActiveTournament: removing deleted tournament id ${tournament_key}`);
            navigate("/tournaments");
        }
    }
    const onlinePlayerIds = useMemo(() => (
        new Set<UserInfo["id"]>(onlinePlayers?.online_players ?? [])
    ), [onlinePlayers]);
    const playersOrEntrantsTab = (tournament: Tournament) => {
        if (tournament.controls?.director || privs.admin || privs.group_admin) {
            return (
                <Tab eventKey="players" title="Players">
                    <ActiveTournamentPlayers/>
                </Tab>
            );
        } else if (!isTournamentStarted(tournament)) {
            return (
                <Tab eventKey="entrants" title="Entrants">
                    <ActiveTournamentEntrants/>
                </Tab>
            );
        } else {
            return null;
        }
    };
    if (tournament) {
        if (!tabDetails.find((td) => td.tournamentKey === tournament_key)) {
            dispatch(addActiveTournament(tournament));
        }
    } else {
        return <Spinner className="text-center" animation="border"/>;
    }
    // for debugger
    ActiveTournamentContext.displayName = `ActiveTournamentContext ${tournament.id}: ${tournament.name}`;
    return (
        <ActiveTournamentContext.Provider value={{tournament, onlinePlayerIds, options, setOptions}}>
            <Container fluid>
                {(tournamentRePairTournamentRound && tournament?.id === tournamentRePairTournamentRound.tournament_id) &&
                    <ActiveTournamentRePair tournament={tournament}
                                            tournamentRound={tournamentRePairTournamentRound}
                                            onClose={closeTournamentRePair}/>
                }
                <ActiveTournamentSummaryCard/>

                <Tabs className="mt-3"
                      activeKey={options.activeTabKey ?? undefined}
                      id="active-tournament-players-rounds-tabs"
                      mountOnEnter
                      unmountOnExit
                      onSelect={onSelect}>
                    {playersOrEntrantsTab(tournament)}
                    {(privs.finance_view) && (
                        <Tab eventKey="financials" title="Financials">
                            <ActiveTournamentFinancials/>
                        </Tab>
                    )}
                    {(tournament.controls?.staff || privs.admin) && (
                        <Tab eventKey="teams" title="Teams">
                            <ActiveTournamentTeams/>
                        </Tab>
                    )}
                    {(isTournamentStarted(tournament) && tournament.teams.length > 0) && (
                        <Tab eventKey="team-scores" title="Team standings">
                            <ActiveTournamentTeamStandings/>
                        </Tab>
                    )}
                    {(isTournamentStarted(tournament)) && (
                        <Tab eventKey="crosstable" title="Crosstable">
                            <ActiveTournamentCrossTable/>
                        </Tab>
                    )}
                    {(isTournamentStarted(tournament)) && (
                        <Tab eventKey="standings" title="Standings">
                            <ActiveTournamentStandings/>
                        </Tab>
                    )}
                    {(rounds ?? []).map((round: TournamentRound) => (
                        <Tab eventKey={`round-${round.round_num}`}
                             key={`round-${round.round_num}`}
                             tabClassName="minTab"
                             title={roundTitle(tournament, round)}>
                            <ActiveTournamentRoundCard round={round}
                                                       scores={scoresForRounds[round.round_num]}
                                                       isCurrentRound={isTournamentCurrentRound(tournament, round.round_num)}/>
                        </Tab>
                    ))}
                </Tabs>
            </Container>
        </ActiveTournamentContext.Provider>
    );
};

export default ActiveTournament;