import React from "react";
import Spinner from "react-bootstrap/Spinner";

import {useGetTournamentQuery} from "../../api/chessMasterApi";
import {Tournament} from "../../models/Tournament";
import TournamentRollList from "./TournamentRollList";

interface TournamentRollProps {
    tournamentKey: Tournament["key"]
    onClose: () => void
}

// Load the tournament before presenting its roll list. It's fraught doing the loading and
// updating state without triggering a render loop

const TournamentRoll: React.FC<TournamentRollProps> = ({tournamentKey, onClose}) => {
    const {data: tournament} = useGetTournamentQuery({key: tournamentKey});

    return (tournament) ? (
        <TournamentRollList tournament={tournament} onClose={onClose}/>
    ) : (
        <Spinner className="text-center" animation="border"/>
    );
};


export default TournamentRoll;