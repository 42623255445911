import React from 'react';
import {useDispatch} from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
    SaveSettingsRequest,
    useSaveSettingsMutation
} from "../../api/chessMasterApi";
// import {selectSettings} from "./settingsSlice";
import {addToast, newToastMessage} from "../toast/toastSlice";
import {setApiError} from "../api/ApiSlice";

export const Settings: React.FC<{onClose: () => void}> = ({onClose}) => {
    const dispatch = useDispatch();
    const [saveSettings] = useSaveSettingsMutation();
    const doSaveSettings = (settings: SaveSettingsRequest) => {
        saveSettings(settings)
            .unwrap()
            .then(() => {
                dispatch(addToast(newToastMessage({heading: "Settings", body: "Settings saved", priority: "success"})));
                onClose();
            })
            .catch((error) => dispatch(setApiError(`saveSettings: ${error.data.message}`)));
    };

    return (
        <Modal show={true}
               onHide={onClose}
               backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                (Settings)
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary"
                        onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary"
                        onClick={() => doSaveSettings({settings: "blah"})}>
                    Save changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};