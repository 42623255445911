import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import {
    useGetTournamentQuery,
    useUpdateStaffInTournamentMutation
} from "../../api/chessMasterApi";
import {createStaffUpdates} from "../../util/createStaffUpdates";
import TournamentAdmin from "../tournamentTab/TournamentAdmin";
import {Tournament} from "../../models/Tournament";
import {TournamentStaff} from "../../models/TournamentStaff";
import {UserInfo} from "../../models/User";
import {displayServerErrorsInForm} from "../../util/displayServerErrorsInForm";

export const splitStaffByDirector = (staff: TournamentStaff[]) => {
    const directors: UserInfo["id"][] = [];
    const nonDirectors: UserInfo["id"][] = [];

    staff.forEach((s) => ((s.director) ? directors : nonDirectors).push(s.user_id));

    return [directors, nonDirectors];
};

export interface TournamentStaffForm {
    staff: TournamentStaff[]
}

interface Props {
    tournamentKey: Tournament["key"]
    onClose: () => void
}

export const ActiveTournamentUpdateStaff: React.FC<Props> = ({tournamentKey, onClose}) => {
    const dispatch = useDispatch();
    const {data: tournament} = useGetTournamentQuery({key: tournamentKey});
    const formMethods = useForm<TournamentStaffForm>({
        defaultValues: {staff: tournament?.staff},
        reValidateMode: "onChange",
    });
    const [updateStaffInTournament] = useUpdateStaffInTournamentMutation();
    const onSubmit = ({staff}: TournamentStaffForm) => {
        updateStaffInTournament({
            key: tournamentKey,
            staffUpdates: createStaffUpdates(tournament?.staff ?? [], staff)
        })
            .unwrap()
            .then(onClose)
            .catch((error) => {
                displayServerErrorsInForm(error.data, formMethods, dispatch);
            });
    };
    useEffect(
        () => formMethods.reset({staff: tournament?.staff}),
        [formMethods, tournament]
    );

    return (
        <Modal show={true}
               onHide={onClose}
               size="lg">
            <Form onSubmit={formMethods.handleSubmit(onSubmit)}
                  autoComplete="off">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update staff for tournament “{tournament?.name}”
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormProvider {...formMethods}>
                        {(tournament) ? <TournamentAdmin tournament={tournament}/>
                                      : <span className="text-muted">(Loading ...)</span>}
                    </FormProvider>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonToolbar>
                        <ButtonGroup className="me-1">
                            <Button variant="secondary" onClick={onClose}>Cancel</Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button variant="primary" type="submit">Save</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
