import React, {useContext, useMemo} from "react";
import DataTable, {TableColumn} from "react-data-table-component";

import Button from "react-bootstrap/Button";

import {setActiveTournamentPlayerSummary} from "./activeTournamentSlice";
import {formatFullName} from "../../util/formatFullName";
import {ActiveTournamentContext} from "./ActiveTournament";
import UserLichessOnlineSymbol from "../../components/UserLichessOnlineSymbol";
import {selectConstantsGenderById} from "../constants/constantsSlice";
import {UserInfo} from "../../models/User";
import {createPlayerStandings, PlayerGroupStanding} from "../../util/createPlayerStandings";
import {useAppDispatch, useAppSelector} from "../../store";

const RankForPlayerGroup: React.FC<{playerGroup: PlayerGroupStanding}> = ({playerGroup}) => (
    <span>
        {(playerGroup.players.length === 1) ? playerGroup.groupRank.min : `${playerGroup.groupRank.min}-${playerGroup.groupRank.max}`}
    </span>
);

interface PlayersForPlayerGroupProps {
    playerGroup: PlayerGroupStanding
}

const PlayersForPlayerGroup: React.FC<PlayersForPlayerGroupProps> = ({playerGroup}) => {
    const dispatch = useAppDispatch();
    const {tournament, onlinePlayerIds} = useContext(ActiveTournamentContext);
    const genderById = useAppSelector(selectConstantsGenderById);
    const renderShortGender = (userInfo: UserInfo) => (
        genderById[userInfo.gender_id].short
    );

    return (
        <ul className="list-unstyled">
            {playerGroup.players.map((player, index) => {
                const playerUserInfo = tournament.ref_user[player.user_id];

                return (
                    <li key={index}>
                        <Button variant="link"
                                className="p-0 text-start"
                                onClick={() => {
                                    dispatch(setActiveTournamentPlayerSummary({
                                        tournament,
                                        player: playerUserInfo
                                    }));
                                }}>
                            {formatFullName(playerUserInfo)} ({renderShortGender(playerUserInfo)})
                        </Button>
                        {(player.team_id) && (
                            <span className="ms-1">({tournament.ref_group[player.team_id].name})</span>
                        )}
                        {(onlinePlayerIds.has(player.user_id)) && (
                            <UserLichessOnlineSymbol/>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const ActiveTournamentStandings: React.FC = () => {
    const {tournament} = useContext(ActiveTournamentContext);
    const playerStandings = useMemo(() => {
            const participatingPlayers = tournament.players.filter((player) => player.participating);

            return createPlayerStandings(participatingPlayers);
        },
        [tournament.players]
    );
    const columns: TableColumn<PlayerGroupStanding>[] = [{
        selector: (row) => row.groupRank.min,
        name: "Rank",
        sortable: true,
        center: true,
        sortFunction: (a, b) => b.groupRank.min - a.groupRank.min,
        format: (row) => <RankForPlayerGroup playerGroup={row}/>
    }, {
        selector: (row) => row.points,
        name: "Points",
        center: true,
        sortable: true,
        format: (row) => row.points
    }, {
        name: "Players",
        grow: 12,
        cell: (row) => <PlayersForPlayerGroup playerGroup={row}/>
    }];

    return (
        <DataTable<PlayerGroupStanding> data={playerStandings}
                                        columns={columns}
                                        keyField="id"
                                        noDataComponent="(No players)"/>
    );
};

export default ActiveTournamentStandings;
