import {isMatchStatusBye, Match} from "../models/Match";
import {UserInfo} from "../models/User";
import {TournamentRound} from "../models/TournamentRound";

export interface CrossTableResult {
    match: Match
    opponentId: UserInfo["id"] | "BYE"
    colour: "black" | "white" | "bye"
    winColour?: "black" | "white"
    byeColour?: "black" | "white"
    result: string
}

export type CrossTableResults = Record<number, Record<UserInfo["id"], CrossTableResult>>

// in the cross table, the rows show results for the game for the player in that row
export const classesForCrossTableResult = (result: CrossTableResult, colour: "black" | "white" | "bye") => {
    const classes = [];
    if (result?.winColour === colour) {
        classes.push("player-win");
    }
    if (colour === "black") {
        classes.push("bg-dark", "text-light");
    } else if (result?.opponentId === "BYE") {
        classes.push("bg-secondary", "text-light");
    }
    return classes.join(" ");
};

// in the player summary, the rows show the colour of the result for black and white
export const classesForCrossTablePlayerSummary = (result: CrossTableResult, colour: "black" | "white" | "bye") => {
    const classes = ["text-center", "w-100", "h-100", "py-3"];
    if (result?.winColour === colour) {
        classes.push("player-win");
    }
    if (colour === "black") {
        classes.push("bg-dark", "text-light");
    } else if (result?.byeColour === colour) {
        classes.push("bg-secondary", "text-light");
    }
    return classes.join(" ");
};

const opponentIdForMatch = (match: Match, colour: CrossTableResult["colour"]): UserInfo["id"] | "BYE" => {
    if (isMatchStatusBye(match)) {
        return "BYE";
    } else if (colour === "white") {
        return (match.black_player_id) ? match.black_player_id : "BYE";
    } else {
        return (match.white_player_id) ? match.white_player_id : "BYE";
    }
};

const byeColour = (match: Match) => {
    if (!match.white_player_id) {
        return "white";
    } else if (!match.black_player_id) {
        return "black";
    } else {
        console.log(`Match with bye but two players: white id ${match.white_player_id}, black ${match.black_player_id}`);
        // throw new Error(`Match with bye but two players: white id ${match.white_player_id}, black ${match.black_player_id}`);
    }
};

const matchStatus = (match: Match, colour: CrossTableResult["colour"]): CrossTableResult => {
    const opponentId = opponentIdForMatch(match, colour);

    switch (match.status) {
    case "HalfPointBye":
        return {match, opponentId, byeColour: byeColour(match), colour, result: "0.5"};
    case "FullPointBye":
        return {match, opponentId, byeColour: byeColour(match), colour, result: "1.0"};
    case "ZeroPointBye":
        return {match, opponentId, byeColour: byeColour(match), colour, result: "0.0"};
    case "WhiteWin":
        return {match, opponentId, colour, winColour: "white", result: (colour === "white") ? "Win" : "Loss"};
    case "BlackWin":
        return {match, opponentId, colour, winColour: "black", result: (colour === "black") ? "Win" : "Loss"};
    case "WhiteForfeit":
        return {match, opponentId, colour, result: (colour === "white") ? "Forfeit" : "Win"};
    case "BlackForfeit":
        return {match, opponentId, colour, result: (colour === "black") ? "Forfeit" : "Win"};
    case "DoubleForfeit":
        return {match, opponentId, colour, result: "Forfeit"};
    case "Draw":
        return {match, opponentId, colour, result: "Draw"};
    case "Pending":
    case "Playing":
        return {match, opponentId, colour, result: match.status};
    default:
        throw new Error(`matchStatus: unknown status ${match.status}`);
    }
};

export const playerResults = (matches: Match[]) => {
    const results: Record<UserInfo["id"], CrossTableResult> = {};

    matches.forEach((match) => {
        if (match.white_player_id === null) {
            if (match.black_player_id === null) {
                // strange, we have a match with no players?!?
                console.log(`playerResults: match (${match.id}) has no players!?`);
                return;
            }
            results[match.black_player_id] = matchStatus(match, "black");
        } else if (match.black_player_id === null) {
            results[match.white_player_id] = matchStatus(match, "white");
        } else {
            results[match.white_player_id] = matchStatus(match, "white");
            results[match.black_player_id] = matchStatus(match, "black");
        }
    });
    return results;
};

export const crossTableResultsForRounds = (rounds: TournamentRound[]): CrossTableResults => {
    const crossTableResults: CrossTableResults = {};

    rounds.forEach((round) => {
        crossTableResults[round.round_num] = playerResults(round.matches)
    });

    return crossTableResults;
};