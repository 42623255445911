import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import {useSearchUserInfosQuery} from "../../api/chessMasterApi";
import {UserInfo} from "../../models/User";
import {mergeUsingId} from "../../util/mergeUsingId";
import {formatFullName} from "../../util/formatFullName";
import GroupPicker from "../GroupPicker";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";
import {GroupMinSchema} from "../../models/Group";

export const userLabelKey = (ui: UserInfo) => `${formatFullName(ui)} (${ui.username})`;

interface Props {
    onChange: (users: UserInfo[]) => void
    selectedUserInfos: UserInfo[]
    isInvalid: boolean
}

const MultiPersonPicker: React.FC<Props> = ({onChange, selectedUserInfos, isInvalid}) => {
    const [query, setQuery] = useState("");
    const [userSearchError, setUserSearchError] = useState<string | null>(null);
    const [groupSearchError, setGroupSearchError] = useState<string | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<GroupMinSchema | undefined>(undefined);
    const {data: userInfos, isFetching, error: searchUserInfoError} = useSearchUserInfosQuery({query, group_id: selectedGroup?.id});
    const allUserInfos = useMemo(
        () => mergeUsingId(userInfos ?? [], selectedUserInfos),
        [selectedUserInfos, userInfos]
    );
    // the search is done phonetically so simple matching doesn't work, instead offer all
    // results except those that are already in the selected users.
    const filterByCallback = useCallback(
        (option: UserInfo): boolean => {
        return !selectedUserInfos.includes(option);
    }, [selectedUserInfos]);
    const formatSearchError = (userSearchError: string | null, groupSearchError: string | null) => {
        if (userSearchError && groupSearchError) {
            return `User: ${userSearchError}; group: ${groupSearchError}`;
        } else {
            return userSearchError || groupSearchError;
        }
    };

    useEffect(
        () => setUserSearchError((searchUserInfoError) ? formatErrorMessage(searchUserInfoError) : null),
        [searchUserInfoError]
    );

    return (
        <>
            <InputGroup className={(isInvalid || searchUserInfoError) ? "is-invalid" : ""}
                        hasValidation>
                <AsyncTypeahead<UserInfo> id="person-picker"
                                          filterBy={filterByCallback}
                                          minLength={2}
                                          isLoading={isFetching}
                                          onSearch={setQuery}
                                          options={allUserInfos}
                                          onChange={onChange}
                                          labelKey={userLabelKey}
                                          selected={selectedUserInfos}
                                          multiple/>
                <InputGroup.Text className="p-0 w-50">
                    <GroupPicker onChange={(group) => setSelectedGroup(group)}
                                 selectedGroup={selectedGroup}
                                 setErrorMessage={setGroupSearchError}/>
                </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
                {formatSearchError(userSearchError, groupSearchError)}
            </Form.Control.Feedback>
        </>
    );
};

export default MultiPersonPicker;