import React, {useMemo} from "react";

import {
    TournamentForUser,
    useChildrenForUserQuery,
    useTournamentsForUserQuery
} from "../../api/chessMasterApi";
import {formatFullName} from "../../util/formatFullName";
import {ChildCard} from "./ChildCard";
import {TournamentLite} from "../../models/Tournament";
import {UserInfo} from "../../models/User";

const makeUserToTournamentsMap = (tournamentsForUser: TournamentForUser[]) => {
    const map: Record<UserInfo["id"], TournamentLite[]> = {};

    tournamentsForUser.forEach((tfu) => {
        const userId = tfu.user.id;
        if (map[userId]) {
            map[userId].push(tfu.tournament);
        } else {
            map[userId] = [tfu.tournament];
        }
    });
    return map;
};

interface Props {
    guardianId: UserInfo["id"]
    query: string
}

const ChildList: React.FC<Props> = ({guardianId, query}) => {
    const {data: kids} = useChildrenForUserQuery({id: guardianId, query});
    const sortedKids = useMemo(
        () => [...kids ?? []].sort((c1, c2) => formatFullName(c1.child).localeCompare(formatFullName(c2.child))),
    [kids]
    );
    const {data: tournamentsForUser} = useTournamentsForUserQuery({userId: guardianId});
    const userToTournamentsMap = makeUserToTournamentsMap(tournamentsForUser ?? []);

    if (sortedKids.length === 0) {
        return <span className="text-muted">(No children)</span>;
    } else {
        return (
            <>
                {sortedKids.map((child, index) =>
                    <ChildCard key={index}
                               child={child.child}
                               tournaments={userToTournamentsMap[child.child.id]}
                               index={index}/>
                )}
            </>
        );
    }
};


export default ChildList;
