import React from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import {selectUserTab, setShowBulkUpload} from "./userTabSlice";
import UserBulkUploadFileCard from "./UserBulkUploadFileCard";
import UserBulkUploadFieldMappingCard from "./UserBulkUploadFieldMappingCard";
import UserBulkUploadGroupCard from "./UserBulkUploadGroupCard";
import {useAppSelector} from "../../store";
import {GroupMinSchema} from "../../models/Group";

const bulkUploadSchema = yup.object().shape({
    givenNameField: yup.string().required().label("Given name"),
    surnameField: yup.string().required().label("Surname"),
    eMailField: yup.string().label("E-mail"),
    csvFile: yup.string().required().label("CSV/XLS file")
});

export interface BulkUploadForm {
    surnameField: string;
    givenNameField: string;
    nameField: string;
    eMailField: string;
    method: "separateGivenNameSurname" | "combinedGivenNameSurnameCommaReversed";
    group: GroupMinSchema | null;
    csvFile: FileList | null;
}

const defaultValues: BulkUploadForm = {
    surnameField: 'surname',
    givenNameField: "given_name",
    nameField: "",
    eMailField: "",
    method: "separateGivenNameSurname",
    group: null,
    csvFile: null
};

const UserBulkUpload: React.FC = () => {
    const dispatch = useDispatch();
    const {showBulkUpload, bulkUploadPending, bulkUploadFile} = useAppSelector(selectUserTab);
    const formMethods = useForm<BulkUploadForm>({
        defaultValues,
        resolver: yupResolver(bulkUploadSchema)
    });
    const hideBulkUpload = () => {
        dispatch(setShowBulkUpload(false));
    };
    const onSubmit = (bulkUploadForm: BulkUploadForm) => {
        console.log("UserBulkUpload.onSubmit", bulkUploadForm);
        // const desc = {
        //     method: bulkUploadForm.method,
        //     group: bulkUploadForm.group,
        //     fields: {
        //         given_name: bulkUploadForm.givenNameField,
        //         surname: bulkUploadForm.surnameField,
        //         name: bulkUploadForm.nameField,
        //         e_mail: bulkUploadForm.eMailField
        //     },
        //     filename: bulkUploadForm.csvFile![0],
        //     file: bulkUploadFile
        // };
        // dispatch(api.userBulkUpload(desc));
        dispatch(setShowBulkUpload(false));     // XXX this should close when it succeeds
    };

    return (
        <Modal show={showBulkUpload}
               onHide={() => hideBulkUpload()}
               backdrop="static"
               size="lg">
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Header>
                        <Modal.Title>Bulk user upload</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <UserBulkUploadFieldMappingCard/>
                    </Modal.Body>

                    <Modal.Body>
                        <UserBulkUploadGroupCard/>
                    </Modal.Body>

                    <Modal.Body>
                        <UserBulkUploadFileCard/>
                    </Modal.Body>

                    <Modal.Footer>
                        <ButtonToolbar>
                            <ButtonGroup className="me-1">
                                <Button variant="secondary"
                                        onClick={() => hideBulkUpload()}
                                        disabled={bulkUploadPending}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button type="submit"
                                        variant="primary"
                                        disabled={!bulkUploadFile || bulkUploadPending}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default UserBulkUpload;
