import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import DataTable, {TableColumn} from "react-data-table-component";

import Button from "react-bootstrap/Button";

import {chessMasterApi, useSearchGroupsQuery} from "../../api/chessMasterApi";
import {selectConstantsGroupTypeById} from "../constants/constantsSlice";
import {editGroup} from "./groupsTabSlice";
import {Group} from "../../models/Group";
import {DisplayAddress} from "../../components/DisplayAddress";
import {GroupType} from "../../models/GroupType";
import {BsFillPeopleFill} from "react-icons/bs";
import GroupMembershipAddUsersModal from "./GroupMembershipAddUsersModal";
import {useAppSelector} from "../../store";
import {SearchErrorMessageContext} from "../../components/SearchErrorMessageContext";
import {formatErrorMessage} from "../../middleware/RTKQueryErrorLogger";

const GroupName: React.FC<{group: Group}> = ({group}) => {
    const dispatch = useDispatch();
    const [lazyGetGroup] = chessMasterApi.endpoints.getGroup.useLazyQuery();

    return (
        <Button variant="link"
                className="p-0 text-start"
                onClick={async () => {
                    lazyGetGroup({id: group.id})
                        .unwrap()
                        .then((group) => dispatch(editGroup(group)));
                }}>
            {group.name}
        </Button>
    );
};

const GroupNameCell: React.FC<{group: Group}> = ({group}) => (
    <div>
        <GroupName group={group}/>
        <br/>
        <small>
            ({group.user_ids.length} {(group.user_ids.length === 1) ? "member" : "members"})
        </small>
    </div>
);

export const GroupList: React.FC<{query: string, groupTypeId: GroupType["id"]}> = ({query, groupTypeId}) => {
    const [runEditGroupMembers, setRunEditGroupMembers] = useState<Group | undefined>(undefined);
    const {setSearchErrorMessage} = useContext(SearchErrorMessageContext);
    const [currentPage] = useState<number>(1);
    const [sizePerPage] = useState<number>(10);
    const {data: groups, isFetching, error} = useSearchGroupsQuery({query, group_type_id: groupTypeId, offset: (currentPage - 1) * sizePerPage, limit: sizePerPage * 100, editable: true});
    const groupTypeById = useAppSelector(selectConstantsGroupTypeById);
    const columns: TableColumn<Group>[] = [{
        name: "Name",
        sortable: true,
        selector: (row) => row.name,
        grow: 2,
        cell: (row) => <GroupNameCell group={row}/>
    }, {
        name: "Members",
        maxWidth: "4rem",
        center: true,
        cell: (row) => (
            <Button variant="link" className="fs-3"
                    onClick={() => setRunEditGroupMembers(row)}>
                <BsFillPeopleFill/>
            </Button>
        )
    }, {
        selector: (row) => row.description,
        name: "Description",
        grow: 2
    }, {
        selector: (row) => row.url,
        name: "URL",
        cell: (row) => (row.url) && (
            <a href={(row.url.startsWith("http")) ? row.url : `https://${row.url}`}
               target="_blank"
               rel="noreferrer">
               {row.url}
            </a>
        )
    }, {
        name: "Group type",
        grow: 1,
        sortable: true,
        selector: (row) => groupTypeById[row.group_type_id]?.description,
    }, {
        name: "Address",
        grow: 2,
        cell: (row) => <DisplayAddress address={row.address}/>
    }, {
        name: "Postal address",
        grow: 2,
        cell: (row) => <DisplayAddress address={row.postal_address}/>
    }];

    useEffect(
        () => setSearchErrorMessage?.((error) ? formatErrorMessage(error) : null),
        [error, setSearchErrorMessage]
    );

    return (
        <>
            {(runEditGroupMembers) && (
                <GroupMembershipAddUsersModal group={runEditGroupMembers}
                                              onClose={() => setRunEditGroupMembers(undefined)}/>
            )}
            <DataTable<Group> data={groups ?? []}
                              columns={columns}
                              keyField="id"
                              noDataComponent="(No groups)"
                              progressPending={isFetching}
                              pagination/>
        </>
    );
};